import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class CargoSettlesActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        cargoItemInfo:paramsPage.currCargoItem?paramsPage.currCargoItem:{},
        sonsData:[],
      };
    }
    componentDidMount(){}

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:8,paddingRight:8,backgroundColor:'#FFF',display:'flex',flexDirection:'row'}}>
                <div style={{width:'40%',height:'100%',backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{fontSize:16,marginLeft:10}}>{stringUtil.language(1084)}：</span>
                        <span style={{width:0,flexGrow:1,fontSize:16,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.cargoItemInfo.targetName?this.state.cargoItemInfo.targetName:this.state.cargoItemInfo.cargoMode==1?stringUtil.language(426):''} {stringUtil.language(1237,this.state.cargoItemInfo.cargoNo)}</span>
                    </div>
                    <div style={{width:'100%',height:0,flexGrow:1,overflow:'auto'}}>
                        {(this.state.cargoItemInfo&&this.state.cargoItemInfo.settles?this.state.cargoItemInfo.settles:[]).map((item,key)=>{
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth:1}} key={key}>
                                <span style={{width:0,flexGrow:1,color:'#333',fontSize:15,marginLeft:7}}>{arithUtil.settleSub(arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m'),1)}</span>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{color:'#333',fontSize:12,marginTop:3}}>{stringUtil.language(1241)}</span>
                                    <span style={{color:'#498C45',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.money)}</span>
                                    <span style={{color:'#333',fontSize:12,marginTop:3}}>{stringUtil.currency(1)}</span>
                                </div>
                                {this.state.cargoItemInfo.state==1?<div style={{width:50,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currEditItem=JSON.parse(JSON.stringify(item));
                                    this.state.currEditKey=key;
                                    this.setState({delShowPop:true});
                                }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#F00'}}>&#xe612;</i>
                                </div>:''}
                            </div>)
                        })}
                    </div>
                    {this.state.cargoItemInfo.state==1?<div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderTopStyle:'solid',borderTopColor:'#DCDCDC',borderTopWidth:1}}>
                        <div style={{width:'85%',height:40,borderRadius:5,backgroundColor:'#009A61',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.settleInfo={time:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),money:0};
                            this.setState({addShowPop:true});
                        }}><span style={{color:'#FFF',fontSize:17,fontWeight:'bold'}}>{stringUtil.language(1129)}</span></div>
                    </div>:''}
                </div>
                {this.state.addShowPop&&this.state.settleInfo?<div style={{width:0,flexGrow:1,height:'100%',backgroundColor:'#FFF',display:'flex',flexDirection:'column',borderLeftStyle:'solid',borderLeftColor:'#A9A9A9',borderLeftWidth:1}}>
                    <div style={{height:60,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:100,fontSize:16,textAlign:'center'}}>{stringUtil.language(1241)+stringUtil.language(1039)}:</span>
                        <div><DatePicker locale='zhCN' selected={new Date(this.state.settleInfo.time)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                                this.state.settleInfo.time = date.valueOf();
                                this.setState({settleInfo:this.state.settleInfo});
                            }} />
                        </div>
                    </div>
                    <div style={{height:60,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:100,fontSize:16,textAlign:'center'}}>{stringUtil.language(1241)+stringUtil.language(1041)}:</span>
                        <input style={{width:250,height:35,borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:3,padding:0,textAlign:'center'}} type='number' onChange={(event)=>{
                            this.state.settleInfo.money=event.target.value?arithUtil.yuanToFen(event.target.value):0;
                        }} placeholder='0'/>
                        <span style={{fontSize:16}}>{stringUtil.currency(1)}</span>
                    </div>
                    <div style={{width:350,height:60,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{width:'80%',height:35,borderRadius:5,backgroundColor:'#0FA771',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if (!this.state.settleInfo||!this.state.settleInfo.money) {
                                ToastBox.error(stringUtil.language(190));return;
                            }
                            if(this.state.isVisiblity){return;}
                            this.state.isVisiblity = true;
                            stringUtil.httpRequest(urlUtil.SALE_CARGO_SETTLE_URL+'/'+this.state.cargoItemInfo.id,{
                                time:this.state.settleInfo.time,
                                money:this.state.settleInfo.money,
                            },['key','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }else{this.state.isVisiblity=false;ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{this.state.isVisiblity=false;ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}><span style={{color:'#FFF',fontSize:17}}>{stringUtil.language(1144)}</span></div>
                    </div>
                </div>:<div style={{width:0,flexGrow:1,height:'100%',backgroundColor:'#FFF'}}></div>}
            </div>
            <MaskModal visible={this.state.delShowPop?true:false} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <span>{stringUtil.language(505)+stringUtil.language(1241)}？</span>
                </div>}
                okBnEvents={(event) => {
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_SETTLE_URL+'/'+this.state.cargoItemInfo.id,{id:this.state.currEditItem.id},['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.state.cargoItemInfo.settles.splice(this.state.currEditKey,1);
                            this.setState({delShowPop:false});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        </div>)
    }
}

const styles = {};