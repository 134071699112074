import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(106)}
 export default class ProcessStatisticsActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            warehouseId:null,
            dateTimeStr:arithUtil.getCurrDateTime(1),
        },
        processSettleVO:{},
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = successResponse.data;
                if(this.state.warehousesAll&&this.state.warehousesAll.length>0){
                    this.state.searchs.warehouseId = this.state.warehousesAll[0].id;
                    this.state.searchs.warehouseName = this.state.warehousesAll[0].name;
                    this.initData();
                }
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_PROCESS_SETTLE_URL,{
            warehouseId:this.state.searchs.warehouseId,
            date:this.state.searchs.dateTimeStr,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({processSettleVO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.dateTimeStr)} onChange={(date) => {
                                this.state.searchs.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(15)}}>
                            <span style={{width:50}}>{stringUtil.language(892)}</span>
                            <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                                this.state.searchs.warehouseName = JSON.parse(event.target.value).name;
                                this.initData();
                            }}>
                            {(this.state.warehousesAll?this.state.warehousesAll:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </select>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="9"><b><font size="3">${stringUtil.language(106)}（${this.state.searchs.warehouseName}${this.state.searchs.dateTimeStr}）</font></b></td></tr>
                                <tr><td align="left"><b><font size="3">${stringUtil.language(549)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(550)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(555)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(551)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(556)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(557)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(558)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(567)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(568)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td></tr>`);
                            let totalInfo={collectNumber:0,collectWeight:0,lossNumber:0,lossWeight:0,surplusNumber:0,surplusWeight:0,useNumber:0,useWeight:0};
                            for(let i=0;i<(this.state.processSettleVO.commodities?this.state.processSettleVO.commodities:[]).length;i++){
                                let item = this.state.processSettleVO.commodities[i];
                                totalInfo.collectNumber=totalInfo.collectNumber+item.collectNumber;
                                totalInfo.collectWeight=(totalInfo.collectWeight).add(item.collectWeight);
                                totalInfo.lossNumber=totalInfo.lossNumber+item.lossNumber;
                                totalInfo.lossWeight=(totalInfo.lossWeight).add(item.lossWeight);
                                totalInfo.surplusNumber=totalInfo.surplusNumber+item.surplusNumber;
                                totalInfo.surplusWeight=(totalInfo.surplusWeight).add(item.surplusWeight);
                                totalInfo.useNumber=totalInfo.useNumber+item.useNumber;
                                totalInfo.useWeight=(totalInfo.useWeight).add(item.useWeight);
                                resArr.push(`<tr><td align="left"><font size="3">${item.commodityName}</font></td>
                                    <td align="left"><font size="3">${item.collectNumber+item.commodityUnitMaster}</font></td>
                                    <td align="left"><font size="3">${arithUtil.keToWeight(item.collectWeight,this.state.weightMode)}</font></td>
                                    <td align="left"><font size="3">${item.lossNumber+item.commodityUnitMaster}</font></td>
                                    <td align="left"><font size="3">${arithUtil.keToWeight(item.lossWeight,this.state.weightMode)}</font></td>
                                    <td align="left"><font size="3">${item.surplusNumber+item.commodityUnitMaster}</font></td>
                                    <td align="left"><font size="3">${arithUtil.keToWeight(item.surplusWeight,this.state.weightMode)}</font></td>
                                    <td align="left"><font size="3">${item.useNumber+item.commodityUnitMaster}</font></td>
                                    <td align="left"><font size="3">${arithUtil.keToWeight(item.useWeight,this.state.weightMode)}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left"><font size="3">${stringUtil.language(890)}</font></td>
                                <td align="left"><font size="3">${totalInfo.collectNumber}</font></td>
                                <td align="left"><font size="3">${arithUtil.keToWeight(totalInfo.collectWeight,this.state.weightMode)}</font></td>
                                <td align="left"><font size="3">${totalInfo.lossNumber}</font></td>
                                <td align="left"><font size="3">${arithUtil.keToWeight(totalInfo.lossWeight,this.state.weightMode)}</font></td>
                                <td align="left"><font size="3">${totalInfo.surplusNumber}</font></td>
                                <td align="left"><font size="3">${arithUtil.keToWeight(totalInfo.surplusWeight,this.state.weightMode)}</font></td>
                                <td align="left"><font size="3">${totalInfo.useNumber}</font></td>
                                <td align="left"><font size="3">${arithUtil.keToWeight(totalInfo.useWeight,this.state.weightMode)}</font></td></tr>`);
                            resArr.push(`<tr></tr><tr></tr><tr><td align="left"><b><font size="3">${stringUtil.language(953)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(569)}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(570)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(947)}【${stringUtil.currency(1)}】</font></b></td>
                                <td align="left"><b><font size="3">${stringUtil.language(559)}【${stringUtil.currency(1)}/${stringUtil.language(1173)}】</font></b></td></tr>`);
                            let totalFinishInfo={number:0,weight:0,money:0};
                            for(let i=0;i<(this.state.processSettleVO.finishCommodities?this.state.processSettleVO.finishCommodities:[]).length;i++){
                                let item = this.state.processSettleVO.finishCommodities[i];
                                totalFinishInfo.number=totalFinishInfo.number+item.number;
                                totalFinishInfo.weight=(totalFinishInfo.weight).add(item.weight);
                                totalFinishInfo.money=(totalFinishInfo.money).add(item.money);
                                resArr.push(`<tr><td align="left"><font size="3">${item.commodityName}</font></td>
                                    <td align="left"><font size="3">${item.number+item.commodityUnitMaster}</font></td>
                                    <td align="left"><font size="3">${arithUtil.keToWeight(item.weight,this.state.weightMode)}</font></td>
                                    <td align="left"><font size="3">${arithUtil.fenToYuan(item.money)}</font></td>
                                    <td align="left"><font size="3">${arithUtil.fenToYuan((item.money).div(item.number))}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left"><font size="3">${stringUtil.language(890)}</font></td>
                                <td align="left"><font size="3">${totalFinishInfo.number}</font></td>
                                <td align="left"><font size="3">${arithUtil.keToWeight(totalFinishInfo.weight,this.state.weightMode)}</font></td>
                                <td align="left"><font size="3">${arithUtil.fenToYuan(totalFinishInfo.money)}</font></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),stringUtil.language(106)+'('+this.state.searchs.warehouseName+this.state.searchs.dateTimeStr+')');
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(106)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.dateTimeStr}</span>
                    </div>:''}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <div style={{width:'65%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'20%',fontSize:12,textAlign:'center'}}>{stringUtil.language(954)}</span>
                            <span style={{width:'22%',fontSize:12,textAlign:'center'}}>{stringUtil.language(550)}</span>
                            <span style={{width:'16%',fontSize:12,textAlign:'center'}}>{stringUtil.language(551)}</span>
                            <span style={{width:'21%',fontSize:12,textAlign:'center'}}>{stringUtil.language(552)}</span>
                            <span style={{width:'21%',fontSize:12,textAlign:'center'}}>{stringUtil.language(553)}</span>
                        </div>
                        <div style={{width:'35%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'20%',fontSize:12,textAlign:'center'}}>{stringUtil.language(953)}</span>
                            <span style={{width:'33%',fontSize:12,textAlign:'center'}}>{stringUtil.language(569)}</span>
                            <span style={{width:'24%',fontSize:12,textAlign:'center'}}>{stringUtil.language(947)}</span>
                            <span style={{width:'23%',fontSize:12,textAlign:'center'}}>{stringUtil.language(559)}</span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <div style={{width:'65%'}}>
                            {(()=>{this.state.totalInfo={collectNumber:0,collectWeight:0,lossNumber:0,lossWeight:0,surplusNumber:0,surplusWeight:0,useNumber:0,useWeight:0}})()}
                            {this.state.processSettleVO.commodities?this.state.processSettleVO.commodities.map((item,key)=>{
                                this.state.totalInfo.collectNumber=this.state.totalInfo.collectNumber+item.collectNumber;
                                this.state.totalInfo.collectWeight=(this.state.totalInfo.collectWeight).add(item.collectWeight);
                                this.state.totalInfo.lossNumber=this.state.totalInfo.lossNumber+item.lossNumber;
                                this.state.totalInfo.lossWeight=(this.state.totalInfo.lossWeight).add(item.lossWeight);
                                this.state.totalInfo.surplusNumber=this.state.totalInfo.surplusNumber+item.surplusNumber;
                                this.state.totalInfo.surplusWeight=(this.state.totalInfo.surplusWeight).add(item.surplusWeight);
                                this.state.totalInfo.useNumber=this.state.totalInfo.useNumber+item.useNumber;
                                this.state.totalInfo.useWeight=(this.state.totalInfo.useWeight).add(item.useWeight);
                                return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:'20%',fontSize:16}}>{item.commodityName}</span>
                                    <span style={{width:'9%',fontSize:16,textAlign:'center'}}>{item.collectNumber+item.commodityUnitMaster}</span>
                                    <span style={{width:'13%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.collectWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                    <span style={{width:'7%',fontSize:16,textAlign:'center'}}>{item.lossNumber+item.commodityUnitMaster}</span>
                                    <span style={{width:'9%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.lossWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                    <span style={{width:'9%',fontSize:16,textAlign:'center'}}>{item.surplusNumber+item.commodityUnitMaster}</span>
                                    <span style={{width:'12%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.surplusWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                    <span style={{width:'9%',fontSize:16,textAlign:'center'}}>{item.useNumber+item.commodityUnitMaster}</span>
                                    <span style={{width:'12%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.useWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                </div>)
                            }):''}
                        </div>
                        <div style={{width:'35%'}}>
                            {(()=>{this.state.totalFinishInfo={number:0,weight:0,money:0}})()}
                            {this.state.processSettleVO.finishCommodities?this.state.processSettleVO.finishCommodities.map((item,key)=>{
                                this.state.totalFinishInfo.number=this.state.totalFinishInfo.number+item.number;
                                this.state.totalFinishInfo.weight=(this.state.totalFinishInfo.weight).add(item.weight);
                                this.state.totalFinishInfo.money=(this.state.totalFinishInfo.money).add(item.money);
                                return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={{width:'20%',fontSize:16}}>{item.commodityName}</span>
                                    <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{item.number+item.commodityUnitMaster}</span>
                                    <span style={{width:'23%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                    <span style={{width:'24%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                    <span style={{width:'23%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan((item.money).div(item.number))}</span>
                                </div>)
                            }):''}
                        </div>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{width:'65%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'20%',fontSize:16,color:'#D89573'}}>{stringUtil.language(890)}</span>
                            <span style={{width:'9%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.collectNumber}</span>
                            <span style={{width:'13%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.collectWeight,this.state.weightMode)}</span>
                            <span style={{width:'7%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.lossNumber}</span>
                            <span style={{width:'9%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.lossWeight,this.state.weightMode)}</span>
                            <span style={{width:'9%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.surplusNumber}</span>
                            <span style={{width:'12%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.surplusWeight,this.state.weightMode)}</span>
                            <span style={{width:'9%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.useNumber}</span>
                            <span style={{width:'12%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.useWeight,this.state.weightMode)}</span>
                        </div>
                        <div style={{width:'35%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'20%',fontSize:16,textAlign:'center'}}></span>
                            <span style={{width:'10%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalFinishInfo.number}</span>
                            <span style={{width:'23%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalFinishInfo.weight,this.state.weightMode)}</span>
                            <span style={{width:'24%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalFinishInfo.money)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};