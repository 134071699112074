import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

//{stringUtil.language(282)}
 export default class BuyerStatisticsActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            targetType:-1,//对象stringUtil.language(1135)-1stringUtil.language(1097)
            targetId:-1,//对象stringUtil.language(1155)-1stringUtil.language(1097)
            buyerId:-1,
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),//起始stringUtil.language(1039)0stringUtil.language(1097)
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),//截止stringUtil.language(1039)0stringUtil.language(1097)
            targetName:'stringUtil.language(425)',
        },
        buyerStatistics:{},
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        buyersAll:[],
        buyerSettleDO:[],
        personsAll:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll = [];
                this.state.personsAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(425)});
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.buyersAll=successResponse.data;
                this.state.buyersAll.unshift({id:-1,code:'',name:stringUtil.language(281)});
                this.setState({buyersAll:this.state.buyersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.getDataList();
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_BUYER_SETTLE_URL,{
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            buyerId:this.state.searchs.buyerId,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),//arithUtil.formatToTimeStr(new Date(),'Y-M-D'),
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.buyerStatistics = successResponse.data;
                this.state.totalNum = this.state.buyerStatistics.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({buyerSettleDO:JSON.parse(JSON.stringify(this.state.buyerStatistics.items))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
      var _this= this;
      _this.setState({goValue : e.target.value})
      var value = e.target.value;
      if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
      else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
      else _this.pageClick(value);
    }
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1039)}:</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'),0);
                                this.getDataList();
                            }} /></div> {stringUtil.language(1174)} <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'));
                                this.getDataList();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span>{stringUtil.language(1047)}:</span><Select showSearch defaultValue={stringUtil.language(425)} style={{width:150,height:30}} onChange={(value)=>{
                                this.state.searchs.targetType = JSON.parse(value).targetType;
                                this.state.searchs.targetId = JSON.parse(value).targetId;
                                this.state.searchs.targetName = JSON.parse(value).noteName;
                                this.getDataList();
                            }}
                            >
                            {this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span>{stringUtil.language(427)}</span><select style={{width:150,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.buyerId = JSON.parse(event.target.value).id;
                                this.getDataList();
                            }}>
                            <option style={{display:'none'}}></option>
                            {this.state.buyersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </select>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(282)}</span>
                        <span style={{fontSize:17}}>{arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D')+' '+stringUtil.language(1174)+' '+arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D')}</span>
                    </div>:''}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'14%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1067)}</span>
                        <span style={{width:'16%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1048)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(428)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                        <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{stringUtil.language(891)}</span>
                    </div>
                    {this.state.buyerSettleDO.map((item,key)=>{
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'14%',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m')}</span>
                            <span style={{width:'16%',fontSize:12}}>{stringUtil.subStr(item.customerName,8)}</span>
                            <span style={{width:'12%',fontSize:12}}>{item.commodityName}</span>
                            <span style={{width:'12%',fontSize:12}}>{item.targetType==1?stringUtil.language(426)+(item.targetName?'_'+item.targetName:''):item.targetName}</span>
                            <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{item.number}</span>
                            <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{item.mode==0?(arithUtil.fenToYuan(item.money)).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2):(arithUtil.fenToYuan(item.money)).div(item.number)}</span>
                            <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                            <span style={{width:'12%',fontSize:12,textAlign:'center'}}>{item.buyerName?item.buyerName:''}</span>
                        </div>)
                    })}
                    <span style={{height:'40px',lineHeight:'40px',color:'#F00',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(283)}{arithUtil.fenToYuan(this.state.buyerStatistics.money)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};