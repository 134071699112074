import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';

 //{stringUtil.language(239)}
 export default class InOutWarehouseActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            warehouseId:-1,
            warehouseName:stringUtil.language(453),
            commodityName:'',
            targetType:-1,
            targetId:-1,
            targetName:stringUtil.language(517),
            cargoId:'',
            cargoNo:stringUtil.language(1097),
            dateTimeStr:arithUtil.getCurrDateTime(1),
        },
        owners:[{targetType:-1,targetId:-1,targetName:stringUtil.language(517),cargoId:'',cargoNo:stringUtil.language(1097)}],
        stockCommodityDO:[],stockCommodityDODatas:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = successResponse.data;
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.initData();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SALE_STOCK_COMMODITY_DATE_URL,{
            date:this.state.searchs.dateTimeStr,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.stockCommodityDO=successResponse.data;
                if(this.state.stockCommodityDO.length>0&&this.state.owners.length<2){//整理stringUtil.language(1034)条stringUtil.language(1173)的数据
                    for(let i=0;i<this.state.stockCommodityDO.length;i++){
                        let dataTemp = this.state.stockCommodityDO[i];
                        let isHavaOwnerCargo=0;
                        for(let j=0;j<this.state.owners.length;j++){
                            let ownerTemp = this.state.owners[j];
                            if(dataTemp.targetType==ownerTemp.targetType&&dataTemp.targetId==ownerTemp.targetId&&dataTemp.cargoId==ownerTemp.cargoId){
                                isHavaOwnerCargo=1;break;
                            }
                        }
                        if(!isHavaOwnerCargo)this.state.owners.push({
                            targetType:dataTemp.targetType,
                            targetId:dataTemp.targetId,
                            targetName:dataTemp.targetName,
                            cargoId:dataTemp.cargoId,
                            cargoNo:dataTemp.cargoNo
                        });
                    }
                }
                this.searchsData();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchsData(){
        this.state.stockCommodityDODatas=[];
        for(let i=0;i<this.state.stockCommodityDO.length;i++){//stringUtil.language(1034)
            let dataTemp = this.state.stockCommodityDO[i];
            if(this.state.searchs.warehouseId==-1||dataTemp.warehouseId==this.state.searchs.warehouseId){//stringUtil.language(1064)
                if((this.state.searchs.targetType==-1&&this.state.searchs.targetId==-1&&this.state.searchs.cargoId=='')||(dataTemp.targetType==this.state.searchs.targetType&&dataTemp.targetId==this.state.searchs.targetId&&dataTemp.cargoId==this.state.searchs.cargoId)){//stringUtil.language(519)
                    if((dataTemp.commodityName).indexOf(this.state.searchs.commodityName)!=-1){//stringUtil.language(1020)
                        this.state.stockCommodityDODatas.push(dataTemp);
                    }
                }
            }
        }
        this.setState({stockCommodityDODatas:this.state.stockCommodityDODatas});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.dateTimeStr)} onChange={(date) => {
                                this.state.searchs.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(892)}</span>
                            <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                                this.state.searchs.warehouseName = JSON.parse(event.target.value).name;
                                this.searchsData();
                            }}>
                            {(this.state.warehousesAll?this.state.warehousesAll:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(856)}</span>
                            <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.targetType = JSON.parse(event.target.value).targetType;
                                this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                                this.state.searchs.targetName = JSON.parse(event.target.value).targetName;
                                this.state.searchs.cargoId = JSON.parse(event.target.value).cargoId;
                                this.state.searchs.cargoNo = JSON.parse(event.target.value).cargoNo;
                                this.searchsData();
                            }}>
                            {(this.state.owners?this.state.owners:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,5):''):stringUtil.subStr(item.targetName,7)} {stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{width:200,height:30,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <SearchInput onChangeEvents={(value)=>{
                                this.state.searchs.commodityName = value;
                                this.searchsData();
                            }} placeholder={stringUtil.language(35)}/>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(150)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.dateTimeStr}</span>
                    </div>:''}
                    <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'8%',height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(1064)}</span>
                        <span style={{width:'8%',height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(518)}</span>
                        <span style={{width:'14%',height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:12,textAlign:'center'}}>{stringUtil.language(519)}</span>
                        <div style={{width:'17.5%',height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(520)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'33%',fontSize:10,textAlign:'center'}}>{stringUtil.language(932)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(933)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            </div>
                        </div>
                        <div style={{width:'17.5%',height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(521)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'33%',fontSize:10,textAlign:'center'}}>{stringUtil.language(932)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(933)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            </div>
                        </div>
                        <div style={{width:'17.5%',height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>今{stringUtil.language(1179)}{stringUtil.language(1194)}库</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'33%',fontSize:10,textAlign:'center'}}>{stringUtil.language(932)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(933)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            </div>
                        </div>
                        <div style={{width:'17.5%',height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:12,textAlign:'center'}}>{stringUtil.language(483)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'33%',fontSize:10,textAlign:'center'}}>{stringUtil.language(932)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(933)}</span>
                                <span style={{width:'33.5%',fontSize:10,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            </div>
                        </div>
                    </div>
                    {(()=>{this.state.totalInfo={beforeNumberScale:0,beforeNumber:0,beforeWeight:0,inNumberScale:0,inNumber:0,inWeight:0,
                        outNumberScale:0,outNumber:0,outWeight:0,numberScale:0,number:0,weight:0}})()}
                    {this.state.stockCommodityDODatas.map((item,key)=>{
                        this.state.totalInfo.beforeNumberScale=this.state.totalInfo.beforeNumberScale+(item.beforeNumber).div(item.unitScale,1);
                        this.state.totalInfo.beforeNumber=this.state.totalInfo.beforeNumber+item.beforeNumber;
                        this.state.totalInfo.beforeWeight=(this.state.totalInfo.beforeWeight).add(item.beforeWeight);
                        this.state.totalInfo.inNumberScale=this.state.totalInfo.inNumberScale+(item.inNumber).div(item.unitScale,1);
                        this.state.totalInfo.inNumber=this.state.totalInfo.inNumber+item.inNumber;
                        this.state.totalInfo.inWeight=(this.state.totalInfo.inWeight).add(item.inWeight);
                        this.state.totalInfo.outNumberScale=this.state.totalInfo.outNumberScale+(item.outNumber).div(item.unitScale,1);
                        this.state.totalInfo.outNumber=this.state.totalInfo.outNumber+item.outNumber;
                        this.state.totalInfo.outWeight=(this.state.totalInfo.outWeight).add(item.outWeight);
                        this.state.totalInfo.numberScale=this.state.totalInfo.numberScale+(item.number).div(item.unitScale,1);
                        this.state.totalInfo.number=this.state.totalInfo.number+item.number;
                        this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14}}>{stringUtil.subStr(item.warehouseName,6)}</span>
                            <span style={{width:'8%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14}}>{stringUtil.subStr(item.commodityName,7)}</span>
                            <span style={{width:'14%',height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:14}}>{(item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+(stringUtil.subStr(item.targetName,4)):''):stringUtil.subStr(item.targetName,6))+' '+stringUtil.subStr(item.cargoNo,5)+stringUtil.language(1176)}</span>
                            <div style={{width:'17.5%'}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'33%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{(item.beforeNumber).div(item.unitScale,1)}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.beforeNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{arithUtil.keToWeight(item.beforeWeight,this.state.weightMode)}</span><span style={{fontSize:8,paddingTop:4}}>{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}</span>
                                </div>
                            </div></div>
                            <div style={{width:'17.5%'}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'33%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{(item.inNumber).div(item.unitScale,1)}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.inNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{arithUtil.keToWeight(item.inWeight,this.state.weightMode)}</span><span style={{fontSize:8,paddingTop:4}}>{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}</span>
                                </div>
                            </div></div>
                            <div style={{width:'17.5%'}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'33%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{(item.outNumber).div(item.unitScale,1)}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.outNumber}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{arithUtil.keToWeight(item.outWeight,this.state.weightMode)}</span><span style={{fontSize:8,paddingTop:4}}>{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}</span>
                                </div>
                            </div></div>
                            <div style={{width:'17.5%'}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'33%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{(item.number).div(item.unitScale,1)}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitSlave?item.unitSlave:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{item.number}</span><span style={{fontSize:8,paddingTop:4}}>{item.unitMaster?item.unitMaster:stringUtil.language(1173)}</span>
                                </div>
                                <div style={{width:'33.5%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:14}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span><span style={{fontSize:8,paddingTop:4}}>{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}</span>
                                </div>
                            </div></div>
                        </div>)
                    })}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'30%',fontSize:14,color:'#D89573'}}>{stringUtil.language(890)}</span>
                        <div style={{width:'17.5%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:'33%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeNumberScale}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeNumber}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.beforeWeight,this.state.weightMode)}</span>
                        </div>
                        <div style={{width:'17.5%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:'33%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.inNumberScale}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.inNumber}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.inWeight,this.state.weightMode)}</span>
                        </div>
                        <div style={{width:'17.5%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:'33%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.outNumberScale}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.outNumber}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.outWeight,this.state.weightMode)}</span>
                        </div>
                        <div style={{width:'17.5%',display:'flex',flexDirection:'row'}}>
                            <span style={{width:'33%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.numberScale}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.number}</span>
                            <span style={{width:'34%',fontSize:14,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};