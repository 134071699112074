import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabSettingStallWarehouse extends Component{
  constructor(props){
    super(props);
    this.state={
      warehousesAll:[],
      currWarehouseItem:{},
      addShowPop:false,
      editShowPop:false,
      delShowPop:false,
    };
  }
  componentDidMount(){
      this.initWarehouses();
  }
  initWarehouses(){
      stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({warehousesAll:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
            <span style={{width:'14%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1104)}</span>
            <span style={{width:'55%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1089)}</span>
            <span style={{width:'31%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{height:'87%',borderColor:'#F0F0F0',borderStyle:'solid',borderWidth: 1,overflow:'auto'}}>
            {this.state.warehousesAll.map((item,key)=>{
                return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:'14%',textAlign:'center'}}>{key+1}</span>
                    <span style={{width:'55%'}}>{item.name}</span>
                    <div style={{width:'31%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        {otherUtil.authorityFindCode("011302")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.currWarehouseItem = {id:item.id,name:item.name};
                            this.setState({editShowPop:!this.state.editShowPop});
                        }}>&#xe63a;</i>:<div style={{position:'absolute'}}/>}
                        {otherUtil.authorityFindCode("011303")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currWarehouseItem = {id:item.id,name:item.name};
                            this.setState({delShowPop:!this.state.delShowPop});
                        }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                    </div>
                </div>)
            })}
        </div>
        {otherUtil.authorityFindCode("011301")!=null?<div style={{height:'8%',display:'flex',flexDirection:'row',alignItems:'center'}}>
            <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                   this.state.currWarehouseItem = {};
                   this.setState({addShowPop:!this.state.addShowPop});
                }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#FFF'}}>&#xe601;</i>&nbsp;&nbsp;{stringUtil.language(1064)}</span>
        </div>:<div style={{position:'absolute'}}/>}
      <MaskModal visible={this.state.addShowPop} title={stringUtil.language(791)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.35)}
          content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                    {stringUtil.language(1089)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} placeholder={stringUtil.language(174)} onChange={(event)=>{
                      this.state.currWarehouseItem.name = event.target.value;
                    }}/>
                </div>
            </div>}
          okBnEvents={(event) => {
            if (typeof(this.state.currWarehouseItem.name)=='undefined' || this.state.currWarehouseItem.name==null || this.state.currWarehouseItem.name=='') {
                ToastBox.error(stringUtil.language(107));return;
            }
            this.setState({addShowPop: false});
            stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,{name:this.state.currWarehouseItem.name},['key','post',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.initWarehouses();
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({addShowPop: false})}}/>
        <MaskModal visible={this.state.editShowPop} title={stringUtil.language(792)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.35)}
          content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currWarehouseItem.id}>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                    {stringUtil.language(1089)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} defaultValue={this.state.currWarehouseItem.name} placeholder={stringUtil.language(174)} onChange={(event)=>{
                      this.state.currWarehouseItem.name = event.target.value;
                    }}/>
                </div>
            </div>}
          okBnEvents={(event) => {
            if (typeof(this.state.currWarehouseItem.name)=='undefined' || this.state.currWarehouseItem.name==null || this.state.currWarehouseItem.name=='') {
                ToastBox.error(stringUtil.language(107));return;
            }
            this.setState({editShowPop: false});
            stringUtil.httpRequest(urlUtil.WAREHOUSE_URL+'/'+this.state.currWarehouseItem.id,{name:this.state.currWarehouseItem.name},['key','put',1],(successResponse)=>{
                if (successResponse.status==200) {
                    ToastBox.success(stringUtil.language(388));
                    this.initWarehouses();
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
          }} closeBnEvents={() => {this.setState({editShowPop: false})}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(180)}？
                    </div>}
            okBnEvents={(event) => {
                this.setState({delShowPop: false});
                stringUtil.httpRequest(urlUtil.WAREHOUSE_URL+'/'+this.state.currWarehouseItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initWarehouses();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  inputText:{
      height:arithUtil.pxToDp(30),
      borderColor:'#d0d0d0',
      borderStyle:'solid',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5)
  },
  tableTitle:{
      borderStyle:'solid',
      borderColor:'#F0F0F0',
      borderWidth: 1,
      backgroundColor:'#F2F2F2',
      display:'flex',
      flexDirection:'row',
      alignItems:'center'
  },
};