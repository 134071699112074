import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

export default class OrdersInitChoiceView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        commodities:[],commodityDatas:[],
        buyersAll:[],
        keySearch:'',
        choiceCommoditys:[],choiceBuyer:null,
        inputTextTagVal:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200){
                this.state.commodities=successResponse.data;
                this.setState({commodityDatas:this.state.commodities});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({buyersAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.props.successEvent(this.state.choiceCommoditys,this.state.choiceBuyer);
    }

    render(){
        return(<div style={{width:'98.5%',height:'100%'}}>
            <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{marginLeft:10}}>业务员:</span>
                <Select showSearch defaultValue={'选择业务员'} style={{width:150,height:30,fontSize:10}} onChange={(event)=>{
                    this.state.choiceBuyer = JSON.parse(event);
                    this.props.successEvent(this.state.choiceCommoditys,this.state.choiceBuyer);
                }}>{this.state.buyersAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name} ({stringUtil.language(1156)}:{item.code})</option>)
                    })}
                </Select>
                <input style={{width:150,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,paddingLeft:5,marginLeft:20}} onChange={(event)=>{
                    this.state.keySearch = event.target.value;
                    if(this.state.keySearch==null||this.state.keySearch==''){this.setState({commodityDatas:JSON.parse(JSON.stringify(this.state.commodities))});
                    }else{this.state.commodityDatas=[];
                        for(let i=0;i<this.state.commodities.length;i++){
                            if((this.state.commodities[i].code?this.state.commodities[i].code:'').indexOf(this.state.keySearch)!=-1||(this.state.commodities[i].name).indexOf(this.state.keySearch)!=-1){
                                this.state.commodityDatas.push(JSON.parse(JSON.stringify(this.state.commodities[i])));
                            }
                        }
                        this.setState({keySearch:this.state.keySearch});
                    }
                }} placeholder={stringUtil.language(32)}/>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{
                    if(this.state.isAllChoice){this.state.isAllChoice=0;
                        this.state.choiceCommoditys=[];
                    }else{this.state.isAllChoice=1;
                        this.state.choiceCommoditys=JSON.parse(JSON.stringify(this.state.commodityDatas));
                    }
                    this.props.successEvent(this.state.choiceCommoditys,this.state.choiceBuyer);
                    this.setState({choiceCommoditys:this.state.choiceCommoditys});
                }}>{this.state.isAllChoice?<i className="iconfontIndexCss" style={{fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</i>:
                    <i className="iconfontIndexCss" style={{fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</i>}
                    <span style={{marginRight:7}}>{stringUtil.language(1036)}</span>
                </div>
            </div>
            {(this.state.commodityDatas?this.state.commodityDatas:[]).map((item,key)=>{
                let isChoice=0;
                for(let i=0;i<(this.state.choiceCommoditys?this.state.choiceCommoditys:[]).length;i++){let commodityItem=this.state.choiceCommoditys[i];
                    if(commodityItem.id==item.id){
                        isChoice=1;break;
                    }
                }
                return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth:1,cursor:'pointer'}} onClick={()=>{
                    let isChoice=0;
                    for(let i=0;i<(this.state.choiceCommoditys?this.state.choiceCommoditys:[]).length;i++){let commodityItem=this.state.choiceCommoditys[i];
                        if(commodityItem.id==item.id){
                            isChoice=1;this.state.choiceCommoditys.splice(i,1);break;
                        }
                    }
                    if(!isChoice)this.state.choiceCommoditys.push(JSON.parse(JSON.stringify(item)));
                    this.props.successEvent(this.state.choiceCommoditys,this.state.choiceBuyer);
                    this.setState({choiceCommoditys:this.state.choiceCommoditys});
                }} key={key}>{isChoice?<i className="iconfontIndexCss" style={{fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</i>:
                    <i className="iconfontIndexCss" style={{fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</i>}
                    <span style={{width:0,flexGrow:1,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.code?'('+item.code+')':''}{item.name}</span>
                    <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                        <span>{item.stockNumber} 件</span>
                    </div>
                </div>)
            })}
            {/* <div style={{width:'100%',height:60,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                <div style={{width:120,height:'85%',backgroundColor:'#1B912A',borderRadius:200,justifyContent:'center',alignItems:'center',marginRight:10}} onClick={()=>{
                    if(!this.state.choiceCommoditys||!this.state.choiceCommoditys.length){ToastBox.error(stringUtil.language(387));return;}
                    if(!this.state.choiceBuyer){ToastBox.error(stringUtil.language(432));return;}
                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'OrdersAddActivity',mainStacks:[
                        {title:stringUtil.language(1128),navigatePage:''},
                        {title:stringUtil.language(1202),navigatePage:'OrdersListActivity',paramsPage:'参数内容'},
                        {title:stringUtil.language(1222),navigatePage:'OrdersAddActivity',paramsPage:{choiceCommoditys:JSON.stringify(this.state.choiceCommoditys),buyerItemInfo:JSON.stringify(this.state.choiceBuyer)}}
                    ]}});
                }}><span style={{color:'#FFF',fontSize:18}}>添加订货单</span>
                </div>
            </div> */}
        </div>)
    }
}

const styles = {};