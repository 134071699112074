import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class WholesaleOutActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        wholesaleInfo:paramsPage.wholesaleInfoItemInfo?paramsPage.wholesaleInfoItemInfo:{},
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_URL+'/'+this.state.wholesaleInfo.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
              this.setState({wholesaleInfo:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' onPressFourStr={stringUtil.language(1045)} onPressFour={()=>{
                this.setState({isShowTabTitle:true},()=>{
                    const printArea = document.getElementById('printArea');
                    const iframe = document.createElement('IFRAME');
                    let doc = null;
                    iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                    document.body.appendChild(iframe);
                    doc = iframe.contentWindow.document;
                    doc.write(printArea.innerHTML);
                    doc.close();
                    iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                    iframe.contentWindow.print();
                    if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                    this.setState({isShowTabTitle:false});
                });
            }} context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{width:'100%',height:'94%',display:'flex',flexDirection:'column',backgroundColor:'#FFF',overflowY:'auto'}} id='printArea'>
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontSize:25,fontWeight:'bold'}}>{this.state.wholesaleInfo.marketName}{this.state.wholesaleInfo.shopName}</span>
                    <span style={{fontSize:17}}>{stringUtil.language(1244)}（{stringUtil.language(373)}）{stringUtil.language(1045)}:{arithUtil.getCurrDateTime(1)}</span>
                </div>
                <div style={{width:'100%',height:45,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:0,flexGrow:3,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1048)}:{this.state.wholesaleInfo.customerName+(this.state.wholesaleInfo.customerAlias?this.state.wholesaleInfo.customerAlias:'')}</span>
                    <span style={{width:0,flexGrow:3,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1040)}:{this.state.wholesaleInfo.wholesaleNo}</span>
                    <span style={{width:0,flexGrow:2,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1245)}:</span>
                    <span style={{width:0,flexGrow:3,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1041)}:{arithUtil.fenToYuan(this.state.wholesaleInfo.money)}</span>
                    <span style={{width:0,flexGrow:2,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1250)}:</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap'}}>
                    <div style={{width:'49.5%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{width:0,flexGrow:2,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(518)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1021)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1046)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1050)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1041)}</span></div>
                    </div>
                    <div style={{width:'49.5%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{width:0,flexGrow:2,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(518)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1021)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1046)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1050)}</span></div>
                        <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{stringUtil.language(1041)}</span></div>
                    </div>
                    {(this.state.wholesaleInfo.commodities?this.state.wholesaleInfo.commodities:[]).map((item,key)=>{
                        return(<div style={{width:'49.5%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <div style={{width:0,flexGrow:2,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:0,flexGrow:1,fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden',marginLeft:5}}>{item.commodityCode?'('+item.commodityCode+')':''}{item.commodityName}</span>
                            </div>
                            <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{item.mode?item.number:arithUtil.keToWeight(item.weight,this.state.weightMode)}</span></div>
                            <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{!item.mode?(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)):item.commodityUnitMaster}</span></div>
                            <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{!item.mode?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode):arithUtil.fenToYuan(item.unitPrice,2)}</span></div>
                            <div style={{width:0,flexGrow:1,height:40,borderStyle:'solid',borderWidth:0.3,borderColor:'#808080',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span>{arithUtil.fenToYuan(item.money)}</span></div>
                        </div>);
                    })}
                </div>
                <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1037)}:{this.state.wholesaleInfo.workerId?this.state.wholesaleInfo.workerName:stringUtil.language(431)}</span>
                    <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1247)}:</span>
                    <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1246)}:{this.state.wholesaleInfo.operaterId?this.state.wholesaleInfo.operaterName:stringUtil.language(431)}</span>
                    <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1064)}:{this.state.wholesaleInfo.warehouseName}</span>
                    <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1248)}:</span>
                    <span style={{width:0,flexGrow:1,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{stringUtil.language(1249)}:</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {};