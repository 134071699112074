import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ProcessOrderInfoActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        processDetailDO:JSON.parse(paramsPage.processItem),
        keyTagVal:-1,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL+'/'+this.state.processDetailDO.id,null,['key','get',1],(successResponse)=>{
            if(successResponse.status==200){
                this.setState({processDetailDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:8,paddingRight:8,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                        <span>{stringUtil.language(1051)}({stringUtil.language(1175)}{this.state.processDetailDO.cargoNo}{stringUtil.language(1176)})</span>
                        <span style={{color:'#C0C0C0',fontSize:10,paddingBottom:2}}>{this.state.processDetailDO.warehouseName}</span>
                    </div>
                    <span>{stringUtil.language(1110)}:{this.state.processDetailDO.teamName?this.state.processDetailDO.teamName:stringUtil.language(431)}，{stringUtil.language(1109)}:{this.state.processDetailDO.workerName?this.state.processDetailDO.workerName:stringUtil.language(431)}</span>
                    <span>{arithUtil.formatToTimeStr(new Date(this.state.processDetailDO.time),'Y-M-D h:m')}</span>
                </div>
                <div style={{height:'53%',position:'relative'}}>
                    <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'6%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1104)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(549)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1105)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(550)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(551)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(552)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(553)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(554)}</span>
                    </div>
                    <div style={{position:'absolute',left:0,top:25,right:0,bottom:30,overflow:'auto'}}>
                        {(()=>{this.state.totalInfo={collectNumber:0,collectWeight:0,lossNumber:0,lossWeight:0,surplusNumber:0,surplusWeight:0,useNumber:0,useWeight:0,purchase:0};})()}
                        {this.state.processDetailDO.processCommodities?this.state.processDetailDO.processCommodities.map((item,key)=>{
                            this.state.totalInfo.collectNumber=this.state.totalInfo.collectNumber+item.collectNumber;
                            this.state.totalInfo.collectWeight=(this.state.totalInfo.collectWeight).add(item.collectWeight);
                            this.state.totalInfo.lossNumber=this.state.totalInfo.lossNumber+item.lossNumber;
                            this.state.totalInfo.lossWeight=(this.state.totalInfo.lossWeight).add(item.lossWeight);
                            this.state.totalInfo.surplusNumber=this.state.totalInfo.surplusNumber+item.surplusNumber;
                            this.state.totalInfo.surplusWeight=(this.state.totalInfo.surplusWeight).add(item.surplusWeight);
                            this.state.totalInfo.useNumber=this.state.totalInfo.useNumber+item.useNumber;
                            this.state.totalInfo.useWeight=(this.state.totalInfo.useWeight).add(item.useWeight);
                            this.state.totalInfo.purchase=(this.state.totalInfo.purchase).add(item.money);
                            return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'6%',fontSize:16,textAlign:'center'}}>{key+1}</span>
                                <span style={{width:'10%',fontSize:14}}>{item.commodityName}</span>
                                <span style={{width:'8%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.specs,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}/{item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173)}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{item.collectNumber+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.collectWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{item.lossNumber+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.lossWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{item.surplusNumber+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.surplusWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{item.useNumber+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                <span style={{width:'8.5%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.useWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                <span style={{width:'8%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                            </div>)
                        }):''}
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',position:'absolute',left:0,right:0,bottom:0}}>
                        <span style={{width:'24%',color:'#52A056',fontSize:16}}>{stringUtil.language(890)}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{this.state.totalInfo.collectNumber}{stringUtil.language(1173)}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.collectWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{this.state.totalInfo.lossNumber}{stringUtil.language(1173)}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.lossWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{this.state.totalInfo.surplusNumber}{stringUtil.language(1173)}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.surplusWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{this.state.totalInfo.useNumber}{stringUtil.language(1173)}</span>
                        <span style={{width:'8.5%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.useWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        <span style={{width:'8%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.purchase)}</span>
                    </div>
                </div>
                <div style={{height:'39%',position:'relative'}}>
                    <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(546)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1105)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1106)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1107)}</span>
                        <span style={{width:'9%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1108)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1069)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(947)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(559)}</span>
                    </div>
                    <div style={{position:'absolute',left:0,top:25,right:0,bottom:30,overflow:'auto'}}>
                        {(()=>{this.state.totalInfo={number:0,weight:0,materialMoney:0,teamMoney:0,subMaterialMoney:0,otherMoney:0,money:0};})()}
                        {this.state.processDetailDO.processFinishCommodities?this.state.processDetailDO.processFinishCommodities.map((item,key)=>{
                            this.state.totalInfo.number=this.state.totalInfo.number+item.number;
                            this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                            this.state.totalInfo.materialMoney=(this.state.totalInfo.materialMoney).add(item.materialMoney);
                            this.state.totalInfo.teamMoney=(this.state.totalInfo.teamMoney).add(item.teamMoney);
                            this.state.totalInfo.subMaterialMoney=(this.state.totalInfo.subMaterialMoney).add(item.subMaterialMoney);
                            this.state.totalInfo.otherMoney=(this.state.totalInfo.otherMoney).add(item.otherMoney);
                            this.state.totalInfo.money=(this.state.totalInfo.money).add(item.money);
                            return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'17%',fontSize:14}}>{item.commodityName}</span>
                                <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.standard,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}/{item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173)}</span>
                                <span style={{width:'8%',fontSize:16,textAlign:'center'}}>{item.number+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                                <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.materialMoney)}</span>
                                <span style={{width:'8%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.teamMoney)}</span>
                                <span style={{width:'9%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.subMaterialMoney)}</span>
                                <span style={{width:'8%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.otherMoney)}</span>
                                <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={{width:'10%',fontSize:16,textAlign:'center'}}>{item.number?arithUtil.fenToYuan((item.money).div(item.number)):''}{!item.number&&item.weight?arithUtil.fenToYuan((item.money).div(arithUtil.keToWeight(item.weight,this.state.weightMode))):''}</span>
                            </div>)
                        }):''}
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',position:'absolute',left:0,right:0,bottom:0}}>
                        <span style={{width:'27%',color:'#52A056',fontSize:16}}>{stringUtil.language(890)}</span>
                        <span style={{width:'8%',color:'#52A056',fontSize:16,textAlign:'center'}}>{this.state.totalInfo.number}{stringUtil.language(1173)}</span>
                        <span style={{width:'10%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        <span style={{width:'10%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.materialMoney)}</span>
                        <span style={{width:'8%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.teamMoney)}</span>
                        <span style={{width:'9%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.subMaterialMoney)}</span>
                        <span style={{width:'8%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.otherMoney)}</span>
                        <span style={{width:'10%',color:'#52A056',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.money)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {};