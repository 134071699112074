import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class ReceivedSendAddActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        currCargoItem:null,
        currMode:paramsPage.mode?paramsPage.mode:null,
        currReceivedSendItem:{time:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),mode:paramsPage.mode?paramsPage.mode:null},
        commodities:[],
        currCommodityTotal:{variety:0,numberText:0,number:0,weightText:0,weight:0},
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let warehouseCommoditys = successResponse.data;
                this.state.commodities = [];
                this.state.currCommodityTotal = {variety:0,numberText:0,number:0};
                if (warehouseCommoditys.length>0) {
                    for(let i=0;i<warehouseCommoditys.length;i++){
                        let warehouseCommoditysTemp = warehouseCommoditys[i];
                        this.state.commodities.push({
                            cargoId:warehouseCommoditysTemp.cargoId,
                            cargoNo:warehouseCommoditysTemp.cargoNo,
                            cargoCommodityId:warehouseCommoditysTemp.cargoCommodityId,
                            commodityId:warehouseCommoditysTemp.commodityId,
                            commodityName:warehouseCommoditysTemp.commodityName,
                            commodityCode:warehouseCommoditysTemp.commodityCode,
                            targetType:warehouseCommoditysTemp.targetType,
                            targetId:warehouseCommoditysTemp.targetId,
                            targetName:warehouseCommoditysTemp.targetName,
                            standard:warehouseCommoditysTemp.standard,
                            numberText:warehouseCommoditysTemp.number,
                            number:0,
                            weightText:warehouseCommoditysTemp.weight,
                            weight:0,
                        });
                    }
                }
                this.countsOrderTotal();
                this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    countsOrderTotal(){
        this.state.currCommodityTotal = {variety:0,numberText:0,number:0,weightText:0,weight:0};
        if (this.state.commodities.length>0) {
            for(let i=0;i<this.state.commodities.length;i++){
                let commodityTemp = this.state.commodities[i];
                if (commodityTemp.number!=0) {
                    this.state.currCommodityTotal.variety = this.state.currCommodityTotal.variety+1;
                    this.state.currCommodityTotal.numberText = (this.state.currCommodityTotal.numberText).add(commodityTemp.numberText);
                    this.state.currCommodityTotal.number = (this.state.currCommodityTotal.number).add(commodityTemp.number);
                    this.state.currCommodityTotal.weightText = (this.state.currCommodityTotal.weightText).add(commodityTemp.weightText);
                    this.state.currCommodityTotal.weight = (this.state.currCommodityTotal.weight).add(commodityTemp.weight);
                }
            }
            this.setState({currCommodityTotal:this.state.currCommodityTotal});
        }
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:arithUtil.pxToDp(8),paddingRight:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
            <div style={{height:'11%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1117)}：</span>
                        <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} placeholder={stringUtil.language(66)} onChange={(event)=>{
                            this.state.currReceivedSendItem.title = event.target.value;
                        }}/>
                    </div>
                    <div style={{height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,}}>
                        <DatePicker locale='zhCN' selected={new Date(this.state.currReceivedSendItem.time)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                            this.state.currReceivedSendItem.time = date.valueOf();
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }} />
                    </div>
                    {!this.state.currMode?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1135)}：</span>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currReceivedSendItem.mode = 0;
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currReceivedSendItem.mode==null||this.state.currReceivedSendItem.mode==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                            <span> {stringUtil.language(1226)}，</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currReceivedSendItem.mode = 1;
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currReceivedSendItem.mode==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                            <span> {stringUtil.language(1208)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currReceivedSendItem.mode = 2;
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currReceivedSendItem.mode==2?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                            <span> {stringUtil.language(1225)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currReceivedSendItem.mode = 3;
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currReceivedSendItem.mode==3?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                            <span> {stringUtil.language(1209)}</span>
                        </div>
                    </div>:<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1135)}：{this.state.currReceivedSendItem.mode==1?stringUtil.language(1208):this.state.currReceivedSendItem.mode==2?stringUtil.language(1225):this.state.currReceivedSendItem.mode==3?stringUtil.language(1209):''}</span>
                    </div>}
                    {!this.state.currReceivedSendItem.mode?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1119)}：</span>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currReceivedSendItem.sign = 0;
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currReceivedSendItem.sign==null||this.state.currReceivedSendItem.sign==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                            <span> {stringUtil.language(585)}，</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currReceivedSendItem.sign = 1;
                            this.setState({currReceivedSendItem:this.state.currReceivedSendItem});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currReceivedSendItem.sign==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>
                            <span> {stringUtil.language(586)}</span>
                        </div>
                    </div>:''}
                </div>
                <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'7%'})}>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'38%',color:'#A9A9A9'})}>{stringUtil.language(1084)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',color:'#A9A9A9'})}>{stringUtil.language(958)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',color:'#A9A9A9'})}>{stringUtil.language(1021)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',color:'#A9A9A9'})}>{stringUtil.language(1022)}</span>
                </div>
                <div style={{height:'71%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.commodities.map((item,key)=>{
                        return(<div style={{height:45,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',justifyContent:'flex-start'})}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'38%',justifyContent:'flex-start'})}>{item.targetType==1?stringUtil.language(1030)+(item.targetName?'_'+stringUtil.subStr(item.targetName,11):''):stringUtil.subStr(item.targetName,11)} {stringUtil.language(1237,item.cargoNo)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',})}>{item.numberText} / {arithUtil.keToWeight(item.weightText,this.state.weightMode)}</span>
                            <div style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',display:'flex',justifyContent:'center',alignItems:'center'})}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(90)})} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.commodities[key].number = parseFloat(event.target.value);
                                    if(this.state.commodities[key].standard){
                                        this.state.commodities[key].weight = (this.state.commodities[key].number).mul(this.state.commodities[key].standard);
                                        this.refs['commoditiesWeightInput'+key].value=arithUtil.keToWeight(this.state.commodities[key].weight,this.state.weightMode);
                                    }
                                    this.countsOrderTotal();
                                }}/>
                            </div>
                            <div style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',display:'flex',justifyContent:'center',alignItems:'center'})}>
                                <input ref={'commoditiesWeightInput'+key} style={arithUtil.stylesAdd(styles.textInput,{width:90})} type='number' placeholder='0' onChange={(event)=>{
                                    this.state.commodities[key].weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                                    this.countsOrderTotal();
                                }}/>
                            </div>
                        </div>)
                    })}
                </div>
                <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1}}>
                    <span style={{color:'#D2855D'}}>{stringUtil.language(890)}{this.state.currCommodityTotal.variety}{stringUtil.language(587)}{this.state.currCommodityTotal.numberText}{stringUtil.language(1173)}{arithUtil.keToWeight(this.state.currCommodityTotal.weightText,this.state.weightMode)}{stringUtil.language(1023)}，{stringUtil.language(588)}{this.state.currCommodityTotal.number}{stringUtil.language(1173)}{arithUtil.keToWeight(this.state.currCommodityTotal.weight,this.state.weightMode)}{stringUtil.language(1023)}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if (this.state.currReceivedSendItem.title==null || this.state.currReceivedSendItem.title=='') {
                                ToastBox.error(stringUtil.language(219));return;
                            }
                            this.state.currReceivedSendItem.commodities = [];
                            for(let i=0;i<this.state.commodities.length;i++){
                                let commodityTemp = this.state.commodities[i];
                                if (commodityTemp.number!=0 || commodityTemp.weight!=0) {
                                    this.state.currReceivedSendItem.commodities.push(JSON.parse(JSON.stringify(commodityTemp)));
                                }
                            }
                            if (this.state.currReceivedSendItem.commodities.length==0) {
                                ToastBox.error(stringUtil.language(22));return;
                            }
                            let stockInOutInsertVo = {commodities:[]};
                            if(this.state.currReceivedSendItem.time)stockInOutInsertVo.time = this.state.currReceivedSendItem.time;
                            stockInOutInsertVo.title = this.state.currReceivedSendItem.title!=null?this.state.currReceivedSendItem.title:'';
                            stockInOutInsertVo.sign = this.state.currReceivedSendItem.sign?this.state.currReceivedSendItem.sign:0;
                            stockInOutInsertVo.mode = this.state.currReceivedSendItem.mode?this.state.currReceivedSendItem.mode:0;
                            for(let i=0;i<this.state.currReceivedSendItem.commodities.length;i++){
                                let commodityTemp = {};
                                commodityTemp.cargoId = this.state.currReceivedSendItem.commodities[i].cargoId!=null?this.state.currReceivedSendItem.commodities[i].cargoId:'';
                                commodityTemp.cargoCommodityId = this.state.currReceivedSendItem.commodities[i].cargoCommodityId!=null?this.state.currReceivedSendItem.commodities[i].cargoCommodityId:'';
                                commodityTemp.commodityId = this.state.currReceivedSendItem.commodities[i].commodityId!=null?this.state.currReceivedSendItem.commodities[i].commodityId:0;
                                commodityTemp.targetType = this.state.currReceivedSendItem.commodities[i].targetType!=null?this.state.currReceivedSendItem.commodities[i].targetType:0;
                                commodityTemp.targetId = this.state.currReceivedSendItem.commodities[i].targetId!=null?this.state.currReceivedSendItem.commodities[i].targetId:0;
                                commodityTemp.targetName = this.state.currReceivedSendItem.commodities[i].targetName!=null?this.state.currReceivedSendItem.commodities[i].targetName:'';
                                commodityTemp.weight = this.state.currReceivedSendItem.commodities[i].weight!=null?this.state.currReceivedSendItem.commodities[i].weight:0;
                                commodityTemp.number = this.state.currReceivedSendItem.commodities[i].number!=null?this.state.currReceivedSendItem.commodities[i].number:0;
                                stockInOutInsertVo.commodities.push(commodityTemp);
                            }
                            stringUtil.httpRequest(urlUtil.SALE_STOCK_IN_OUT_URL,stockInOutInsertVo,['json','post',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks}});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>{stringUtil.language(1027)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'20%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
};