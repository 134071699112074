import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';

export default class TabMoneyTransfer extends Component{
  constructor(props){
    super(props);
    this.state={
      searchs:{
          accountInId:-1,
          accountOutId:-1,
          startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
          endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
      },
      transferSearchDtoObj:{},
      accountsAll:[],
      transferAccountItemList:[],
      totalNum:'',//总记录数
      currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
      goValue:'',
      totalPage:'',//总{stringUtil.language(1199)}数
      isDateTimePickerVisible: false,
      addShowPop:false,addShowPopIndex:0,
      infoShowPop:false,
      delShowPop:false,
      transferAccountInsertItem:{},
      currTransferAccountItem:{},
    };
  }
  componentDidMount(){
      this.getDataList();
      stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.accountsAll= successResponse.data;
              this.setState({accountsAll: this.state.accountsAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  getDataList(){
      stringUtil.httpRequest(urlUtil.FINANCE_TRANSFER_ACCOUNT_URL,{
          accountInId:this.state.searchs.accountInId,
          accountOutId:this.state.searchs.accountOutId,
          startTime:arithUtil.settleSub(this.state.searchs.startTime),
          endTime:arithUtil.settleSub(this.state.searchs.endTime),
          currPage:this.state.currentPage-1,
          pageSize:20
      },['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
            this.state.transferSearchDtoObj = successResponse.data;
            this.state.totalNum = this.state.transferSearchDtoObj.count;
            this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
            this.state.transferAccountItemList = JSON.parse(JSON.stringify(this.state.transferSearchDtoObj.items));
            this.setState({
                totalNum:this.state.totalNum,
                totalPage:this.state.totalPage,
                transferAccountItemList:this.state.transferAccountItemList
            });
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  goPrevClick(){//上一步
      var _this = this;
      let cur = this.state.currentPage;
      if(cur > 1) _this.pageClick( cur - 1);
  }
  goNext(){//下一步
      var _this = this;
      let cur = _this.state.currentPage;
      if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
  }
  goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
    var _this= this;
    _this.setState({goValue : e.target.value})
    var value = e.target.value;
    if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
    else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
    else _this.pageClick(value);
  }
  pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
      let _this = this;
      if(pageNum != _this.state.currentPage){
          _this.state.currentPage = pageNum
      }
      this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:7,paddingRight:7}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                {stringUtil.language(1194)}：<select style={{width:150,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                    this.state.searchs.accountOutId = JSON.parse(event.target.value).id;
                    this.getDataList();
                }}><option value={JSON.stringify({id:-1,name:stringUtil.language(695)})}>{stringUtil.language(695)}</option>
                    {this.state.accountsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                {stringUtil.language(1195)}：<select style={{width:150,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                    this.state.searchs.accountInId = JSON.parse(event.target.value).id;
                    this.getDataList();
                }}><option value={JSON.stringify({id:-1,name:stringUtil.language(695)})}>{stringUtil.language(695)}</option>
                    {this.state.accountsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
                <div style={styles.time}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                        this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                        this.getDataList();
                    }} />
                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                </div> {stringUtil.language(1174)} <div style={styles.time}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                        this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                        this.getDataList();
                    }} />
                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                </div>
            </div>
        </div>
        <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%',display:'flex',flexDirection:'row',alignItems:'center'})}>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',color:'#A9A9A9'})}>{stringUtil.language(1040)}</span>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',color:'#A9A9A9'})}>{stringUtil.language(1039)}</span>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',color:'#A9A9A9'})}>{stringUtil.language(720)}</span>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',color:'#A9A9A9'})}>{stringUtil.language(721)}</span>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',color:'#A9A9A9'})}>{stringUtil.language(1041)}</span>
            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{height:'77%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
            {this.state.transferAccountItemList.map((item,key)=>{
                return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',textAlign:'left'})}>ZZ.{item.transferAccountNo}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',textAlign:'left'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',textAlign:'left'})}>{item.accountOutName}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',textAlign:'left'})}>{item.accountInName}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',color:'#52A056',fontSize:15,fontWeight:'bold'})}>{arithUtil.fenToYuan(item.money)}</span>
                    {(item.describe!=null&&item.describe!='')?<div style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                        <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.currTransferAccountItem = JSON.parse(JSON.stringify(item));
                            this.setState({infoShowPop: !this.state.infoShowPop,});
                        }}>&#xe64c;</i>
                        {otherUtil.authorityFindCode("030804")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currTransferAccountItem = JSON.parse(JSON.stringify(item));
                            this.setState({delShowPop: !this.state.delShowPop});
                        }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                    </div>:
                    <div style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                        {otherUtil.authorityFindCode("030804")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currTransferAccountItem = JSON.parse(JSON.stringify(item));
                            this.setState({delShowPop: !this.state.delShowPop});
                        }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                    </div>}
                </div>);
            })}
        </div>
        <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
            {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                current={this.state.currentPage}
                totalPage={this.state.totalPage}
                goValue={this.state.goValue}
                pageClick={this.pageClick.bind(this)}
                goPrev={this.goPrevClick.bind(this)}
                goNext={this.goNext.bind(this)}
                switchChange={this.goSwitchChange.bind(this)}/>:''}
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                {otherUtil.authorityFindCode("030802")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer',marginRight:20}} onClick={()=>{
                    this.state.transferAccountInsertItem = {time:arithUtil.getCurrDateTime(0),describe:''};
                    this.setState({ addShowPopIndex:this.state.addShowPopIndex+1,addShowPop: !this.state.addShowPop, });
                }}>
                    <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                    <span style={{color:'#52A056'}}> {stringUtil.language(722)}</span>
                </div>:<div style={{position:'absolute'}}/>}
                <span style={{color:'#F00'}}>{stringUtil.language(1182)}{this.state.totalNum}{stringUtil.language(1086)}{arithUtil.fenToYuan(this.state.transferSearchDtoObj.money)}{stringUtil.currency(1)}</span>
            </div>
        </div>
        <MaskModal title={stringUtil.language(722)} visible={this.state.addShowPop} width={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.7)}
            content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                <div style={styles.modalRow}>
                    <span>{stringUtil.language(720)}：</span>
                    <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                        this.state.transferAccountInsertItem.accountOutId = JSON.parse(event.target.value).id;
                        this.state.transferAccountInsertItem.accountOutName = JSON.parse(event.target.value).name;
                      }}><option style={{display:'none'}}></option>
                      {this.state.accountsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                      })}
                    </select>
                </div>
                <div style={styles.modalRow}>
                    <span>{stringUtil.language(721)}：</span>
                    <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                        this.state.transferAccountInsertItem.accountInId = JSON.parse(event.target.value).id;
                        this.state.transferAccountInsertItem.accountInName = JSON.parse(event.target.value).name;
                      }}><option style={{display:'none'}}></option>
                      {this.state.accountsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                      })}
                    </select>
                </div>
                <div style={styles.modalRow}>
                    <span style={{width:100}}>{stringUtil.language(704)}：</span>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.transferAccountInsertItem.time)} onChange={(date) => {
                      this.state.transferAccountInsertItem.time = arithUtil.formatToTimeStr(date,'Y-M-D h:m:s');
                      this.setState({transferAccountInsertItem:this.state.transferAccountInsertItem});
                    }} />
                </div>
                <div style={styles.modalRow}>
                    <span>{stringUtil.language(723)}：</span>
                    <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(150)})} type='number' placeholder='0' onChange={(event)=>{
                        this.state.transferAccountInsertItem.money = arithUtil.yuanToFen(event.target.value);
                    }}/><span> {stringUtil.currency(1)}</span>
                </div>
                <div style={arithUtil.stylesAdd(styles.modalRow,{display:'flex',justifyContent:'flex-start',alignItems:'flex-start'})}>
                    <span>{stringUtil.language(318)}</span>
                    <input style={arithUtil.stylesAdd(styles.inputText,{width:'70%',height:arithUtil.pxToDp(70),textAlign:'left',textAlignVertical:'top'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                        this.state.transferAccountInsertItem.describe = event.target.value;
                    }}/>
                </div>
            </div>}
            okBnEvents={(event) => {
                if (this.state.transferAccountInsertItem.accountOutId==null) {
                    ToastBox.error(stringUtil.language(120));return;
                }
                if (this.state.transferAccountInsertItem.accountInId==null) {
                    ToastBox.error(stringUtil.language(121));return;
                }
                if (this.state.transferAccountInsertItem.money==null||this.state.transferAccountInsertItem.money==0) {
                    ToastBox.error(stringUtil.language(241));return;
                }
                let transferAccountInsertVo = {
                    time:arithUtil.timeToNumber(this.state.transferAccountInsertItem.time),
                    accountInId:this.state.transferAccountInsertItem.accountInId,
                    accountOutId:this.state.transferAccountInsertItem.accountOutId,
                    money:this.state.transferAccountInsertItem.money,
                    describe:this.state.transferAccountInsertItem.describe,
                }
                this.setState({ addShowPop: false });
                stringUtil.httpRequest(urlUtil.FINANCE_TRANSFER_ACCOUNT_URL,transferAccountInsertVo,['json','post',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        this.getDataList();
                        ToastBox.success(stringUtil.language(388));
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }} closeBnEvents={() => {this.setState({ addShowPop: false, });}}/>
        <MaskModal title={stringUtil.language(706)+'（ZZ.'+this.state.currTransferAccountItem.transferAccountNo+'）'} visible={this.state.infoShowPop} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.4)}
            content={<div style={{display:'flex',justifyContent:'center',alignItems:'center',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                {this.state.currTransferAccountItem.describe}
            </div>}
            closeBnTopEvents={() => {this.setState({ infoShowPop: false, });}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(44)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delShowPop: false});
                stringUtil.httpRequest(urlUtil.FINANCE_TRANSFER_ACCOUNT_URL+'/'+this.state.currTransferAccountItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  inputText:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5)
  },
  textInput:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5),
      textAlign:'center',
      textAlignVertical:'center'
  },
  tableTitle:{
    borderStyle:'solid',
      borderColor:'#F0F0F0',
      borderWidth: 1,
      backgroundColor:'#F2F2F2',
      display:'flex',
      flexDirection:'row'
  },
  tableTd:{
      fontSize:16,
      textAlign:'center',
      textAlignVertical:'center',
  },
  time:{
    height:25,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    borderStyle:'solid',
    borderWidth:1,
    borderColor:'#A9A9A9',
    borderRadius:3,
    paddingLeft:3,
    paddingRight:3
},
  modalRow:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      marginTop:arithUtil.pxToDp(10),
  },
};