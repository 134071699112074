import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

  export default class CustomerCollectView extends Component{
    constructor(props) {
        super(props);
        this.state={
            currWholesaleItem:{id:JSON.parse(this.props.paramsInfo.wholesaleItem).id},
            accountsAll:[],
            customerCollectInsertItem:null,
        };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({accountsAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_URL+'/'+this.state.currWholesaleItem.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.currWholesaleItem = successResponse.data;
                this.state.customerCollectInsertItem = {time:arithUtil.getCurrDateTime(),collects:[],noCollectMoneyTotal:0,collectMoney:0,discountMoney:0,describe:''};
                if(this.state.currWholesaleItem.wholesaleMoneys!=null&&this.state.currWholesaleItem.wholesaleMoneys.length>0){
                    for(let i=0;i<this.state.currWholesaleItem.wholesaleMoneys.length;i++){
                        let collectedMoneyObj = {collectMoney:0,discountMoney:0};
                        let wholesaleMoneysList = this.state.currWholesaleItem.wholesaleMoneys[i];
                        for(let j=0;j<wholesaleMoneysList.wholesaleCollects.length;j++){
                            collectedMoneyObj.collectMoney = (collectedMoneyObj.collectMoney).add(wholesaleMoneysList.wholesaleCollects[j].collectMoney);
                            collectedMoneyObj.discountMoney = (collectedMoneyObj.discountMoney).add(wholesaleMoneysList.wholesaleCollects[j].discountMoney);
                        }
                        let noCollectMoney = (wholesaleMoneysList.arrears).sub(collectedMoneyObj.collectMoney).sub(collectedMoneyObj.discountMoney);//{stringUtil.language(1087)}
                        this.state.customerCollectInsertItem.noCollectMoneyTotal = (this.state.customerCollectInsertItem.noCollectMoneyTotal).add(noCollectMoney);
                        this.state.currWholesaleItem.wholesaleMoneys[i].noCollectMoney = noCollectMoney;
                        this.state.customerCollectInsertItem.collects.push({
                            collectMode:1,
                            wholesaleId:this.state.currWholesaleItem.id,
                            targetType:wholesaleMoneysList.targetType,
                            targetId:wholesaleMoneysList.targetId,
                            discountMoney:0,
                            collectMoney:noCollectMoney,//{stringUtil.language(1087)}
                            targetName:wholesaleMoneysList.targetName,
                        });
                    }
                    this.collectTotal();
                    this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                }
                this.props.successEvent(this.state.customerCollectInsertItem);
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        document.onkeydown=(e)=>{
            console.log('keyCode：',e)
            if(e.keyCode===16||e.keyCode===13){
                if(e.keyCode===16||(e.keyCode===13&&e.code=='NumpadEnter')){
                    let isBt=0;
                    for(let i=0;i<this.state.currWholesaleItem.wholesaleMoneys.length;i++){
                        if(document.getElementById('money'+i)==document.activeElement){
                            document.getElementById('money'+i+i).focus();isBt=1;break;
                        }else if(document.getElementById('money'+i+i)==document.activeElement){
                            if(i!=this.state.currWholesaleItem.wholesaleMoneys.length-1){
                                document.getElementById('money'+(i+1)).focus();isBt=1;
                            }else{
                                this.state.submitResultTag=0;//stringUtil.language(1027)stringUtil.language(1038)按钮
                                this.props.successEvent(this.state.submitResultTag);
                                document.getElementById('money'+(this.state.currWholesaleItem.wholesaleMoneys.length-1)+(this.state.currWholesaleItem.wholesaleMoneys.length-1)).blur();
                                isBt=1;
                            }break;
                        }
                    }
                    if(!isBt&&(this.state.submitResultTag==0||this.state.submitResultTag==1)){if(this.state.submitResultTag==0){
                        this.state.submitResultTag=1;//stringUtil.language(1028)stringUtil.language(1038)按钮
                        this.props.successEvent(this.state.submitResultTag);
                    }else{
                        this.state.submitResultTag=-1;//不选中按钮
                        this.props.successEvent(this.state.submitResultTag);
                        document.getElementById('money0').focus();
                    }}
                    if(!isBt&&(this.state.submitResultTag==null||this.state.submitResultTag==-1)){document.getElementById('money0').focus();}
                }
                if(e.code=='Enter'){
                    if(this.state.submitResultTag!=null)this.props.successEvent('submitResult'+this.state.submitResultTag);
                }
            }else if(e.keyCode===33){//up
                if(this.state.accountsAll.length>0){
                    if(this.state.customerCollectInsertItem==null)this.state.customerCollectInsertItem={};
                    if(this.state.customerCollectInsertItem.accountId==null){
                        this.state.customerCollectInsertItem.accountId = this.state.accountsAll[0].id;
                        this.state.customerCollectInsertItem.accountIndex = 0;
                    }else{
                        for(let i=0;i<this.state.accountsAll.length;i++){
                            if(this.state.customerCollectInsertItem.accountId==this.state.accountsAll[0].id){
                                this.state.customerCollectInsertItem.accountId = this.state.accountsAll[this.state.accountsAll.length-1].id;
                                this.state.customerCollectInsertItem.accountIndex = this.state.accountsAll.length-1;
                                break;
                            }else if(this.state.customerCollectInsertItem.accountId==this.state.accountsAll[i].id){
                                this.state.customerCollectInsertItem.accountId = this.state.accountsAll[i-1].id;
                                this.state.customerCollectInsertItem.accountIndex = i-1;
                                break;
                            }
                        }
                    }
                    this.props.successEvent(this.state.customerCollectInsertItem);
                    this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                }
            }else if(e.keyCode===34){//down
                if(this.state.accountsAll.length>0){
                    if(this.state.customerCollectInsertItem==null)this.state.customerCollectInsertItem={};
                    if(this.state.customerCollectInsertItem.accountId==null){
                        this.state.customerCollectInsertItem.accountId = this.state.accountsAll[0].id;
                        this.state.customerCollectInsertItem.accountIndex = 0;
                    }else{
                        for(let i=0;i<this.state.accountsAll.length;i++){
                            if(this.state.customerCollectInsertItem.accountId==this.state.accountsAll[this.state.accountsAll.length-1].id){
                                this.state.customerCollectInsertItem.accountId = this.state.accountsAll[0].id;
                                this.state.customerCollectInsertItem.accountIndex = 0;
                                break;
                            }else if(this.state.customerCollectInsertItem.accountId==this.state.accountsAll[i].id){
                                this.state.customerCollectInsertItem.accountId = this.state.accountsAll[i+1].id;
                                this.state.customerCollectInsertItem.accountIndex = i+1;
                                break;
                            }
                        }
                    }
                    this.props.successEvent(this.state.customerCollectInsertItem);
                    this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                }
            }
            this.state.regIndexVal=-1;
        }
    }
    collectTotal(){
        this.state.customerCollectInsertItem.collectMoney = 0;//stringUtil.language(894)
        this.state.customerCollectInsertItem.discountMoney = 0;//总stringUtil.language(1042)
        if (this.state.customerCollectInsertItem.collects.length>0) {
            for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
                let collectDetailedTemp = this.state.customerCollectInsertItem.collects[i];
                if (collectDetailedTemp.collectMoney!=0) {
                    this.state.customerCollectInsertItem.collectMoney = (this.state.customerCollectInsertItem.collectMoney).add(collectDetailedTemp.collectMoney);
                }
                if (collectDetailedTemp.discountMoney!=0) {
                    this.state.customerCollectInsertItem.discountMoney = (this.state.customerCollectInsertItem.discountMoney).add(collectDetailedTemp.discountMoney);
                }
            }
        }
    }

    render(){
        if(this.state.customerCollectInsertItem==null) return(<div/>);
        return(<div style={{width:'98.5%',height:'100%'}}>
            <div style={{width:'100%',height:'15%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                <span style={{fontSize:16,paddingLeft:10}}>{stringUtil.language(903)}{this.state.currWholesaleItem.customerName}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{fontSize:16}}>{stringUtil.language(880)}</span><span style={{fontSize:25,color:'#F00',fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.customerCollectInsertItem.noCollectMoneyTotal)}{stringUtil.currency(1)}</span>
                </div>
            </div>
            <span style={{fontSize:12,color:'#D3D3D3',textAlignVertical:'center'}}>  ● {stringUtil.language(17)}</span>
            <div style={{height:'55%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                {this.state.currWholesaleItem.wholesaleMoneys!=null?this.state.currWholesaleItem.wholesaleMoneys.map((item,key)=>{
                    return(<div style={{height:45,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                        <span style={{color:'#52A056',fontWeight:'bold'}}>&nbsp;&nbsp;{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,7)}</span>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>¥ {arithUtil.fenToYuan(item.noCollectMoney)}</span>&nbsp;&nbsp;&nbsp;
                            <span>{stringUtil.language(905)}</span><input ref={'collectMoneyVal'+key} id={'money'+key} style={arithUtil.stylesAdd(styles.textInput,{width:90})} type='number' placeholder='0' defaultValue={this.state.customerCollectInsertItem.collects[key].collectMoney?arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].collectMoney):''} onChange={(event)=>{
                                this.state.customerCollectInsertItem.collects[key].collectMoney = event.target.value!=''?arithUtil.yuanToFen(event.target.value):0;
                                this.collectTotal();
                                this.props.successEvent(this.state.customerCollectInsertItem);
                            }}/><span>{stringUtil.currency(1)}，</span>
                            <span>{stringUtil.language(879)}</span><input id={'money'+key+key} style={arithUtil.stylesAdd(styles.textInput,{width:50})} type='number' placeholder='0' defaultValue={this.state.customerCollectInsertItem.collects[key].discountMoney?arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].discountMoney):''} onChange={(event)=>{
                                this.state.customerCollectInsertItem.collects[key].discountMoney = event.target.value!=''?arithUtil.yuanToFen(event.target.value):0;
                                let collectAddDiscount = (this.state.customerCollectInsertItem.collects[key].collectMoney).add(this.state.customerCollectInsertItem.collects[key].discountMoney);
                                if(collectAddDiscount>item.noCollectMoney){
                                    this.state.customerCollectInsertItem.collects[key].collectMoney = (item.noCollectMoney).sub(this.state.customerCollectInsertItem.collects[key].discountMoney);
                                    this.refs['collectMoneyVal'+key].value=arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].collectMoney);
                                }
                                this.collectTotal();
                                this.props.successEvent(this.state.customerCollectInsertItem);
                            }}/><span>{stringUtil.currency(1)}&nbsp;&nbsp;</span>
                        </div>
                    </div>)
                }):<div/>}
            </div>
            <div style={{height:'30%'}}>
                {/* <span style={{height:arithUtil.pxToDp(30),textAlignVertical:'center',color:'#D2855D',fontWeight:'bold'}}>总计：{stringUtil.language(1080)} {arithUtil.fenToYuan(this.state.customerCollectInsertItem.collectMoney)} {stringUtil.currency(1)}{stringUtil.language(906)} {arithUtil.fenToYuan(this.state.customerCollectInsertItem.discountMoney)} {stringUtil.currency(1)}</span> */}
                <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>{/* 水平滚动 */}
                    {this.state.accountsAll.map((item,key)=>{
                        let accountIndexKey = this.state.customerCollectInsertItem.accountIndex;
                        return(<div style={{width:65,height:65,borderStyle:'solid',borderWidth:1,borderColor:'#DCDCDC',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                            this.state.customerCollectInsertItem.accountId = item.id;
                            this.state.customerCollectInsertItem.accountIndex = key;
                            this.props.successEvent(this.state.customerCollectInsertItem);
                            this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                        }}>
                            {item.type==0?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#F4B015':'#A9A9A9'}}>&#xe632;</i>:
                            item.type==1?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#3BCA72':'#A9A9A9'}}>&#xe605;</i>:
                            item.type==2?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#5A9EF7':'#A9A9A9'}}>&#xe65f;</i>:
                            <i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#CC4B46':'#A9A9A9'}}>&#xe607;</i>}
                            <span style={{color:(accountIndexKey!=null&&accountIndexKey==key)?'#696969':'#A9A9A9'}}>{item.name}</span>
                        </div>)
                    })}
                </div>
            </div>
        </div>)
    }
}

const styles = {
    textInput:{
        height:35,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:5,
        textAlign:'center',
        textAlignVertical:'center'
    },
};