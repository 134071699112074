import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class CargoSonsActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        cargoItemInfo:paramsPage.currCargoItem?paramsPage.currCargoItem:{},
        searchs:{
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrBefourDateTime(30,''),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        sonsData:[],
      };
    }
    componentDidMount(){
        this.getDataList();
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_SON_URL,{
            cargoId:this.state.cargoItemInfo.id,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let dtoObj = successResponse.data;
                this.state.totalNum = dtoObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                let datas=JSON.parse(JSON.stringify(dtoObj.items));
                if(!this.state.cargoSonInfo&&datas.length){this.state.cargoSonInfo=JSON.parse(JSON.stringify(datas[0]));this.initDataInfo();}
                this.setState({sonsData:datas});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
		var _this= this;
		_this.setState({goValue : e.target.value})
		var value = e.target.value;
		if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
		else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
		else _this.pageClick(value);
	}
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }
    initDataInfo(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_SON_URL+'/'+this.state.cargoSonInfo.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({cargoSonInfo:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:8,paddingRight:8}}>
                <div style={{width:'30%',height:'100%',display:'flex',flexDirection:'column',backgroundColor:'#FFF'}}>
                    <div style={{height:0,flexGrow:1,overflow:'auto'}}>
                        {(this.state.sonsData?this.state.sonsData:[]).map((item,key)=>{
                            return(<div style={{height:50,display:'flex',flexDirection:'column',justifyContent:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth:1}} onClick={()=>{
                                this.state.cargoSonInfo=JSON.parse(JSON.stringify(item));
                                this.initDataInfo();
                            }} key={key}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{color:'#333',fontSize:16,marginLeft:7}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                    <span style={{width:0,flexGrow:1,color:'#333',fontSize:16,textAlign:'right',marginRight:7}}>{item.contractNo?stringUtil.language(1242)+':'+item.contractNo:''}</span>
                                </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{color:'#A9A9A9',fontSize:10,marginLeft:7}}>{arithUtil.formatToTimeStr(new Date(item.time),'h:m:s')}</span>
                                    <span style={{width:0,flexGrow:1,color:'#A9A9A9',fontSize:10,marginRight:7,textAlign:'right',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.number?item.number+stringUtil.language(1173):''}{item.weight?arithUtil.keToWeight(item.weight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)):''}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                    {this.state.totalPage>1?<div style={{height:'10%'}}>
                        <Pagination total={this.state.totalNum}
                            current={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            goValue={this.state.goValue}
                            pageClick={this.pageClick.bind(this)}
                            goPrev={this.goPrevClick.bind(this)}
                            goNext={this.goNext.bind(this)}
                            switchChange={this.goSwitchChange.bind(this)}/>
                    </div>:''}
                    {this.state.cargoItemInfo.state==1?<div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                        <div style={{width:'94%',height:40,borderRight:5,backgroundColor:'#009A61',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CargoSonsAddActivity',mainStacks:[
                                {title:stringUtil.language(1128),navigatePage:''},
                                {title:stringUtil.language(1052),navigatePage:'CargoSonsAddActivity',paramsPage:{cargoItemInfo:this.state.cargoItemInfo}}
                            ]}});
                        }}><span style={{color:'#FFF',fontSize:17,fontWeight:'bold'}}>{stringUtil.language(1129)}</span></div>
                    </div>:''}
                </div>
                {this.state.cargoSonInfo?<div style={{width:0,flexGrow:1,height:'100%',display:'flex',flexDirection:'column',backgroundColor:'#FFF',borderLeftStyle:'solid',borderLeftColor:'#A9A9A9',borderLeftWidth:1}}>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:16,marginLeft:10}}>{stringUtil.language(1039)}：</span>
                            <span style={{fontSize:16,fontWeight:'bold',whiteSpace:'nowrap'}}>{arithUtil.formatToTimeStr(new Date(this.state.cargoSonInfo.time),'Y-M-D h:m')}</span>
                        </div>
                        {this.state.cargoSonInfo.contractNo?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:16,marginLeft:10}}>{stringUtil.language(1242)}：</span>
                            <span style={{fontSize:16,fontWeight:'bold'}}>{this.state.cargoSonInfo.contractNo}</span>
                        </div>:''}
                    </div>
                    <div style={{height:35,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:0,flexGrow:1,color:'#A9A9A9',fontSize:15,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:'13%',color:'#A9A9A9',fontSize:15,textAlign:'center'}}>{stringUtil.language(1024)}</span>
                        <span style={{width:'23%',color:'#A9A9A9',fontSize:15,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'31%',color:'#A9A9A9',fontSize:15,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                    </div>
                    {(()=>{this.state.totalInfo={number:0,weight:0};})()}
                    <div style={{width:'100%',height:0,flexGrow:1,borderColor:'#F0F0F0',borderWidth:1,overflow:'auto'}}>
                        {this.state.cargoSonInfo.commodities!=null?this.state.cargoSonInfo.commodities.map((item,key)=>{
                            this.state.totalInfo.number=this.state.totalInfo.number+item.number;
                            this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:0,flexGrow:1,marginLeft:7,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                                <span style={{width:'13%',textAlign:'center'}}>{arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>
                                <span style={{width:'23%',textAlign:'center'}}>{item.number}{item.unitMaster}</span>
                                <span style={{width:'31%',textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}{this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023)}</span>
                            </div>)
                        }):''}
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:0,flexGrow:1,color:'#D2855D',textAlign:'center'}}>{stringUtil.language(1072)}</span>
                            <span style={{width:'13%',color:'#D2855D',textAlign:'center'}}></span>
                            <span style={{width:'23%',color:'#D2855D',textAlign:'center'}}>{this.state.totalInfo.number}</span>
                            <span style={{width:'31%',color:'#D2855D',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                        </div>
                    </div>
                    {this.state.cargoSonInfo.describe?<div style={{width:'100%',height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:0,flexGrow:1,marginLeft:10}} numberOfLines={1}>{stringUtil.language(858)}{this.state.cargoSonInfo.describe}</span>
                    </div>:''}
                    {this.state.cargoItemInfo.state==1?<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                        <div style={{width:100,height:30,borderRadius:15,borderStyle:'solid',borderColor:'#F00',borderWidth:1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({delShowPop:true});
                        }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#F00'}}>&#xe612;</i>
                            <span style={{color:'#F00'}}>{stringUtil.language(1113)}</span>
                        </div>
                    </div>:''}
                </div>:''}
            </div>
            <MaskModal visible={this.state.delShowPop?true:false} content={<div style={{width:'100%',height:'100%',alignItems:'center',justifyContent:'center'}}>
                    <span>{stringUtil.language(505)+stringUtil.language(1240)}？</span>
                </div>}
                okBnEvents={(event) => {
                    this.state.delShowPop=false;
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_SON_URL+'/'+this.state.cargoSonInfo.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(530));
                            this.state.cargoSonInfo=null;
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        </div>)
    }
}

const styles = {};