import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as excelUtil from '../utils/excelUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //{stringUtil.language(221)}
 export default class SaleTimingActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            workerId:-1,//stringUtil.language(1031)stringUtil.language(1155)-1stringUtil.language(1097)
            targetType:-1,//对象stringUtil.language(1135)-1stringUtil.language(1097)
            targetId:-1,//对象stringUtil.language(1155)-1stringUtil.language(1097)
            customerId:-1,//stringUtil.language(1048)stringUtil.language(1155)-1stringUtil.language(1097)
            commodityId:-1,//stringUtil.language(1063)stringUtil.language(1155)-1stringUtil.language(1097)
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),//起始stringUtil.language(1039)0stringUtil.language(1097)
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),//截止stringUtil.language(1039)0stringUtil.language(1097)
            workerName:stringUtil.language(472),
            targetName:stringUtil.language(425),
            customerName:stringUtil.language(618),
            commodityName:stringUtil.language(619)
        },
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        workersAll:[],
        personsAll:[],
        customersAll:[],customersDatas:[],
        customerSearchShowPop:false,
        commoditysAll:[],commoditysDatas:[],
        commoditySearchShowPop:false,
        wholesaleSequenceVO:{},
        wholesaleSequenceDOList:[]
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.getDataList();
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workersAll = successResponse.data;
                this.state.workersAll.unshift({id:-1,noteName:stringUtil.language(472)});
                this.setState({workersAll:this.state.workersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll = [];
                let allOwnerItem = {targetType:-1,targetId:-1,noteName:stringUtil.language(425)};
                this.state.personsAll.unshift(allOwnerItem);
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                        this.setState({personsAll:this.state.personsAll});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_SEQUENCE_URL,{
            customerId:this.state.searchs.customerId,
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            workerId:this.state.searchs.workerId,
            commodityId:this.state.searchs.commodityId,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),//arithUtil.formatToTimeStr(new Date(),'Y-M-D'),
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.wholesaleSequenceVO = successResponse.data;
                this.state.totalNum = this.state.wholesaleSequenceVO.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({wholesaleSequenceDOList:JSON.parse(JSON.stringify(this.state.wholesaleSequenceVO.items))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
      var _this= this;
      _this.setState({goValue : e.target.value})
      var value = e.target.value;
      if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
      else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
      else _this.pageClick(value);
    }
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'12%',display:'flex',flexDirection:'column',justifyContent:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{height:'50%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{width:'19%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1122)}:</span><select style={{height:30,display:'flex',flexGrow:1,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.workerId = JSON.parse(event.target.value).id;
                                this.state.searchs.workerName = JSON.parse(event.target.value).noteName;
                                this.getDataList();
                            }}>
                            {this.state.workersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{width:'19%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1047)}:</span><Select showSearch defaultValue={stringUtil.language(425)}  style={{height:30,display:'flex',flexGrow:1}} onChange={(value)=>{
                                this.state.searchs.targetType = JSON.parse(value).targetType;
                                this.state.searchs.targetId = JSON.parse(value).targetId;
                                this.state.searchs.targetName = JSON.parse(value).noteName;
                                this.getDataList();
                            }}>
                            {this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{width:'49%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1039)}:</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'),0);
                                this.getDataList();
                            }} /></div> {stringUtil.language(1174)} <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(new Date(date.valueOf()),'Y-M-D'));
                                this.getDataList();
                            }} /></div>
                        </div>
                        <div style={{width:'10%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_SEQUENCE_PRINT_URL,{
                                    customerId:this.state.searchs.customerId,
                                    targetType:this.state.searchs.targetType,
                                    targetId:this.state.searchs.targetId,
                                    workerId:this.state.searchs.workerId,
                                    commodityId:this.state.searchs.commodityId,
                                    startTime:arithUtil.settleSub(this.state.searchs.startTime),
                                    endTime:arithUtil.settleSub(this.state.searchs.endTime),//arithUtil.formatToTimeStr(new Date(),'Y-M-D'),
                                },['key','get',1],(successResponse)=>{
                                    if (successResponse.status==200) {
                                        let resArr=new Array();
                                        resArr.push(`<tr><td align="left"><b><font size="3">${stringUtil.language(1040)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1039)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1048)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(620)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1084)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(969)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1022)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1050)}</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1068)}【${stringUtil.currency(1)}】</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(1069)}【${stringUtil.currency(1)}】</font></b></td>
                                        <td align="left"><b><font size="3">${stringUtil.language(970)}</font></b></td></tr>`);
                                        for(let i=0;i<(successResponse.data?successResponse.data:[]).length;i++){
                                            let item = successResponse.data[i];
                                            resArr.push(`<tr><td align="left"><font size="3">NO.${item.wholesaleNo}</font></td>
                                                <td align="left"><font size="3">${arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</font></td>
                                                <td align="left"><font size="3">${item.customerName}</font></td>
                                                <td align="left"><font size="3">${(item.targetType==1?stringUtil.language(426)+(item.targetName?'_':''):'')+(item.targetName?item.targetName:'')}</font></td>
                                                <td align="left"><font size="3">${item.cargoNo}</font></td>
                                                <td align="left"><font size="3">${item.commodityName}</font></td>
                                                <td align="left"><font size="3">${item.number}</font></td>
                                                <td align="left"><font size="3">${arithUtil.keToWeight(item.weight,this.state.weightMode)}</font></td>
                                                <td align="left"><font size="3">${item.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173)))}</font></td>
                                                <td align="left"><font size="3">${arithUtil.fenToYuan(item.goodsMoney)}</font></td>
                                                <td align="left"><font size="3">${arithUtil.fenToYuan(item.otherMoney)}</font></td>
                                                <td align="left"><font size="3">${item.workerId==0?stringUtil.language(431):item.workerName}</font></td></tr>`);
                                        }
                                        otherUtil.createExcel(resArr.join(''),stringUtil.language(221));
                                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                                <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                            </div>
                        </div>
                    </div>
                    <div style={{height:'50%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{width:'19%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1048)}:</span><div style={{height:30,display:'flex',flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderStyle:'solid',borderColor:'#D0D0D0',borderWidth:1,borderRadius:2,paddingLeft:5,paddingRight:5,backgroundColor:'#FFF'}} onClick={(event)=>{
                                if(this.state.customersAll.length==0){
                                    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.customersAll = successResponse.data;
                                            this.state.customersAll.unshift({id:-1,noteName:stringUtil.language(618),warning:0,quota:0,arrears:0});
                                            this.state.customerSearchShowPop = !this.state.customerSearchShowPop;
                                            this.setState({customersDatas:this.state.customersAll});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{
                                    this.state.customerSearchShowPop = !this.state.customerSearchShowPop;
                                    this.setState({customersDatas:this.state.customersAll});
                                }
                            }}><span>{this.state.searchs.customerName}</span><i className="iconfontIndexCss" style={{fontSize:9,color:'#A9A9A9'}}>&#xe603;</i>
                            </div>
                        </div>
                        <div style={{width:'19%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1063)}:</span><div style={{height:30,display:'flex',flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderStyle:'solid',borderColor:'#D0D0D0',borderWidth:1,borderRadius:2,paddingLeft:5,paddingRight:5,backgroundColor:'#FFF'}} onClick={(event)=>{
                                if(this.state.commoditysAll.length==0){
                                    stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.commoditysAll = successResponse.data;
                                            this.state.commoditysAll.unshift({id:-1,name:stringUtil.language(619)});
                                            this.state.commoditySearchShowPop = !this.state.commoditySearchShowPop;
                                            this.setState({commoditysDatas:this.state.commoditysAll});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{
                                    this.state.commoditySearchShowPop = !this.state.commoditySearchShowPop;
                                    this.setState({commoditysDatas:this.state.commoditysAll});
                                }
                            }}><span>{this.state.searchs.commodityName}</span><i className="iconfontIndexCss" style={{fontSize:9,color:'#A9A9A9'}}>&#xe603;</i>
                            </div>
                        </div>
                        <div style={{width:'49%'}}></div>
                        <div style={{width:'10%'}}></div>
                    </div>
                </div>
                <div style={{width:'100%',height:'88%'}} id='printArea'>
                    <div style={{height:'5%',display:'flex',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'8%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1040)}</span>
                        <span style={{width:'15%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1039)}</span>
                        <span style={{width:'10%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1048)}</span>
                        <span style={{width:'10%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(620)}</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1084)}</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(969)}</span>
                        <span style={{width:'5%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'8%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1050)}</span>
                        <span style={{width:'6%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1068)}</span>
                        <span style={{width:'5%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(1069)}</span>
                        <span style={{width:'6%',fontSize:12,textAlign:'center',color:'#A9A9A9'}}>{stringUtil.language(970)}</span>
                    </div>
                    {(()=>{this.state.totalInfo={number:0,weight:0,goodsMoney:0,otherMoney:0}})()}
                    <div style={{height:'85%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflowY:'auto'}}>
                        {this.state.wholesaleSequenceDOList.map((item,key)=>{
                            // this.state.totalInfo.number=(this.state.totalInfo.number).add(item.number);
                            // this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                            // this.state.totalInfo.goodsMoney=(this.state.totalInfo.goodsMoney).add(item.goodsMoney);
                            // this.state.totalInfo.otherMoney=(this.state.totalInfo.otherMoney).add(item.otherMoney);
                            return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'8%',fontSize:12}}>NO.{stringUtil.subStrEnd(item.wholesaleNo,8)}</span>
                                <span style={{width:'15%',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m')}</span>
                                <span style={{width:'10%',fontSize:12}}>{stringUtil.subStr(item.customerName,6)}</span>
                                <span style={{width:'10%',fontSize:12}}>{(item.targetType==1?stringUtil.language(1051)+(item.targetName?'_':''):'')+(item.targetName?stringUtil.subStr(item.targetName,6):'')}</span>
                                <span style={{width:'9%',fontSize:12}}>{item.cargoNo}</span>
                                <span style={{width:'9%',fontSize:12}}>{stringUtil.subStr(item.commodityName,9)}</span>
                                <span style={{width:'5%',fontSize:12,textAlign:'center'}}>{item.number}</span>
                                <span style={{width:'8%',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={{width:'9%',fontSize:12,textAlign:'center'}}>{item.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173)))}</span>
                                <span style={{width:'6%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                                <span style={{width:'5%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.otherMoney)}</span>
                                <span style={{width:'6%',fontSize:12,textAlign:'center'}}>{item.workerId==0?stringUtil.language(431):item.workerName}</span>
                            </div>);
                        })}
                    </div>
                    <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                            current={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            goValue={this.state.goValue}
                            pageClick={this.pageClick.bind(this)}
                            goPrev={this.goPrevClick.bind(this)}
                            goNext={this.goNext.bind(this)}
                            switchChange={this.goSwitchChange.bind(this)}/>:''}
                        <span style={{color:'#F00'}}>{stringUtil.language(1182)}{this.state.wholesaleSequenceVO.wholesaleCount}{stringUtil.language(1189)}，{this.state.wholesaleSequenceVO.wholesaleNum}{stringUtil.language(1173)}{arithUtil.keToWeight(this.state.wholesaleSequenceVO.wholesaleWeight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}{stringUtil.language(907)}{arithUtil.fenToYuan(this.state.wholesaleSequenceVO.wholesaleGoodsMoney)}{stringUtil.currency(1)}，{stringUtil.language(1069)}{arithUtil.fenToYuan(this.state.wholesaleSequenceVO.wholesaleOtherMoney)}{stringUtil.currency(1)}</span>
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(392)} visible={this.state.commoditySearchShowPop} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.85)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <input style={{width:arithUtil.pxToDp(150),height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} id='keyId' onChange={(event)=>{
                            this.state.keySearchCom = event.target.value;
                            if(this.state.keySearchCom=='')this.setState({commoditysDatas:this.state.commoditysAll});
                        }} placeholder={stringUtil.language(32)}/>
                        <span style={{width:arithUtil.pxToDp(50),height:25,color:'#FFF',lineHeight:25,borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.commoditysDatas = [];
                            if (this.state.keySearchCom!=null&&this.state.keySearchCom!='') {
                                for(let i=0;i<this.state.commoditysAll.length;i++){
                                    if ((this.state.commoditysAll[i].name).indexOf(this.state.keySearchCom)!=-1||(this.state.commoditysAll[i].code?this.state.commoditysAll[i].code:'').indexOf(this.state.keySearchCom)!=-1) {
                                        this.state.commoditysDatas.push(this.state.commoditysAll[i]);
                                    }
                                }
                            }else{this.state.commoditysDatas = this.state.commoditysAll;}
                            this.setState({commoditysDatas:this.state.commoditysDatas});
                        }}>{stringUtil.language(1034)}</span>
                    </div>
                    <div style={{height:'91%',overflow:'auto'}}>
                        {(this.state.commoditysDatas?this.state.commoditysDatas:this.state.commoditysAll).map((item,key)=>{
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                                this.state.searchs.commodityId = item.id;
                                this.state.searchs.commodityName = item.name;
                                this.state.commoditySearchShowPop = false;
                                this.getDataList();
                            }}>
                                <img style={{width:30,height:30,borderRadius:15}} loading="lazy" src={arithUtil.imageShow(item.thumbnail)} title={stringUtil.language(84)}/>
                                <div style={{marginLeft:7,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                    <span style={{color:'#696969',fontWeight:'bold'}}>{item.name}</span>
                                    <span style={{color:'#FF8C00',fontSize:'8px'}}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({commoditySearchShowPop: false})}}/>
            <MaskModal title={stringUtil.language(457)} visible={this.state.customerSearchShowPop} width={300} height={arithUtil.ProportionTo(document.body.clientHeight,0.85)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}}>
                    <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <input style={{width:arithUtil.pxToDp(150),height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} id='keyId' onChange={(event)=>{
                            this.state.keySearch = event.target.value;
                            if(this.state.keySearch=='')this.setState({customersDatas:this.state.customersAll});
                        }} placeholder={stringUtil.language(32)}/>
                        <span style={{width:arithUtil.pxToDp(50),height:'25px',color:'#FFF',lineHeight:'25px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.customersDatas = [];
                            if (this.state.keySearch!=null&&this.state.keySearch!='') {
                                for(let i=0;i<this.state.customersAll.length;i++){
                                    if ((this.state.customersAll[i].noteName).indexOf(this.state.keySearch)!=-1) {
                                        this.state.customersDatas.push(this.state.customersAll[i]);
                                    }
                                }
                            }else{this.state.customersDatas = this.state.customersAll;}
                            this.setState({customersDatas:this.state.customersDatas});
                        }}>{stringUtil.language(1034)}</span>
                    </div>
                    <div style={{height:'91%',overflow:'auto'}}>
                        {(this.state.customersDatas?this.state.customersDatas:this.state.customersAll).map((item,key)=>{
                            return(<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                                this.state.searchs.customerId = item.id;
                                this.state.searchs.customerName = item.noteName;
                                this.state.customerSearchShowPop = false;
                                this.getDataList();
                            }}>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <img style={{width:30,height:30,borderRadius:15}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                    <div style={{marginLeft:7,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <span style={{color:item.warning!=2?'#696969':'#D3D3D3',fontWeight:item.warning!=2?'bold':'normal'}}>{item.noteName}</span>
                                        <span style={{color:'#FF8C00',fontSize:'8px'}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                    </div>
                                </div>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                            </div>)
                        })}
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({customerSearchShowPop: false})}}/>
        </div>)
    }
}

const styles = {
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};