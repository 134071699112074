import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class ReceivedSendActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            keySearch:'',
            sign:-1,
            warehouseId:-1,
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        warehousesAll:[],
        infoShowPop:false,
        delShowPop:false,
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        inOutItemsData:[],
        currInOutItem:{},
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.getDataList();
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll= successResponse.data;
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.IN_OUT_SEARCH_URL,{
            warehouseId:this.state.searchs.warehouseId,
            title:this.state.searchs.keySearch,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),
            sign:this.state.searchs.sign,
            currPage:this.state.currentPage-1
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let inOutObj = successResponse.data;
                this.state.totalNum = inOutObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.state.inOutItemsData = JSON.parse(JSON.stringify(inOutObj.items));
                this.setState({
                    totalNum:this.state.totalNum,
                    totalPage:this.state.totalPage,
                    inOutItemsData:this.state.inOutItemsData
                });
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
		var _this= this;
		_this.setState({goValue : e.target.value})
		var value = e.target.value;
		if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
		else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
		else _this.pageClick(value);
	}
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:arithUtil.pxToDp(8),paddingRight:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    {otherUtil.authorityFindCode("020702")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.props.navigationStacks.push({title:stringUtil.language(582),navigatePage:'ReceivedSendAddActivity',paramsPage:stringUtil.language(582)+'参数'});
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ReceivedSendAddActivity',mainStacks:this.props.navigationStacks}});
                    }}>
                        <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                        <span style={{color:'#52A056'}}> {stringUtil.language(582)}</span>
                    </div>:<div style={{position:'absolute'}}/>}
                    <select style={{width:250,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                        this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                        this.getDataList();
                    }}>
                        {this.state.warehousesAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                    <select style={{width:80,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                        this.state.searchs.sign = JSON.parse(event.target.value).sign;
                        this.getDataList();
                    }}>
                        {[{sign:-1,name:stringUtil.language(583)},{sign:0,name:stringUtil.language(1185)},{sign:1,name:stringUtil.language(1186)}].map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                                this.getDataList();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div> {stringUtil.language(1174)} <div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                                this.getDataList();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div>
                    </div>
                </div>
                <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1040)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1039)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9'})}>{stringUtil.language(956)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(957)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'24%',color:'#A9A9A9'})}>{stringUtil.language(1064)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(1117)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                </div>
                <div style={{height:this.state.totalPage>1?'77%':'87%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.inOutItemsData.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',textAlign:'left'})}>PD.{item.stockInOutNo}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',textAlign:'left'})}>{item.workerId?item.workerName:stringUtil.language(431)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%'})}>{!item.mode?(item.sign?stringUtil.language(1186):stringUtil.language(1185)):item.mode==1?stringUtil.language(1208):item.mode==2?stringUtil.language(1225):stringUtil.language(1209)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'24%',textAlign:'left'})}>{item.warehouseName}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',textAlign:'left'})}>{item.title}</span>
                            <div style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                                <i className="iconfontIndexCss" style={{fontSize:22,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                    this.state.currInOutItem = JSON.parse(JSON.stringify(item));
                                    stringUtil.httpRequest(urlUtil.SALE_STOCK_IN_OUT_URL+'/'+this.state.currInOutItem.id,null,['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.currInOutItem = JSON.parse(JSON.stringify(successResponse.data));
                                            this.setState({
                                                currInOutItem: this.state.currInOutItem,
                                                infoShowPop:!this.state.infoShowPop                //有可能是下拉刷新
                                            })
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}>&#xe64c;</i>
                                {otherUtil.authorityFindCode("020604")!=null?<i className="iconfontIndexCss" style={{fontSize:22,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                    this.state.currInOutItem = JSON.parse(JSON.stringify(item));
                                    this.setState({delShowPop:!this.state.delShowPop});
                                }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                            </div>
                        </div>);
                    })}
                </div>
                {this.state.totalPage>1?<div style={{height:'10%'}}>
                    <Pagination total={this.state.totalNum}
                        current={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        goValue={this.state.goValue}
                        pageClick={this.pageClick.bind(this)}
                        goPrev={this.goPrevClick.bind(this)}
                        goNext={this.goNext.bind(this)}
                        switchChange={this.goSwitchChange.bind(this)}/>
                </div>:''}
            </div>
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(40)}
                    </div>}
                okBnEvents={(event) => {
                    this.setState({delShowPop: false});
                    stringUtil.httpRequest(urlUtil.SALE_STOCK_IN_OUT_URL+'/'+this.state.currInOutItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
            <MaskModal title={this.state.currInOutItem.title!=null?this.state.currInOutItem.title:stringUtil.language(584)} visible={this.state.infoShowPop} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'12%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <span>{stringUtil.language(882)}{this.state.currInOutItem.stockInOutNo}</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.currInOutItem.time),'Y-M-D h:m')} {!this.state.currInOutItem.mode?(this.state.currInOutItem.sign?stringUtil.language(1186):stringUtil.language(1185)):this.state.currInOutItem.mode==1?stringUtil.language(1208):this.state.currInOutItem.mode==2?stringUtil.language(1225):stringUtil.language(1209)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <span>{stringUtil.language(1118)}：{this.state.currInOutItem.workerId?this.state.currInOutItem.workerName:stringUtil.language(431)}</span><span>{stringUtil.language(892)}{this.state.currInOutItem.warehouseName}</span>
                        </div>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'7%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'50%',color:'#A9A9A9'})}>{stringUtil.language(456)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                    </div>
                    <div style={{height:'65%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                        {this.state.currInOutItem.commodities!=null?this.state.currInOutItem.commodities.map((item,key)=>{
                            this.state.currInOutItem.numberTotal = (this.state.currInOutItem.numberTotal!=null?this.state.currInOutItem.numberTotal:0).add(item.number);
                            this.state.currInOutItem.weightTotal = (this.state.currInOutItem.weightTotal!=null?this.state.currInOutItem.weightTotal:0).add(item.weight);
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'})}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'50%',textAlign:'left'})}>{item.targetType==0?stringUtil.subStr(item.targetName,10):item.targetType==1?stringUtil.language(426):stringUtil.language(438)} {stringUtil.language(1237,item.cargoNo)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                    <div style={{height:'16%'}}>
                        <span style={{textAlignVertical:'center',color:'#D2855D',fontSize:12}}>{stringUtil.language(326)} {this.state.currInOutItem.commodities!=null?this.state.currInOutItem.commodities.length:0} {stringUtil.language(1177)} {this.state.currInOutItem.numberTotal!=null?this.state.currInOutItem.numberTotal:0} {stringUtil.language(1173)} {this.state.currInOutItem.weightTotal!=null?arithUtil.keToWeight(this.state.currInOutItem.weightTotal,this.state.weightMode):0} {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(10)}}>
                            {stringUtil.language(858)}{this.state.currInOutItem.describe}
                        </div>
                    </div>
                </div>} closeBnTopEvents={() => {this.setState({ infoShowPop: false, })}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'33%',
        fontSize:13,
        textAlign:'center',
        textAlignVertical:'center',
    },
    time:{
        height:25,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
        paddingLeft:3,
        paddingRight:3
    },
};