import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

 export default class ShareClientDown extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={}
    }
    componentDidMount(){}
    isWeiXin(){
        let ua = window.navigator.userAgent.toLowerCase();//window.navigator.userAgent属性包含了浏览器stringUtil.language(1135)、版本、stringUtil.language(1026)系统stringUtil.language(1135)、浏览器引擎stringUtil.language(1135)等信息，这个属性可以用来判断浏览器stringUtil.language(1135)
        if(ua.match(/MicroMessenger/i) == 'micromessenger')return true;//通过正则表达式匹配ua中是否含有MicroMessenger字符串
        else return false;
    }

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
            {this.isWeiXin()?<div style={{height:90,backgroundColor:'#000',display:'flex',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <div>
                    <div><span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>扫描后，请{stringUtil.language(1198)}击</span><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>右上角</span></div>
                    <div><span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>选择</span><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>在浏览器打开</span></div>
                </div>
                <i className="iconfontIndexCss" style={{fontSize:50,color:'#FFF'}}>&#xea08;</i>
            </div>:''}
            {!this.isWeiXin()?<a href={urlUtil.FILE_APP_URL} style={{height:200,textDecoration:'none',margin:5,backgroundColor:'#1E90FF',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} download={stringUtil.language(98)+'.apk'}>
                <i className="iconfontIndexCss" style={{fontSize:80,color:'#A4CA39'}}>&#xe61d;</i>
                <div style={{width:'85%',height:40,backgroundColor:'#FFF',borderRadius:7,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:10}}>
                    <span style={{color:'#1E90FF',fontSize:20,fontWeight:'bold'}}>下载Android版卖菜帮</span>
                </div>
            </a>:''}
            {!this.isWeiXin()?<a href='' style={{height:200,textDecoration:'none',margin:5,backgroundColor:'#FFA500',borderRadius:10,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} download={stringUtil.language(98)+'.apk'}>
                <i className="iconfontIndexCss" style={{fontSize:80,color:'#A4CA39'}}>&#xe630;</i>
                <div style={{width:'85%',height:40,backgroundColor:'#FFF',borderRadius:7,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:10}}>
                    <span style={{color:'#FFA500',fontSize:20,fontWeight:'bold'}}>下载IOS版卖菜帮</span>
                </div>
            </a>:''}
        </div>)
    }
}

const styles = {};