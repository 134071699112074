import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
 
export default class Register extends Component{
	constructor(props){
        super(props);
        this.state={
            currRegisterData:{
                noteName:'',
                mobile:'',
                password:'',
                code:'',
                surePassword:'',
            },
            timer: 60,
        };
    }
    componentDidUpdate(){
        if(this.state.timer === 1){
          clearInterval(this.interval);
          this.setState({timer:60});
        }
    }
    componentWillUnmount(){
      clearInterval(this.interval);
    }

	render(){
		return(<div style={{width:'100%',height:'100vh',backgroundColor:'#ECECEC'}}>
        <div style={{height:50,backgroundColor:'#FFF',display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:20}}>
            <img style={{width:'30px',height:'30px'}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
            <span style={{color:'#3CB371',marginLeft:15}}>{stringUtil.language(84)}</span>
        </div>
        <div style={arithUtil.stylesAdd(styles.phoneRow,{paddingTop:arithUtil.pxToDp(35)})}>
            <span style={{width:100,color:'#505050',fontSize:17}}>{stringUtil.language(959)}</span>
            <input style={styles.phoneInputText} onChange={(event)=>{
                this.state.currRegisterData.noteName = event.target.value;
            }} placeholder={stringUtil.language(67)}/>
        </div><br/>
        <div style={styles.phoneRow}>
            <span style={{width:100,color:'#505050',fontSize:17}}>{stringUtil.language(934)}</span>
            <input style={styles.phoneInputText} type='number' onChange={(event)=>{
                this.state.currRegisterData.mobile = event.target.value;
            }} placeholder={stringUtil.language(100)}/>
        </div><br/>
        <div style={styles.phoneRow}>
            <span style={{width:100,color:'#505050',fontSize:17}}>{stringUtil.language(960)}</span>
            <input style={arithUtil.stylesAdd(styles.phoneInputText,{width:arithUtil.pxToDp(150)})} type='number' onChange={(event)=>{
                this.state.currRegisterData.code = event.target.value;
            }} placeholder={stringUtil.language(327)}/>
            {this.state.timer==60?<div style={{width:arithUtil.pxToDp(150),height:arithUtil.pxToDp(35),display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                if(this.state.currRegisterData.mobile!=null && this.state.currRegisterData.mobile!=''){
                    let resultBool = (this.state.currRegisterData.mobile).phoneCheck();
                    if(resultBool){
                        this.state.timer = 60;
                        this.interval = setInterval(()=>this.setState((prevState)=>({timer:prevState.timer-1})),1000);
                    }else{ToastBox.error(stringUtil.language(59));}
                }else{ToastBox.error(stringUtil.language(153));}
            }}>
                <span style={{fontSize:15,color:'#d2855d',textDecorationLine:'underline'}}>{stringUtil.language(328)}</span>
            </div>:<div style={{width:arithUtil.pxToDp(150),height:arithUtil.pxToDp(35),display:'flex',justifyContent:'center',alignItems:'center'}}>
                <span style={{fontSize:15,color:'#d2855d'}}>{this.state.timer}s{stringUtil.language(329)}</span>
            </div>}
        </div><br/>
        <div style={styles.phoneRow}>
            <span style={{width:100,color:'#505050',fontSize:17}}>{stringUtil.language(220)}</span>
            <input style={styles.phoneInputText} type='password' onChange={(event)=>{
                this.state.currRegisterData.password = event.target.value;
            }} placeholder={stringUtil.language(152)}/>
        </div><br/>
        <div style={styles.phoneRow}>
            <span style={{width:100,color:'#505050',fontSize:17}}>{stringUtil.language(589)}</span>
            <input style={styles.phoneInputText} type='password' onChange={(event)=>{
                this.state.currRegisterData.surePassword = event.target.value;
            }} placeholder={stringUtil.language(68)}/>
        </div>
        <div style={styles.phoneRow}>
            <span style={{width:arithUtil.pxToDp(405),height:'35px',color:'#FFF',lineHeight:'35px',borderRadius:5,backgroundColor:'#52A056',marginTop:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                if (this.state.currRegisterData.noteName=='') {
                  ToastBox.error(stringUtil.language(107));return;
                }
                if (this.state.currRegisterData.mobile=='') {
                  ToastBox.error(stringUtil.language(153));return;
                }
                let resultBool = (this.state.currRegisterData.mobile).phoneCheck();
                if(!resultBool){
                    ToastBox.error(stringUtil.language(59));return;
                }
                if (this.state.currRegisterData.code=='') {
                  ToastBox.error(stringUtil.language(161));return;
                }
                if (this.state.currRegisterData.password=='') {
                  ToastBox.error(stringUtil.language(211));return;
                }
                if (this.state.currRegisterData.password.indexOf(" ") != -1) {
                  ToastBox.error(stringUtil.language(69));return;
                }
                if (this.state.currRegisterData.password.length<6) {
                  ToastBox.error(stringUtil.language(41));return;
                }
                if (this.state.currRegisterData.password!=this.state.currRegisterData.surePassword) {
                  ToastBox.error(stringUtil.language(42));return;
                }
                stringUtil.httpRequest(urlUtil.USER_URL,{
                  noteName:this.state.currRegisterData.noteName,
                  mobile:this.state.currRegisterData.mobile,
                  password:this.state.currRegisterData.password,
                  code:this.state.currRegisterData.code,
                },['key','post',0],(successResponse)=>{
                  if (successResponse.status==200) {
                    ToastBox.success(successResponse.message);
                    this.props.history.push({pathname:"/"});
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }}>{stringUtil.language(1187)}&nbsp;&nbsp;{stringUtil.language(1188)}</span>
        </div>
    </div>);
	}
}

const styles = {
    phoneInputText:{
        width:arithUtil.pxToDp(300),
        height:arithUtil.pxToDp(35),
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        color:'#808080',
        padding:0,
        paddingLeft:arithUtil.pxToDp(10),
    },
    phoneRow:{
        display:'flex',
        flexDirection:'row',
        flexWrap:'nowrap',
        justifyContent:'flex-start',
        alignItems:'center',
        marginLeft:arithUtil.pxToDp(50)
    },
  };