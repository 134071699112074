import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import MaskModal from '../components/MaskModal';
 import Pagination from '../components/Pagination';

 export default class WorkerCollectExpend extends Component{
    constructor(props) {
      super(props);
      this.state={
        collectPayRecordDO:{},
        totalInfo:{
            customerCollectMoneyTotal:0,
            otherCollectMoneyTotal:0,
            collectMoneyTotal:0,
            bossCollectMoneyTotal:0,
            shopCollectMoneyTotal:0,
            ownerExpendMoneyTotal:0,
            shopExpendMoneyTotal:0,
            supplierPayMoneyTotal:0,
            ownerPayMoneyTotal:0,
            workerPayMoneyTotal:0,
            payMoneyTotal:0,
            bossPayMoneyTotal:0,
            shopPayMoneyTotal:0,
            handMoneyTotal:0,
            balanceTotal:0
        },
        inputTextTagVal:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SUMM_COLLECT_PAY_RECORD_URL,{date:arithUtil.getCurrDateTime(1)},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.collectPayRecordDO = successResponse.data?successResponse.data:{};
                if(this.state.collectPayRecordDO.workerRecord){
                    for(let i=0;i<this.state.collectPayRecordDO.workerRecord.length;i++){
                        let item = this.state.collectPayRecordDO.workerRecord[i];
                        this.state.totalInfo = {
                            customerCollectMoneyTotal:(this.state.totalInfo.customerCollectMoneyTotal).add(item.customerCollectMoney),
                            otherCollectMoneyTotal:(this.state.totalInfo.otherCollectMoneyTotal).add(item.otherCollectMoney),
                            collectMoneyTotal:(this.state.totalInfo.collectMoneyTotal).add(item.collectMoney),
                            bossCollectMoneyTotal:(this.state.totalInfo.bossCollectMoneyTotal).add(item.bossCollectMoney),
                            shopCollectMoneyTotal:(this.state.totalInfo.shopCollectMoneyTotal).add(item.shopCollectMoney),
                            ownerExpendMoneyTotal:(this.state.totalInfo.ownerExpendMoneyTotal).add(item.ownerExpendMoney),
                            shopExpendMoneyTotal:(this.state.totalInfo.shopExpendMoneyTotal).add(item.shopExpendMoney),
                            supplierPayMoneyTotal:(this.state.totalInfo.supplierPayMoneyTotal).add(item.supplierPayMoney),
                            ownerPayMoneyTotal:(this.state.totalInfo.ownerPayMoneyTotal).add(item.ownerPayMoney),
                            workerPayMoneyTotal:(this.state.totalInfo.workerPayMoneyTotal).add(item.workerPayMoney),
                            payMoneyTotal:(this.state.totalInfo.payMoneyTotal).add(item.payMoney),
                            bossPayMoneyTotal:(this.state.totalInfo.bossPayMoneyTotal).add(item.bossPayMoney),
                            shopPayMoneyTotal:(this.state.totalInfo.shopPayMoneyTotal).add(item.shopPayMoney),
                            handMoneyTotal:(this.state.totalInfo.handMoneyTotal).add(item.handMoney),
                            balanceTotal:(this.state.totalInfo.balanceTotal).add(item.balance)
                        }
                    }
                }
                this.setState({collectPayRecordDO:this.state.collectPayRecordDO});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'99%',height:'99%',display:'flex',flexDirection:'row'}}>
            <div style={{width:'100%',height:'100%',position:'absolute',zIndex:1}}>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#CCCCCC'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#CCCCCC'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#CCCCCC'}}></div>
            </div>
            <div style={{width:'25%',height:'100%',display:'flex',flexDirection:'column',position:'relative',zIndex:10}}>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(267)}{arithUtil.fenToYuan(this.state.totalInfo.collectMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(268)}{arithUtil.fenToYuan(this.state.totalInfo.customerCollectMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(269)}{arithUtil.fenToYuan(this.state.totalInfo.otherCollectMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(270)}{arithUtil.fenToYuan(this.state.totalInfo.payMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(271)}{arithUtil.fenToYuan(this.state.totalInfo.shopExpendMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(272)}{arithUtil.fenToYuan(this.state.totalInfo.supplierPayMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(273)}{arithUtil.fenToYuan(this.state.totalInfo.ownerExpendMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(274)}{arithUtil.fenToYuan(this.state.totalInfo.ownerPayMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(275)}{arithUtil.fenToYuan(this.state.totalInfo.workerPayMoneyTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold',color:'#F00'}}>{stringUtil.language(276)}{arithUtil.fenToYuan(this.state.totalInfo.balanceTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(277)}{arithUtil.fenToYuan(this.state.totalInfo.handMoneyTotal)}</span></div>
            </div>
            <div style={{width:'75%',height:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row',position:'relative',zIndex:10}}>
            {this.state.collectPayRecordDO.workerRecord?this.state.collectPayRecordDO.workerRecord.map((item,key)=>{
                return(<div style={{width:122,borderLeftStyle:'solid',borderLeftColor:'#FFF',borderLeftWidth:0.5,display:'flex',flexDirection:'column'}} key={key}>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{(item.workerId==0?stringUtil.language(431):stringUtil.subStr(item.workerName,5))+':'+arithUtil.fenToYuan(item.collectMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.customerCollectMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.otherCollectMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.payMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.shopExpendMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.supplierPayMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.ownerExpendMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.ownerPayMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.workerPayMoney)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold',color:'#F00'}}>{arithUtil.fenToYuan(item.balance)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.handMoney)}</span></div>
                </div>)
            }):''}
            </div>
        </div>)
    }
}

const styles = {}