import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import TabTopActivity from '../components/TabTopActivity';
 import MaskModal from '../components/MaskModal';
 import Pagination from '../components/Pagination';

  export default class PurchaseOrderInfoView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        currPurchaseOrderInfo:{},
        inputTextTagVal:'',
        keyTagVal:-1,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_PURCHASE_URL+'/'+this.props.purchaseOrderItem.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({currPurchaseOrderInfo:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
            <div style={{height:50,display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:10,paddingRight:10}}>
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <span>{stringUtil.language(422)}{this.state.currPurchaseOrderInfo.supplierName}</span>
                    <span style={{color:'#D2855D',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.currPurchaseOrderInfo.time),'Y-M-D h:m:s')}</span>
                </div>
                <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <span>{stringUtil.language(955)}：{this.state.currPurchaseOrderInfo.workerName}</span>
                    <span style={{color:'#F00',fontWeight:'bold'}}>NO.{this.state.currPurchaseOrderInfo.purchaseNo}</span>
                </div>
            </div>
            <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'18%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'28%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                <span style={{width:'18%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'18%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                <span style={{width:'18%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
            </div>
            <div>
                {this.state.currPurchaseOrderInfo.commodities!=null?this.state.currPurchaseOrderInfo.commodities.map((item,key)=>{
                    let otherMoneyTotal = 0;
                    for(let i=0;i<item.costs.length;i++){
                        otherMoneyTotal = otherMoneyTotal.add(item.costs[i].money);
                    }
                    return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'18%'}}>{item.commodityName}</span>
                        <span style={{width:'28%',textAlign:'center'}}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                        <span style={{width:'18%',textAlign:'center'}}>{item.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/kg':'/g')):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+item.unitMaster)}</span>
                        <span style={{width:'18%',textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                        <span style={{width:'18%',textAlign:'center'}}>{otherMoneyTotal}</span>
                    </div>)
                }):''}
            </div>
            <div style={{paddingLeft:10,paddingRight:10}}>
                <div style={{width:'100%',display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                    {this.state.currPurchaseOrderInfo.costs!=null?this.state.currPurchaseOrderInfo.costs.map((item,key)=>{
                        return(<div style={{width:'49%',height:25,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{color:'#808080'}}>{item.costClauseName}：</span>
                            {item.calMode==0?<span style={{color:'#D2855D'}}>{arithUtil.fenToYuan(item.money,2)} {stringUtil.currency(1)}/{stringUtil.language(1173)}</span>:
                            item.calMode==1?<span style={{color:'#D2855D'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode)} {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>:
                            item.calMode==2?<span style={{color:'#D2855D'}}>{item.money} %</span>:
                            <span style={{color:'#D2855D'}}>{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}</span>}
                        </div>)
                    }):''}
                </div>
                <div style={{width:'100%',height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span>{stringUtil.language(858)}{this.state.currPurchaseOrderInfo.describe}</span>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(1112)}：</span><span style={{color:'#D2855D',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currPurchaseOrderInfo.money)}{stringUtil.currency(1)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {}