import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabSettingStallInfo from './TabSettingStallInfo';
import TabSettingPrintSet from './TabSettingPrintSet';
import TabSettingStallCommodity from './TabSettingStallCommodity';
import TabSettingExpendItem from './TabSettingExpendItem';
import TabSettingCollectionItem from './TabSettingCollectionItem';
import TabSettingStallWarehouse from './TabSettingStallWarehouse';
import TabSettingUseSetting from './TabSettingUseSetting';
import TabSettingSalesman from './TabSettingSalesman';

export default class TabSetting extends Component{
  constructor(props){
    super(props);
    this.state={
      positionVal: 0,
    };
  }

  render(){
    let showViews = [
        <TabSettingStallInfo context={this}/>,
        <TabSettingPrintSet context={this}/>,
        <TabSettingStallCommodity context={this}/>,
        <TabSettingExpendItem context={this}/>,
        <TabSettingCollectionItem context={this}/>,
        <TabSettingStallWarehouse context={this}/>,
        <TabSettingUseSetting context={this}/>,
        <TabSettingSalesman context={this}/>
      ];
    let tabItems = [
        {name:stringUtil.language(759),backgroundcolor:'#FF8383',icon:<i className="iconfontIndexCss" style={{fontSize:'25px',color:'#FFF'}}>&#xe675;</i>},
        {name:stringUtil.language(760),backgroundcolor:'#9478B5',icon:<i className="iconfontIndexCss" style={{fontSize:'25px',color:'#FFF'}}>&#xe867;</i>},
        {name:stringUtil.language(449),backgroundcolor:'#52A056',icon:<i className="iconfontIndexCss" style={{fontSize:'25px',color:'#FFF'}}>&#xe618;</i>},
        {name:stringUtil.language(714),backgroundcolor:'#FF8383',icon:<i className="iconfontIndexCss" style={{fontSize:'35px',color:'#FFF'}}>&#xe666;</i>},
        {name:stringUtil.language(703),backgroundcolor:'#9478B5',icon:<i className="iconfontIndexCss" style={{fontSize:'35px',color:'#FFF'}}>&#xe65c;</i>},
        {name:stringUtil.language(761),backgroundcolor:'#52A056',icon:<i className="iconfontIndexCss" style={{fontSize:'25px',color:'#FFF'}}>&#xe6ea;</i>},
        {name:stringUtil.language(762),backgroundcolor:'#7ECEF4',icon:<i className="iconfontIndexCss" style={{fontSize:'25px',color:'#FFF'}}>&#xe61a;</i>},
        {name:stringUtil.language(891),backgroundcolor:'#7ECEF4',icon:<i className="iconfontIndexCss" style={{fontSize:'25px',color:'#FFF'}}>&#xe622;</i>}
    ];
    let showView = <div>{stringUtil.language(62)}</div>;
      for(let i=0;i<showViews.length;i++){
          let svs = showViews[i];
          if(this.state.positionVal==i && typeof(svs) != undefined){showView = svs}
        }
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%'}}>
        <div style={arithUtil.stylesAdd(styles.leftContainer,{width:'80%'})}>{showView}</div>
        <div style={{height:'100%',marginLeft:arithUtil.pxToDp(4),backgroundColor:'#FFF',borderRadius:5,width:'20%',display:'flex',flexDirection:'row',flexWrap:'wrap',overflow:'auto'}}>
            {tabItems.map((item,key)=>{
                if (typeof(item) != undefined) {
                    return(<div key={key} style={arithUtil.stylesAdd(styles.rightMenuItem,{cursor:'pointer'})} onClick={()=>{ this.setState({ positionVal:key }); }}>
                        <div style={{width:arithUtil.pxToDp(50),height:arithUtil.pxToDp(50),backgroundColor:this.state.positionVal === key ? item.backgroundcolor:'#D3D3D3',borderRadius:arithUtil.pxToDp(25),display:'flex',justifyContent:'center',alignItems:'center'}}>
                            {item.icon}
                        </div>
                        <span style={{color:this.state.positionVal === key ? '#696969':'#C0C0C0',fontSize:13}}>{item.name}</span>
                    </div>)
                }
            })}
        </div>
      </div>)
  }
}

const styles = {
  leftContainer:{
      height:'100%',
      marginRight:arithUtil.pxToDp(4),
      backgroundColor:'#FFF',
      borderRadius:5,
      padding:arithUtil.pxToDp(7)
  },
  rightMenuItem:{
      width:'50%',
      height:arithUtil.pxToDp(90),
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
  }
};