import React,{Component} from 'react';
// import moment from "moment/moment";
 import urlUtil from '../utils/urlUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
const BASE_WIN_HEIGHT = 926;
const BASE_WIN_WIDTH = 600;

// import * as arithUtil from '../utils/arithUtil.js';
//任意值的{stringUtil.language(1181)}换
export function pxToDp(val) {
    return val;//val * (document.documentElement.clientHeight || document.body.clientHeight) / BASE_WIN_WIDTH;
}
//任意值的{stringUtil.language(1181)}换
export function ProportionTo(val,scale) {
    return val * scale;
}

/*功能{stringUtil.language(1198)}:1、加减乘除 2、支持链式调用 
  import '../utils/arithUtil';在用到的文{stringUtil.language(1173)}中导{stringUtil.language(1195)}
  console.log((0.1).add(0.2), (0.1).sub(0.002, 1), (0.1).mul(0.002, 1), (0.0001).div(0.003, 1));
  arg:{stringUtil.language(1175)}二个数字  acc:保存小数位(不传则不做保存小数位处理)*/
  (function(Number) {
    // 加法
    Number.prototype.add = function(arg, acc) {
      var r1, r2, m;
      try {
        r1 = this.toString().split('.')[1].length;
      } catch (e) {r1 = 0;}
      try {
        r2 = arg.toString().split('.')[1].length;
      } catch (e) {r2 = 0;}
      m = Math.pow(10, Math.max(r1, r2));
      var a = parseInt(this * m + (this<0?-0.5:0.5));
      var b = arg < 0 ? parseInt(arg * m) : parseInt(arg * m + 0.5);// 兼容减法
      var result = (a + b) / m;
      // 小数位数处理
      if (acc>=0) {
        return Number(Number(result).toFixed(parseInt(acc)));
      } else {return result;}
    };
    // 减法
    Number.prototype.sub = function(arg, acc) {
      return this.add(-arg, acc);
    }; 
    // 乘法
    Number.prototype.mul = function(arg, acc) {
      var m = 0;
      var s1 = this.toString();
      var s2 = arg.toString();
      try {
        m += s1.split('.')[1].length;
      } catch (e) {}
      try {
        m += s2.split('.')[1].length; // m是累加结果
      } catch (e) {}
      var result = (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
      // 小数位数处理
      if (acc>=0) {
        return Number(Number(result).toFixed(parseInt(acc)));
      } else {return result;}
    }; 
    // 除法
    Number.prototype.div = function(arg, acc) {
      var r1, r2, m;
      try {
        r1 = this.toString().split('.')[1].length;
      } catch (e) {r1 = 0;}
      try {
        r2 = arg.toString().split('.')[1].length;
      } catch (e) {r2 = 0;}
      // 解决分子分母含有0的情况
      if (!this || !arg) {return 0;}
      m = Math.pow(10, Math.max(r1, r2));
      var a = parseInt(this * m + ((String(this).indexOf(".")+1)>0?0.5:0));
      var b = parseInt(arg * m + 0.5);
      var result = a / b;
      // 小数位数处理
      if (acc>=0) {
        return Number(Number(result).toFixed(parseInt(acc)));
      } else {return result;}
    };
    // 去掉double{stringUtil.language(1135)}小数{stringUtil.language(1198)}后面多余的0
    Number.prototype.cutZero = function(acc) {
        var result = this;
        //拷贝一份 返回去掉零的新串
        var newstr=this;
        //循环变量 小数部分长度
        var leng = this.length-this.toString().indexOf(".")-1
        //判断是否有效数
        if(this.toString().indexOf(".")>-1){
           //循环小数部分
           for(var i=leng;i>0;i--){
                //如果newstr末尾有0
                if(newstr.lastIndexOf("0")>-1 && newstr.substr(newstr.length-1,1)==0){
                    var k = newstr.lastIndexOf("0");
                    //如果小数{stringUtil.language(1198)}后只有一个0 去掉小数{stringUtil.language(1198)}
                    if(newstr.charAt(k-1)=="."){
                        result = newstr.substring(0,k-1);
                    }else{newstr=newstr.substring(0,k);}//否则 去掉一个0
                }else{result = newstr;}//如果末尾没有0
            }
        }
        if (acc>=0) {
            return Number(Number(result).toFixed(parseInt(acc)));
        } else {return result;}
    }
    //四舍五{stringUtil.language(1195)}，默认以分显示的{stringUtil.language(1041)}四舍五{stringUtil.language(1195)}保留以元显示的整数
    Number.prototype.round = function(acc=2,isSiSheWuRu=0) {//loc：1四舍五{stringUtil.language(1195)}的位数在整数部分（分的显示）,0四舍五{stringUtil.language(1195)}的位数在小数部分（元的显示）
      let line=0,res=0;
      if(isSiSheWuRu==0){
        line=(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).carry!=null)?JSON.parse(localStorage.getItem("shopConfigInfo")).carry:50;
      }else{line=Math.pow(10,acc)/2;}
      if(acc>0){//acc：1保留十位，2百位，3千位……以此类推
        let r1;try {r1=parseFloat(this.toString());}catch(e){r1=0;}
        res = r1-r1%Math.pow(10,acc);
        if((r1%Math.pow(10,acc))>=line) res=res+Math.pow(10,acc);//(Math.pow(10,acc)/2)
      }else{
        let r1 = 0;
        try {r1 = this.toString().split('.')[1].length;} catch (e) {r1 = 0;}
        if(r1<-acc){r1=-acc};
        let a = parseInt(this * Math.pow(10, r1));
        let b = parseInt(Math.pow(10, r1+acc));
        res = a-a%b;
        if((a%b/Math.pow(10, r1))>=fenToYuan(line)) res=res+Math.pow(10, r1+acc);
        res = res/Math.pow(10, r1);
        if(isSiSheWuRu!=0)res=parseFloat(this).toFixed(-acc);
      }
      return res;
      // if(loc==1){//acc0个位，1十位，2百位，3千位……以此类推
      //   let r1;try {r1=parseFloat(this.toString());}catch(e){r1=0;}
      //   let res = r1-r1%Math.pow(10,acc);
      //   if((r1%Math.pow(10,acc))>=(Math.pow(10,acc)/2)) res=res+Math.pow(10,acc);
      //   return res;
      // }else if(loc==0){
      //   return parseFloat(this.toFixed(acc));
      // }
    }
    //{stringUtil.language(1041)}(分)以scale作为分界{stringUtil.language(1195)}/舍为整元。大于scal分，进100分；小于scal分，舍去
    Number.prototype.roundDouble = function(scal=20,acc=2) {
      scal=(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).carry!=null)?JSON.parse(localStorage.getItem("shopConfigInfo")).carry:50;
      let r1;try {r1=parseFloat(this.toString());}catch(e){r1=0;}
      let res = r1-r1%Math.pow(10,acc);
      if((r1%Math.pow(10,acc))>=scal) res=res+Math.pow(10,acc);
      return res;//返回分
    }
  })(Number);
  //四舍五{stringUtil.language(1195)}小数部分
  export function roundXiaoshu(number,n){
    let numberStr = number + "";
    let reg = /^(-|\+)?(\d+(\.\d*)?|\.\d+)$/i;
    if(!reg.test(numberStr)) {return number;}//输{stringUtil.language(1195)}的数字格式不对
    let sign = numberStr.charAt(0) === '-' ? (numberStr=numberStr.slice(1),-1):1; // 判断是否是负数
    let pointIndex = numberStr.indexOf("."); // 记录小数{stringUtil.language(1198)}的位置
    if (pointIndex > -1) {
      numberStr = numberStr.replace(".", "");
    } else { // 没有小数{stringUtil.language(1198)}直接stringUtil.language(1129)补0；
      numberStr += ".";
      numberStr+=new Array(n).join('0');
      if(numberStr!=null&&numberStr.length>0){numberStr=numberStr+'';
        if(numberStr.charAt(numberStr.length-1) === '.')numberStr = numberStr.substring(0,numberStr.length-1);
      }else{numberStr='0';}
      return parseFloat(sign===-1?'-'+numberStr:numberStr);
    }
    let numberArray = numberStr.split(""); //{stringUtil.language(1181)}成数组
    let len = numberArray.length;
    let oldPointNum = len - pointIndex; // 获取原数据有多少位小数
    if (oldPointNum < n) { // 要保留的小数{stringUtil.language(1198)}比原来的要大，直接补0
      while (n - oldPointNum > 0) {
        numberArray.push(0);n--;
      }
    } else if (oldPointNum > n) { // 模拟四舍五{stringUtil.language(1195)}
      let i = oldPointNum - n; // stringUtil.language(1190)倒数{stringUtil.language(1175)}i个数字开始比较
      let more = numberArray[len - i] >= 5 ? true : false;
      while (more) {
        i++;
        more = +numberArray[len - i] + 1 === 10 ? true : false; // 进位后判断是否等于10，是则继续进位
        numberArray[len - i] = more&&i!==(len+1) ? 0 : +numberArray[len - i] + 1; // stringUtil.language(1069)位置的数字进位直接变成0，{stringUtil.language(1175)}一位的例外
      }
      numberArray.length = len- (oldPointNum-n); // 截取多余的小数
    }
    numberArray.splice(pointIndex, 0, ".");
    let res = sign===-1?'-'+numberArray.join(""):numberArray.join("");
    if(res!=null&&res.length>0){res=res+'';
      if(res.charAt(res.length-1) === '.')res = res.substring(0,res.length-1);
    }else{res='0';}
    return parseFloat(res);
  }

  //Date{stringUtil.language(1135)}格式化。  Date{stringUtil.language(1135)}{stringUtil.language(1039)}.Format("yyyy-MM-dd HH:mm:ss")
  Date.prototype.Format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, //stringUtil.language(1178)份 
        "d+": this.getDate(), //stringUtil.language(1179) 
        "h+": this.getHours(), //小时 
        "m+": this.getMinutes(), //分 
        "s+": this.getSeconds(), //秒 
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
        "S": this.getMilliseconds() //毫秒 
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o){
      if (new RegExp("(" + k + ")").test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }
    }
    return fmt;
  }

  
/*对象合并 */
  export function stylesAdd(styleone,styletwo,styleThree={}){
    return Object.assign(JSON.parse(JSON.stringify(styleone)),styletwo,styleThree);
  }

/**
 * 获取当前{stringUtil.language(1067)}{stringUtil.language(1039)}
 * isDateTime为1日期，2时间，0默认日期加时间
 */
 export function getCurrDateTime(isDateTime=0,isSettleTime=false){
    var timestamp = Date.parse(new Date());
    timestamp = timestamp / 1000;
    //获取当前{stringUtil.language(1039)}
    var n = timestamp * 1000;
    if(isSettleTime){
      let settleTime=localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime!=null?JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime:4;
      n=n-settleTime*60*60*1000;
    }
   
    var date = new Date(n);
    //年
    var Y = date.getFullYear();
    //stringUtil.language(1178)
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    //stringUtil.language(1179)
    var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    //时
    var h = date.getHours();
    //分
    var m = date.getMinutes();
    //秒
    var s = date.getSeconds();
    if(isDateTime==1){//Y-M-D
      return Y+"-"+M+"-"+D;
    } else if (isDateTime == 2) {//H:m:s
      return h+":"+m+":"+s;
    } else {//Y-M-D H:m:s
      return Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s;
    }
  }

  //获取当前秒
  export function getCurrSeconds(){
    return new Date((Date.parse(new Date())/1000)*1000).getSeconds();
  }
  
  /**
   * 获取type{stringUtil.language(1191)}前{stringUtil.language(1067)}{stringUtil.language(1039)}。type=0为当{stringUtil.language(1191)}
   * hhmmss 自己传需要的时分秒
   */
  export function getCurrBefourDateTime(type, hhmmss) {
      let now = new Date();
      let date
      if (type === 0) {
          date = new Date(now + 8 * 3600 * 1000); // 增加8小时 当{stringUtil.language(1191)}
      } else {
          let now = new Date();
          date = new Date(now - 3 * type * 8 * 3600 * 1000); // type{stringUtil.language(1191)}前
      }
      var result = date.toJSON().substr(0, 19).replace('T', ' ').substring(0, 10) + ' ' + hhmmss;
      if(hhmmss==''){
        result = date.toJSON().substr(0, 19).replace('T', ' ').substring(0, 10);
      }
      return result;
  }
  
  /**
   * {stringUtil.language(1039)}戳{stringUtil.language(1181)}化为年 stringUtil.language(1178) stringUtil.language(1179) 时 分 秒
   * time : 传入时间 2018-07-26 17:58:54
   * format：返回格式，支持自定义，但参数必须与formateArr里保持一致
   * .formatToTime('2020-11-24 23:53:20','{stringUtil.language(19)}')
  */
 export function formatToTime(time, format) {
    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];
    var time = time.replace(/-/g, '/');
    var date = Date.parse(time);
    date = new Date(date);
    returnArr.push(date.getFullYear());
    returnArr.push(parseInt(date.getMonth() + 1));
    returnArr.push(parseInt(date.getDate()));
    returnArr.push(parseInt(date.getHours()));
    returnArr.push(parseInt(date.getMinutes()));
    returnArr.push(parseInt(date.getSeconds()));
    for (var i in returnArr) {
      let returnArrVal = returnArr[i];
      if (returnArrVal<10) {
        returnArrVal = '0'+returnArrVal;
      }
      format = format.replace(formateArr[i], returnArrVal);
    }
    return format;
  }

  /**
   * {stringUtil.language(1039)}戳{stringUtil.language(1181)}化为年 stringUtil.language(1178) stringUtil.language(1179) 时 分 秒
   * time : 传{stringUtil.language(1195)}Date{stringUtil.language(1135)}{stringUtil.language(1039)}new Date({stringUtil.language(1039)}戳)
   * format：返回格式，支持自定义，但参数必须与formateArr里保持一致 'Y-M-D h:m:s'
   * .formatToTimeStr('2020-11-24 23:53:20','{stringUtil.language(19)}')
  */
 export function formatToTimeStr(time, format) {
    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];
    returnArr.push(time.getFullYear());
    returnArr.push(parseInt(time.getMonth() + 1));
    returnArr.push(parseInt(time.getDate()));
    returnArr.push(parseInt(time.getHours()));
    returnArr.push(parseInt(time.getMinutes()));
    returnArr.push(parseInt(time.getSeconds()));
    for (var i in returnArr) {
      let returnArrVal = returnArr[i];
      if (returnArrVal<10) {
        returnArrVal = '0'+returnArrVal;
      }
      format = format.replace(formateArr[i], returnArrVal);
    }
    return format;
  }
  //与formatToTimeStr一样，但返回的值若小于10将不显示前面的0
  export function formatToTimeStrs(time, format) {
    var formateArr = ['Y', 'M', 'D', 'h', 'm', 's'];
    var returnArr = [];
    returnArr.push(time.getFullYear());
    returnArr.push(parseInt(time.getMonth() + 1));
    returnArr.push(parseInt(time.getDate()));
    returnArr.push(parseInt(time.getHours()));
    returnArr.push(parseInt(time.getMinutes()));
    returnArr.push(parseInt(time.getSeconds()));
    for (var i in returnArr) {
      let returnArrVal = returnArr[i];
      format = format.replace(formateArr[i], returnArrVal);
    }
    return format;
  }
  /**
   * {stringUtil.language(1039)}或{stringUtil.language(1067)}{stringUtil.language(1181)}化为{stringUtil.language(1039)}戳（毫秒）
   * time : 'Y-M-D h:m:s'
   * tag:0开始{stringUtil.language(1039)}，1不是开始{stringUtil.language(1039)}（结束{stringUtil.language(1039)}），2{stringUtil.language(1067)}自定义,{stringUtil.language(1039)}为当前{stringUtil.language(1039)}
  */
 export function timeToNumber(time,tag=1) {
    let settleTime=localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime!=null?JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime:4;
    let isDate=false;
    if(time!=null&&(time+'').length<=11){
      let res = time+' 23:59:59';
      if(tag==0||tag==2)res = time+' 00:00:00';
      time=res;
      isDate=true;
    }
    if(tag==2)time=formatToTime(time,'Y-M-D ')+getCurrDateTime(2);
    var timestamp = (new Date(time)).valueOf();
    if(isDate&&tag==1)timestamp=timestamp.add(999);
    return timestamp;
  }

  export function timeToSettleNumber(time,tag=1) {
    let settleTime=localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime!=null?JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime:4;
    let isDate=false;
    if(time!=null&&(time+'').length<=11){
      let res = time+' 23:59:59';
      if(tag==0)res = time+' 00:00:00';
      time=res;
      isDate=true;
    }
    var timestamp = (new Date(time)).valueOf();
    if(isDate&&tag==1)timestamp=timestamp.add(999);
    return timestamp.add((3600000).mul(settleTime));
  }

  //{stringUtil.language(787)}毫秒差
  export function settleSub(timestamp,isBeforeOne=0) {
    let settleTime=localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime!=null?JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime:4;
    if(isBeforeOne==0)timestamp=timestamp.add((3600000).mul(settleTime));
    if(new Date(getCurrDateTime())<new Date(getCurrDateTime(1)+' '+(parseInt(settleTime+'')<10?'0'+settleTime:settleTime+'')+':00:00')){
      timestamp=timestamp.sub(86400000);
    }
    return timestamp;
  }

  /**
   * 字符串{stringUtil.language(1181)}换成{stringUtil.language(1039)}格式{stringUtil.language(1067)}
   * time : 'Y-M-D h:m:s'
  */
  export function strToDate(time) {
    if(typeof(time)=='undefined' || time==null) return getCurrDateTime();
    if(time.indexOf(":")==-1){
      time = time+' 00:00:00';
    }
    var arr1 = time.split(" "); 
    var sdate = arr1[0].split('-'); 
    var date = new Date(sdate[0], sdate[1]-1, sdate[2]); 
    return date;
  }

  /**
   * 间隔{stringUtil.language(1191)}数
   * startDate起始{stringUtil.language(1039)}戳，endDate结束{stringUtil.language(1039)}戳
   */
  export function getDateDiff(startDates,endDates){
    // let stime = Date.parse(new Date(startDates));
    // let etime = Date.parse(new Date(endDates));
    // // 两个{stringUtil.language(1039)}戳相差的毫秒数
    // let usedTime = etime - stime;
    // // {stringUtil.language(1151)}相差的{stringUtil.language(1191)}数  
    // let days = Math.floor(usedTime / (24 * 3600000 ));
    // // {stringUtil.language(1151)}{stringUtil.language(1191)}数后{stringUtil.language(1058)}的毫秒数
    // let leave1 = usedTime % (24 * 3600000 );  
    // // {stringUtil.language(1151)}{stringUtil.language(1194)}小时数  
    // let hours = Math.floor(leave1 / 3600000);
    // // {stringUtil.language(1151)}小时数后{stringUtil.language(1058)}的毫秒数
    // let leave2 = leave1 % 3600000;        
    // // {stringUtil.language(1151)}相差分钟数
    // var minutes = Math.floor(leave2 / 60000);
    // let dates = days + '{stringUtil.language(1191)}'+ hours + '时' + minutes + '分';
    let dates = Math.ceil(Math.abs((startDates - endDates))/(1000*60*60*24));//例1.75，ceil向上取整2，round四舍五{stringUtil.language(1195)}2，floor向下取整1
    return dates;
  }

  /*图片显示处理*/
  export function imageShow(urlStr,type=0) {
    let resultUrl;
    if (typeof(urlStr)!=undefined && urlStr!=null && urlStr!='') {
      resultUrl = urlUtil.FILE_ADDRESS_URL+urlStr;
    }else{
      if(type==0){//stringUtil.language(1098)
        resultUrl = require('../images/default_head.png').default;
      }else if(type==1){//stringUtil.language(1063)
        resultUrl = require('../images/default_commodity.png').default;
      }else if(type==2){//商户
        resultUrl = require('../images/default_shop.png').default;
      }
    }
    return resultUrl;
  }

  //后台数据库存储：{stringUtil.language(1041)}(分)、{stringUtil.language(1022)}(克)，前台显示：{stringUtil.language(1041)}(元)、{stringUtil.language(1022)}({stringUtil.language(1023)}/市{stringUtil.language(1172)})
  //元-->分
  export function yuanToFen(yuan,acc=0){
    let yuanTemp = parseFloat(yuan+'');
    let result = yuanTemp.mul(100.0,acc);
    return result;
  }
  //分-->元
  export function fenToYuan(fen,decimal=2){
    let fenTemp = parseFloat(fen+'');
    let result = fenTemp.div(100.0, decimal);
    return result;
  }
  //{stringUtil.language(1023)}-->克
  export function gongjinToKe(gongjin){
    let gongjinTemp = parseFloat(gongjin+'');
    let result = gongjinTemp.mul(1000.0, 0);
    return result;
  }
  //克-->{stringUtil.language(1023)}
  export function keToGongjin(ke){
    let keTemp = parseFloat(ke+'');
    let result = keTemp.div(1000.0, 2);
    return result;
  }
  //市{stringUtil.language(1172)}-->克
  export function shijinToKe(shijin){
    let shijinTemp = parseFloat(shijin+'');
    let result = shijinTemp.mul(500.0, 0);
    return result;
  }
  //克-->市{stringUtil.language(1172)}
  export function keToShijin(ke){
    let keTemp = parseFloat(ke+'');
    let result = keTemp.div(500.0, 2);
    return result;
  }
  /**克-->{stringUtil.language(1023)}/市{stringUtil.language(1172)}
   * mode：0{stringUtil.language(1023)}，1市{stringUtil.language(1172)}
  */
  export function keToWeight(ke,mode){
    let keTemp = parseFloat(ke+'');
    let result = 0;
    if (mode==0) {
      result = keTemp.div(1000.0, 2);
    }else{
      result = keTemp.div(500.0, 2);
    }
    return result;
  }
  /**{stringUtil.language(1023)}/市{stringUtil.language(1172)}-->克
   * mode：0{stringUtil.language(1023)}，1市{stringUtil.language(1172)}
  */
   export function weightToKe(weight,mode){
    let weightTemp = parseFloat(weight+'');
    let result = 0;
    if (mode==0) {
      result = weightTemp.mul(1000.0, 0);
    }else{
      result = weightTemp.mul(500.0, 0);
    }
    return result;
  }
  /**{stringUtil.language(1022)}{stringUtil.language(1050)}{stringUtil.language(1181)}换。sourceMode：原{stringUtil.language(813)}，0{stringUtil.language(1023)}/1市{stringUtil.language(1172)}   toMode{stringUtil.language(1181)}换的{stringUtil.language(813)}*/
   export function unitPriceTo(unitPrice,sourceMode,toMode){
    let unitPriceTemp = parseFloat(unitPrice+'');
    let result = 0;
    if (sourceMode==0 && toMode==1) {//{stringUtil.language(1023)}-->市{stringUtil.language(1172)}
      result = unitPriceTemp.div(2.0);
    }else if (sourceMode==1 && toMode==0) {//市{stringUtil.language(1172)}-->{stringUtil.language(1023)}
      result = unitPriceTemp.mul(2.0);
    }else{
      result = unitPriceTemp;
    }
    return result;
  }
  /**{stringUtil.language(1021)}{stringUtil.language(1050)}{stringUtil.language(1181)}换。scale:比例(1辅=scale主)    sourceMode：原{stringUtil.language(1152)}，0主(数据库以整数存储的最小{stringUtil.language(1046)})/1辅   toMode{stringUtil.language(1181)}换的{stringUtil.language(1152)}0主/1辅*/
  export function unitPriceToNum(unitPrice,scale,sourceMode,toMode,decimal=2){
    let unitPriceTemp=parseFloat(unitPrice+''),scaleTemp=parseFloat(scale+'');
    let result = 0;
    if (sourceMode==0 && toMode==1) {//主(小{stringUtil.language(1046)}，相当于克)-->辅
      result = unitPriceTemp.mul(scaleTemp);
    }else if (sourceMode==1 && toMode==0) {//辅-->主(小{stringUtil.language(1046)}，相当于克)
      result = unitPriceTemp.div(scaleTemp,decimal);
    }else{
      result = unitPriceTemp;
    }
    return result;
  }

  //返回取整后的数字
  export function getRoundMoney(money){
    money = fenToYuan(money);
		let nTemp=parseInt(money)/*整数部分*/,nResult=nTemp%10/*个位数字*/;
		let resultRound=0,dResult = money - nTemp + nResult;//个位+小数
		if (dResult>=3 && dResult<5){//[3,8)个位5
			resultRound = nTemp - nResult + 5;
		}else if (dResult>=8 && dResult<10){//[8,10)向十位进1
			resultRound = nTemp - nResult + 10;
		}else {
			resultRound = money;
		}
		return yuanToFen(resultRound);
  }