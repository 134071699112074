import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(91)}
 export default class BuyerSortActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        buyerRankDO:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SALE_BUYER_RANK_URL,{
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime)
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({buyerRankDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:50}}>{stringUtil.language(888)}</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                            this.initData();
                        }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                            this.initData();
                        }} /></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(91)}</span>
                        <span style={{fontSize:15}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}</span>
                        <span style={{fontSize:15}}>{arithUtil.formatToTimeStr(new Date(this.state.searchs.startTime),'Y-M-D')+' '+stringUtil.language(1174)+' ' +arithUtil.formatToTimeStr(new Date(this.state.searchs.endTime),'Y-M-D')}&nbsp;&nbsp;&nbsp;{stringUtil.language(1046)}:{stringUtil.currency(1)}</span>
                    </div>:''}
                    <div style={{height:30,borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'29%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1031)}</span>
                        <span style={{width:'21%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'23%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'27%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(889)}</span>
                    </div>
                    {(()=>{this.state.totalInfo={saleNumber:0,saleWeight:0,saleMoney:0};})()}
                    {this.state.buyerRankDO?this.state.buyerRankDO.map((item,key)=>{
                        this.state.totalInfo={
                            saleNumber:(this.state.totalInfo.saleNumber).add(item.saleNumber),
                            saleWeight:(this.state.totalInfo.saleWeight).add(item.saleWeight),
                            saleMoney:(this.state.totalInfo.saleMoney).add(item.saleMoney),
                        };
                        return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'29%',fontSize:12}}>{key+1}、{item.buyerName}</span>
                            <span style={{width:'21%',fontSize:12,textAlign:'center'}}>{item.saleNumber}</span>
                            <span style={{width:'23%',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                            <span style={{width:'27%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleMoney)}</span>
                        </div>);
                    }):''}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'29%',color:'#D2855D',textAlign:'center'}}>{stringUtil.language(890)}</span>
                        <span style={{width:'21%',color:'#D2855D',fontSize:12,textAlign:'center'}}>{this.state.totalInfo.saleNumber}</span>
                        <span style={{width:'23%',color:'#D2855D',fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</span>
                        <span style={{width:'27%',color:'#D2855D',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleMoney)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};