import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

 export default class CargoTransferQuarrelActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        newCargoSourceWay:0,//0新增，1在售
        sourceBatchItem:paramsPage.cargoItemInfo?JSON.parse(JSON.stringify(paramsPage.cargoItemInfo)):{},
        currBatchItem:paramsPage.cargoItemInfo?JSON.parse(JSON.stringify(paramsPage.cargoItemInfo)):{},
        defaultCargoNo:1,
        wholesaleCargosData:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:2,//-1库存 0代销 1stringUtil.language(1051) 2炒货
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let wholesaleCargosDataTemp = successResponse.data.items;
                for(let i=0;i<wholesaleCargosDataTemp.length;i++){
                    let cargoItem = wholesaleCargosDataTemp[i];
                    if(cargoItem.targetId==this.state.sourceBatchItem.targetId)this.state.wholesaleCargosData.push(cargoItem);
                }
                this.setState({wholesaleCargosData: this.state.wholesaleCargosData});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        for(let i=0;i<this.state.sourceBatchItem.commodities.length;i++){
            let sourchItemTemp = this.state.sourceBatchItem.commodities[i];
            for(let j=0;j<this.state.currBatchItem.commodities.length;j++){
                let currItemTemp = this.state.currBatchItem.commodities[j];
                if(sourchItemTemp.id==currItemTemp.id){
                    this.state.currBatchItem.commodities[j].sendNumber = 0;
                    this.state.currBatchItem.commodities[j].sendWeight = 0;
                    let numberTotal = sourchItemTemp.sendNumber+sourchItemTemp.transferNumber+sourchItemTemp.purchaseNumber+sourchItemTemp.stockInOutNumber;
                    let weightTotal = (sourchItemTemp.sendWeight).add(sourchItemTemp.transferWeight).add(sourchItemTemp.purchaseWeight).add(sourchItemTemp.stockInOutWeight);
                    this.state.currBatchItem.commodities[j].transferNumber = numberTotal-sourchItemTemp.saleNumber;
                    this.state.currBatchItem.commodities[j].transferWeight = weightTotal.sub(sourchItemTemp.saleWeight, 1);
                    let costTotal = sourchItemTemp.purchaseGoodsMoney;
                    if(sourchItemTemp.purchaseCosts){
                        for(let a=0;a<sourchItemTemp.purchaseCosts.length;a++){
                            costTotal = costTotal.add(sourchItemTemp.purchaseCosts[a].money);
                        }
                    }
                    costTotal = costTotal.add(sourchItemTemp.processMoney?sourchItemTemp.processMoney:0);
                    if(numberTotal!=0)this.state.currBatchItem.commodities[j].transferMoney = costTotal.mul(this.state.currBatchItem.commodities[j].transferNumber).div(numberTotal,2);
                    else this.state.currBatchItem.commodities[j].transferMoney = costTotal.mul(this.state.currBatchItem.commodities[j].transferWeight).div(weightTotal,2);
                    if(this.state.currBatchItem.commodities[j].transferNumber>0){
                        if(this.state.currBatchItem.commodities[j].transferWeight<=0){
                            if(this.state.currBatchItem.commodities[j].standard){
                                this.state.currBatchItem.commodities[j].transferWeight=(this.state.currBatchItem.commodities[j].transferNumber).mul(this.state.currBatchItem.commodities[j].standard);
                            }else{
                                this.state.currBatchItem.commodities[j].transferWeight=0;
                            }
                        }
                    }else{
                        if(this.state.currBatchItem.commodities[j].transferWeight<=0){
                            this.state.currBatchItem.commodities.splice(j,1);
                        }else{this.state.currBatchItem.commodities[j].transferNumber=0;}
                    }
                }
            }
        }
        stringUtil.httpRequest(urlUtil.SALE_CARGO_NO_URL,{cargoMode:2},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.defaultCargoNo = (successResponse.data)[JSON.parse(localStorage.getItem("currentUserItem")).id]?(successResponse.data)[JSON.parse(localStorage.getItem("currentUserItem")).id]:1;
                if(this.state.newCargoSourceWay)this.state.currBatchItem.cargoNo = null;
                else this.state.currBatchItem.cargoNo = this.state.defaultCargoNo;
                this.setState({currBatchItem:this.state.currBatchItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <div style={{width:'100%',height:'6%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={{flexGrow:1,height:'100%',display:'flex'}}><TabTopActivity viewHeight='100%' viewWidth='100%' context={this.props.context} navigationStacks={this.props.navigationStacks}/></div>
                <div style={{height:'100%',backgroundColor:'#ECECEC',display:'flex',flexDirection:'row',alignItems:'center',paddingRight:10}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.currBatchItem.cargoNo = this.state.defaultCargoNo;
                        this.setState({newCargoSourceWay:0});
                    }}><i className="iconfontIndexCss" style={{fontSize:this.state.newCargoSourceWay==0?16:13,color:this.state.newCargoSourceWay==0?'#52A056':'#C0C0C0'}}>&#xe614;</i>{stringUtil.language(433)}</div>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                        this.state.currBatchItem.cargoNo = null;
                        this.setState({newCargoSourceWay:1});
                    }}><i className="iconfontIndexCss" style={{fontSize:this.state.newCargoSourceWay==1?16:13,color:this.state.newCargoSourceWay==1?'#52A056':'#C0C0C0'}}>&#xe614;</i>{stringUtil.language(434)}</div>
                </div>
            </div>
            <div style={{width:'100%',height:'94%',backgroundColor:'#FFF',position:'relative'}}>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5}}>
                    <span>{stringUtil.language(92)} / {this.state.sourceBatchItem.cargoNo}</span>
                    {this.state.newCargoSourceWay==0?<div style={{width:350,height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(437)}</span><input style={{flexGrow:1,height:'100%',display:'flex',padding:0}} type='number' value={this.state.currBatchItem.cargoNo?this.state.currBatchItem.cargoNo+'':''} onChange={(event)=>{
                            if(/(^[1-9]\d*$)/.test(event.target.value+'')){//正整数
                                this.state.currBatchItem.cargoNo = event.target.value;
                            }
                            this.setState({currBatchItem:this.state.currBatchItem});
                        }} placeholder={stringUtil.language(87)}/>
                    </div>:<div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:60}}>{stringUtil.language(437)}</span><select style={{width:290,height:25,color:this.state.currBatchItem.cargoNo?'#000':'#DCDCDC'}} onChange={(event)=>{
                            this.state.currBatchItem.cargoId = JSON.parse(event.target.value).id;
                            this.state.currBatchItem.cargoNo = JSON.parse(event.target.value).cargoNo;
                            this.setState({currBatchItem:this.state.currBatchItem});
                        }}>
                            <option style={{display:'none'}}>{stringUtil.language(140)}</option>
                            {this.state.wholesaleCargosData.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.cargoMode==0?item.targetName:(item.cargoMode==1?stringUtil.language(426)+(item.targetName?'_'+item.targetName:''):stringUtil.language(438))} / {item.cargoNo}</option>)
                            })}
                        </select>
                    </div>}
                </div>
                <div style={{width:'100%',height:20,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1}}>
                    <span style={{width:'23%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                    <span style={{width:'30%',color:'#F00',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(440)}/{stringUtil.language(1022)}</span>
                    <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1024)}</span>
                    <span style={{width:'13%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1025)}</span>
                    <span style={{width:'19%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1060)}</span>
                </div>
                <div style={{position:'absolute',left:0,top:50,right:0,bottom:40,borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,paddingLeft:5,paddingRight:5,overflow:'auto'}}>
                    {this.state.currBatchItem.commodities?this.state.currBatchItem.commodities.map((item,key)=>{
                        return(<div style={{width:'100%',height:45,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'23%',textAlign:'left'}}>{item.commodityName}</span>
                            <span style={{width:'30%',textAlign:'center'}}>{item.transferNumber}{stringUtil.language(1173)} {arithUtil.keToWeight(item.transferWeight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                            <span style={{width:'15%',textAlign:'center'}}>{arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>
                            <span style={{width:'13%',textAlign:'center'}}>{arithUtil.keToWeight(item.reduce,this.state.weightMode)}</span>
                            <input style={{width:'19%',height:25,borderStyle:'solid',borderColor:'#87CEFA',borderWidth:1,borderRadius:2,textAlign:'center'}} type='number' defaultValue={item.transferMoney?arithUtil.fenToYuan(item.transferMoney):''} placeholder={'0'+stringUtil.currency(1)} onChange={(event)=>{
                                this.state.currBatchItem.commodities[key].transferMoney  = arithUtil.yuanToFen(event.target.value);
                            }}/>
                        </div>)
                    }):<div/>}
                </div>
                <div style={{width:'100%',height:40,position:'absolute',bottom:0,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderWidth:1,borderColor:'#DCDCDC'}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(858)}</span><input style={{height:'100%',padding:0}} onChange={(event)=>{
                            this.state.currBatchItem.receiveDescribe = event.target.value;
                        }} placeholder={stringUtil.language(12)}/>
                    </div>
                    <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if (this.state.currBatchItem.cargoNo==null || this.state.currBatchItem.cargoNo=='') {
                            ToastBox.error(stringUtil.language(136));return;
                        }
                        if (this.state.currBatchItem.commodities==null || this.state.currBatchItem.commodities.length==0) {
                            ToastBox.error(stringUtil.language(141));return;
                        }
                        let cargoInsertVo = {commodities:[]};
                        cargoInsertVo.transferId = this.state.sourceBatchItem.id;
                        cargoInsertVo.cargoMode = 2;
                        cargoInsertVo.cargoNo = this.state.currBatchItem.cargoNo!=null?this.state.currBatchItem.cargoNo:0;
                        if(this.state.currBatchItem.receiveDescribe)cargoInsertVo.receiveDescribe = this.state.currBatchItem.receiveDescribe;
                        for(let i=0;i<this.state.currBatchItem.commodities.length;i++){
                            let commodityTemp = {costs:[]};
                            commodityTemp.commodityId = this.state.currBatchItem.commodities[i].commodityId!=null?this.state.currBatchItem.commodities[i].commodityId:0;
                            commodityTemp.standard = this.state.currBatchItem.commodities[i].standard!=null?this.state.currBatchItem.commodities[i].standard:0;
                            commodityTemp.reduce = this.state.currBatchItem.commodities[i].reduce!=null?this.state.currBatchItem.commodities[i].reduce:0;
                            commodityTemp.sendNumber = this.state.currBatchItem.commodities[i].sendNumber!=null?this.state.currBatchItem.commodities[i].sendNumber:0;
                            commodityTemp.sendWeight = this.state.currBatchItem.commodities[i].sendWeight!=null?this.state.currBatchItem.commodities[i].sendWeight:0;
                            commodityTemp.transferNumber = this.state.currBatchItem.commodities[i].transferNumber!=null?this.state.currBatchItem.commodities[i].transferNumber:0;
                            commodityTemp.transferWeight = this.state.currBatchItem.commodities[i].transferWeight!=null?this.state.currBatchItem.commodities[i].transferWeight:0;
                            commodityTemp.transferMoney = this.state.currBatchItem.commodities[i].transferMoney!=null?this.state.currBatchItem.commodities[i].transferMoney:0;
                            if (this.state.currBatchItem.commodities[i].costs==null)this.state.currBatchItem.commodities[i].costs=[];
                            for(let j=0;j<this.state.currBatchItem.commodities[i].costs.length;j++){
                                let costsSource = JSON.parse(JSON.stringify(this.state.currBatchItem.commodities[i].costs[j]));
                                commodityTemp.costs.push({
                                    costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                    money:costsSource.money!=null?costsSource.money:0,
                                });
                            }
                            cargoInsertVo.commodities.push(commodityTemp);
                        }
                        if(this.state.newCargoSourceWay==0){//{stringUtil.language(433)}
                            stringUtil.httpRequest(urlUtil.SALE_CARGO_URL,cargoInsertVo,['json','post',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }else{//{stringUtil.language(434)}上{stringUtil.language(1129)}{stringUtil.language(1055)}来的{stringUtil.language(1063)}
                            stringUtil.httpRequest(urlUtil.SALE_CARGO_COMMODITY_URL+'/'+this.state.currBatchItem.cargoId,{transferId:this.state.sourceBatchItem.id,items:cargoInsertVo.commodities},['json','put',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }
                    }}>{stringUtil.language(441)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
};