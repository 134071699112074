import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class ProcessOrdersActivity extends Component{
  constructor(props) {
      super(props);
      this.state = {
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchConditions:{
            cargoId:"",
            warehouseId:-1
        },
        infoShowPop:false,
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        processItemAll:[],
        currProcessItem:{},
      }
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.getDataList();
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_PROCESS_SEARCH_URL,{
            cargoId:this.state.searchConditions.cargoId,
            warehouseId:this.state.searchConditions.warehouseId,
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let processSearchObj = successResponse.data;
                this.state.totalNum = processSearchObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.state.processItemAll = JSON.parse(JSON.stringify(processSearchObj.items));
                this.setState({
                    totalNum:this.state.totalNum,
                    totalPage:this.state.totalPage,
                    processItemAll:this.state.processItemAll
                });
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
		var _this= this;
		_this.setState({goValue : e.target.value})
		var value = e.target.value;
		if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
		else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
		else _this.pageClick(value);
	}
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

    render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
        <div style={{height:'93%',padding:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
            <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1040)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1039)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',color:'#A9A9A9'})}>{stringUtil.language(1064)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1084)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(949)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1111)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
            </div>
            <div style={{height:this.state.totalPage>1?'87%':'97%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                {this.state.processItemAll.map((item,key)=>{
                    let moneyTotal = 0;
                    for(let i=0;i<item.costs.length;i++){
                        moneyTotal = moneyTotal.add(item.costs[i].money);
                    }
                    return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{textAlign:'left'})}>JG.{item.processNo}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',fontSize:10})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',fontSize:13,textAlign:'left'})}>{stringUtil.subStr(item.warehouseName,20)}</span>
                        <span style={styles.tableTd}>{item.cargoNo}</span>
                        <span style={styles.tableTd}>{item.workerName}</span>
                        <span style={styles.tableTd}>{arithUtil.fenToYuan(moneyTotal)}</span>
                        <div style={arithUtil.stylesAdd(styles.tableTd,{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                            {item.state==0?(otherUtil.authorityFindCode("020302")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#228B22',cursor:'pointer'}} onClick={()=>{
                                this.state.currProcessItem = JSON.parse(JSON.stringify(item));
                                stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL+'/'+this.state.currProcessItem.id,null,['key','get',1],(successResponse)=>{
                                    if (successResponse.status==200) {
                                        this.state.currProcessItem = JSON.parse(JSON.stringify(successResponse.data));
                                        this.props.navigationStacks.push({title:stringUtil.language(565),navigatePage:'ProcessOrderSaveActivity',paramsPage:{currProcessInfo:this.state.currProcessItem}});
                                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcessOrderSaveActivity',mainStacks:this.props.navigationStacks}});
                                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                            }}>&#xe63a;</i>:<i className="iconfontIndexCss" style={{fontSize:25,color:'transparent'}}>&#xe63a;</i>):<i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                if(otherUtil.authorityFindCode("020302")!=null){
                                    this.state.currProcessItem = JSON.parse(JSON.stringify(item));
                                    stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL+'/'+this.state.currProcessItem.id,null,['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.currProcessItem = JSON.parse(JSON.stringify(successResponse.data));
                                            this.setState({infoShowPop: !this.state.infoShowPop})
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }else{ToastBox.error(stringUtil.language(321));}
                            }}>&#xe64c;</i>}
                            {otherUtil.authorityFindCode("020304")!=null?<i className="iconfontIndexCss" style={{fontSize:25,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                this.state.currProcessItem = JSON.parse(JSON.stringify(item));
                                this.setState({delShowPop:!this.state.delShowPop});
                            }}>&#xe612;</i>:<div/>}
                        </div>
                    </div>);
                })}
            </div>
            {this.state.totalPage>1?<div style={{height:'10%'}}>
                <Pagination total={this.state.totalNum}
                    current={this.state.currentPage}
                    totalPage={this.state.totalPage}
                    goValue={this.state.goValue}
                    pageClick={this.pageClick.bind(this)}
                    goPrev={this.goPrevClick.bind(this)}
                    goNext={this.goNext.bind(this)}
                    switchChange={this.goSwitchChange.bind(this)}/>
            </div>:''}
        </div>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(39)}
                </div>}
            okBnEvents={(event) => {
                this.setState({delShowPop: false});
                stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL+'/'+this.state.currProcessItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        <MaskModal title={stringUtil.language(566)+'（'+this.state.currProcessItem.processNo+'）'} visible={this.state.infoShowPop} width={arithUtil.pxToDp(700)} height={arithUtil.ProportionTo(document.body.clientHeight,0.85)}
            content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                <div style={{height:'5%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span>{stringUtil.language(322)}{stringUtil.subStr(this.state.currProcessItem.warehouseName,20)}/{this.state.currProcessItem.cargoNo}</span>
                    <span>{stringUtil.language(950)}{this.state.currProcessItem.workerName}</span>
                    <span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.currProcessItem.time),'Y-M-D h:m:s')}</span>
                </div>
                <div style={{width:'100%',height:'5%',display:'flex',flexDirection:'row',backgroundColor:'#DCDCDC'}}>
                    <div style={{width:'40%',display:'flex',flexDirection:'row'}}>
                        <span style={styles.modalTableTitleItem}>{stringUtil.language(951)}</span>
                        <span style={styles.modalTableTitleItem}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                        <span style={styles.modalTableTitleItem}>{stringUtil.language(952)}</span>
                    </div>
                    <div style={{width:'40%',display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(953)}</span>
                        <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1048)}</span>
                        <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                        <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1060)}</span>
                    </div>
                    <div style={{width:'20%',display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                        <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'70%'})}>{stringUtil.language(886)}</span>
                        <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'30%'})}>{stringUtil.language(1041)}</span>
                    </div>
                </div>
                <div style={{width:'100%',height:'82%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <div style={{width:'40%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                        {this.state.currProcessItem.processCommodities!=null?this.state.currProcessItem.processCommodities.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:arithUtil.pxToDp(5)}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{textAlign:'left'})}>{item.commodityName}</span>
                                <span style={styles.modalTableTitleItem}>{item.number}/{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={styles.modalTableTitleItem}>{(item.lossRate).mul(100)}%</span>
                            </div>)
                        }):<div/>}
                    </div>
                    <div style={{width:'40%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                        {this.state.currProcessItem.processFinishCommodities!=null?this.state.currProcessItem.processFinishCommodities.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:arithUtil.pxToDp(5)}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%',textAlign:'left'})}>{item.commodityName}</span>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%',textAlign:'left'})}>{item.customerName}</span>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{item.number}/{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{arithUtil.fenToYuan(item.money)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                    <div style={{width:'20%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                        {this.state.currProcessItem.costs!=null?this.state.currProcessItem.costs.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'70%',textAlign:'left'})}>{item.costClauseName}</span>
                                <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'30%'})}>{arithUtil.fenToYuan(item.money)}</span>
                            </div>)
                        }):<div/>}
                    </div>
                </div>
                <div style={{height:'8%'}}>
                    <span>{stringUtil.language(858)}{this.state.currProcessItem.describe}</span>
                </div>
            </div>} closeBnTopEvents={() => {this.setState({ infoShowPop: false, });}}/>
      </div>)
    }
}

const styles = {
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'13%',
        fontSize:13,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    modalTableTitleItem:{
        width:'33%',
        fontSize:13,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        color:'#808080'
    },
};