import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';

export default class TabProcess extends Component{
  constructor(props){
    super(props);
    this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchsPro:{
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
            state:-1,//(-1stringUtil.language(1097)，0stringUtil.language(1145)，1stringUtil.language(986))
            cargoId:null,
            workerId:-1,
            workerName:'',
            keyVal:''
        },
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        processSearchVOAll:[],
        keyTagVal:-1,
    };
    if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
  }
  componentDidMount(){
    this.getDataList();
    stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
        state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
        cargoMode:1,//-1库存 0代销 1stringUtil.language(1051) 2炒货
        currPage:0,
        pageSize:1000
    },['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.state.cargosData=successResponse.data.items;
            this.state.cargosData.unshift({id:-1,cargoMode:0,targetName:stringUtil.language(517),cargoNo:stringUtil.language(571)});
            this.setState({cargosData: this.state.cargosData});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.state.workersAll = successResponse.data;
            this.state.workersAll.unshift({id:0,noteName:stringUtil.language(431)});
            this.state.workersAll.unshift({id:-1,noteName:stringUtil.language(724)});
            this.setState({workersAll:this.state.workersAll});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
    getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_PROCESS_SEARCH_URL,{
            cargoId:this.state.searchsPro.cargoId!=-1?this.state.searchsPro.cargoId:null,
            warehouseId:-1,
            state:this.state.searchsPro.state,
            startTime:this.state.searchsPro.startTime,
            endTime:this.state.searchsPro.endTime,
            teamId:this.state.searchsPro.workerId,
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let processSearchVO = successResponse.data;
                this.state.totalNum = processSearchVO.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.setState({processSearchVOAll:JSON.parse(JSON.stringify(processSearchVO.items))});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
        var _this= this;
        _this.setState({goValue : e.target.value})
        var value = e.target.value;
        if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
        else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
        else _this.pageClick(value);
    }
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
        <div style={{height:'9%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
            <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsPro.startTime,1)),'Y-M-D'))} onChange={(date) => {
                    this.state.searchsPro.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                    this.getDataList();
                }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;
                <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsPro.endTime,1)),'Y-M-D'))} onChange={(date) => {
                    this.state.searchsPro.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                    this.getDataList();
                }} /></div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                    <span style={{width:70}}>{stringUtil.language(548)}:</span><select style={arithUtil.stylesAdd(styles.inputText,{width:150})} onChange={(event)=>{
                        this.state.searchsPro.workerId = JSON.parse(event.target.value).id;
                        this.state.searchsPro.workerName = JSON.parse(event.target.value).noteName;
                        this.getDataList();
                    }}>
                    {(this.state.workersAll?this.state.workersAll:[]).map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                    })}
                    </select>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                    <span style={{width:60}}>{stringUtil.language(1084)}:</span><select style={arithUtil.stylesAdd(styles.inputText,{width:200})} onChange={(event)=>{
                        this.state.searchsPro.cargoId = JSON.parse(event.target.value).id;
                        this.state.searchsPro.cargoNo = JSON.parse(event.target.value).cargoNo;
                        this.getDataList();
                    }}>
                    {(this.state.cargosData?this.state.cargosData:[]).map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.cargoMode==0?item.targetName:(item.cargoMode==1?stringUtil.language(426)+'_'+item.targetId:stringUtil.language(438))} / {item.cargoNo}</option>)
                    })}
                    </select>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.searchsPro.state = -1;//(-1stringUtil.language(1097)，0stringUtil.language(1145)，1stringUtil.language(986))
                        this.getDataList();
                    }}>{this.state.searchsPro.state==-1?<i className="iconfontIndexCss" style={{color:'#52A056',fontSize:15}}>&#xe71f;{stringUtil.language(984)}</i>:
                        <i className="iconfontIndexCss" style={{color:'#696969',fontSize:15}}>&#xe641;{stringUtil.language(984)}</i>}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.searchsPro.state = 0;//(-1stringUtil.language(1097)，0stringUtil.language(1145)，1stringUtil.language(986))
                        this.getDataList();
                    }}>{this.state.searchsPro.state==0?<i className="iconfontIndexCss" style={{color:'#52A056',fontSize:15,paddingLeft:20}}>&#xe71f;{stringUtil.language(985)}</i>:
                        <i className="iconfontIndexCss" style={{color:'#696969',fontSize:15,paddingLeft:20}}>&#xe641;{stringUtil.language(985)}</i>}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.searchsPro.state = 1;//(-1stringUtil.language(1097)，0stringUtil.language(1145)，1stringUtil.language(986))
                        this.getDataList();
                    }}>{this.state.searchsPro.state==1?<i className="iconfontIndexCss" style={{color:'#52A056',fontSize:15,paddingLeft:20}}>&#xe71f;{stringUtil.language(986)}</i>:
                        <i className="iconfontIndexCss" style={{color:'#696969',fontSize:15,paddingLeft:20}}>&#xe641;{stringUtil.language(986)}</i>}
                    </div>
                </div>
                {/* <div style={{width:200,height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF',marginLeft:15}}>
                    <SearchInput onChangeEvents={(value)=>{
                        this.state.searchsPro.keyVal = value;
                        console.log('stringUtil.language(1196)新刷新stringUtil.language(867)')
                    }} placeholder='输{stringUtil.language(1195)}{stringUtil.language(164)}'/>
                </div> */}
            </div>
            <div style={{height:30,paddingLeft:10,paddingRight:10,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth: 1,cursor:'pointer'}} onClick={()=>{
                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcessOrderAddActivity',mainStacks:[
                    {title:stringUtil.language(1128),navigatePage:''},
                    {title:stringUtil.language(352),navigatePage:'ProcessOrderAddActivity',paramsPage:stringUtil.language(352)+'参数'}
                ]}});
            }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D2855D'}}>&#xe601;</i>
                <span style={{color:'#D2855D',fontWeight:'bold'}}> {stringUtil.language(352)}</span>
            </div>
        </div>
        <div style={{height:'5%',display:'flex',alignItems:'center',backgroundColor:'#F2F2F2'}}>
            <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(725)}</span>
            <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(726)}</span>
            <span style={{width:'10%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(548)}</span>
            <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(727)}</span>
            <span style={{width:'12%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1064)}</span>
            <span style={{width:'10%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(987)}</span>
            <span style={{width:'10%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1065)}</span>
            <span style={{width:'22%',color:'#A9A9A9',fontSize:13,textAlign:'center'}}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{height:this.state.totalPage>1?'76%':'86%',backgroundColor:'#FFF',overflow:'auto'}}>
            {this.state.processSearchVOAll.map((item,key)=>{
                return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:'12%',fontSize:13}}>JG.{item.processNo}</span>
                    <span style={{width:'12%',fontSize:13,textAlign:'center'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                    <span style={{width:'10%',fontSize:13}}>{item.teamName}</span>
                    <span style={{width:'12%',fontSize:13,fontWeight:'bold'}}>{stringUtil.language(1175)}{item.cargoNo}{stringUtil.language(1176)}</span>
                    <span style={{width:'12%',fontSize:13}}>{item.warehouseName}</span>
                    <span style={{width:'10%',fontSize:13,textAlign:'center'}}>{item.workerName}</span>
                    <div style={{width:'10%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        {item.state?<span style={{color:'#FFF',fontSize:13,backgroundColor:'#52A056',borderStyle:'solid',borderColor:'#52A056',borderWidth:1,paddingLeft:5,paddingRight:5}}>{stringUtil.language(1144)}</span>:
                        <span style={{color:'#F00',fontSize:13,borderStyle:'solid',borderColor:'#F00',borderWidth:1,paddingLeft:5,paddingRight:5}}>{stringUtil.language(1145)}</span>}
                    </div>
                    <div style={{width:'22%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        <div style={{width:70,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#D4D4D4',borderRadius:5,marginLeft:3,marginRight:3,cursor:'pointer'}} onClick={()=>{
                            this.state.currProcessItem=JSON.parse(JSON.stringify(item));
                            this.setState({delShowPop:!this.state.delShowPop});
                        }}><span style={{color:'#696969'}}>{stringUtil.language(1113)}</span>
                        </div>
                        {/* <div style={{flexGrow:1,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#8AEAAA',borderRadius:5,marginLeft:3,marginRight:3,cursor:'pointer'}} onClick={()=>{
                            console.log('stringUtil.language(1092)事stringUtil.language(1173)执行')
                        }}><span style={{color:'#696969'}}>stringUtil.language(1092)</span>
                        </div> */}
                        {item.state?<div style={{width:70,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#25C557',borderRadius:5,marginLeft:3,marginRight:3,cursor:'pointer'}} onClick={()=>{
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcessOrderInfoActivity',mainStacks:[
                                {title:stringUtil.language(1128),navigatePage:''},
                                {title:stringUtil.language(566),navigatePage:'ProcessOrderInfoActivity',paramsPage:{processItem:JSON.stringify(item)}}
                            ]}});
                        }}><span style={{color:'#FFF'}}>{stringUtil.language(1146)}</span>
                        </div>:<div style={{width:70,height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#25C557',borderRadius:5,marginLeft:3,marginRight:3,cursor:'pointer'}} onClick={()=>{
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ProcessOrderSaveActivity',mainStacks:[
                                {title:stringUtil.language(1128),navigatePage:''},
                                {title:stringUtil.language(729),navigatePage:'ProcessOrderSaveActivity',paramsPage:{processItem:JSON.stringify(item)}}
                            ]}});
                        }}><span style={{color:'#FFF'}}>{stringUtil.language(728)}</span>
                        </div>}
                    </div>
                </div>)
            })}
        </div>
        {this.state.totalPage>1?<div style={{height:'10%'}}>
            <Pagination total={this.state.totalNum}
                current={this.state.currentPage}
                totalPage={this.state.totalPage}
                goValue={this.state.goValue}
                pageClick={this.pageClick.bind(this)}
                goPrev={this.goPrevClick.bind(this)}
                goNext={this.goNext.bind(this)}
                switchChange={this.goSwitchChange.bind(this)}/>
        </div>:''}
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(45)}
                </div>}
            okBnEvents={(event) => {
                this.state.delShowPop=false;
                stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL+'/'+this.state.currProcessItem.id,null,['key','delete',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.getDataList();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
    time:{
        height:25,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
    inputText:{
        height:30,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:5
    },
};