import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

  export default class OwnerCargoCommodityEditView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        cargoId:this.props.paramsInfo.cargoId,
        currBatchCommodityItem:JSON.parse(this.props.paramsInfo.currBatchCommodityItem),//1stringUtil.language(426)，2stringUtil.language(411)
        costsAll:[],
        delShowPop:false,
        selectDiglogTagEntCost:false
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({costsAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'100%'}}>
            {otherUtil.authorityFindCode("020104")!=null?<div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',position:'absolute',right:0,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                this.setState({delShowPop:!this.state.delShowPop});
            }}><i className="iconfontIndexCss" style={{fontSize:15,color:'#F00'}}>&#xe612;</i><span style={{color:'#F00',fontSize:13}}>{stringUtil.language(529)}</span>
            </div>:''}
            <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(306)}</span>
                    <input style={{width:100,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} placeholder='0' defaultValue={this.state.currBatchCommodityItem.sendNumber?this.state.currBatchCommodityItem.sendNumber:''} type='number' onChange={(event)=>{
                        this.state.currBatchCommodityItem.sendNumber = parseInt(event.target.value);
                        this.props.successEvent(this.state.currBatchCommodityItem);
                    }}/>
                    <span> {stringUtil.language(1173)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(307)}</span>
                    <input style={{width:100,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} placeholder='0' defaultValue={this.state.currBatchCommodityItem.sendWeight?arithUtil.keToWeight(this.state.currBatchCommodityItem.sendWeight,this.state.weightMode):''} type='number' onChange={(event)=>{
                        this.state.currBatchCommodityItem.sendWeight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                        this.props.successEvent(this.state.currBatchCommodityItem);
                    }}/>
                    <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                </div>
            </div>
            <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,paddingRight:10,borderBottomStyle:'solid',borderBottomColor:'#AFEEEE',borderBottomWidth:1}}>
                <div style={{width:'50%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(308)}</span>
                    <input style={{width:100,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} placeholder='0' defaultValue={this.state.currBatchCommodityItem.standard?arithUtil.keToWeight(this.state.currBatchCommodityItem.standard,this.state.weightMode):''} type='number' onChange={(event)=>{
                        this.state.currBatchCommodityItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                        this.props.successEvent(this.state.currBatchCommodityItem);
                    }}/>
                    <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(309)}</span>
                    <input style={{width:100,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} placeholder='0' defaultValue={this.state.currBatchCommodityItem.reduce?arithUtil.keToWeight(this.state.currBatchCommodityItem.reduce,this.state.weightMode):''} type='number' onChange={(event)=>{
                        this.state.currBatchCommodityItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                        this.props.successEvent(this.state.currBatchCommodityItem);
                    }}/>
                    <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                </div>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span>{stringUtil.language(89)}</span>
                <i className="iconfontIndexCss" style={{fontSize:30,color:'#696969',cursor:'pointer'}} onClick={()=>{
                    this.setState({selectDiglogTagEntCost:true});
                }}>&#xe601;</i>
                <MaskModal title={stringUtil.language(33)} visible={this.state.selectDiglogTagEntCost} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.5)}
                    content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                    {this.state.costsAll.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} onClick={()=>{
                                this.state.currBatchCommodityItem.costs.push({
                                    costClauseId:item.id,
                                    costClauseName:item.name,
                                    payee:item.payee,
                                    calMode:item.calMode,
                                    money:0
                                });
                                this.props.successEvent(this.state.currBatchCommodityItem);
                                this.setState({selectDiglogTagEntCost:false});
                            }}>
                                <span>{item.name}</span>
                                <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                                <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                        </div>);
                    })}
                </div>} closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCost:false});}}/>
            </div>
            <div style={{paddingLeft:10,paddingRight:10}}>
                {this.state.currBatchCommodityItem.costs!=null?this.state.currBatchCommodityItem.costs.map((item,key)=>{
                    return(<div key={key} style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                        <span>{key+1+'、'+item.costClauseName}</span>
                        <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                        <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                        {item.calMode==0?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input style={{width:80,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder='0' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                this.state.currBatchCommodityItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                this.props.successEvent(this.state.currBatchCommodityItem);
                            }}/>
                            <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                        item.calMode==1?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input style={{width:80,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder='0' defaultValue={item.money?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode):''} onChange={(event)=>{
                                this.state.currBatchCommodityItem.costs[key].money = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                this.props.successEvent(this.state.currBatchCommodityItem);
                            }}/>
                            <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                        item.calMode==2?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input style={{width:80,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder='0' defaultValue={item.money?item.money:''} onChange={(event)=>{
                                this.state.currBatchCommodityItem.costs[key].money = parseFloat(event.target.value);
                                this.props.successEvent(this.state.currBatchCommodityItem);
                            }}/>
                            <span style={{color:'#D2855D'}}> %</span></div>:
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                            <input style={{width:80,height:35,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,textAlign:'center'}} type='number' placeholder='0' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                this.state.currBatchCommodityItem.costs[key].money = arithUtil.yuanToFen(event.target.value);
                                this.props.successEvent(this.state.currBatchCommodityItem);
                            }}/></div>}
                        <i className="iconfontIndexCss" style={{fontSize:'20px',color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currBatchCommodityItem.costs.splice(key,1);
                            this.props.successEvent(this.state.currBatchCommodityItem);
                            this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem})
                        }}>&#xe612;</i>
                    </div>)
                }):<div/>}
            </div>
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(104)}
                </div>}
                okBnEvents={(event) => {
                    this.setState({delShowPop: false});
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_COMMODITY_URL+'/'+this.state.cargoId,{cargoCommodityId:this.state.currBatchCommodityItem.id},['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(530));
                            this.props.successEvent(-1);
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        </div>)
    }
}

const styles = {};