import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';

 //{stringUtil.language(236)}
 export default class PurchaseStatisticsActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            supplierId:-1,
            workerId:-1,
            commodityName:'',
            startTime:arithUtil.getCurrDateTime(1),
            endTime:arithUtil.getCurrDateTime(1)
        },
        purchaseSettleDOAll:[],
        suppliersAll:[],
        personsAll:[]
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
        stringUtil.httpRequest(urlUtil.OAUTH_SUPPLIER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.suppliersAll = successResponse.data;
                this.state.suppliersAll.unshift({id:-1,noteName:stringUtil.language(325),isDel:0});
                this.setState({suppliersAll:this.state.suppliersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.personsAll = successResponse.data;
                this.state.personsAll.unshift({id:0,noteName:stringUtil.language(431)});
                this.state.personsAll.unshift({id:-1,noteName:stringUtil.language(472)});
                this.setState({personsAll:this.state.personsAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_PURCHASE_SETTLE_URL,{
            commodityName:this.state.searchs.commodityName,
            supplierId:this.state.searchs.supplierId,
            workerId:this.state.searchs.workerId,
            startDate:this.state.searchs.startTime,
            endDate:this.state.searchs.endTime,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({purchaseSettleDOAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                            this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endTime)} onChange={(date) => {
                            this.state.searchs.endTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                        <select style={{width:150,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5,marginLeft:5}} onChange={(event)=>{
                            this.state.searchs.workerId = JSON.parse(event.target.value).id;
                            this.state.searchs.workerName = JSON.parse(event.target.value).noteName;
                            this.initData();
                        }}>{this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                        </select>
                        <select style={{width:200,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5,marginLeft:5}} onChange={(event)=>{
                            this.state.searchs.supplierId = JSON.parse(event.target.value).id;
                            this.state.searchs.supplierName = JSON.parse(event.target.value).noteName;
                            this.initData();
                        }}>{this.state.suppliersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                        </select>
                        <div style={{width:200,height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF',marginLeft:5}}>
                            <SearchInput onChangeEvents={(value)=>{
                                this.state.searchs.commodityName = value;
                                this.initData();
                            }} placeholder={stringUtil.language(35)}/>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="11"><b><font size="3">${stringUtil.language(581)}（${this.state.searchs.startTime}${stringUtil.language(1174)}${this.state.searchs.endTime}）</font></b></td></tr>
                            <tr><td colspan="11"><b><font size="3">${stringUtil.language(887)}${this.state.searchs.workerId!=-1?this.state.searchs.workerName:stringUtil.language(472)}，${stringUtil.language(422)}${this.state.searchs.supplierId!=-1?this.state.searchs.supplierName:stringUtil.language(325)}</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1067)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(921)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}【${this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1050)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1068)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1069)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1116)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1061)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(955)}</font></b></td></tr>`);
                            let totalInfo = {numberTotal:0,weightTotal:0,goodsMoneyTotal:0,otherMoneyTotal:0,moneyTotal:0};
                            for(let key=0;key<this.state.purchaseSettleDOAll.length;key++){let item=this.state.purchaseSettleDOAll[key];
                                totalInfo.numberTotal = (totalInfo.numberTotal).add(item.number);
                                totalInfo.weightTotal = (totalInfo.weightTotal).add(item.weight);
                                totalInfo.goodsMoneyTotal = (totalInfo.goodsMoneyTotal).add(item.goodsMoney);
                                totalInfo.otherMoneyTotal = (totalInfo.otherMoneyTotal).add(item.otherMoney);
                                totalInfo.moneyTotal = (totalInfo.moneyTotal).add(item.money);
                                let priceUnit = 0,avgPrice = 0;
                                if (item.mode==0) {//stringUtil.language(1114)
                                    if (item.weight!=0) {
                                        priceUnit = (arithUtil.fenToYuan(item.goodsMoney)).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)+'';
                                        avgPrice = (arithUtil.fenToYuan(item.money)).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)+'';
                                    }
                                    priceUnit = priceUnit+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172));
                                    avgPrice = avgPrice+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172));
                                }else{
                                    if (item.number!=0) {
                                        priceUnit = (arithUtil.fenToYuan(item.goodsMoney)).div(item.number,2)+'';
                                        avgPrice = (arithUtil.fenToYuan(item.money)).div(item.number,2)+'';
                                    }
                                    priceUnit = priceUnit+'/'+((item.commodityUnit!=null&&item.commodityUnit!='')?item.commodityUnit:stringUtil.language(1173));
                                    avgPrice = avgPrice+'/'+((item.commodityUnit!=null&&item.commodityUnit!='')?item.commodityUnit:stringUtil.language(1173));
                                }
                                resArr.push(`<tr><td align="left"><font size="3">${item.date}</font></td>
                                <td align="left"><font size="3">${item.commodityName}</font></td>
                                <td align="left"><font size="3">${item.supplierName}</font></td>
                                <td align="right"><font size="3">${item.number}</font></td>
                                <td align="right"><font size="3">${arithUtil.keToWeight(item.weight,this.state.weightMode)}</font></td>
                                <td align="right"><font size="3">${priceUnit}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.goodsMoney)}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.otherMoney)}</font></td>
                                <td align="right"><font size="3">${avgPrice}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(item.money)}</font></td>
                                <td align="left"><font size="3">${item.workerId==0?stringUtil.language(431):item.workerName}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left" colspan="3"><b><font size="3">${stringUtil.language(890)}</font></b></td>
                            <td align="right"><b><font size="3">${totalInfo.numberTotal}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.keToWeight(totalInfo.weightTotal,this.state.weightMode)}</font></b></td>
                            <td align="left"><b><font size="3"></font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.goodsMoneyTotal)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.otherMoneyTotal)}</font></b></td>
                            <td align="left"><b><font size="3"></font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.moneyTotal)}</font></b></td>
                            <td align="left"><b><font size="3"></font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),(this.state.searchs.workerId!=-1?this.state.searchs.workerName:'')+stringUtil.language(581) +'（'+this.state.searchs.startTime+stringUtil.language(1174)+this.state.searchs.endTime+'）');
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(581)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.startTime+' '+stringUtil.language(1174)+' ' +this.state.searchs.endTime}</span>
                    </div>:''}
                    <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'10%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1067)}</span>
                        <span style={{width:'10%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:'11%',fontSize:12,textAlign:'center'}}>{stringUtil.language(921)}</span>
                        <span style={{width:'14%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                        <span style={{width:'10%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1050)}</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1068)}({stringUtil.currency(1)})</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1116)}</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1061)}({stringUtil.currency(1)})</span>
                        <span style={{width:'9%',fontSize:12,textAlign:'center'}}>{stringUtil.language(955)}</span>
                    </div>
                    {(()=>{this.state.totalInfo = {numberTotal:0,weightTotal:0,goodsMoneyTotal:0,otherMoneyTotal:0,moneyTotal:0}})()}
                    {this.state.purchaseSettleDOAll.map((item,key)=>{
                        this.state.totalInfo.numberTotal = (this.state.totalInfo.numberTotal).add(item.number);
                        this.state.totalInfo.weightTotal = (this.state.totalInfo.weightTotal).add(item.weight);
                        this.state.totalInfo.goodsMoneyTotal = (this.state.totalInfo.goodsMoneyTotal).add(item.goodsMoney);
                        this.state.totalInfo.otherMoneyTotal = (this.state.totalInfo.otherMoneyTotal).add(item.otherMoney);
                        this.state.totalInfo.moneyTotal = (this.state.totalInfo.moneyTotal).add(item.money);
                        let priceUnit = 0,avgPrice = 0;
                        if (item.mode==0) {//stringUtil.language(1114)
                            if (item.weight!=0) {
                                priceUnit = (arithUtil.fenToYuan(item.goodsMoney)).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)+'';
                                avgPrice = (arithUtil.fenToYuan(item.money)).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)+'';
                            }
                            priceUnit = priceUnit+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172));
                            avgPrice = avgPrice+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172));
                        }else{
                            if (item.number!=0) {
                                priceUnit = (arithUtil.fenToYuan(item.goodsMoney)).div(item.number,2)+'';
                                avgPrice = (arithUtil.fenToYuan(item.money)).div(item.number,2)+'';
                            }
                            priceUnit = priceUnit+'/'+((item.commodityUnit!=null&&item.commodityUnit!='')?item.commodityUnit:stringUtil.language(1173));
                            avgPrice = avgPrice+'/'+((item.commodityUnit!=null&&item.commodityUnit!='')?item.commodityUnit:stringUtil.language(1173));
                        }
                        return(<div style={{display:'flex',flexDirection:'row'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%'})}>{item.date}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%'})}>{stringUtil.subStr(item.commodityName,5)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%'})}>{stringUtil.subStr(item.supplierName,6)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%'})}>{priceUnit}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.otherMoney)}</span>
                            <span style={styles.tableTd}>{avgPrice}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.money)}</span>
                            <span style={styles.tableTd}>{item.workerId==0?stringUtil.language(431):item.workerName}</span>
                        </div>)
                    })}
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{stringUtil.language(890)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'14%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{this.state.totalInfo.numberTotal} / {arithUtil.keToWeight(this.state.totalInfo.weightTotal,this.state.weightMode)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.otherMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.moneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    tableTd:{
        width:'9%',
        height:30,
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};