import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ShareAgreement extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
        searchs:{},
      }
    }
    componentDidMount(){}

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
          <span style={{height:40,fontSize:25,fontWeight:'bold',textAlignVertical:'center'}}>用户协议：</span>
          <span>        本《用户许可协议》是一份您（个人或{stringUtil.language(1189)}一法人团体）与广东卖菜帮软{stringUtil.language(1173)}有限公司就旗下产品之软{stringUtil.language(1173)}下载、使用，但不限于票据{stringUtil.language(1045)}、充值、{stringUtil.language(1048)}服务、{stringUtil.language(1063)}产销资讯、购销交易沟通等服务所达成的{stringUtil.language(1097)}协议（以下称“本协议”）。</span>
          <span>        本公司在此提示用户，本【软{stringUtil.language(1173)}产品】必须获得授权使用，需要支{stringUtil.language(1193)}有偿服务{stringUtil.language(1111)}。您一旦安装、拷贝或以{stringUtil.language(1069)}方式使用本产品，即表示{stringUtil.language(1131)}接受本协议条款之约束。</span>
          <span>        如您对本协议有任何疑问，请向本公司（400-802-0829）进行咨询。一旦您使用本服务，即表示您已阅读并完全{stringUtil.language(1131)}接受本协议项下所述条款和条{stringUtil.language(1173)}的约束。如果您不{stringUtil.language(1131)}本协议的任何条款，请您不要使用本【软{stringUtil.language(1173)}产品】服务。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>一、权利声明</span>
          <span>1、本产品版权属于广东卖菜帮软{stringUtil.language(1173)}有限公司所有，并受著作权法、国际版权公约以及其它知识产权之法律及条约之保护。公司享有本产品系统平台相关的软{stringUtil.language(1173)}、技术、程序、代{stringUtil.language(1184)}、用户界面、相关的商标、图形标记等各项内容完整的、不可分割的所有权及/或知识产权；</span>
          <span>2、本公司提供的服务内容中所涉及的数据，文字、软{stringUtil.language(1173)}、声音、图片、录像、图表等，均受相关知识产权法以及{stringUtil.language(1069)}相关法律的保护。未经本公司或者{stringUtil.language(1069)}相关权利人授权，用户不得复制、使用、{stringUtil.language(1160)}、摘编、翻译、{stringUtil.language(1186)}行,{stringUtil.language(1175)}三方未经本公司及/或其相关权利人的书面许可，不得以任何方式擅自进行使用。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>二、授权内容</span>
          <span>1、本『软{stringUtil.language(1173)}产品』需授权使用，属于支{stringUtil.language(1193)}有偿服务{stringUtil.language(1111)}，用户必须按照规定支{stringUtil.language(1193)}产品系统使用服务费；</span>
          <span>2、经卖菜帮授权您可以在任意电脑、{stringUtil.language(1088)}、平板、{stringUtil.language(1185)}银POS机上安装本产品的版本，并使用本『软{stringUtil.language(1173)}产品』；</span>
          <span>3、有偿服务{stringUtil.language(1111)}按计费标准{stringUtil.language(1151)}（计费标准统一{stringUtil.language(1186)}布），一旦您未履行有偿{stringUtil.language(1193)}费义务，我方有权中断您使用本『软{stringUtil.language(1173)}产品』的权利。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>三、平台方责任义务</span>
          <span>        除自然灾害、战争、国家电力、互联网全面中断等不可抗因素外，我方有责任和义务保障系统全年每{stringUtil.language(1191)}24小时不中断运行，为用户提供数据安全保障，并承诺提供稳定高效的应用服务。如{stringUtil.language(1186)}生意外我方将在最长2小时以内解决故障恢复运行。</span>
          <span>        如数据{stringUtil.language(1186)}生丢失或泄露，我方有责任承担相应损失及法律追诉之责任。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>四、用户义务与限制规范</span>
          <span>用户使用我方产品系统，必须必须遵守相关限制规定，履行用户义务：</span>
          <span>1、用户在本产品{stringUtil.language(1019)}时，不得使用虚假身份信息。用户应当妥善保存其{stringUtil.language(1140)}信息和{stringUtil.language(1183)}{stringUtil.language(1184)}，由于用户泄{stringUtil.language(1183)}所导致的损失需由用户自行承担。如用户{stringUtil.language(1186)}现他人冒用或盗用其{stringUtil.language(1140)}或{stringUtil.language(1183)}{stringUtil.language(1184)}，或其{stringUtil.language(1140)}存在{stringUtil.language(1069)}未经合法授权使用之情形，应立即以有效方式通知本公司。用户理解并{stringUtil.language(1131)}本公司有权根据用户的通知、请求或依据判断，采取相应的行动或措施，包括但不限于冻结账号、限制账号功能等，本公司对采取上述行动所导致的损失不承担除法律有明确规定外的责任；</span>
          <span>2、用户不得{stringUtil.language(1190)}事任何利用本公司平台系统漏洞进行有损{stringUtil.language(1069)}用户、本公司或互联网安全的行为；</span>
          <span>3、用户理解并{stringUtil.language(1131)}自行承担使用本服务的风险，且用户在使用本服务时，应遵循中国法律的相关规定，由于用户行为所造成的任何损害和后果，本公司均不承担除法律有明确规定外的责任；</span>
          <span>4、未经我方{stringUtil.language(1131)}，您不得将我方授权的账号给予任何{stringUtil.language(1175)}三方使用，否则我方有权终止本协议，并按照本合同授权年使用服务费的30%向乙方{stringUtil.language(1185)}取违约金；</span>
          <span>5、未经我方{stringUtil.language(1131)}，您不得让我方同行软{stringUtil.language(1173)}公司对我方软{stringUtil.language(1173)}进行登录、反向工程、反向编译或反汇编等抄袭，如有{stringUtil.language(1186)}生我方有权追究乙方相关法律责任；</span>
          <span>6、您应保留所有『软{stringUtil.language(1173)}产品』拷贝上之著作权标示，不得将本『软{stringUtil.language(1173)}产品』以任何方式去除或阻止其包含之内容、功能或广告；</span>
          <span>7、不论在何{stringUtil.language(1177)}情况下，本公司均不对由于网络连接故障、通讯线路、{stringUtil.language(1175)}三方网站、电脑硬{stringUtil.language(1173)}等任何原因给用户造成的任何损失承担除法律有明确规定外的责任；</span>
          <span>8、用户知悉并确认，本公司通过公告、邮{stringUtil.language(1173)}、短信、{stringUtil.language(1140)}通知以及用户在{stringUtil.language(1140)}中登记的即时通讯工具等方式，向用户{stringUtil.language(1186)}{stringUtil.language(1194)}关于本服务的通知、规则、提示等信息，均为有效通知。该等信息一经公布或{stringUtil.language(1186)}布，即视为已送达{stringUtil.language(1174)}用户。</span>

          <span style={{height:40,fontSize:25,fontWeight:'bold',textAlignVertical:'center',marginTop:20}}>隐私政策：</span>
          <span>本产品在使用过程中需要获取用户部分隐私信息，特此向用户说明：</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>一、允许应用读取、保存、分享{stringUtil.language(1073)}及联系人的相关数据。</span>
          <span>        获取用户{stringUtil.language(1099)}是为了本产品在{stringUtil.language(833)}以及{stringUtil.language(1045)}、{stringUtil.language(1094)}分享的时候显示；获取用户{stringUtil.language(1073)}是为了作为应用登录使用的唯一{stringUtil.language(1140)}，在{stringUtil.language(1189)}据{stringUtil.language(1045)}时候显示以及必要时候方便联系到{stringUtil.language(1048)}；获取{stringUtil.language(784)}是为了在{stringUtil.language(1045)}给{stringUtil.language(1048)}的{stringUtil.language(1189)}据上显示，方便{stringUtil.language(1048)}与商户之间联系，以及帮助{stringUtil.language(1048)}获悉{stringUtil.language(1063)}的来源等。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>二、允许应用读取设备上的已安装应用软{stringUtil.language(1173)}列表。</span>
          <span>        读取本地设备已安装应用是为了更新本产品，将完善或增加的功能即时提供给您使用；读取{stringUtil.language(1069)}的应用是为了合规使用其提供的相关服务项（如：{stringUtil.language(1094)}提供的分享、支{stringUtil.language(1193)}等）。本公司绝不会恶意传播和{stringUtil.language(1186)}布{stringUtil.language(1151)}机病毒以及{stringUtil.language(1069)}违法、违规信息。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>三、允许应用拍摄照片和视频</span>
          <span>        本产品采集照片或视频信息是为了帮助您更方便的识别{stringUtil.language(1048)}和分辨{stringUtil.language(1063)}。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>四、允许应用读取存储卡上的照片、媒体内容和文{stringUtil.language(1173)}。</span>
          <span>        产品读取设备本地存储卡照片、文{stringUtil.language(1173)}等内容也是为了帮助您更方便的识别{stringUtil.language(1048)}和分辨{stringUtil.language(1063)}，在{stringUtil.language(1037)}等{stringUtil.language(1026)}中更快捷的选取{stringUtil.language(1048)}和{stringUtil.language(1063)}。</span>
          <span style={{height:30,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>五、允许应用{stringUtil.language(1160)}或{stringUtil.language(1113)}存储卡上的照片、媒体内容和文{stringUtil.language(1173)}。</span>
          <span>        本产品{stringUtil.language(1160)}或{stringUtil.language(1113)}的照片或文{stringUtil.language(1173)}是本产品在使用中产生的文{stringUtil.language(1173)}，并且是在您知悉并{stringUtil.language(1131)}的情况下执行，本公司绝不会在您不知悉并且不{stringUtil.language(1131)}的情况下{stringUtil.language(1160)}或{stringUtil.language(1113)}您任何文{stringUtil.language(1173)}。</span>
          <span>        总述：本公司承诺绝不把获取的任何信息、图片、文{stringUtil.language(1173)}等内容在用户不知悉或不征求用户{stringUtil.language(1131)}的情况下用于任何商业行为以及非法透漏给他人，获取信息旨在更好的维护{stringUtil.language(1048)}与商户之间的合法权益，方便用户更好地使用本产品，如有违反，本公司愿承担相关法律责任。</span>
        </div>)
    }
}

const styles = {};