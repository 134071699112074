import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(284)}
 export default class CollectPayAccountsActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        collectPayRecordDO:{},
        currOwnerItem:{},
        dateTimeStr:arithUtil.getCurrDateTime(1),
      };
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_COLLECT_PAY_RECORD_URL,{date:this.state.dateTimeStr},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.collectPayRecordDO = successResponse.data;
                if (typeof(this.state.collectPayRecordDO)=='undefined') {
                    this.state.collectPayRecordDO = {};
                }
                this.setState({collectPayRecordDO: this.state.collectPayRecordDO});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:50}}>{stringUtil.language(888)}</span>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                            this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:arithUtil.pxToDp(5),overflow:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(284)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr}</span>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1}}>
                        <div style={{width:arithUtil.ProportionTo(document.body.clientWidth,0.13)}}>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#FF8C00',fontWeight:'bold'})}>{stringUtil.language(893)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(509)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(477)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#52A056'})}>{stringUtil.language(894)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#52A056'})}>{stringUtil.language(142)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#52A056'})}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{stringUtil.language(415)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(442)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(443)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(444)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(445)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(446)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#F00'})}>{stringUtil.language(895)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#F00'})}>{stringUtil.language(142)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{color:'#F00'})}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{stringUtil.language(415)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(447)}</span>
                            <span style={styles.tableTitle}>{stringUtil.language(192)}</span>
                        </div>
                        {(()=>{
                            if (this.state.collectPayRecordDO.workerRecord!=null) {
                                if (this.state.collectPayRecordDO.workerRecord.length==0) {
                                    this.state.personNumbers = (1.048).div(1);
                                }else{
                                    if (this.state.collectPayRecordDO.workerRecord.length>=5) {
                                        this.state.personNumbers = (1.048).div(5);
                                    }else{
                                        this.state.personNumbers = (1.048).div(this.state.collectPayRecordDO.workerRecord.length);
                                    }
                                }
                            }else{
                                this.state.personNumbers = (1.048).div(1);
                            }
                            this.state.totalInfo = {
                                customerCollectMoneyTotal:0,otherCollectMoneyTotal:0,collectMoneyTotal:0,bossCollectMoneyTotal:0,
                                shopCollectMoneyTotal:0,ownerExpendMoneyTotal:0,shopExpendMoneyTotal:0,supplierPayMoneyTotal:0,
                                ownerPayMoneyTotal:0,workerPayMoneyTotal:0,payMoneyTotal:0,bossPayMoneyTotal:0,shopPayMoneyTotal:0,
                                handMoneyTotal:0,balanceTotal:0
                            }
                        })()}
                        <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                            {this.state.collectPayRecordDO.workerRecord!=null?this.state.collectPayRecordDO.workerRecord.map((item,key)=>{
                                this.state.totalInfo = {
                                    customerCollectMoneyTotal:(this.state.totalInfo.customerCollectMoneyTotal).add(item.customerCollectMoney),
                                    otherCollectMoneyTotal:(this.state.totalInfo.otherCollectMoneyTotal).add(item.otherCollectMoney),
                                    collectMoneyTotal:(this.state.totalInfo.collectMoneyTotal).add(item.collectMoney),
                                    bossCollectMoneyTotal:(this.state.totalInfo.bossCollectMoneyTotal).add(item.bossCollectMoney),
                                    shopCollectMoneyTotal:(this.state.totalInfo.shopCollectMoneyTotal).add(item.shopCollectMoney),
                                    ownerExpendMoneyTotal:(this.state.totalInfo.ownerExpendMoneyTotal).add(item.ownerExpendMoney),
                                    shopExpendMoneyTotal:(this.state.totalInfo.shopExpendMoneyTotal).add(item.shopExpendMoney),
                                    supplierPayMoneyTotal:(this.state.totalInfo.supplierPayMoneyTotal).add(item.supplierPayMoney),
                                    ownerPayMoneyTotal:(this.state.totalInfo.ownerPayMoneyTotal).add(item.ownerPayMoney),
                                    workerPayMoneyTotal:(this.state.totalInfo.workerPayMoneyTotal).add(item.workerPayMoney),
                                    payMoneyTotal:(this.state.totalInfo.payMoneyTotal).add(item.payMoney),
                                    bossPayMoneyTotal:(this.state.totalInfo.bossPayMoneyTotal).add(item.bossPayMoney),
                                    shopPayMoneyTotal:(this.state.totalInfo.shopPayMoneyTotal).add(item.shopPayMoney),
                                    handMoneyTotal:(this.state.totalInfo.handMoneyTotal).add(item.handMoney),
                                    balanceTotal:(this.state.totalInfo.balanceTotal).add(item.balance)
                                }
                                return(<div style={{width:'80px'}} key={key}>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#FF8C00',fontWeight:'bold'})}>{item.workerId==0?stringUtil.language(431):item.workerName}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.customerCollectMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.otherCollectMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#52A056'})}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#52A056'})}>{arithUtil.fenToYuan(item.bossCollectMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#52A056'})}>{arithUtil.fenToYuan(item.shopCollectMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.ownerExpendMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.shopExpendMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.supplierPayMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.ownerPayMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.workerPayMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#F00'})}>{arithUtil.fenToYuan(item.payMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#F00'})}>{arithUtil.fenToYuan(item.bossPayMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#F00'})}>{arithUtil.fenToYuan(item.shopPayMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.handMoney)}</span>
                                    <span style={styles.tableTd}>{arithUtil.fenToYuan(item.balance)}</span>
                                </div>)
                            }):<div />}
                        </div>
                        <div style={{width:arithUtil.ProportionTo(document.body.clientWidth,0.06)}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#FF8C00',fontSize:20,fontWeight:'bold'})}>{stringUtil.language(1061)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.customerCollectMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.otherCollectMoneyTotal)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#52A056'})}>{arithUtil.fenToYuan(this.state.totalInfo.collectMoneyTotal)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#52A056'})}>{arithUtil.fenToYuan(this.state.totalInfo.bossCollectMoneyTotal)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#52A056'})}>{arithUtil.fenToYuan(this.state.totalInfo.shopCollectMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.ownerExpendMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.shopExpendMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.supplierPayMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.ownerPayMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.workerPayMoneyTotal)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#F00'})}>{arithUtil.fenToYuan(this.state.totalInfo.payMoneyTotal)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#F00'})}>{arithUtil.fenToYuan(this.state.totalInfo.bossPayMoneyTotal)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#F00'})}>{arithUtil.fenToYuan(this.state.totalInfo.shopPayMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.handMoneyTotal)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(this.state.totalInfo.balanceTotal)}</span>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'flex-end',paddingBottom:arithUtil.pxToDp(10)}}>
                        <span>{stringUtil.language(285)}{arithUtil.fenToYuan(this.state.collectPayRecordDO.beforeBalance)} {stringUtil.currency(1)}，</span>
                        <span style={{color:'#52A056',fontSize:25,fontWeight:'bold'}}>{stringUtil.language(286)}{arithUtil.fenToYuan((this.state.totalInfo.balanceTotal).add(this.state.collectPayRecordDO.beforeBalance))} {stringUtil.currency(1)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        height:arithUtil.pxToDp(30),
        fontSize:13,
        display:'flex',
        alignItems:'center',
        paddingLeft:10
    },
    tableTd:{
        height:arithUtil.pxToDp(30),
        fontSize:13,
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};