import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //{stringUtil.language(342)}
 export default class SupplierAccountsDetailsActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        reconciliationVO:{},
        suppliersAll:[],
        currSupplierItem:{},
        dateTimeStr:arithUtil.getCurrDateTime(1,true),
        dateTimeStrEnd:arithUtil.getCurrDateTime(1,true),
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_SUPPLIER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.suppliersAll = successResponse.data;
                if (this.state.suppliersAll.length>0) {
                    this.state.currSupplierItem = JSON.parse(JSON.stringify(this.state.suppliersAll[0]));
                    this.initData();
                }
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SUPPLIER_RECONCILIATION_URL+'/'+this.state.currSupplierItem.id,{
            startDate:this.state.dateTimeStr,
            endDate:this.state.dateTimeStrEnd,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({reconciliationVO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(422)}</span>
                            <Select showSearch style={{width:200}} onChange={(event)=>{
                                this.state.currSupplierItem = JSON.parse(event);
                                this.initData();
                            }}>{/* <option style={{display:'none'}}></option> */}
                                    {this.state.suppliersAll.map((item,key)=>{
                                    return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                                    })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(904)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                                this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStrEnd)} onChange={(date) => {
                                this.state.dateTimeStrEnd = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(460)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="10"><b><font size="3">${this.state.currSupplierItem.noteName}${stringUtil.language(461)}（${this.state.dateTimeStr+stringUtil.language(1174)+this.state.dateTimeStrEnd}）</font></b></td></tr>
                            <tr><td align="right" style="color: #F00" colspan="10"><b><font size="3">{stringUtil.language(288)}${arithUtil.fenToYuan(this.state.reconciliationVO.beforeMoney)}${stringUtil.currency(1)}</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1067)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':stringUtil.language(1172)+'】'}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1050)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1068)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1069)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(462)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1061)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(463)}【${stringUtil.currency(1)}】</font></b></td></tr>`);
                            let totalInfo = {orderNumberTotal:0,orderWeightTotal:0,orderGoodsMoneyTotal:0,orderCotherTotal:0,orderOotherTotal:0,orderTotal:0,moneyMoneyTotal:0}
                            for(let i=0;i<(this.state.reconciliationVO.items?this.state.reconciliationVO.items:[]).length;i++){
                                let item = this.state.reconciliationVO.items[i];
                                let debtTotalVal = 0;
                                if(item.type==0){//stringUtil.language(1044)
                                    if(i==0){
                                        debtTotalVal = (this.state.reconciliationVO.beforeMoney).add(item.money);
                                        this.state.reconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }else{
                                        debtTotalVal = (this.state.reconciliationVO.items[i-1].debtTotalVal).add(item.money);
                                        this.state.reconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }
                                    totalInfo.orderOotherTotal = totalInfo.orderOotherTotal.add(item.purchase.costMoney);
                                    totalInfo.orderTotal = totalInfo.orderTotal.add(item.money);
                                    if(item.purchase.commodities!=null&&item.purchase.commodities.length>0){
                                        totalInfo.orderNumberTotal = totalInfo.orderNumberTotal.add(item.purchase.commodities[0].number);
                                        if(item.purchase.commodities[0].mode==0) totalInfo.orderWeightTotal = totalInfo.orderWeightTotal.add(item.purchase.commodities[0].weight);
                                        totalInfo.orderGoodsMoneyTotal = totalInfo.orderGoodsMoneyTotal.add(item.purchase.commodities[0].goodsMoney);
                                        totalInfo.orderCotherTotal = totalInfo.orderCotherTotal.add(item.purchase.commodities[0].costMoney);
                                        resArr.push(`<tr><td align="left" rowspan="${item.purchase.commodities.length}"><b><font size="3">${arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</font></b></td>
                                        <td align="left"><b><font size="3">${item.purchase.commodities[0].commodityName}</font></b></td>
                                        <td align="right"><b><font size="3">${item.purchase.commodities[0].number}</font></b></td>
                                        <td align="right"><b><font size="3">${arithUtil.keToWeight(item.purchase.commodities[0].weight,this.state.weightMode)}</font></b></td>
                                        <td align="right"><b><font size="3">${item.purchase.commodities[0].mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.purchase.commodities[0].unitPrice,2),0,this.state.weightMode)):arithUtil.fenToYuan(item.purchase.commodities[0].unitPrice,2)}</font></b></td>
                                        <td align="right"><b><font size="3">${arithUtil.fenToYuan(item.purchase.commodities[0].goodsMoney)}</font></b></td>
                                        <td align="right"><b><font size="3">${arithUtil.fenToYuan(item.purchase.commodities[0].costMoney)}</font></b></td>
                                        <td align="right" rowspan="${item.purchase.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(item.purchase.costMoney)}</font></b></td>
                                        <td align="right" rowspan="${item.purchase.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(item.money)}</font></b></td>
                                        <td align="right" rowspan="${item.purchase.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(debtTotalVal)}</font></b></td></tr>`);
                                        for(let j=0;j<item.purchase.commodities.length;j++){
                                            if(j==0)continue;
                                            totalInfo.orderNumberTotal = totalInfo.orderNumberTotal.add(item.purchase.commodities[j].number);
                                            if(item.purchase.commodities[j].mode==0) totalInfo.orderWeightTotal = totalInfo.orderWeightTotal.add(item.purchase.commodities[j].weight);
                                            totalInfo.orderGoodsMoneyTotal = totalInfo.orderGoodsMoneyTotal.add(item.purchase.commodities[j].goodsMoney);
                                            totalInfo.orderCotherTotal = totalInfo.orderCotherTotal.add(item.purchase.commodities[j].costMoney);
                                            resArr.push(`<tr><td align="left"><b><font size="3">${item.purchase.commodities[j].commodityName}</font></b></td>
                                            <td align="right"><b><font size="3">${item.purchase.commodities[j].number}</font></b></td>
                                            <td align="right"><b><font size="3">${arithUtil.keToWeight(item.purchase.commodities[j].weight,this.state.weightMode)}</font></b></td>
                                            <td align="right"><b><font size="3">${item.purchase.commodities[j].mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.purchase.commodities[j].unitPrice,2),0,this.state.weightMode)):arithUtil.fenToYuan(item.purchase.commodities[j].unitPrice,2)}</font></b></td>
                                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(item.purchase.commodities[j].goodsMoney)}</font></b></td>
                                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(item.purchase.commodities[j].costMoney)}</font></b></td></tr>`);
                                        }
                                    }
                                }else{//stringUtil.language(1043)
                                    if(i==0){
                                        debtTotalVal = (this.state.reconciliationVO.beforeMoney).sub(item.money);
                                        this.state.reconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }else{
                                        debtTotalVal = (this.state.reconciliationVO.items[i-1].debtTotalVal).sub(item.money);
                                        this.state.reconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }
                                    totalInfo.moneyMoneyTotal = totalInfo.moneyMoneyTotal.add(item.money);
                                    resArr.push(`<tr><td align="left"><b><font size="3">${arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</font></b></td>
                                    <td align="left" style="color: #F00" colspan="8"><b><font size="3">${stringUtil.language(1126)}：${arithUtil.fenToYuan(item.money)} ${stringUtil.currency(1)}，${stringUtil.language(879)}：${arithUtil.fenToYuan(item.discount)} ${stringUtil.currency(1)}</font></b></td>
                                    <td align="right"><b><font size="3">${arithUtil.fenToYuan(debtTotalVal)}</font></b></td></tr>`);
                                }
                            }
                            resArr.push(`<tr style="height: 25px"><td></td></tr>
                            <tr><td align="left" colspan="10"><b><font size="3">${stringUtil.language(332)}${this.state.totalInfo.orderNumberTotal}，${arithUtil.keToWeight(this.state.totalInfo.orderWeightTotal,this.state.weightMode)+(this.state.weightMode==0?'stringUtil.language(1023)':'stringUtil.language(1172)')}{stringUtil.language(907)}${arithUtil.fenToYuan(this.state.totalInfo.orderGoodsMoneyTotal)}${stringUtil.currency(1)}{stringUtil.language(195)}${arithUtil.fenToYuan(this.state.totalInfo.orderCotherTotal)}${stringUtil.currency(1)}{stringUtil.language(196)}${arithUtil.fenToYuan(this.state.totalInfo.orderOotherTotal)}${stringUtil.currency(1)}{stringUtil.language(909)}${arithUtil.fenToYuan(this.state.totalInfo.orderTotal)}${stringUtil.currency(1)}</font></b></td></tr>
                            <tr><td align="left" colspan="10"><b><font size="3">${stringUtil.language(333)}${arithUtil.fenToYuan(this.state.totalInfo.moneyMoneyTotal)}${stringUtil.currency(1)}</font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),this.state.currSupplierItem.noteName+stringUtil.language(461)+'（'+this.state.dateTimeStr+stringUtil.language(1174)+this.state.dateTimeStrEnd+'）');
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>【{this.state.currSupplierItem.noteName}】{stringUtil.language(163)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr+' '+stringUtil.language(1174)+' '+this.state.dateTimeStrEnd}</span>
                    </div>:''}
                    <span style={{height:arithUtil.pxToDp(40),color:'#F00',fontSize:20,display:'flex',flexDirection:'row',alignItems:'center'}}>{stringUtil.language(288)}{arithUtil.fenToYuan(this.state.reconciliationVO.beforeMoney)}{stringUtil.currency(1)}</span>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'10%'})}>{stringUtil.language(1067)}</span>
                        <div style={{width:'54%',display:'flex',flexDirection:'row'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1020)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1021)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1022)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1050)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1068)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1069)}</span>
                        </div>
                        <span style={styles.tableTitle}>{stringUtil.language(910)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1070)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1061)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(463)}</span>
                    </div>
                    {(()=>{this.state.totalInfo = {orderNumberTotal:0,orderWeightTotal:0,orderGoodsMoneyTotal:0,orderCotherTotal:0,orderOotherTotal:0,orderTotal:0,moneyMoneyTotal:0}})()}
                    {this.state.reconciliationVO.items?this.state.reconciliationVO.items.map((item,key)=>{//ReconciliationItem
                        let debtTotalVal = 0;
                        if(key==0){
                            if (item.type==0) {
                                debtTotalVal = (this.state.reconciliationVO.beforeMoney).add(item.money);
                                this.state.reconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }else{
                                debtTotalVal = (this.state.reconciliationVO.beforeMoney).sub(item.money);
                                this.state.reconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }
                        }else{
                            if (item.type==0) {//stringUtil.language(1044)
                                debtTotalVal = (this.state.reconciliationVO.items[key-1].debtTotalVal).add(item.money);
                                this.state.reconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }else{//stringUtil.language(1043)
                                debtTotalVal = (this.state.reconciliationVO.items[key-1].debtTotalVal).sub(item.money);
                                this.state.reconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }
                        }
                        let itemShowView = <div/>;
                        if (item.type==0) {
                            this.state.totalInfo.orderOotherTotal = this.state.totalInfo.orderOotherTotal.add(item.purchase.costMoney);
                            this.state.totalInfo.orderTotal = this.state.totalInfo.orderTotal.add(item.money);
                            itemShowView = <div style={{display:'flex',flexDirection:'row',borderStyle:'solid',borderWidth:0.5,borderColor:'#F0F0F0'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',borderBottomWidth:0})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                <div style={{width:'54%',borderLeftWidth:0.5,borderLeftColor:'#F0F0F0',borderRightWidth:0.5,borderRightColor:'#F0F0F0'}}>
                                    {(item.purchase.commodities?item.purchase.commodities:[]).map((itemb,keyb)=>{
                                        this.state.totalInfo.orderNumberTotal = this.state.totalInfo.orderNumberTotal.add(itemb.number);
                                        if(itemb.mode==0) this.state.totalInfo.orderWeightTotal = this.state.totalInfo.orderWeightTotal.add(itemb.weight);
                                        this.state.totalInfo.orderGoodsMoneyTotal = this.state.totalInfo.orderGoodsMoneyTotal.add(itemb.goodsMoney);
                                        this.state.totalInfo.orderCotherTotal = this.state.totalInfo.orderCotherTotal.add(itemb.costMoney);
                                        return(<div style={{display:'flex',flexDirection:'row'}} key={key+''+keyb}>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{stringUtil.subStr(itemb.commodityName,5)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{itemb.number}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)):arithUtil.fenToYuan(itemb.unitPrice,2)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{arithUtil.fenToYuan(itemb.goodsMoney)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{arithUtil.fenToYuan(itemb.costMoney)}</span>
                                        </div>)
                                    })}
                                </div>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(item.purchase.costMoney)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(debtTotalVal)}</span>
                            </div>;
                        }else{
                            this.state.totalInfo.moneyMoneyTotal = this.state.totalInfo.moneyMoneyTotal.add(item.money);
                            itemShowView = <div style={{display:'flex',flexDirection:'row'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                <div style={{width:'81%',display:'flex',flexDirection:'row'}}>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',color:'#F00',textAlign:'left',paddingLeft:10})}>{stringUtil.language(1126)}：{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}，{stringUtil.language(879)}：{arithUtil.fenToYuan(item.discount)} {stringUtil.currency(1)}</span>
                                </div>
                                <span style={styles.tableTd}>{arithUtil.fenToYuan(debtTotalVal)}</span>
                            </div>;
                        }
                        return(itemShowView)
                    }):<div/>}
                    <div style={{marginTop:arithUtil.pxToDp(15),paddingBottom:10}}>
                        <span style={{color:'#D2855D'}}>{stringUtil.language(332)}{this.state.totalInfo.orderNumberTotal}，{arithUtil.keToWeight(this.state.totalInfo.orderWeightTotal,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}{stringUtil.language(907)}{arithUtil.fenToYuan(this.state.totalInfo.orderGoodsMoneyTotal)}{stringUtil.currency(1)}{stringUtil.language(195)}{arithUtil.fenToYuan(this.state.totalInfo.orderCotherTotal)}{stringUtil.currency(1)}{stringUtil.language(196)}{arithUtil.fenToYuan(this.state.totalInfo.orderOotherTotal)}{stringUtil.currency(1)}{stringUtil.language(909)}{arithUtil.fenToYuan(this.state.totalInfo.orderTotal)}{stringUtil.currency(1)}</span><br/>
                        <span style={{color:'#D2855D'}}>{stringUtil.language(333)}{arithUtil.fenToYuan(this.state.totalInfo.moneyMoneyTotal)}{stringUtil.currency(1)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    tableTitle:{
        width:'9%',
        height:arithUtil.pxToDp(30),
        fontSize:12,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    tableTd:{
        width:'9%',
        height:arithUtil.pxToDp(30),
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};