import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {PropTypes} from 'prop-types';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
 
//弹{stringUtil.language(1194)}框
export default class MaskModal extends Component {
    constructor (props) {
        super(props);
        this.modal = null;
    }
    componentDidMount () {
        this.modal = document.createElement('div');
        if (this.props.visible) {
            document.body.appendChild(this.modal);
            this._renderLayer();
        }
    }
    componentDidUpdate () {
        if (this.props.visible) {
            document.body.appendChild(this.modal);
            this._renderLayer();
        } else {
            if (this.modal.parentNode!=null && this.modal!=null) {
                this.modal.parentNode.removeChild(this.modal);
            }
        }
    }
 
    closeBnTopEvents = () => {
        const { closeBnTopEvents } = this.props;
        (closeBnTopEvents instanceof Function) && closeBnTopEvents();
    }
    closeBnEvents = () => {
        const { closeBnEvents } = this.props;
        (closeBnEvents instanceof Function) && closeBnEvents();
    }
    okBnEvents = () => {
        const { okBnEvents } = this.props;
        (okBnEvents instanceof Function) && okBnEvents();
    }
 
    // 渲染模态框内容
    _renderLayer () {
        let mask = {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.65)',
            height: '100%',
            zIndex: 49,
        };
        let modalWrap = {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 49,
        };
        let modal = {
            fontSize: 14,
            width: this.props.width,
            height: this.props.height,
            margin:'auto',
            marginTop:(document.body.clientHeight-this.props.height)/2,
            backgroundColor: '#fff',
            borderRadius: 4,
            overflow: 'hidden',
            position:'relative'
        };

        const {title, content} = this.props;
        let JSXdom = (
            <div>
                <div style={mask} />
                <div style={modalWrap}>
                    <div style={modal}>
                        <div style={{width:'100%',height:40,borderBottom:'1px solid #D0D0D0',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'flex-end'}}>
                            <h1>&nbsp;&nbsp;{title}</h1>
                            <div style={{width:50,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end',cursor:'pointer'}} onClick={this.props.closeBnTopEvents?this.closeBnTopEvents:this.closeBnEvents}>
                                <span style={{fontSize:'20px',fontWeight:'bold',color:'#aaa'}}>✖</span>
                            </div>
                        </div>
                        <div style={{position:'absolute',left:5,top:40,right:5,bottom:this.props.okBnEvents!=null||this.props.closeBnEvents!=null?65:0}}>{content}</div>
                        {this.props.okBnEvents!=null||this.props.closeBnEvents!=null?<div style={{width:'100%',height:45,display:'flex',justifyContent:'space-around',alignItems:'center',position:'absolute',bottom:0}}>
                            {this.props.okBnEvents!=null?<span style={{width:120,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:this.props.okBnColor?this.props.okBnColor:'#d2855d',textAlign:'center',cursor:'pointer'}} onClick={this.okBnEvents}>{this.props.okBnTitle}</span>:<div/>}
                            {this.props.closeBnEvents!=null?<span style={{width:120,height:'30px',color:this.props.closeBnColor=='#F5F5F5'?'#696969':'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:this.props.closeBnColor?this.props.closeBnColor:'#FFF',textAlign:'center',cursor:'pointer',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1}} onClick={this.closeBnEvents}>{this.props.closeBnTitle}</span>:<div/>}
                        </div>:<div/>}
                    </div>
                </div>
            </div>
        );
        ReactDOM.render(JSXdom, this.modal);
    }
 
    render () {
        return null;
    }
    
}

MaskModal.propsTypes={
    width:PropTypes.string.isRequired,
    height:PropTypes.string.isRequired,
    context:PropTypes.array.isRequired,//context={this}
    visible:PropTypes.bool.isRequired,
    title:PropTypes.string.isRequired,
    content:PropTypes.node.isRequired,
    okBnEvents:PropTypes.func.isRequired,
    closeBnEvents:PropTypes.func.isRequired,
    closeBnTopEvents:PropTypes.func.isRequired,
    okBnTitle:PropTypes.string.isRequired,
    closeBnTitle:PropTypes.string.isRequired,
    okBnColor:PropTypes.string.isRequired,
    closeBnColor:PropTypes.string.isRequired,
};
MaskModal.defaultProps = {
    width:350,
    height:250,
    title:'提示',
    okBnTitle:stringUtil.language(1027),
    closeBnTitle:stringUtil.language(1028),
    okBnColor:'#52A056',
    closeBnColor:'#F5F5F5',
};