import  "react-app-polyfill/ie11";
import  "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router,Route } from 'react-router-dom';
import {registerLocale} from 'react-datepicker';
import zhCN from 'date-fns/locale/zh-CN';
import './index.css';//全局样式

//组件都以大写字母开头，文件以小写字母开头，命名方式均采用驼峰式
import Login from './pages/Login';
import Register from './pages/Register';
import MeInfo from './pages/MeInfo';
import Main from './pages/Main';
import AddBossStall from './pages/AddBossStall';
import AddStallEmployee from './pages/AddStallEmployee';
import ShareBusinessCus from './pages/ShareBusinessCus';
import ShareBusinessDetailCus from './pages/ShareBusinessDetailCus';
import ShareWholesaleConfirmInfo from './pages/ShareWholesaleConfirmInfo';
import ShareCargoInfo from './pages/ShareCargoInfo';
import ShareLookSource from './pages/ShareLookSource';
import ShareClientDown from './pages/ShareClientDown';
import ShareAgreement from './pages/ShareAgreement';
import ShareCollectDoc from './pages/ShareCollectDoc';
import ShareWxPromitConcern from './pages/ShareWxPromitConcern';
class RouterSet extends React.Component {
  render(){
    registerLocale('zhCN', zhCN);
    return (
      <Router basename="/shop">
        <div>
          {/*如果想在访问其他地址时不显示首页组件，可以在首页组件所在路线标签中加入exact */}
          <Route exact path="/" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/MeInfo" component={MeInfo} />
          <Route path="/Main" component={Main} />
          <Route path="/AddBossStall" component={AddBossStall} />
          <Route path="/AddStallEmployee" component={AddStallEmployee} />
          <Route path="/ShareBusinessCus" component={ShareBusinessCus} />
          <Route path="/ShareBusinessDetailCus" component={ShareBusinessDetailCus} />
          <Route path="/ShareWholesaleConfirmInfo" component={ShareWholesaleConfirmInfo} />
          <Route path="/ShareCargoInfo" component={ShareCargoInfo} />
          <Route path="/ShareLookSource" component={ShareLookSource} />
          <Route path="/ShareClientDown" component={ShareClientDown} />
          <Route path="/ShareAgreement" component={ShareAgreement} />
          <Route path="/ShareCollectDoc" component={ShareCollectDoc} />
          <Route path="/ShareWxPromitConcern" component={ShareWxPromitConcern} />
        </div>
      </Router>
    );
  }
}

var rootDom = document.getElementById('root');
rootDom.style.width = `${window.screen.width}px`;//界面宽度固定显示为设备宽度
rootDom.style.height = `${document.documentElement.clientHeight || document.body.clientHeight}px`;
//document.documentElement.clientHeight || document.body.clientHeight网页可见区域高
//document.documentElement.scrollHeight || document.body.scrollHeight网页正文全文高
// document.onkeydown=(e)=>{
//   if(e.keyCode===13){
//       for (var i = 0; i < document.getElementsByTagName("input").length; i++) {
//           if(document.getElementsByTagName("input")[i] == document.activeElement) {
//             if(document.getElementsByTagName("input")[i+1]!=null) document.getElementsByTagName("input")[i+1].focus();
//             else document.getElementsByTagName("input")[0].focus();
//             break;
//           }else{}//未获得焦点
//       }
//   }
// }

ReactDOM.render(<RouterSet />,document.getElementById('root'));
  