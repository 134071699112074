import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import MaskModal from '../components/MaskModal';
 import Pagination from '../components/Pagination';

 export default class AccountCollectExpend extends Component{
    constructor(props) {
      super(props);
      this.state={
        accountSettleDO:[],
        totalInfo:{
          customerCollect:0,shopCollect:0,collectTotal:0,shopExpend:0,ownerExpend:0,supplierPay:0,
          workerPay:0,ownerPay:0,paymentTotal:0,handMoney:0,beforeBalance:0,todayBalance:0,
        },
        inputTextTagVal:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SUMM_ACCOUNT_SETTLE_URL,{date:arithUtil.getCurrDateTime(1)},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
              this.state.totalInfo = {
                customerCollect:0,shopCollect:0,collectTotal:0,shopExpend:0,ownerExpend:0,supplierPay:0,
                workerPay:0,ownerPay:0,paymentTotal:0,handMoney:0,beforeBalance:0,todayBalance:0,
              };
              for(let i=0;i<successResponse.data.length;i++){
                let item = successResponse.data[i];
                let collectTotal = (item.customerCollect).add(item.shopCollect);
                let paymentTotal = (item.shopExpend).add(item.ownerExpend).add(item.supplierPay).add(item.workerPay).add(item.ownerPay);
                let todayBalance = collectTotal.sub(paymentTotal).add(item.handMoney).add(item.beforeBalance);
                this.state.totalInfo.customerCollect = (this.state.totalInfo.customerCollect).add(item.customerCollect);
                this.state.totalInfo.shopCollect = (this.state.totalInfo.shopCollect).add(item.shopCollect);
                this.state.totalInfo.collectTotal = (this.state.totalInfo.collectTotal).add(collectTotal);
                this.state.totalInfo.shopExpend = (this.state.totalInfo.shopExpend).add(item.shopExpend);
                this.state.totalInfo.ownerExpend = (this.state.totalInfo.ownerExpend).add(item.ownerExpend);
                this.state.totalInfo.supplierPay = (this.state.totalInfo.supplierPay).add(item.supplierPay);
                this.state.totalInfo.workerPay = (this.state.totalInfo.workerPay).add(item.workerPay);
                this.state.totalInfo.ownerPay = (this.state.totalInfo.ownerPay).add(item.ownerPay);
                this.state.totalInfo.paymentTotal = (this.state.totalInfo.paymentTotal).add(paymentTotal);
                this.state.totalInfo.handMoney = (this.state.totalInfo.handMoney).add(item.handMoney);
                this.state.totalInfo.beforeBalance = (this.state.totalInfo.beforeBalance).add(item.beforeBalance);
                this.state.totalInfo.todayBalance = (this.state.totalInfo.todayBalance).add(todayBalance);
              }
              this.setState({accountSettleDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'99%',height:'99%',display:'flex',flexDirection:'row'}}>
            <div style={{width:'100%',height:'100%',position:'absolute',zIndex:1}}>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#CCCCCC'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#CCCCCC'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#F0F0F0'}}></div>
                <div style={{width:'100%',height:'9.03%',backgroundColor:'#CCCCCC'}}></div>
            </div>
            <div style={{width:'25%',height:'100%',display:'flex',flexDirection:'column',position:'relative',zIndex:10}}>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(267)}{arithUtil.fenToYuan(this.state.totalInfo.collectTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(268)}{arithUtil.fenToYuan(this.state.totalInfo.customerCollect)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(269)}{arithUtil.fenToYuan(this.state.totalInfo.shopCollect)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(270)}{arithUtil.fenToYuan(this.state.totalInfo.paymentTotal)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(271)}{arithUtil.fenToYuan(this.state.totalInfo.shopExpend)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(272)}{arithUtil.fenToYuan(this.state.totalInfo.supplierPay)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(273)}{arithUtil.fenToYuan(this.state.totalInfo.ownerExpend)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(274)}{arithUtil.fenToYuan(this.state.totalInfo.ownerPay)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:30,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15}}>{stringUtil.language(275)}{arithUtil.fenToYuan(this.state.totalInfo.workerPay)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold',color:'#F00'}}>{stringUtil.language(276)}{arithUtil.fenToYuan(this.state.totalInfo.todayBalance)}</span></div>
                <div style={{width:'100%',height:'9.03%',paddingLeft:20,display:'flex',flexDirection:'row',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(277)}{arithUtil.fenToYuan(this.state.totalInfo.handMoney)}</span></div>
            </div>
            <div style={{width:'75%',height:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row',position:'relative',zIndex:10}}>
            {this.state.accountSettleDO.map((item,key)=>{
                let collectTotal = (item.customerCollect).add(item.shopCollect);
                let paymentTotal = (item.shopExpend).add(item.ownerExpend).add(item.supplierPay).add(item.workerPay).add(item.ownerPay);
                let todayBalance = collectTotal.sub(paymentTotal).add(item.handMoney).add(item.beforeBalance);
                return(<div style={{width:122,borderLeftStyle:'solid',borderLeftColor:'#FFF',borderLeftWidth:0.5,display:'flex',flexDirection:'column'}} key={key}>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.subStr(item.accountName,5)+':'+arithUtil.fenToYuan(collectTotal)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.customerCollect)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.shopCollect)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{arithUtil.fenToYuan(paymentTotal)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.shopExpend)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.supplierPay)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.ownerExpend)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.ownerPay)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15}}>{arithUtil.fenToYuan(item.workerPay)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold',color:'#F00'}}>{arithUtil.fenToYuan(todayBalance)}</span></div>
                    <div style={{width:'100%',height:'9.03%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:15,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.handMoney)}</span></div>
                </div>)
            })}
            </div>
        </div>)
    }
}

const styles = {}