import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

//溯源
 export default class ShareLookSource extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
        weightMode:stringUtil.getUrlParamsVal('weightMode',search)?stringUtil.getUrlParamsVal('weightMode',search):0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            wholesaleId:stringUtil.getUrlParamsVal('wholesaleId',search)?otherUtil.encDec(stringUtil.getUrlParamsVal('wholesaleId',search),1):0,
            stallId:stringUtil.getUrlParamsVal('stallId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('stallId',search),1)):0,
            customerId:stringUtil.getUrlParamsVal('customerId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('customerId',search),1)):0,
        },
        traceCommodityDO:[]
      }
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SHARE_TRACE_COMMODITY_URL+'/'+this.state.searchs.wholesaleId,{
            sign:otherUtil.encDec(this.state.searchs.stallId+','+this.state.searchs.customerId),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({traceCommodityDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
            <img style={{width:'100%',height:120}} loading="lazy" src={require('../images/qrcodetop.png').default} title={stringUtil.language(84)}/>
            <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <span style={{fontSize:20,fontWeight:'bold',borderBottomStyle:'solid',borderBottomColor:'#F00',borderBottomWidth:1}}>农产品流通追溯信息</span>
            </div>
            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#90EE90',paddingLeft:10}}><span style={{color:'#FFF'}}>本{stringUtil.language(917)}{stringUtil.language(1182)}涉及以下{stringUtil.language(1063)}：</span></div>
            <div style={{position:'absolute',left:0,top:190,right:0,bottom:0,overflow:'auto'}}>
                {this.state.traceCommodityDO.map((item,key)=>{
                    return(<div key={key}>
                        <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#D3D3D3',borderBottomWidth:1,paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                            if(item.isChoice){
                                this.state.traceCommodityDO[key].isChoice = false;
                            }else{this.state.traceCommodityDO[key].isChoice = true;}
                            this.setState({traceCommodityDO:this.state.traceCommodityDO});
                        }}>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                {item.isChoice?<i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe8e2;</i>:
                                <i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}}>&#xe67d;</i>}
                                <span style={{color:'#52A056',fontSize:20,fontWeight:'bold',marginLeft:10}}>{item.commodityName}</span>
                            </div>
                            <span style={{color:'#52A056',fontSize:17}}>{item.number}{stringUtil.language(1173)} {arithUtil.keToWeight(item.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                        </div>
                        {item.isChoice?<div style={{display:'flex',flexDirection:'column',backgroundColor:'#F5F5F5',paddingLeft:10,paddingTop:10,paddingBottom:10}}>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(1063)}源产地<span>：</span></div>
                                <span style={{fontSize:14}}>{item.originAdname}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>产地生产者<span>：</span></div>
                                <span style={{fontSize:14}}>{item.producer}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(1186)}货{stringUtil.language(1039)}<span>：</span></div>
                                <span style={{fontSize:14}}>{arithUtil.formatToTimeStr(new Date(item.sendTime),'Y年M'+stringUtil.language(1178)+'D'+stringUtil.language(1179)+' h:m:s')}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(1186)}往市场<span>：</span></div>
                                <span style={{fontSize:14}}>{item.market}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(1122)}商户<span>：</span></div>
                                <span style={{fontSize:14}}>{item.shop}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(992)}<span>：</span></div>
                                <span style={{fontSize:14}}>{item.receiver}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(970)}<span>：</span></div>
                                <span style={{fontSize:14}}>{item.saler}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(1122)}{stringUtil.language(1039)}<span>：</span></div>
                                <span style={{fontSize:14}}>{arithUtil.formatToTimeStr(new Date(item.saleTime),'Y年M'+stringUtil.language(1178)+'D'+stringUtil.language(1179)+' h:m:s')}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>销往城市<span>：</span></div>
                                <span style={{fontSize:14}}>{item.saleAdname}</span>
                            </div>
                            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:100,fontSize:14,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{stringUtil.language(1176)}{stringUtil.language(1186)}{stringUtil.language(1048)}<span>：</span></div>
                                <span style={{fontSize:14}}>{item.customer}</span>
                            </div>
                        </div>:''}
                    </div>);
                })}
            </div>
        </div>)
    }
}

const styles = {};