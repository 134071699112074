import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

  //已{stringUtil.language(1113)}的联系人{stringUtil.language(1171)}
  export default class DeleteContactActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        roleIndex:paramsPage.roleIndex?paramsPage.roleIndex:0,//0stringUtil.language(1048)，1stringUtil.language(1049)，2stringUtil.language(921)，3stringUtil.language(1031)
        customersAll:[],
        customers:[],
        currCustomerItem:null,//当前选中的stringUtil.language(1048)
        ownersAll:[],
        owners:[],
        currOwnerItem:null,//当前选中的stringUtil.language(1049)
        suppliersAll:[],
        suppliers:[],
        currSupplierItem:null,//当前选中的stringUtil.language(921)
        workersAll:[],
        workers:[],
        currWorkerItem:null,//当前选中的stringUtil.language(1031)
        delShowPop:false,
        currContactItem:{},
      };
    }
    componentDidMount(){
        this.getDataList();
    }
    getDataList(){
        if (this.state.roleIndex==0) {
            stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:2},['key','get',1],(successResponse)=>{
                if (successResponse.status==200) {
                    this.state.customersAll = successResponse.data;
                    this.setState({
                        customersAll:this.state.customersAll,
                        customers:this.state.customersAll,
                    });
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if (this.state.roleIndex==1) {
            stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:2},['key','get',1],(successResponse)=>{
                if (successResponse.status==200) {
                    this.state.ownersAll = successResponse.data;
                    this.setState({
                      ownersAll:this.state.ownersAll,
                      owners:this.state.ownersAll,
                    });
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if (this.state.roleIndex==2) {
            stringUtil.httpRequest(urlUtil.OAUTH_SUPPLIER_URL,{state:2},['key','get',1],(successResponse)=>{
                if (successResponse.status==200) {
                    this.state.suppliersAll = successResponse.data;
                    this.setState({
                      suppliersAll:this.state.suppliersAll,
                      suppliers:this.state.suppliersAll,
                    });
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }else if (this.state.roleIndex==3) {
            stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:3},['key','get',1],(successResponse)=>{
                if (successResponse.status==200) {
                    this.state.workersAll = successResponse.data;
                    this.setState({
                      workersAll:this.state.workersAll,
                      workers:this.state.workersAll,
                    });
                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        }
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',padding:'0.5%',backgroundColor:'#FFF'}}>
                {this.state.roleIndex==0?<div style={{width:'100%',height:'100%'}}>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9'})}>{stringUtil.language(1089)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1088)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',color:'#A9A9A9'})}>{stringUtil.language(386)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>地址</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1091)} / {stringUtil.language(1090)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{height:'93%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                        {this.state.customers.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%'})}>{item.noteName}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%'})}>{item.mobile}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%'})}>{item.adname}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left'})}>{item.address}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%'})}>{arithUtil.fenToYuan(item.initArrears)} / {arithUtil.fenToYuan(item.arrears)}</span>
                                {otherUtil.authorityFindCode("010503")!=null?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',color:'#D2855D',fontWeight:'bold',display:'flex',justifyContent:'center',alignItems:'center',textDecorationLine:'underline',cursor:'pointer'})} onClick={()=>{
                                    this.state.currContactItem = JSON.parse(JSON.stringify(item));
                                    this.setState({delShowPop:!this.state.delShowPop});
                                }}> {stringUtil.language(920)} </span>:<div/>}
                            </div>)
                        })}
                    </div>
                  </div>:
                this.state.roleIndex==1?<div style={{width:'100%',height:'100%'}}>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9'})}>{stringUtil.language(1089)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1088)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',color:'#A9A9A9'})}>{stringUtil.language(386)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>地址</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1091)} / {stringUtil.language(1090)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{height:'93%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                        {this.state.owners.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%'})}>{item.noteName}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%'})}>{item.mobile}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%'})}>{item.adname}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left'})}>{item.address}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%'})}>{arithUtil.fenToYuan(item.initArrears)} / {arithUtil.fenToYuan(item.arrears)}</span>
                                {otherUtil.authorityFindCode("010703")!=null?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',color:'#D2855D',fontWeight:'bold',display:'flex',justifyContent:'center',alignItems:'center',textDecorationLine:'underline',cursor:'pointer'})} onClick={()=>{
                                    this.state.currContactItem = JSON.parse(JSON.stringify(item));
                                    this.setState({delShowPop:!this.state.delShowPop});
                                }}> {stringUtil.language(920)} </span>:<div/>}
                            </div>)
                        })}
                    </div>
                </div>:
                this.state.roleIndex==2?<div style={{width:'100%',height:'100%'}}>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9'})}>{stringUtil.language(1089)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1088)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%',color:'#A9A9A9'})}>{stringUtil.language(386)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>地址</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1091)} / {stringUtil.language(1090)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{height:'93%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                        {this.state.suppliers.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%'})}>{item.noteName}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%'})}>{item.mobile}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'21%'})}>{item.adname}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left'})}>{item.address}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%'})}>{arithUtil.fenToYuan(item.initArrears)} / {arithUtil.fenToYuan(item.arrears)}</span>
                                {otherUtil.authorityFindCode("010603")!=null?<span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',height:'100%',color:'#D2855D',fontWeight:'bold',display:'flex',justifyContent:'center',alignItems:'center',textDecorationLine:'underline',cursor:'pointer'})} onClick={()=>{
                                    this.state.currContactItem = JSON.parse(JSON.stringify(item));
                                    this.setState({delShowPop:!this.state.delShowPop});
                                }}> {stringUtil.language(920)} </span>:<div/>}
                            </div>)
                        })}
                    </div>
                </div>:<div style={{width:'100%',height:'100%'}}>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.3%',color:'#A9A9A9'})}>{stringUtil.language(1089)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.3%',color:'#A9A9A9'})}>{stringUtil.language(1091)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.3%',color:'#A9A9A9'})}>{stringUtil.language(1090)}</span>
                    </div>
                    <div style={{height:'93%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                        {this.state.workers.map((item,key)=>{
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.3%'})}>{item.noteName}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.3%'})}>{arithUtil.fenToYuan(item.initArrears)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.3%'})}>{arithUtil.fenToYuan(item.arrears)}</span>
                            </div>)
                        })}
                    </div>
                </div>}
            </div>
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(494)}{this.state.roleIndex==0?stringUtil.language(1048):this.state.roleIndex==1?stringUtil.language(1049):this.state.roleIndex==2?stringUtil.language(921):stringUtil.language(1031)}{this.state.currContactItem.noteName}？
                    </div>}
                okBnEvents={(event) => {
                    this.setState({delShowPop: false});
                    let urlStr = urlUtil.OAUTH_CUSTOMER_RESTORE_URL;
                    if (this.state.roleIndex==1) urlStr = urlUtil.OAUTH_OWNER_RESTORE_URL;
                    if (this.state.roleIndex==2) urlStr = urlUtil.OAUTH_SUPPLIER_RESTORE_URL;
                    stringUtil.httpRequest(urlStr+'/'+this.state.currContactItem.id,null,['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
        </div>)
    }
}

const styles = {
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'20%',
        fontSize:13,
        textAlign:'center',
        textAlignVertical:'center',
    },
};