import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import ProcessMaterialView from './ProcessMaterialView';

 //{stringUtil.language(352)}
 export default class ProcessOrderAddActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        workersAll:[],
        commodityTypesAll:[],
        commoditysAll:[],
        commoditysData:[],
        proCommodityTypeItem:{},
        currCommodityTypeItem:{},
        keyTagVal:-1,
      };
      this.initDatas();
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    initDatas(){
        this.state.processVoItem={createTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),
            cargoId:null,//null未选择stringUtil.language(1084)
            processCommodities:[],
            processFinishCommodities:[],
        };
        this.state.processCommoditiesChoice=[];
        this.state.processFinishCommoditiesChoice=[];
        this.state.warehouseCommodityDO=[];
        this.state.warehouseCommodityDatas=[];
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workersAll = successResponse.data;
                this.state.workersAll.unshift({id:0,noteName:stringUtil.language(431)});
                this.setState({workersAll:this.state.workersAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:1,//-1库存 0代销 1stringUtil.language(1051) 2炒货
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({cargosData:successResponse.data.items});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commodityTypesAll= successResponse.data;
                this.state.commodityTypesAll.unshift({id:0,name:stringUtil.language(854)});
                this.state.commodityTypesAll.unshift({id:-1,name:stringUtil.language(389)});
                this.setState({commodityTypesAll: this.state.commodityTypesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commoditysAll = successResponse.data;
                this.searchFinishCommoditys();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initProcessCommodities(){
        if(this.state.processVoItem.cargoId==null){ToastBox.error(stringUtil.language(215));return;}
        stringUtil.httpRequest(urlUtil.SALE_WAREHOUSE_COMMODITY_URL,{cargoId:this.state.processVoItem.cargoId},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehouseCommodityDO=successResponse.data;
                this.state.warehouseCommodityDatas=this.state.warehouseCommodityDO;
                this.setState({warehouseCommodityDO:this.state.warehouseCommodityDO});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchCommoditys(){
        this.state.warehouseCommodityDO = [];
        for(let i=0;i<this.state.warehouseCommodityDO.length;i++){
            let commodityItem = this.state.warehouseCommodityDO[i];
            if (commodityItem.commodityName.indexOf(this.state.keySearch?this.state.keySearch:'')!=-1||(commodityItem.code?commodityItem.code:'').indexOf(this.state.keySearch?this.state.keySearch:'')!=-1) {
                if (this.state.proCommodityTypeItem.id==null || this.state.proCommodityTypeItem.id==0 || commodityItem.commodityTypeId==this.state.proCommodityTypeItem.id) {
                    this.state.warehouseCommodityDO.push(commodityItem);
                }
            }
        }
        this.setState({warehouseCommodityDO:this.state.warehouseCommodityDO});
    }
    searchFinishCommoditys(){
        this.state.commoditysData = [];
        for(let i=0;i<this.state.commoditysAll.length;i++){
            let commodityItem = this.state.commoditysAll[i];
            if(commodityItem.targetType==1) {
                if (commodityItem.name.indexOf(this.state.keySearch?this.state.keySearch:'')!=-1||(commodityItem.code?commodityItem.code:'').indexOf(this.state.keySearch?this.state.keySearch:'')!=-1) {
                    if (this.state.currCommodityTypeItem.id==null || this.state.currCommodityTypeItem.id==-1 || commodityItem.shopCommodityTypeId==this.state.currCommodityTypeItem.id) {
                        this.state.commoditysData.push(commodityItem);
                    }
                }
            }
        }
        this.setState({commoditysData:this.state.commoditysData});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:8,paddingRight:8,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                        <span style={{width:50}}>{stringUtil.language(1084)}:</span><select style={arithUtil.stylesAdd(styles.inputText,{width:200})} onChange={(event)=>{
                            this.initDatas();
                            this.state.processVoItem.cargoId = JSON.parse(event.target.value).id;
                            this.state.processVoItem.cargoNo = JSON.parse(event.target.value).cargoNo;
                            this.initProcessCommodities();
                        }}><option style={{color:'#C0C0C0',display:'none'}}>{stringUtil.language(215)}</option>
                        {(this.state.cargosData?this.state.cargosData:[]).map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.cargoMode==0?item.targetName:(item.cargoMode==1?stringUtil.language(426)+(item.targetName?'_'+item.targetName:''):stringUtil.language(438))} / {item.cargoNo}</option>)
                        })}
                        </select>
                    </div>
                    <div><DatePicker locale='zhCN' selected={new Date(this.state.processVoItem.createTime)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                        this.state.processVoItem.createTime = date.valueOf();
                        this.setState({processVoItem:this.state.processVoItem});
                    }} /></div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                        <span style={{width:70}}>{stringUtil.language(548)}:</span><select style={arithUtil.stylesAdd(styles.inputText,{width:150})} onChange={(event)=>{
                            this.state.processVoItem.teamId = JSON.parse(event.target.value).id;
                            this.state.processVoItem.teamName = JSON.parse(event.target.value).noteName;
                            this.setState({processVoItem:this.state.processVoItem});
                        }}><option style={{color:'#C0C0C0',display:'none'}}>{stringUtil.language(105)}</option>
                        {(this.state.workersAll?this.state.workersAll:[]).map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                        })}
                        </select>
                    </div>
                </div>
                <div style={{height:'46%',position:'relative'}}>
                    <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'6%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1104)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(549)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1105)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(550)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(551)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(552)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(553)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(554)}</span>
                    </div>
                    <div style={{position:'absolute',left:0,top:25,right:0,bottom:60,overflow:'auto'}}>
                        {(()=>{this.state.totalInfo={collectNumber:0,collectWeight:0,lossNumber:0,lossWeight:0,surplusNumber:0,surplusWeight:0,useNumber:0,useWeight:0,purchase:0};})()}
                        {this.state.processVoItem.processCommodities.map((item,key)=>{
                            this.state.processVoItem.processCommodities[key].useNumber=item.collectNumber-item.lossNumber-item.surplusNumber;
                            this.state.processVoItem.processCommodities[key].useWeight=(item.collectWeight).sub(item.lossWeight).sub(item.surplusWeight);
                            this.state.totalInfo.collectNumber=this.state.totalInfo.collectNumber+item.collectNumber;
                            this.state.totalInfo.collectWeight=(this.state.totalInfo.collectWeight).add(item.collectWeight);
                            this.state.totalInfo.lossNumber=this.state.totalInfo.lossNumber+item.lossNumber;
                            this.state.totalInfo.lossWeight=(this.state.totalInfo.lossWeight).add(item.lossWeight);
                            this.state.totalInfo.surplusNumber=this.state.totalInfo.surplusNumber+item.surplusNumber;
                            this.state.totalInfo.surplusWeight=(this.state.totalInfo.surplusWeight).add(item.surplusWeight);
                            this.state.totalInfo.useNumber=this.state.totalInfo.useNumber+item.useNumber;
                            this.state.totalInfo.useWeight=(this.state.totalInfo.useWeight).add(item.useWeight);
                            this.state.totalInfo.purchase=(this.state.totalInfo.purchase).add(item.useWeight+item.lossWeight?(item.purchaseWeightUnitPrice).mul(arithUtil.keToWeight(item.useWeight+item.lossWeight,0)):(item.purchaseUnitPrice).mul(item.useNumber+item.lossNumber));
                            return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <div style={{width:'6%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{width:'6%',fontSize:12,textAlign:'center'}}>{key+1}</span>
                                    <i className="iconfontIndexCss" style={{fontSize:15,color:'#F00',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                                        this.state.processVoItem.processCommodities.splice(key,1);
                                        this.setState({processVoItem:this.state.processVoItem});
                                    }}>&#xe612;</i>
                                </div>
                                <span style={{width:'10%'}}>{item.commodityName}</span>
                                {/* 2022.08.25吕stringUtil.language(1027)stringUtil.language(1048)先录{stringUtil.language(1105)}，再录stringUtil.language(1021)/stringUtil.language(1022) */}
                                <div style={{width:'8%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={{display:'flex',flexGrow:1,height:'100%',padding:0,textAlign:'center'}} value={item.specs?arithUtil.keToWeight(item.specs,this.state.weightMode):''} type='number' onChange={(event)=>{
                                        this.state.processVoItem.processCommodities[key].specs = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                        this.setState({processVoItem:this.state.processVoItem});
                                    }} placeholder='0'/><span style={{fontSize:12}}>{(this.state.weightMode?stringUtil.language(1172)+'/':stringUtil.language(1023)+'/')+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                </div>
                                <input style={{width:'8.5%',height:30,padding:0,textAlign:'center'}} value={item.collectNumber?item.collectNumber:''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processCommodities[key].collectNumber = event.target.value?parseInt(event.target.value):0;
                                    if(item.specs)this.state.processVoItem.processCommodities[key].collectWeight = (this.state.processVoItem.processCommodities[key].collectNumber).mul(item.specs);
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(550)}/>
                                <input style={{width:'8.5%',height:30,padding:0,textAlign:'center'}} value={item.collectWeight?arithUtil.keToWeight(item.collectWeight,this.state.weightMode):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processCommodities[key].collectWeight = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                    if(item.specs)this.state.processVoItem.processCommodities[key].collectNumber = (this.state.processVoItem.processCommodities[key].collectWeight).div(item.specs,0);
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(555)}/>
                                <input style={{width:'8.5%',height:30,padding:0,textAlign:'center'}} value={item.lossNumber?item.lossNumber:''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processCommodities[key].lossNumber = event.target.value?parseInt(event.target.value):0;
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(551)}/>
                                <input style={{width:'8.5%',height:30,padding:0,textAlign:'center'}} value={item.lossWeight?arithUtil.keToWeight(item.lossWeight,this.state.weightMode):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processCommodities[key].lossWeight = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(556)}/>
                                <input style={{width:'8.5%',height:30,padding:0,textAlign:'center'}} value={item.surplusNumber?item.surplusNumber:''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processCommodities[key].surplusNumber = event.target.value?parseInt(event.target.value):0;
                                    if(item.specs)this.state.processVoItem.processCommodities[key].surplusWeight = (this.state.processVoItem.processCommodities[key].surplusNumber).mul(item.specs);
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(557)}/>
                                <input style={{width:'8.5%',height:30,padding:0,textAlign:'center'}} value={item.surplusWeight?arithUtil.keToWeight(item.surplusWeight,this.state.weightMode):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processCommodities[key].surplusWeight = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                    if(item.specs)this.state.processVoItem.processCommodities[key].surplusNumber = (this.state.processVoItem.processCommodities[key].surplusWeight).div(item.specs,0);
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(558)}/>
                                <span style={{width:'8.5%',textAlign:'center'}}>{item.useNumber}</span>
                                <span style={{width:'8.5%',textAlign:'center'}}>{arithUtil.keToWeight(item.useWeight,this.state.weightMode)}</span>
                                <span style={{width:'8%',textAlign:'center'}}>{arithUtil.fenToYuan(item.useWeight+item.lossWeight?(item.purchaseWeightUnitPrice).mul(arithUtil.keToWeight(item.useWeight+item.lossWeight,0)):(item.purchaseUnitPrice).mul(item.useNumber+item.lossNumber))}</span>
                            </div>)
                        })}
                    </div>
                    <div style={{position:'absolute',left:0,right:0,bottom:0}}>
                        <div style={{width:'18%',height:35,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if(this.state.processVoItem.cargoId==null){ToastBox.error(stringUtil.language(215));return;}
                            this.state.keySearch='';
                            this.state.processCommoditiesChoice=[];
                            this.setState({addProcessShowPop:!this.state.addProcessShowPop});
                        }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#F00'}}>&#xe601;</i>
                        </div>
                        <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'24%',color:'#52A056'}}>{stringUtil.language(890)}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{this.state.totalInfo.collectNumber}{stringUtil.language(1173)}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.collectWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{this.state.totalInfo.lossNumber}{stringUtil.language(1173)}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.lossWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{this.state.totalInfo.surplusNumber}{stringUtil.language(1173)}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.surplusWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{this.state.totalInfo.useNumber}{stringUtil.language(1173)}</span>
                            <span style={{width:'8.5%',color:'#52A056',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.useWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                            <span style={{width:'8%',color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.purchase)}</span>
                        </div>
                    </div>
                </div>
                <div style={{height:'35%',position:'relative'}}>
                    <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'5%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1026)}</span>
                        <span style={{width:'12%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(546)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1105)}</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1106)}({stringUtil.currency(1)})</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1107)}({stringUtil.currency(1)})</span>
                        <span style={{width:'9%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1108)}({stringUtil.currency(1)})</span>
                        <span style={{width:'8%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(947)}({stringUtil.currency(1)})</span>
                        <span style={{width:'10%',color:'#A9A9A9',fontSize:12,textAlign:'center'}}>{stringUtil.language(559)}({stringUtil.currency(1)})</span>
                    </div>
                    <div style={{position:'absolute',left:0,top:25,right:0,bottom:60,overflow:'auto'}}>
                        {(()=>{this.state.totalInfo={number:0,weight:0,materialMoney:0,teamMoney:0,subMaterialMoney:0,otherMoney:0,money:0};})()}
                        {this.state.processVoItem.processFinishCommodities.map((item,key)=>{
                            this.state.processVoItem.processFinishCommodities[key].money=(item.materialMoney).add(item.teamMoney).add(item.subMaterialMoney).add(item.otherMoney);
                            this.state.totalInfo.number=this.state.totalInfo.number+item.number;
                            this.state.totalInfo.weight=(this.state.totalInfo.weight).add(item.weight);
                            this.state.totalInfo.materialMoney=(this.state.totalInfo.materialMoney).add(item.materialMoney);
                            this.state.totalInfo.teamMoney=(this.state.totalInfo.teamMoney).add(item.teamMoney);
                            this.state.totalInfo.subMaterialMoney=(this.state.totalInfo.subMaterialMoney).add(item.subMaterialMoney);
                            this.state.totalInfo.otherMoney=(this.state.totalInfo.otherMoney).add(item.otherMoney);
                            this.state.totalInfo.money=(this.state.totalInfo.money).add(item.money);
                            return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <div style={{width:'5%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{width:'6%',fontSize:12,textAlign:'center'}}>{key+1}</span>
                                    <i className="iconfontIndexCss" style={{fontSize:15,color:'#F00',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                                        this.state.processVoItem.processFinishCommodities.splice(key,1);
                                        this.setState({processVoItem:this.state.processVoItem});
                                    }}>&#xe612;</i>
                                </div>
                                <span style={{width:'12%'}}>{item.commodityName}</span>
                                <div style={{width:'10%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <input style={{display:'flex',flexGrow:1,height:'100%',padding:0,textAlign:'center'}} value={item.standard?arithUtil.keToWeight(item.standard,this.state.weightMode):''} type='number' onChange={(event)=>{
                                        this.state.processVoItem.processFinishCommodities[key].standard = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                        this.setState({processVoItem:this.state.processVoItem});
                                    }} placeholder='0'/><span style={{fontSize:12}}>{(this.state.weightMode?stringUtil.language(1172)+'/':stringUtil.language(1023)+'/')+(item.commodityUnitMaster?item.commodityUnitMaster:stringUtil.language(1173))}</span>
                                </div>
                                <input style={{width:'8%',height:30,padding:0,textAlign:'center'}} value={item.number?item.number:''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processFinishCommodities[key].number = event.target.value?parseInt(event.target.value):0;
                                    let item=this.state.processVoItem.processFinishCommodities[key];
                                    if(item.standard){
                                        this.state.processVoItem.processFinishCommodities[key].weight = (item.number).mul(item.standard);
                                    }
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(1021)}/>
                                <input style={{width:'10%',height:30,padding:0,textAlign:'center'}} value={item.weight?arithUtil.keToWeight(item.weight,this.state.weightMode):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processFinishCommodities[key].weight = event.target.value?arithUtil.weightToKe(parseFloat(event.target.value),this.state.weightMode):0;
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(1022)}/>
                                <input style={{width:'10%',height:30,padding:0,textAlign:'center'}} value={item.materialMoney?arithUtil.fenToYuan(item.materialMoney):''} type='number' onChange={(event)=>{
                                    let purchaseTotal=0,materialMoneyTotal=0;
                                    for(let i=0;i<(this.state.processVoItem.processCommodities?this.state.processVoItem.processCommodities:[]).length;i++){
                                        purchaseTotal=purchaseTotal.add(this.state.processVoItem.processCommodities[i].useWeight+this.state.processVoItem.processCommodities[i].lossWeight?(this.state.processVoItem.processCommodities[i].purchaseWeightUnitPrice).mul(arithUtil.keToWeight(this.state.processVoItem.processCommodities[i].useWeight+this.state.processVoItem.processCommodities[i].lossWeight,0)):(this.state.processVoItem.processCommodities[i].purchaseUnitPrice).mul(this.state.processVoItem.processCommodities[i].useNumber+this.state.processVoItem.processCommodities[i].lossNumber));
                                    }
                                    for(let i=0;i<(this.state.processVoItem.processFinishCommodities?this.state.processVoItem.processFinishCommodities:[]).length;i++){
                                        if(key!=i)materialMoneyTotal=materialMoneyTotal.add(this.state.processVoItem.processFinishCommodities[i].materialMoney);
                                    }
                                    let totalFinishMoneyTemp=(materialMoneyTotal).add(event.target.value?arithUtil.yuanToFen(parseFloat(event.target.value)):0);
                                    let subMoneyTemp=purchaseTotal.sub(materialMoneyTotal);
                                    if(totalFinishMoneyTemp<=purchaseTotal){
                                        this.state.processVoItem.processFinishCommodities[key].materialMoney = event.target.value?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                        let item=this.state.processVoItem.processFinishCommodities[key];
                                        this.state.processVoItem.processFinishCommodities[key].money=(item.materialMoney).add(item.teamMoney).add(item.subMaterialMoney).add(item.otherMoney);
                                        if(totalFinishMoneyTemp<purchaseTotal)for(let i=0;i<(this.state.processVoItem.processFinishCommodities?this.state.processVoItem.processFinishCommodities:[]).length;i++){
                                            if(!this.state.processVoItem.processFinishCommodities[i].materialMoney){
                                                this.state.processVoItem.processFinishCommodities[i].materialMoney = purchaseTotal.sub(totalFinishMoneyTemp);
                                                let item=this.state.processVoItem.processFinishCommodities[i];
                                                this.state.processVoItem.processFinishCommodities[i].money=(item.materialMoney).add(item.teamMoney).add(item.subMaterialMoney).add(item.otherMoney);
                                                break;
                                            }
                                        }
                                        this.setState({processVoItem:this.state.processVoItem});
                                    }else{ToastBox.error(stringUtil.language(25)+'（'+arithUtil.fenToYuan(subMoneyTemp)+'）'+stringUtil.currency(1)+'!');}
                                }} placeholder={stringUtil.language(1106)}/>
                                <input style={{width:'8%',height:30,padding:0,textAlign:'center'}} value={item.teamMoney?arithUtil.fenToYuan(item.teamMoney):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processFinishCommodities[key].teamMoney = event.target.value?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(1107)}/>
                                <input style={{width:'9%',height:30,padding:0,textAlign:'center'}} value={item.subMaterialMoney?arithUtil.fenToYuan(item.subMaterialMoney):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processFinishCommodities[key].subMaterialMoney = event.target.value?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(1108)}/>
                                <input style={{width:'8%',height:30,padding:0,textAlign:'center'}} value={item.otherMoney?arithUtil.fenToYuan(item.otherMoney):''} type='number' onChange={(event)=>{
                                    this.state.processVoItem.processFinishCommodities[key].otherMoney = event.target.value?arithUtil.yuanToFen(parseFloat(event.target.value)):0;
                                    this.setState({processVoItem:this.state.processVoItem});
                                }} placeholder={stringUtil.language(1069)}/>
                                <span style={{width:'10%',textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={{width:'10%',textAlign:'center'}}>{item.number?arithUtil.fenToYuan((item.money).div(item.number)):''}{!item.number&&item.weight?arithUtil.fenToYuan((item.money).div(arithUtil.keToWeight(item.weight,this.state.weightMode))):''}</span>
                            </div>)
                        })}
                    </div>
                    <div style={{position:'absolute',left:0,right:0,bottom:0}}>
                        <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <div style={{width:'18%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                if(this.state.processVoItem.cargoId==null){ToastBox.error(stringUtil.language(215));return;}
                                this.state.keySearch='';
                                this.state.processFinishCommoditiesChoice=[];
                                this.setState({addProcessFinishShowPop:!this.state.addProcessFinishShowPop});
                            }}><i className="iconfontIndexCss" style={{fontSize:'30px',color:'#F00'}}>&#xe601;</i>
                            </div>
                            <div style={{width:'27%'}}/>
                            <span style={{width:'10%',height:'30px',lineHeight:'30px',fontSize:13,borderRadius:5,textAlign:'center',backgroundColor:'#68E18C',cursor:'pointer'}} onClick={()=>{
                                if(this.state.processVoItem.processCommodities.length==0){ToastBox.error(stringUtil.language(156));return;}
                                if(this.state.processVoItem.processFinishCommodities.length==0){ToastBox.error(stringUtil.language(157));return;}
                                for(let i=0;i<(this.state.processVoItem.processFinishCommodities?this.state.processVoItem.processFinishCommodities:[]).length;i++){
                                    if(!this.state.processVoItem.processFinishCommodities[i].number){ToastBox.error(stringUtil.language(948)+this.state.processVoItem.processFinishCommodities[i].commodityName+stringUtil.language(560));return;}
                                }
                                this.setState({addShowPopIndex:this.state.addShowPopIndex==null?0:this.state.addShowPopIndex+1,materialShowPop:!this.state.materialShowPop});
                            }}>{stringUtil.language(561)}</span>
                        </div>
                        <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:'27%',color:'#52A056'}}>{stringUtil.language(890)}</span>
                            <span style={{width:'8%',color:'#52A056',textAlign:'center'}}>{this.state.totalInfo.number}{stringUtil.language(1173)}</span>
                            <span style={{width:'10%',color:'#52A056',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                            <span style={{width:'10%',color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.materialMoney)}</span>
                            <span style={{width:'8%',color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.teamMoney)}</span>
                            <span style={{width:'9%',color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.subMaterialMoney)}</span>
                            <span style={{width:'8%',color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.otherMoney)}</span>
                            <span style={{width:'10%',color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.money)}</span>
                        </div>
                    </div>
                </div>
                <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                    <div style={{width:120,height:30,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#6CE391',borderRadius:5,cursor:'pointer'}} onClick={()=>{
                        if(this.state.processVoItem.teamId==null){ToastBox.error(stringUtil.language(216));return;}
                        let processInsertVO={
                            time:this.state.processVoItem.createTime,
                            cargoId:this.state.processVoItem.cargoId,
                            teamId:this.state.processVoItem.teamId,
                            describe:'',state:0,//0stringUtil.language(1145)，1stringUtil.language(1144)
                            processCommodities:[],
                            processFinishCommodities:[],
                        };
                        for(let i=0;i<this.state.processVoItem.processCommodities.length;i++){
                            let item = this.state.processVoItem.processCommodities[i];
                            processInsertVO.processCommodities.push({
                                commodityId:item.commodityId,
                                cargoCommodityId:item.cargoCommodityId,
                                specs:item.specs,
                                lossNumber:item.lossNumber,
                                lossWeight:item.lossWeight,
                                collectNumber:item.collectNumber,
                                collectWeight:item.collectWeight,
                                surplusNumber:item.surplusNumber,
                                surplusWeight:item.surplusWeight,
                                useNumber:item.useNumber,
                                useWeight:item.useWeight,
                                purchaseUnitPrice:item.purchaseUnitPrice,
                                purchaseWeightUnitPrice:item.purchaseWeightUnitPrice,
                                money:item.useWeight+item.lossWeight?(item.purchaseWeightUnitPrice).mul(arithUtil.keToWeight(item.useWeight+item.lossWeight,0)):(item.purchaseUnitPrice).mul(item.useNumber+item.lossNumber)
                            });
                        }
                        for(let i=0;i<this.state.processVoItem.processFinishCommodities.length;i++){
                            let item = this.state.processVoItem.processFinishCommodities[i];
                            let membersTemp=[];
                            for(let j=0;j<(item.members?item.members:[]).length;j++){
                                membersTemp.push({
                                    memberId:item.members[j].memberId,
                                    weight:item.members[j].weight
                                });
                            }
                            processInsertVO.processFinishCommodities.push({
                                commodityId:item.commodityId,
                                number:item.number,
                                weight:item.weight,
                                standard:item.standard,
                                materialMoney:item.materialMoney,
                                teamMoney:item.teamMoney,
                                subMaterialMoney:item.subMaterialMoney,
                                members:membersTemp,
                                otherMoney:item.otherMoney,
                                money:(item.materialMoney).add(item.teamMoney).add(item.subMaterialMoney).add(item.otherMoney)
                            });
                        }
                        stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL,processInsertVO,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success(stringUtil.language(562));
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}><span style={{color:'#FFF'}}>{stringUtil.language(563)}</span>
                    </div>
                    <div style={{width:120,height:30,display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#25C656',borderRadius:5,marginLeft:20,cursor:'pointer'}} onClick={()=>{
                        if(this.state.processVoItem.cargoId==null){ToastBox.error(stringUtil.language(215));return;}
                        if(this.state.processVoItem.teamId==null){ToastBox.error(stringUtil.language(216));return;}
                        if(this.state.processVoItem.processCommodities.length==0){ToastBox.error(stringUtil.language(156));return;}
                        if(this.state.processVoItem.processFinishCommodities.length==0){ToastBox.error(stringUtil.language(157));return;}
                        let purchaseTotal=0,materialMoneyTotal=0;
                        for(let i=0;i<(this.state.processVoItem.processCommodities?this.state.processVoItem.processCommodities:[]).length;i++){
                            purchaseTotal=purchaseTotal.add(this.state.processVoItem.processCommodities[i].useWeight+this.state.processVoItem.processCommodities[i].lossWeight?(this.state.processVoItem.processCommodities[i].purchaseWeightUnitPrice).mul(arithUtil.keToWeight(this.state.processVoItem.processCommodities[i].useWeight+this.state.processVoItem.processCommodities[i].lossWeight,0)):(this.state.processVoItem.processCommodities[i].purchaseUnitPrice).mul(this.state.processVoItem.processCommodities[i].useNumber+this.state.processVoItem.processCommodities[i].lossNumber));
                        }
                        for(let i=0;i<(this.state.processVoItem.processFinishCommodities?this.state.processVoItem.processFinishCommodities:[]).length;i++){
                            if(!this.state.processVoItem.processFinishCommodities[i].number){ToastBox.error(stringUtil.language(948)+this.state.processVoItem.processFinishCommodities[i].commodityName+stringUtil.language(560));return;}
                            materialMoneyTotal=materialMoneyTotal.add(this.state.processVoItem.processFinishCommodities[i].materialMoney);
                        }
                        if(purchaseTotal!=materialMoneyTotal){ToastBox.error(stringUtil.language(10));return;};//20220930吕stringUtil.language(1027)
                        this.setState({processAddShowPop:!this.state.processAddShowPop});
                    }}><span style={{color:'#FFF'}}>{stringUtil.language(564)}</span>
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(217)} visible={this.state.materialShowPop} width={arithUtil.ProportionTo(document.body.clientWidth,0.16)+((this.state.processVoItem.processCommodities?this.state.processVoItem.processCommodities:[]).length>1?(this.state.processVoItem.processCommodities?this.state.processVoItem.processCommodities:[]).length:2)*70} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{width:'100%',height:'100%'}} key={this.state.addShowPopIndex}>
                    <ProcessMaterialView context={this} paramsInfo={{processVoItem:JSON.stringify(this.state.processVoItem)}} successEvent={(result1)=>{
                        this.state.processVoItemMaterialTemp=result1;
                    }}/>
                </div>}
                okBnEvents={(event) => {
                    if(this.state.processVoItemMaterialTemp==null)this.state.processVoItemMaterialTemp=JSON.parse(JSON.stringify(this.state.processVoItem));
                    for(let i=0;i<(this.state.processVoItemMaterialTemp.processCommodities?this.state.processVoItemMaterialTemp.processCommodities:[]).length;i++){
                        let item=this.state.processVoItemMaterialTemp.processCommodities[i];
                        let processCommoditiesUseTotal=0;
                        for(let j=0;j<(this.state.processVoItemMaterialTemp.processFinishCommodities?this.state.processVoItemMaterialTemp.processFinishCommodities:[]).length;j++){
                            let itemb=this.state.processVoItemMaterialTemp.processFinishCommodities[j];
                            let membersTotalWeight=0;
                            for(let k=0;k<(itemb.members?itemb.members:[]).length;k++){
                                if(item.commodityId==itemb.members[k].memberId){
                                    processCommoditiesUseTotal=processCommoditiesUseTotal.add((itemb.members[k].weight).mul(itemb.number));
                                }
                                membersTotalWeight=membersTotalWeight.add(itemb.members[k].weight);
                            }
                            this.state.processVoItemMaterialTemp.processFinishCommodities[j].standard=membersTotalWeight?membersTotalWeight:itemb.standard;
                        }
                        this.state.processVoItemMaterialTemp.processCommodities[i].useTotalWeightChenpinglong=processCommoditiesUseTotal;
                    }
                    for(let j=0;j<(this.state.processVoItemMaterialTemp.processFinishCommodities?this.state.processVoItemMaterialTemp.processFinishCommodities:[]).length;j++){
                        let itemb=this.state.processVoItemMaterialTemp.processFinishCommodities[j];
                        let processFinishCommoditiesUseChenBen=0;
                        for(let k=0;k<(itemb.members?itemb.members:[]).length;k++){
                            for(let i=0;i<(this.state.processVoItemMaterialTemp.processCommodities?this.state.processVoItemMaterialTemp.processCommodities:[]).length;i++){
                                let item=this.state.processVoItemMaterialTemp.processCommodities[i];
                                if(item.commodityId==itemb.members[k].memberId){
                                    let processCommoditiesUseChenBen=item.useWeight+item.lossWeight?(item.newPurchaseWeightUnitPrice).mul(arithUtil.keToWeight(item.useWeight+item.lossWeight,0)):(item.newPurchaseUnitPrice).mul(item.useNumber+item.lossNumber);
                                    processFinishCommoditiesUseChenBen=processFinishCommoditiesUseChenBen.add((itemb.members[k].weight).mul(itemb.number).div(item.useTotalWeightChenpinglong).mul(processCommoditiesUseChenBen));
                                }
                            }
                        }
                        this.state.processVoItemMaterialTemp.processFinishCommodities[j].materialMoney=processFinishCommoditiesUseChenBen;
                    }
                    this.setState({processVoItem:this.state.processVoItemMaterialTemp,materialShowPop:false});
                }} closeBnEvents={() => {this.setState({materialShowPop:false})}}/>
            <MaskModal title="提示" visible={this.state.processAddShowPop} context={this}
                content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                    {stringUtil.language(4)}
                </div>}
                okBnEvents={(event) => {
                    this.setState({processAddShowPop: false},()=>{
                        let processInsertVO={
                            time:this.state.processVoItem.createTime,
                            cargoId:this.state.processVoItem.cargoId,
                            teamId:this.state.processVoItem.teamId,
                            describe:'',state:1,//0stringUtil.language(1145)，1stringUtil.language(1144)
                            processCommodities:[],
                            processFinishCommodities:[],
                        };
                        for(let i=0;i<this.state.processVoItem.processCommodities.length;i++){
                            let item = this.state.processVoItem.processCommodities[i];
                            processInsertVO.processCommodities.push({
                                commodityId:item.commodityId,
                                cargoCommodityId:item.cargoCommodityId,
                                specs:item.specs,
                                lossNumber:item.lossNumber,
                                lossWeight:item.lossWeight,
                                collectNumber:item.collectNumber,
                                collectWeight:item.collectWeight,
                                surplusNumber:item.surplusNumber,
                                surplusWeight:item.surplusWeight,
                                useNumber:item.useNumber,
                                useWeight:item.useWeight,
                                purchaseUnitPrice:item.purchaseUnitPrice,
                                purchaseWeightUnitPrice:item.purchaseWeightUnitPrice,
                                money:item.useWeight+item.lossWeight?(item.purchaseWeightUnitPrice).mul(arithUtil.keToWeight(item.useWeight+item.lossWeight,0)):(item.purchaseUnitPrice).mul(item.useNumber+item.lossNumber)
                            });
                        }
                        for(let i=0;i<this.state.processVoItem.processFinishCommodities.length;i++){
                            let item = this.state.processVoItem.processFinishCommodities[i];
                            let membersTemp=[];
                            for(let j=0;j<(item.members?item.members:[]).length;j++){
                                membersTemp.push({
                                    memberId:item.members[j].memberId,
                                    weight:item.members[j].weight
                                });
                            }
                            processInsertVO.processFinishCommodities.push({
                                commodityId:item.commodityId,
                                number:item.number,
                                weight:item.weight,
                                standard:item.standard,
                                materialMoney:item.materialMoney,
                                teamMoney:item.teamMoney,
                                subMaterialMoney:item.subMaterialMoney,
                                members:membersTemp,
                                otherMoney:item.otherMoney,
                                money:(item.materialMoney).add(item.teamMoney).add(item.subMaterialMoney).add(item.otherMoney)
                            });
                        }
                        stringUtil.httpRequest(urlUtil.SALE_PROCESS_URL,processInsertVO,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success(stringUtil.language(388));
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    });
                }} closeBnEvents={() => {this.setState({processAddShowPop: false})}}/>
            <MaskModal title={stringUtil.language(319)} visible={this.state.addProcessShowPop} width={450} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}}>
                    <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                                this.state.keySearch = event.target.value;
                                if(this.state.keySearch=='')this.searchCommoditys();
                            }} placeholder={stringUtil.language(32)}/>&nbsp;&nbsp;
                            <span style={{width:arithUtil.pxToDp(50),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.searchCommoditys();
                            }}>{stringUtil.language(1034)}</span>
                        </div>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(100)})} onChange={(event)=>{
                                this.state.proCommodityTypeItem = JSON.parse(event.target.value);
                                this.searchCommoditys();
                            }}>
                            {this.state.commodityTypesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                    <div style={{height:'89%',overflow:'auto'}}>
                        {this.state.warehouseCommodityDO.map((item,key)=>{
                            let isHavaChooseObj=null;let isHavaChooseObjKey=-1;
                            for(let i=0;i<this.state.processCommoditiesChoice.length;i++){
                                let chooseItem = this.state.processCommoditiesChoice[i];
                                if (chooseItem.warehouseId==item.warehouseId && chooseItem.cargoCommodityId==item.cargoCommodityId) {
                                    isHavaChooseObj=this.state.processCommoditiesChoice[i];
                                    isHavaChooseObjKey=i;break;
                                }
                            }
                            return(<div key={key} style={{height:55,backgroundColor:isHavaChooseObj?'rgba(72,209,204,0.3)':'#FFF',display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                let isHavaChoose=0;
                                for(let i=0;i<this.state.processCommoditiesChoice.length;i++){
                                    if (this.state.processCommoditiesChoice[i].warehouseId==item.warehouseId && this.state.processCommoditiesChoice[i].cargoCommodityId==item.cargoCommodityId) {
                                        isHavaChoose=1;
                                        this.state.processCommoditiesChoice.splice(i,1);
                                        break;
                                    }
                                }
                                if(!isHavaChoose)this.state.processCommoditiesChoice.push({
                                    warehouseId:item.warehouseId,
                                    commodityId:item.commodityId,
                                    cargoCommodityId:item.cargoCommodityId,
                                    commodityName:item.commodityName,
                                    commodityUnitMaster:item.unitMaster,
                                    purchaseUnitPrice:item.purchaseUnitPrice,
                                    purchaseWeightUnitPrice:item.purchaseWeightUnitPrice,
                                    specs:0,lossNumber:0,lossWeight:0,collectNumber:0,collectWeight:0,surplusNumber:0,surplusWeight:0,useNumber:0,useWeight:0,money:0
                                });
                                this.setState({processCommoditiesChoice:this.state.processCommoditiesChoice});
                            }}><img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>
                                <span style={{fontSize:17,marginLeft:8}}>{item.commodityName}</span>
                            </div>)
                        })}
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if(this.state.processCommoditiesChoice==null)this.state.processCommoditiesChoice=[];
                    for(let i=0;i<this.state.processCommoditiesChoice.length;i++){
                        this.state.processVoItem.processCommodities.push(this.state.processCommoditiesChoice[i]);
                    }
                    this.setState({addProcessShowPop: false});
                }} closeBnEvents={() => {this.setState({ addProcessShowPop: false, })}}/>
            <MaskModal title={stringUtil.language(320)} visible={this.state.addProcessFinishShowPop} width={450} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}}>
                    <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                                this.state.keySearch = event.target.value;
                                if(this.state.keySearch=='')this.searchFinishCommoditys();
                            }} placeholder={stringUtil.language(32)}/>&nbsp;&nbsp;
                            <span style={{width:arithUtil.pxToDp(50),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.searchFinishCommoditys();
                            }}>{stringUtil.language(1034)}</span>
                        </div>
                        <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(100)})} onChange={(event)=>{
                                this.state.currCommodityTypeItem = JSON.parse(event.target.value);
                                this.searchFinishCommoditys();
                            }}>
                            {this.state.commodityTypesAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                    <div style={{height:'89%',overflow:'auto'}}>
                        {this.state.commoditysData.map((item,key)=>{
                            let isHavaChooseObj=null;let isHavaChooseObjKey=-1;
                            for(let i=0;i<this.state.processFinishCommoditiesChoice.length;i++){
                                let chooseItem = this.state.processFinishCommoditiesChoice[i];
                                if (chooseItem.commodityId==item.id) {
                                    isHavaChooseObj=this.state.processFinishCommoditiesChoice[i];
                                    isHavaChooseObjKey=i;break;
                                }
                            }
                            return(<div key={key} style={{height:55,backgroundColor:isHavaChooseObj?'rgba(72,209,204,0.3)':'#FFF',display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                                let isHavaChoose=0;
                                for(let i=0;i<this.state.processFinishCommoditiesChoice.length;i++){
                                    if (this.state.processFinishCommoditiesChoice[i].commodityId==item.id) {
                                        isHavaChoose=1;
                                        this.state.processFinishCommoditiesChoice.splice(i,1);
                                        break;
                                    }
                                }
                                if(!isHavaChoose){
                                    let membersTotalWeight=0;
                                    for(let i=0;i<(item.members?item.members:[]).length;i++){
                                        membersTotalWeight=membersTotalWeight.add(item.members[i].weight);
                                    }
                                    this.state.processFinishCommoditiesChoice.push({
                                        commodityId:item.id,
                                        commodityName:item.name,
                                        commodityUnitSlave:item.unitSlave,
                                        commodityUnitScale:item.unitScale,
                                        commodityUnitMaster:item.unitMaster,
                                        standard:item.standard?item.standard:membersTotalWeight,
                                        members:item.members,
                                        number:0,weight:0,materialMoney:0,teamMoney:0,subMaterialMoney:0,otherMoney:0,money:0
                                    });
                                }
                                this.setState({processFinishCommoditiesChoice:this.state.processFinishCommoditiesChoice});
                            }}><img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>
                                <span style={{fontSize:17,marginLeft:8}}>{item.name}</span>
                            </div>)
                        })}
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if(this.state.processFinishCommoditiesChoice==null)this.state.processFinishCommoditiesChoice=[];
                    for(let i=0;i<this.state.processFinishCommoditiesChoice.length;i++){
                        this.state.processVoItem.processFinishCommodities.push(this.state.processFinishCommoditiesChoice[i]);
                    }
                    this.setState({addProcessFinishShowPop: false});
                }} closeBnEvents={() => {this.setState({ addProcessFinishShowPop: false, })}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
  };