import React,{Component} from 'react';
import QRCode  from 'qrcode.react'
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
 
export default class Login extends Component{
	constructor(props){
      super(props);
      this.state={
        loginInfo:{mobile:'',password:''},
        startDate: new Date()
      };
  }
  componentDidMount(){
    let authorizationUser = localStorage.getItem("authorizationUser");
    if(authorizationUser!=null){
      let authorizationStall = localStorage.getItem("authorizationStall");
      if (authorizationStall!=null) {
        stringUtil.httpRequest(urlUtil.OAUTH_USER_INFO_URL,null,['key','get',0],(successResponse)=>{
            if (successResponse.status==200) {
              let userInfoItem = successResponse.data;
              localStorage.setItem("currentUserItem", JSON.stringify(userInfoItem));//设置缓存
              stringUtil.httpRequest(urlUtil.OAUTH_SHOP_INFO_URL,null,['key','get',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    let shopInfoItem = successResponse.data;
                    if(shopInfoItem.warehouseId)localStorage.setItem("currentWarehouseItem", JSON.stringify({id:shopInfoItem.warehouseId,name:shopInfoItem.warehouseName}));//id为0{stringUtil.language(975)}默认{stringUtil.language(1064)}
                    localStorage.setItem("currentStallItem", JSON.stringify(shopInfoItem));//设置缓存
                    localStorage.setItem("authorityDatas", JSON.stringify(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).authorities:[]));//设置缓存
                    localStorage.setItem("wholesaleLocalInfo",JSON.stringify({wholesaleWay:shopInfoItem.wholesaleWay}));//设置缓存 0传统{stringUtil.language(796)}，1零{stringUtil.language(1176)}售卖方式，2{stringUtil.language(1084)}售卖方式
                    this.props.history.push({ pathname: "/Main", state: { id:'6666',name:'参数值。。' } });
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
              },(errorResponse)=>{
                ToastBox.error(errorResponse.message+`(${errorResponse.status})`);
                this.props.history.push({ pathname: "/MeInfo", state: { id:'6666',name:'参数值。。' } });
              });
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{
          ToastBox.error(errorResponse.message+`(${errorResponse.status})`);
          this.props.history.push({pathname: "/"});
        });
      }else{this.props.history.push({ pathname: "/MeInfo", state: { id:'6666',name:'参数值。。' } });}//使用指定路由覆盖当前路由
    }else{this.props.history.push({pathname: "/"});}
    //取props.history.push传参的参数值this.props.location.state.id
  }

	render(){
    let screenHeight = document.body.clientWidth;
    if(screenHeight>800){//PC
      return(<div style={{width:'100%',display:'flex',flexDirection:'column',backgroundColor:'#FFF',position:'relative'}}>
        <div style={{height:90,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:20,paddingRight:20}}>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <img style={{width:'50px',height:'50px',borderRadius:5}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
            <div style={{marginLeft:10}}><span style={{fontSize:22,fontWeight:'bold'}}>{stringUtil.language(208)}</span><br/><span>www.maicai168.cn</span></div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <img style={{width:'100%'}} loading="lazy" src={require('../images/bg_img.png').default} title={stringUtil.language(84)}/>
            <div style={{display:'flex',flexDirection:'row',marginLeft:150,marginTop:-200}}>
              <a href={urlUtil.FILE_APP_URL} style={{width:140,height:160,textDecoration:'none',backgroundColor:'#FFF',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} download={stringUtil.language(209)+'.apk'}>
                <QRCode value={urlUtil.IP_ADDRESS_URL+'/share/ShareClientDown'}/*二维{stringUtil.language(1184)}的链接*/ size={120}/*宽高尺寸*/ fgColor="#000"/*颜色*/ />
                <span>{stringUtil.language(151)}</span>
              </a>
              <a href={urlUtil.FILE_PRINT_URL} style={{width:140,height:160,textDecoration:'none',marginLeft:15,backgroundColor:'#FFF',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} download={stringUtil.language(98)+'.exe'}>
                <i className="iconfontIndexCss" style={{width:120,height:120,textAlign:'center',fontSize:'80px',color:'#696969'}}>&#xe867;</i>
                <span>{stringUtil.language(210)}</span>
              </a>
            </div>
          </div>
          <div style={arithUtil.stylesAdd(styles.infoView,{position:'absolute',top:150,right:200})}>
              <span style={{color:'#383838',fontSize:24,marginTop:20}}>{stringUtil.language(99)}</span>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'nowrap',justifyContent:'flex-start',alignItems:'center',marginTop:30}}>
                  <span style={{color:'#505050',whiteSpace:'nowrap'}}>{stringUtil.language(934)}</span>
                  <input style={arithUtil.stylesAdd(styles.phoneInputText,{marginLeft:arithUtil.pxToDp(20)})} type='number' onChange={(event)=>{this.state.loginInfo.mobile = event.target.value;}} placeholder={stringUtil.language(100)}/>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'nowrap',justifyContent:'flex-start',alignItems:'center',marginTop:30}}>
                  <span style={{color:'#505050',whiteSpace:'nowrap'}}>{stringUtil.language(1183)}&nbsp;&nbsp;&nbsp;{stringUtil.language(1184)}</span>
                  <input style={arithUtil.stylesAdd(styles.phoneInputText,{marginLeft:arithUtil.pxToDp(20)})} type='password' onChange={(event)=>{this.state.loginInfo.password = event.target.value;}} placeholder={stringUtil.language(152)}/>
              </div>
              <span style={{width:arithUtil.pxToDp(230),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',marginTop:35,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                if (this.state.loginInfo.mobile=='') {ToastBox.error(stringUtil.language(153));return;}
                if (this.state.loginInfo.password=='') {ToastBox.error(stringUtil.language(211));return;}
                stringUtil.httpRequest(urlUtil.LOGIN_PWD_URL,{
                  mobile:this.state.loginInfo.mobile,
                  password:this.state.loginInfo.password,
                  clientType:1
                },['key','post',0],(successResponse)=>{
                  if (successResponse.status==200) {
                    // ToastBox.success(successResponse.message);
                    let authorization= successResponse.data;
                    localStorage.setItem("authorizationUser", authorization);//设置缓存
                    this.props.history.push({ pathname: "/MeInfo", state: { id:'6666',name:'参数值。。' } });
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }}>{stringUtil.language(935)}</span>
              {/* <div style={{height:'40px',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={()=>{
                this.props.history.push({ pathname: "/Register", state: { id:'6666',name:'参数值。。' } });
              }}><span style={{fontSize:'12px',color:'#959595'}}>- - - 新用户{stringUtil.language(1019)} - - -</span>
              </div> */}
          </div>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:70}}>
          <div style={{width:900,display:'flex',flexDirection:'column'}}>
            <span style={{color:'#00F',fontSize:20}}>{stringUtil.language(522)}</span>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
              <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <img style={{width:150,height:150}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
                <span>{stringUtil.language(936)}</span>
              </div>
              <span style={{width:600}}>{stringUtil.language(1)}</span>
            </div>
            {/* http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010448 */}
            <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'center',marginTop:30}}>
              <span style={{fontSize:10}}>{stringUtil.language(5)}</span>
              <a style={{marginLeft:20}} target='_blank' href='https://beian.miit.gov.cn/'>
                <img style={{float:'left',width:20,height:20}} loading="lazy" src={require('../images/beian.png').default} title={stringUtil.language(84)}/>
                <p style={{float:'left',height:'20px',lineHeight:'20px',margin:'0px 0px 0px 5px',fontSize:10}}>粤公网安备 44010602010448号</p>
              </a>
              <a style={{marginLeft:20}} target='_blank' href='https://beian.miit.gov.cn/'>
                <p style={{float:'left',height:'20px',lineHeight:'20px',margin:'0px 0px 0px 5px',fontSize:10}}>粤ICP备18154006号-2</p>
              </a>
            </div>
          </div>
        </div>
      </div>);
    }else{//移动
      return(<div style={{width:'100%',display:'flex',flexDirection:'column',backgroundColor:'#FFF',position:'relative'}}>
        <div style={{width:'100%',height:90,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'rgba(135,206,235,0.3)'}}>
          <img style={{width:'50px',height:'50px',borderRadius:5,marginLeft:20}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
          <div style={{marginLeft:10}}><span style={{fontSize:22,fontWeight:'bold'}}>{stringUtil.language(84)}</span><br/><span>www.maicai168.cn</span></div>
        </div>
        <div style={{height:50,color:'#808080',fontSize:15,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:20}}>{stringUtil.language(14)}</div>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:10}}>
          <a href={urlUtil.FILE_APP_URL} style={{textDecoration:'none',backgroundColor:'#FFF',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} download={stringUtil.language(209)+'.apk'}>
            <QRCode value={urlUtil.IP_ADDRESS_URL+'/share/ShareClientDown'}/*二维{stringUtil.language(1184)}的链接*/ size={180}/*宽高尺寸*/ fgColor="#000"/*颜色*/ />
            <span>{stringUtil.language(151)}</span>
          </a>
          {/* <a href={urlUtil.FILE_PRINT_URL} style={{textDecoration:'none',backgroundColor:'#FFF',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}} download="{stringUtil.language(98)}.exe">
            <i className="iconfontIndexCss" style={{fontSize:'150px',color:'#696969'}}>&#xe931;</i>
            <span>{stringUtil.language(210)}</span>
          </a> */}
        </div>
      </div>);
    }
	}
}

const styles = {
    infoView:{
      width:'340px',
      height:'300px',
      marginLeft:20,
      backgroundColor:'#FFF',
      borderRadius:8,
      padding:5,
      elevation: 10,  //  设置阴影角度，通过这个设置有无阴影（这个是最stringUtil.language(1078)的，决定有没有阴影）
      shadowColor: 'black',  //  阴影颜色
      shadowOffset: { width: 0, height: 0 },  // 阴影偏移
      shadowOpacity: 0,  // 阴影不透明度
      shadowRadius: 8,  //  圆角
      display:'flex',
      flexDirection:'column',
      flexWrap:'nowrap',
      justifyContent:'flex-start',
      alignItems:'center',
    },
    phoneInputText:{
      width:'75%',
      height:30,
      borderColor:'#d0d0d0',
      borderStyle:'solid',
      borderWidth:1,
      borderRadius:5,
      color:'#808080',
      padding:0,
      paddingLeft:arithUtil.pxToDp(10),
    },
};