import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

  export default class CustomerAddActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        currItem:{},
        districtItemAll:[],
        currDistrictA:null,currDistrictB:null,currDistrictC:null,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SYSTEM_DISTRICT_URL,null,['key','get',0],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.districtItemAll= successResponse.data;
                this.setState({districtItemAll: this.state.districtItemAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:30,paddingTop:30,backgroundColor:'#FFF'}}>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(448)}</span>
                    <input style={styles.phoneInputText} placeholder={stringUtil.language(93)} onChange={(event)=>{
                        this.state.currItem.noteName = event.target.value;
                    }}/>
                </div>
                <div style={{width:arithUtil.pxToDp(430),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(468)}</span>
                        <select style={arithUtil.stylesAdd(styles.phoneInputText,{width:arithUtil.pxToDp(170)})} onChange={(event)=>{
                            this.state.currItem.warning = JSON.parse(event.target.value).warning;
                            this.setState({currItem:this.state.currItem});
                          }}>
                              <option style={{display:'none'}}></option>
                          {[{warning:0,name:stringUtil.language(1074)},{warning:1,name:stringUtil.language(1075)},{warning:2,name:stringUtil.language(1076)},{warning:3,name:stringUtil.language(1077)},{warning:4,name:stringUtil.language(1078)}].map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                          })}
                        </select>
                    </div>
                    {this.state.currItem.warning==1?<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{color:'#F00'}}>&nbsp;&nbsp;&nbsp;&nbsp;{stringUtil.language(912)}</span>
                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(80),color:'#F00'})} type='number' onChange={(event)=>{
                            this.state.currItem.quota = arithUtil.yuanToFen(event.target.value);
                        }}/><span style={{color:'#F00'}}> {stringUtil.currency(1)}</span>
                    </div>:<div style={{display:'flex',flexDirection:'row',alignItems:'center'}}/>}
                </div>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                    <input style={styles.phoneInputText} type='number' placeholder={stringUtil.language(56)} onChange={(event)=>{
                        this.state.currItem.mobile = event.target.value;
                    }}/>
                </div>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(469)}</span>
                    <input style={styles.phoneInputText} placeholder={stringUtil.language(61)} onChange={(event)=>{
                        this.state.currItem.cardId = event.target.value;
                    }}/>
                </div>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(470)}</span>
                    <select style={styles.selectStyle} onChange={(event)=>{
                        this.state.currDistrictA = JSON.parse(event.target.value);
                        if (this.state.currDistrictA==null||this.state.currDistrictA.districts.length==0){
                            this.state.currDistrictB=null;this.state.currDistrictC=null;
                        }
                        this.setState({currDistrictA:this.state.currDistrictA});
                    }}>
                        <option style={{display:'none'}}></option>
                        {this.state.districtItemAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                    <select style={styles.selectStyle} onChange={(event)=>{
                        this.state.currDistrictB = JSON.parse(event.target.value);
                        if (this.state.currDistrictB==null||this.state.currDistrictB.districts.length==0) {
                        this.state.currDistrictC=null;
                        }
                        this.setState({currDistrictB:this.state.currDistrictB});
                    }}>
                        <option style={{display:'none'}}></option>
                        {(this.state.currDistrictA!=null?this.state.currDistrictA.districts:[]).map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                    <select style={styles.selectStyle} onChange={(event)=>{
                        this.state.currDistrictC = JSON.parse(event.target.value);
                    }}>
                        <option style={{display:'none'}}></option>
                        {(this.state.currDistrictB!=null?this.state.currDistrictB.districts:[]).map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                </div>
                <div style={{minHeight:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(387)}</span>
                    <input style={styles.phoneInputText} placeholder={stringUtil.language(58)} onChange={(event)=>{
                        this.state.currItem.address = event.target.value;
                    }}/>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                    <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if (this.state.currItem.noteName==null || this.state.currItem.noteName=='') {
                            ToastBox.error(stringUtil.language(198));return;
                        }
                        let shengStr = this.state.currDistrictA!=null?this.state.currDistrictA.name:'';
                        let shiStr = this.state.currDistrictB!=null?this.state.currDistrictB.name:'';
                        let xianStr = this.state.currDistrictC!=null?this.state.currDistrictC.name:'';
                        if (shengStr!='' || shiStr!='' || xianStr!='') {
                            if (this.state.currDistrictB!=null) {
                                if (this.state.currDistrictC!=null) {
                                    this.state.currItem.adcode = this.state.currDistrictC.adcode;
                                }else{
                                    this.state.currItem.adcode = this.state.currDistrictB.adcode;
                                }
                            }else{
                                this.state.currItem.adcode = this.state.currDistrictA.adcode;
                            }
                            this.state.currItem.adname = shengStr+shiStr+xianStr;
                        }
                        console.log('----',this.state.currItem);
                        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,this.state.currItem,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success(stringUtil.language(388));
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(859)}</span>
                    <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#808080',lineHeight:'30px',borderRadius:3,backgroundColor:'#DCDCDC',marginLeft:arithUtil.pxToDp(10),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks}});
                    }}>{stringUtil.language(860)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    phoneInputText:{
        width:arithUtil.pxToDp(330),
        height:arithUtil.pxToDp(40),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:5,
        color:'#808080',
        padding:0,
        paddingLeft:arithUtil.pxToDp(10),
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    selectStyle:{
        width: '180px',
        height: '25px',
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth:1,
        borderRadius: '2px',
        paddingLeft: '5px',
        fontSize: '12px'
    },
};