import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(1118)}{stringUtil.language(650)}
export default class AuthoritySettingActivity extends Component{
  constructor(props) {
    super(props);
    let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
    this.state={
      pageIndex:0,
      currWorkerItem:paramsPage.currWorkerItem,
      authorityList:otherUtil.authorityListFormat(otherUtil.getAllAuthoritys()),
      submitAuthoritys:[],
      moduleItem:null,
      owners:[],
      keya:0,
      cargoSelfLineItemAll:[],
      workerOwnerOperates:[],
      workerLines:[],
      workerConfigInfo:{},
      accountsAll:[],accountsSource:[],
      accountAuthority:null,
      allWorkerAccountBn:true,
    };
  }
  componentDidMount(){//初始化数据
      this.initDatas();
      stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.setState({owners:successResponse.data})
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.OAUTH_WORKER_OWNER_URL+'/'+this.state.currWorkerItem.id,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
          this.setState({workerOwnerOperates:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.OAUTH_WORKER_CONFIG_URL+'/'+this.state.currWorkerItem.id,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
          this.setState({workerConfigInfo:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
          this.setState({cargoSelfLineItemAll:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.OAUTH_WORKER_LINE_URL+'/'+this.state.currWorkerItem.id,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
          this.setState({workerLines:successResponse.data});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.BASIC_ACCOUNT_DETAILED_URL,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.state.accountsAll=JSON.parse(JSON.stringify(successResponse.data));
            this.state.accountsSource=JSON.parse(JSON.stringify(successResponse.data));
            this.updateAccountDatas();
            this.setState({accountsAll:this.state.accountsAll});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.OAUTH_WORKER_ACCOUNT_AUTHORITY_URL+'/'+this.state.currWorkerItem.id,null,['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.state.accountAuthority=successResponse.data;
            this.updateAccountDatas();
            this.setState({accountsAll:this.state.accountsAll});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  updateAccountDatas(){
    if(this.state.accountsAll&&this.state.accountAuthority){
        for(let i=0;i<this.state.accountsAll.length;i++){
            if(this.state.accountAuthority.mode){//部分权限
                for(let j=0;j<(this.state.accountAuthority.accountIds?this.state.accountAuthority.accountIds:[]).length;j++){
                    if(this.state.accountAuthority.accountIds[j]==this.state.accountsAll[i].id){
                        this.state.accountsAll[i].isWorkerAccountAuthorityChenpinglong=1;break;
                    }
                }
            }else{this.state.accountsAll[i].isWorkerAccountAuthorityChenpinglong=1;}
        }
    }
  }
  initDatas(){
    stringUtil.httpRequest(urlUtil.WORKER_AUTHORITY_URL+'/'+this.state.currWorkerItem.id,null,['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
        let authorityList = successResponse.data;//权限stringUtil.language(1155)数组
        this.allAuthorityIs(authorityList);
        this.allNextAuthorityIs();
        this.setState({moduleItem:(this.state.authorityList!=null&&this.state.authorityList.length>0)?this.state.authorityList[0]:null});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  allAuthorityIs(ancestorsAuthoritys=null,cancelNexts=0){
    for(let i=0;i<this.state.authorityList.length;i++){
      let businessArray = this.state.authorityList[i].childrens;
      for(let j=0;j<businessArray.length;j++){
        let authorityArray = businessArray[j].childrens;
        for(let k=0;k<authorityArray.length;k++){
          let authorityCodeTemp = authorityArray[k].authorityCode;
          let ancestorsTemp = authorityArray[k].ancestors;
          if (ancestorsAuthoritys!=null) {
            if(cancelNexts==0){
              for(let x=0;x<ancestorsAuthoritys.length;x++){
                if (ancestorsAuthoritys[x]==authorityCodeTemp) {
                  this.state.authorityList[i].isOpen = true;
                  this.state.authorityList[i].childrens[j].isOpen = true;
                  this.state.authorityList[i].childrens[j].childrens[k].isOpen = true;
                  this.submitDataMade(this.state.authorityList[i].childrens[j].childrens[k],0);
                }
              }
            }else{
              let isHaveNexts = false;
              for(let x=0;x<ancestorsTemp.length;x++){
                if (ancestorsTemp[x]==ancestorsAuthoritys) {
                  isHaveNexts = true;break;
                }
              }
              if(isHaveNexts){
                this.state.authorityList[i].childrens[j].childrens[k].isOpen = false;
                this.submitDataMade(this.state.authorityList[i].childrens[j].childrens[k],1);
              }
            }
          }else{
            if (this.state.allAuthorityBn) {
              this.state.authorityList[i].isOpen = true;
              this.state.authorityList[i].childrens[j].isOpen = true;
              this.state.authorityList[i].childrens[j].childrens[k].isOpen = true;
              this.submitDataMade(this.state.authorityList[i].childrens[j].childrens[k],0);
            }else{
              this.state.authorityList[i].isOpen = false;
              this.state.authorityList[i].childrens[j].isOpen = false;
              this.state.authorityList[i].childrens[j].childrens[k].isOpen = false;
              this.submitDataMade(this.state.authorityList[i].childrens[j].childrens[k],1);
            }
          }
        }
      }
    }
  }
  allNextAuthorityIs(){
    for(let i=0;i<this.state.authorityList.length;i++){
      for(let j=0;j<this.state.authorityList[i].childrens.length;j++){let isHavaNoOpen=0;
        for(let k=0;k<this.state.authorityList[i].childrens[j].childrens.length;k++){
          if(!this.state.authorityList[i].childrens[j].childrens[k].isOpen){
            this.state.authorityList[i].childrens[j].isOpen=false;
            isHavaNoOpen=1;break;
          }
        }
        if(!isHavaNoOpen)this.state.authorityList[i].childrens[j].isOpen=true;
      }
    }
  }
  /*{stringUtil.language(1144)}的数据整理
    authorityItem：新的数据项
   addOrDel：0{stringUtil.language(1129)}，1{stringUtil.language(1113)}*/
  submitDataMade(authorityItem,addOrDel){
    let isHave = false;
    for(let i=0;i<this.state.submitAuthoritys.length;i++){
      if (this.state.submitAuthoritys[i].authorityCode==authorityItem.authorityCode) {
        if (addOrDel==0) {//{stringUtil.language(1129)}
          isHave = true;break;
        }else if (addOrDel==1) {
          this.state.submitAuthoritys.splice(i,1);
        }
      }
    }
    if(addOrDel==0 && !isHave)this.state.submitAuthoritys.push(authorityItem);
  }
  submitAuthoritys(){//选择的权限this.state.submitAuthoritys
    let authoritysStr = [];
    for(let i=0;i<this.state.submitAuthoritys.length;i++){
      authoritysStr.push(this.state.submitAuthoritys[i].authorityCode);
    }
    stringUtil.httpRequest(urlUtil.WORKER_AUTHORITY_URL+'/'+this.state.currWorkerItem.id,authoritysStr,['json','put',1],(successResponse)=>{
      if (successResponse.status==200) {
        ToastBox.success(successResponse.message);
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

    render(){
      if(this.state.pageIndex==0){
        if(this.state.allAuthorityBn)this.state.titleVal=stringUtil.language(402);else this.state.titleVal=stringUtil.language(1036);
      }else if(this.state.pageIndex==1){
        if(this.state.allWorkerOwnerOperatesBn)this.state.titleVal=stringUtil.language(402);else this.state.titleVal=stringUtil.language(1036);
      }else if(this.state.pageIndex==2){
        if(this.state.allLineBn)this.state.titleVal=stringUtil.language(402);else this.state.titleVal=stringUtil.language(1036);
      }else if(this.state.pageIndex==3){
        if(this.state.allWorkerConfigInfoBn)this.state.titleVal=stringUtil.language(402);else this.state.titleVal=stringUtil.language(1036);
      }else if(this.state.pageIndex==5){
        if(this.state.allWorkerAccountBn)this.state.titleVal=stringUtil.language(402);else this.state.titleVal=stringUtil.language(1036);
      }else{this.state.titleVal='';}
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <div style={{height:'6%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
          <TabTopActivity viewHeight='100%' viewWidth='60%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
          <div style={{width:'40%',height:'100%',backgroundColor:'#ECECEC',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',paddingRight:arithUtil.pxToDp(15)}}>
            {this.state.pageIndex==0?<div style={arithUtil.stylesAdd(styles.allSelectBn,{cursor:'pointer',marginRight:10})} onClick={()=>{
                let stateVal=this.state.isModuleAll?false:true;
                this.state.authorityList[this.state.keya].isOpen=stateVal;
                for(let i=0;i<this.state.authorityList[this.state.keya].childrens.length;i++){
                  this.state.authorityList[this.state.keya].childrens[i].isOpen=stateVal;
                  for(let j=0;j<this.state.authorityList[this.state.keya].childrens[i].childrens.length;j++){
                    this.state.authorityList[this.state.keya].childrens[i].childrens[j].isOpen=stateVal;
                    if (this.state.authorityList[this.state.keya].childrens[i].childrens[j].isOpen) {
                      this.submitDataMade(this.state.authorityList[this.state.keya].childrens[i].childrens[j],0);
                      if (this.state.authorityList[this.state.keya].childrens[i].childrens[j].ancestors.length>0) {
                        this.allAuthorityIs(this.state.authorityList[this.state.keya].childrens[i].childrens[j].ancestors);
                      }
                    }else{
                      this.submitDataMade(this.state.authorityList[this.state.keya].childrens[i].childrens[j],1);
                      this.allAuthorityIs(this.state.authorityList[this.state.keya].childrens[i].childrens[j].authorityCode,1);
                    }
                  }
                }
                this.allNextAuthorityIs();
                this.setState({authorityList:this.state.authorityList});
              }}>
              <i className="iconfontIndexCss" style={{fontSize:15,color:'#52A056',marginRight:5}}>&#xe6a3;</i>
              <span style={{color:'#52A056'}}>{(()=>{
                for(let i=0;i<this.state.authorityList[this.state.keya].childrens.length;i++){
                  for(let j=0;j<this.state.authorityList[this.state.keya].childrens[i].childrens.length;j++){
                    if(!this.state.authorityList[this.state.keya].childrens[i].childrens[j].isOpen){
                      this.state.isModuleAll=0;
                      return stringUtil.language(403);
                    }
                  }
                }
                this.state.isModuleAll=1;
                return stringUtil.language(404);
              })()}</span>
            </div>:''}
            <div style={arithUtil.stylesAdd(styles.allSelectBn,{cursor:'pointer'})} onClick={()=>{
                if(this.state.pageIndex==0){
                  this.state.allAuthorityBn = (this.state.allAuthorityBn)?false:true;
                  this.allAuthorityIs();
                }else if(this.state.pageIndex==1){
                  this.state.workerOwnerOperates=[];
                  this.state.allWorkerOwnerOperatesBn = (this.state.allWorkerOwnerOperatesBn)?false:true;
                  if(this.state.allWorkerOwnerOperatesBn){
                    for(let i=0;i<this.state.owners.length;i++){
                      this.state.workerOwnerOperates.push({ownerId:this.state.owners[i].id,ownerName:this.state.owners[i].noteName,cargoQurey:0,wholesaleQurey:0});
                    }
                  }
                }else if(this.state.pageIndex==2){
                  this.state.workerLines=[];
                  this.state.allLineBn = (this.state.allLineBn)?false:true;
                  if(this.state.allLineBn){
                    for(let i=0;i<this.state.cargoSelfLineItemAll.length;i++){
                      this.state.workerLines.push({lineId:this.state.cargoSelfLineItemAll[i].id,lineName:this.state.cargoSelfLineItemAll[i].name});
                    }
                  }
                }else if(this.state.pageIndex==3){
                  this.state.allWorkerConfigInfoBn = (this.state.allWorkerConfigInfoBn)?false:true;
                  if(this.state.allWorkerConfigInfoBn){
                    this.state.workerConfigInfo={
                      speculation:1,selfSupport:1,seeWholesale:1,temCustomer:0,
                    };
                  }else{
                    this.state.workerConfigInfo={
                      speculation:0,selfSupport:0,seeWholesale:0,temCustomer:1,
                    };
                  }
                }else if(this.state.pageIndex==5){
                  this.state.allWorkerAccountBn = false;
                  if(this.state.accountAuthority&&this.state.accountAuthority.mode){//部分权限
                    this.state.accountAuthority.mode=0;
                    this.state.allWorkerAccountBn=true;
                  }else{if(!this.state.accountAuthority)this.state.accountAuthority={};
                    this.state.accountAuthority.mode=1;
                    this.state.accountAuthority.accountIds=[];
                    this.state.accountsAll=JSON.parse(JSON.stringify(this.state.accountsSource));
                  }
                  this.updateAccountDatas();
                }this.setState({});
              }}>
              <i className="iconfontIndexCss" style={{fontSize:15,color:'#52A056',marginRight:5}}>&#xe6a3;</i>
              <span style={{color:'#52A056'}}>{this.state.titleVal?this.state.titleVal:stringUtil.language(1036)}</span>
            </div>
            <div style={arithUtil.stylesAdd(styles.allSelectBn,{marginLeft:10,cursor:'pointer'})} onClick={()=>{
              if(this.state.pageIndex==0){
                let authoritysStr = [];
                for(let i=0;i<this.state.submitAuthoritys.length;i++){//选择的权限this.state.submitAuthoritys
                  authoritysStr.push(this.state.submitAuthoritys[i].authorityCode);
                }
                stringUtil.httpRequest(urlUtil.WORKER_AUTHORITY_URL+'/'+this.state.currWorkerItem.id,authoritysStr,['json','put',1],(successResponse)=>{
                  if (successResponse.status==200) {
                    ToastBox.success(successResponse.message);
                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }else if(this.state.pageIndex==1){
                let workerOwners = [];
                for(let i=0;i<this.state.workerOwnerOperates.length;i++){
                    workerOwners.push({
                      ownerId:this.state.workerOwnerOperates[i].ownerId,
                      cargoQurey:this.state.workerOwnerOperates[i].cargoQurey?1:0,
                      wholesaleQurey:this.state.workerOwnerOperates[i].wholesaleQurey?1:0
                    });
                }
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_OWNER_NEW_URL+'/'+this.state.currWorkerItem.id,workerOwners,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }else if(this.state.pageIndex==2){
                let workerLinesTemp = [];
                for(let i=0;i<this.state.workerLines.length;i++){
                    workerLinesTemp.push(this.state.workerLines[i].lineId);
                }
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_LINE_URL+'/'+this.state.currWorkerItem.id,workerLinesTemp,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                      ToastBox.success(stringUtil.language(388));
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }else if(this.state.pageIndex==3){
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_CONFIG_URL+'/'+this.state.currWorkerItem.id,{
                    speculation:this.state.workerConfigInfo.speculation,
                    selfSupport:this.state.workerConfigInfo.selfSupport,
                    seeWholesale:this.state.workerConfigInfo.seeWholesale,
                    temCustomer:this.state.workerConfigInfo.temCustomer
                },['key','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }else if(this.state.pageIndex==5){
                let result={workerId:this.state.currWorkerItem.id,mode:0,accountIds:[]};
                if(this.state.accountAuthority&&this.state.accountAuthority.mode){result.mode=1;
                    for(let i=0;i<this.state.accountsAll.length;i++){
                        if(this.state.accountsAll[i].isWorkerAccountAuthorityChenpinglong){
                            result.accountIds.push(this.state.accountsAll[i].id);
                        }
                    }
                }
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_ACCOUNT_AUTHORITY_URL,result,['json','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }
              this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
              this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
            }}>
              <i className="iconfontIndexCss" style={{fontSize:15,color:'#52A056',marginRight:5}}>&#xe614;</i>
              <span style={{color:'#52A056'}}>{stringUtil.language(405)}</span>
            </div>
          </div>
        </div>
        <div style={{width:'100%',height:'94%',backgroundColor:'#FFF',position:'relative'}}>
          <div style={{height:40,display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1}}>
            <div style={{height:'100%',display:'flex',flexDirection:'row',flexGrow:1,justifyContent:'center',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:this.state.pageIndex==0?2:0,borderBottomColor:'#52A056',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:0});}}>
              <span style={{color:this.state.pageIndex==0?'#52A056':'#A9A9A9',fontWeight:this.state.pageIndex==0?'bold':'normal'}}>{stringUtil.language(406)}</span>
            </div>
            {localStorage.getItem("currentStallItem")==null || JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:'100%',display:'flex',flexDirection:'row',flexGrow:1,justifyContent:'center',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:this.state.pageIndex==1?2:0,borderBottomColor:'#52A056',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:1});}}>
              <span style={{color:this.state.pageIndex==1?'#52A056':'#A9A9A9',fontWeight:this.state.pageIndex==1?'bold':'normal'}}>{stringUtil.language(407)}</span>
            </div>:''}
            {localStorage.getItem("currentStallItem")==null || JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:'100%',display:'flex',flexDirection:'row',flexGrow:1,justifyContent:'center',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:this.state.pageIndex==2?2:0,borderBottomColor:'#52A056',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:2});}}>
              <span style={{color:this.state.pageIndex==2?'#52A056':'#A9A9A9',fontWeight:this.state.pageIndex==2?'bold':'normal'}}>{stringUtil.language(408)}</span>
            </div>:''}
            <div style={{height:'100%',display:'flex',flexDirection:'row',flexGrow:1,justifyContent:'center',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:this.state.pageIndex==3?2:0,borderBottomColor:'#52A056',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:3});}}>
              <span style={{color:this.state.pageIndex==3?'#52A056':'#A9A9A9',fontWeight:this.state.pageIndex==3?'bold':'normal'}}>{stringUtil.language(409)}</span>
            </div>
            {localStorage.getItem("currentStallItem")==null || JSON.parse(localStorage.getItem("currentStallItem")).shopRole==0?<div style={{height:'100%',display:'flex',flexDirection:'row',flexGrow:1,justifyContent:'center',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:this.state.pageIndex==5?2:0,borderBottomColor:'#52A056',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:5});}}>
              <span style={{color:this.state.pageIndex==5?'#52A056':'#A9A9A9',fontWeight:this.state.pageIndex==5?'bold':'normal'}}>{stringUtil.language(410)}</span>
            </div>:''}
          </div>
          <div style={{width:'100%',position:'absolute',left:0,top:40,right:0,bottom:0}}>
            {this.state.pageIndex==0?<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column'}}>
              <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center',marginTop:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1}}>
                {this.state.authorityList.map((item,keya)=>{
                  return(<div style={{width:((95).div(this.state.authorityList.length))+'%',height:35,borderStyle:'solid',borderWidth:this.state.moduleItem!=null&&this.state.moduleItem.moduleCode==item.moduleCode?1:0,borderColor:'#A9A9A9',backgroundColor:this.state.moduleItem!=null&&this.state.moduleItem.moduleCode==item.moduleCode?'#DCDCDC':'#FFF',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={keya} onClick={()=>{
                    this.state.authorityList[keya].isOpen = !this.state.authorityList[keya].isOpen;
                    this.setState({keya:keya,moduleItem:item});
                  }}>
                    <span>{item.moduleName}</span>
                  </div>);
                })}
              </div>
              <div style={{width:'100%',display:'flex',flexGrow:1,flexDirection:'column',overflow:'auto'}}>
                {this.state.moduleItem?this.state.moduleItem.childrens.map((item,keyb)=>{
                  return(<div style={{display:'flex',flexDirection:'row',border:'solid 1px #DCDCDC'}} key={keyb}>
                    <div style={{width:200,height:'100%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'rgba(0,0,0,0.1)',cursor:'pointer'}} onClick={()=>{
                      this.state.authorityList[this.state.keya].childrens[keyb].isOpen = !this.state.authorityList[this.state.keya].childrens[keyb].isOpen;
                      for(let i=0;i<this.state.authorityList[this.state.keya].childrens[keyb].childrens.length;i++){
                        if(this.state.authorityList[this.state.keya].childrens[keyb].isOpen){
                          this.state.authorityList[this.state.keya].childrens[keyb].childrens[i].isOpen = true; 
                        }else{this.state.authorityList[this.state.keya].childrens[keyb].childrens[i].isOpen = false;}
                        if (this.state.authorityList[this.state.keya].childrens[keyb].childrens[i].isOpen) {
                          this.submitDataMade(this.state.authorityList[this.state.keya].childrens[keyb].childrens[i],0);
                          if (this.state.authorityList[this.state.keya].childrens[keyb].childrens[i].ancestors.length>0) {
                            this.allAuthorityIs(this.state.authorityList[this.state.keya].childrens[keyb].childrens[i].ancestors);
                          }
                        }else{
                          this.submitDataMade(this.state.authorityList[this.state.keya].childrens[keyb].childrens[i],1);
                          this.allAuthorityIs(this.state.authorityList[this.state.keya].childrens[keyb].childrens[i].authorityCode,1);
                        }
                      }
                      this.allNextAuthorityIs();
                      this.setState({authorityList:this.state.authorityList})
                    }}>{item.isOpen?<i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056',marginLeft:7,marginRight:7}}>&#xe698;</i>
                            :<i className="iconfontIndexCss" style={{fontSize:20,color:'#FFF',marginLeft:7,marginRight:7}}>&#xe6a7;</i>}
                      <span>{item.businessName}</span>
                    </div>
                    <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                      {item.childrens.map((item,keyc)=>{
                        return(<div style={{width:180,height:40,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} key={keyc} onClick={()=>{
                          if (typeof(this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].isOpen)=="undefined") {  
                            this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].isOpen = false;                                        
                          }
                          this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].isOpen = !this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].isOpen;
                          if (this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].isOpen) {
                            this.submitDataMade(this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc],0);
                            if (this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].ancestors.length>0) {
                              this.allAuthorityIs(this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].ancestors);
                            }
                          }else{
                            this.submitDataMade(this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc],1);
                            this.allAuthorityIs(this.state.authorityList[this.state.keya].childrens[keyb].childrens[keyc].authorityCode,1);
                          }
                          this.allNextAuthorityIs();
                          this.setState({authorityList:this.state.authorityList})
                        }}>{item.isOpen?<i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056',marginLeft:7,marginRight:7}}>&#xe698;</i>
                          :<i className="iconfontIndexCss" style={{fontSize:20,color:'#DCDCDC',marginLeft:7,marginRight:7}}>&#xe6a7;</i>}
                          <span style={{color:'#808080',marginLeft:2}}>{item.authorityName}</span>
                        </div>)//权限item
                      })}
                    </div>
                  </div>)//{stringUtil.language(1119)}item
                }):''}
              </div>
            </div>:''}
            {this.state.pageIndex==1?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {this.state.owners.map((item,key)=>{
                  let haveChoiceItem = null;
                  for(let i=0;i<this.state.workerOwnerOperates.length;i++){
                    if (this.state.workerOwnerOperates[i].ownerId==item.id) {
                      haveChoiceItem = this.state.workerOwnerOperates[i];break;
                    }
                  }
                  return(<div style={{width:'30%',borderStyle:'solid',borderWidth:1,borderColor:haveChoiceItem&&(!haveChoiceItem.cargoQurey||!haveChoiceItem.wholesaleQurey)?'#009A61':'#A9A9A9',paddingLeft:10,paddingRight:10,marginLeft:'1.5%',marginTop:5}} key={key}>
                    <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      if (haveChoiceItem) {
                        for(let i=0;i<this.state.workerOwnerOperates.length;i++){
                          if (this.state.workerOwnerOperates[i].ownerId==item.id) {
                            this.state.workerOwnerOperates.splice(i,1);break;
                          }
                        }
                      }else{this.state.workerOwnerOperates.push({ownerId:item.id,ownerName:item.noteName,cargoQurey:0,wholesaleQurey:0});}
                      this.setState({workerOwnerOperates:this.state.workerOwnerOperates});
                    }}><span style={{color:haveChoiceItem&&(!haveChoiceItem.cargoQurey||!haveChoiceItem.wholesaleQurey)?'#009A61':'#808080'}}>{key+1}.{item.noteName}</span>
                        <i className="iconfontIndexCss" style={{fontSize:28,color:haveChoiceItem&&(!haveChoiceItem.cargoQurey||!haveChoiceItem.wholesaleQurey)?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                    </div>
                    <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      if(haveChoiceItem) {
                        for(let i=0;i<this.state.workerOwnerOperates.length;i++){
                          if (this.state.workerOwnerOperates[i].ownerId==haveChoiceItem.ownerId) {
                            if(haveChoiceItem.cargoQurey)this.state.workerOwnerOperates[i].cargoQurey=0;
                            else{this.state.workerOwnerOperates[i].cargoQurey=1;
                              if(this.state.workerOwnerOperates[i].wholesaleQurey==1)this.state.workerOwnerOperates.splice(i,1);
                            }break;
                          }
                        }
                      }else{this.state.workerOwnerOperates.push({ownerId:item.id,ownerName:item.noteName,cargoQurey:0,wholesaleQurey:1});}
                      this.setState({workerOwnerOperates:this.state.workerOwnerOperates});
                    }}><span style={{color:haveChoiceItem&&!haveChoiceItem.cargoQurey?'#009A61':'#A9A9A9'}}>▶ {stringUtil.language(137)}</span>
                    </div>
                    <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                      if(haveChoiceItem) {
                        for(let i=0;i<this.state.workerOwnerOperates.length;i++){
                          if (this.state.workerOwnerOperates[i].ownerId==haveChoiceItem.ownerId) {
                            if(haveChoiceItem.wholesaleQurey)this.state.workerOwnerOperates[i].wholesaleQurey=0;
                            else{this.state.workerOwnerOperates[i].wholesaleQurey=1;
                              if(this.state.workerOwnerOperates[i].cargoQurey==1)this.state.workerOwnerOperates.splice(i,1);
                            }break;
                          }
                        }
                      }else{this.state.workerOwnerOperates.push({ownerId:item.id,ownerName:item.noteName,cargoQurey:1,wholesaleQurey:0});}
                      this.setState({workerOwnerOperates:this.state.workerOwnerOperates});
                    }}><span style={{color:haveChoiceItem&&!haveChoiceItem.wholesaleQurey?'#009A61':'#A9A9A9'}}>▶ {stringUtil.language(90)}</span>
                    </div>
                  </div>);
                })}
              </div>
            </div>:''}
            {this.state.pageIndex==2?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {this.state.cargoSelfLineItemAll.map((item,key)=>{
                  let isHave = 0;
                  for(let i=0;i<this.state.workerLines.length;i++){
                    if (this.state.workerLines[i].lineId==item.id) {
                      isHave = 1;break;
                    }
                  }
                  return(<div style={{width:'30%',height:40,borderStyle:'solid',borderWidth:1,borderColor:isHave==1?'#009A61':'#A9A9A9',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,marginLeft:'1.5%',marginTop:5,cursor:'pointer'}} key={key} onClick={()=>{
                    if (isHave==0) {
                      this.state.workerLines.push({lineId:item.id,lineName:item.name});
                    }else{
                      for(let i=0;i<this.state.workerLines.length;i++){
                        if (this.state.workerLines[i].lineId==item.id) {
                          this.state.workerLines.splice(i,1);break;
                        }
                      }
                    }
                    this.setState({workerLines:this.state.workerLines});
                  }}>
                      <span style={{color:isHave==1?'#009A61':'#808080'}}>{item.name}</span>
                      <i className="iconfontIndexCss" style={{fontSize:28,color:isHave==1?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                  </div>);
                })}
              </div>
            </div>:''}
            {this.state.pageIndex==3?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:5}}>
                <div style={{width:'30%',height:40,borderStyle:'solid',borderWidth:1,borderColor:this.state.workerConfigInfo.speculation==1?'#009A61':'#A9A9A9',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                  if (this.state.workerConfigInfo.speculation==0) this.state.workerConfigInfo.speculation = 1;
                  else this.state.workerConfigInfo.speculation = 0;
                  this.setState({workerConfigInfo:this.state.workerConfigInfo});
                }}>
                  <span style={{color:this.state.workerConfigInfo.speculation==1?'#009A61':'#808080'}}>{stringUtil.language(411)}</span>
                  <i className="iconfontIndexCss" style={{fontSize:28,color:this.state.workerConfigInfo.speculation==1?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                </div>
                <div style={{width:'30%',height:40,borderStyle:'solid',borderWidth:1,borderColor:this.state.workerConfigInfo.selfSupport==1?'#009A61':'#A9A9A9',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                  if (this.state.workerConfigInfo.selfSupport==0) this.state.workerConfigInfo.selfSupport = 1;
                  else this.state.workerConfigInfo.selfSupport = 0;
                  this.setState({workerConfigInfo:this.state.workerConfigInfo});
                }}>
                  <span style={{color:this.state.workerConfigInfo.selfSupport==1?'#009A61':'#808080'}}>{stringUtil.language(412)}</span>
                  <i className="iconfontIndexCss" style={{fontSize:28,color:this.state.workerConfigInfo.selfSupport==1?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                </div>
                <div style={{width:'30%',height:40,borderStyle:'solid',borderWidth:1,borderColor:this.state.workerConfigInfo.seeWholesale==1?'#009A61':'#A9A9A9',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                  if (this.state.workerConfigInfo.seeWholesale==0) this.state.workerConfigInfo.seeWholesale = 1;
                  else this.state.workerConfigInfo.seeWholesale = 0;
                  this.setState({workerConfigInfo:this.state.workerConfigInfo});
                }}>
                  <span style={{color:this.state.workerConfigInfo.seeWholesale==1?'#009A61':'#808080'}}>{stringUtil.language(413)}</span>
                  <i className="iconfontIndexCss" style={{fontSize:28,color:this.state.workerConfigInfo.seeWholesale==1?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                </div>
                <div style={{width:'30%',height:40,borderStyle:'solid',borderWidth:1,borderColor:!this.state.workerConfigInfo.temCustomer?'#009A61':'#A9A9A9',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                  if (this.state.workerConfigInfo.temCustomer) this.state.workerConfigInfo.temCustomer = 0;
                  else this.state.workerConfigInfo.temCustomer = 1;
                  this.setState({workerConfigInfo:this.state.workerConfigInfo});
                }}>
                  <span style={{color:!this.state.workerConfigInfo.temCustomer?'#009A61':'#808080'}}>{stringUtil.language(138)}</span>
                  <i className="iconfontIndexCss" style={{fontSize:28,color:!this.state.workerConfigInfo.temCustomer?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                </div>
              </div>
            </div>:''}
            {this.state.pageIndex==5?<div style={{width:'100%',height:'100%',overflow:'auto'}}>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                {this.state.accountsAll.map((item,key)=>{
                  return(<div style={{width:'30%',height:40,borderStyle:'solid',borderWidth:1,borderColor:item.isWorkerAccountAuthorityChenpinglong?'#009A61':'#A9A9A9',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'1.5%',marginTop:5,cursor:'pointer'}} key={key} onClick={()=>{
                    this.state.allWorkerAccountBn = true;
                    if(item.isWorkerAccountAuthorityChenpinglong){this.state.accountsAll[key].isWorkerAccountAuthorityChenpinglong=0;
                      this.state.accountAuthority.mode=1;
                      this.state.allWorkerAccountBn = false;
                    }else{this.state.accountsAll[key].isWorkerAccountAuthorityChenpinglong=1;
                      this.state.accountAuthority.mode=0;
                      let isHavaNo=0;
                      for(let i=0;i<this.state.accountsAll.length;i++){
                          if(!this.state.accountsAll[i].isWorkerAccountAuthorityChenpinglong){
                              isHavaNo=1;break;
                          }
                      }
                      if(isHavaNo){this.state.accountAuthority.mode=1;this.state.allWorkerAccountBn = false;}
                    }
                    this.setState({accountsAll:this.state.accountsAll});
                  }}><div style={{display:'flex',justifyContent:'center',alignItems:'center',marginLeft:10,marginRight:10}}>
                      {item.type==0?<i className="iconfontIndexCss" style={{fontSize:28,color:'#F4B015'}}>&#xe632;</i>:
                      item.type==1?<i className="iconfontIndexCss" style={{fontSize:28,color:'#3BCA72'}}>&#xe605;</i>:
                      item.type==2?<i className="iconfontIndexCss" style={{fontSize:28,color:'#5A9EF7'}}>&#xe65f;</i>:
                      item.type==3?<i className="iconfontIndexCss" style={{fontSize:28,color:'#CC4B46'}}>&#xe607;</i>:''}
                    </div>
                    <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <span style={{width:'100%',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.name}</span>
                      <span style={{color:'#A9A9A9',fontSize:9}}>{item.ascription==0?stringUtil.language(414):stringUtil.language(415)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                      <i className="iconfontIndexCss" style={{fontSize:28,color:item.isWorkerAccountAuthorityChenpinglong?'#009A61':'#DCDCDC'}}>&#xe614;</i>
                      <span style={{color:item.isWorkerAccountAuthorityChenpinglong?'#009A61':'#DCDCDC',marginLeft:5,marginRight:10}}>{item.isWorkerAccountAuthorityChenpinglong?stringUtil.language(873):stringUtil.language(874)}</span>
                    </div>
                  </div>);
                })}
              </div>
            </div>:''}
          </div>
        </div>
      </div>)
    }
}

const styles = {
  allSelectBn:{
    width:arithUtil.pxToDp(120),
    height:'85%',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    borderStyle:'solid',
    borderWidth:1,
    borderColor:'#52A056'
  },
};