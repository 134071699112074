import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(284)}
 export default class CustomerSaleSettleActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        customerSaleSettleDOAll:[],
        dateTimeStr:arithUtil.getCurrDateTime(1,true),
        dateTimeStrEnd:arithUtil.getCurrDateTime(1,true),
      };
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_CUSTOMER_SALE_SETTLE_URL,{
            startDate:this.state.dateTimeStr,
            endDate:this.state.dateTimeStrEnd,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customerSaleSettleDOAll = successResponse.data;
                this.setState({customerSaleSettleDOAll: this.state.customerSaleSettleDOAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                                this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStrEnd)} onChange={(date) => {
                                this.state.dateTimeStrEnd = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <span style={{marginLeft:15}}>{stringUtil.language(914)}{stringUtil.currency(1)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(200)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr+' '+stringUtil.language(1174)+' ' +this.state.dateTimeStrEnd}&nbsp;&nbsp;&nbsp;{stringUtil.language(1046)}:{stringUtil.currency(1)}</span>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'20%'})}>{stringUtil.language(1048)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1081)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1037)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1038)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1042)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1082)}</span>
                    </div>
                    {(()=>{
                        this.state.totalInfo = {
                            beforeArrearsTotal:0,wholesaleMoneyTotal:0,collectMoneyTotal:0,discountMoneyTotal:0,balanceArrearsTotal:0
                        }
                    })()}
                    {this.state.customerSaleSettleDOAll.map((item,key)=>{
                        let balanceArrears = (item.beforeArrears).add(item.wholesaleMoney).sub(item.collectMoney).sub(item.discountMoney);
                        this.state.totalInfo.beforeArrearsTotal = (this.state.totalInfo.beforeArrearsTotal).add(item.beforeArrears);
                        this.state.totalInfo.wholesaleMoneyTotal = (this.state.totalInfo.wholesaleMoneyTotal).add(item.wholesaleMoney);
                        this.state.totalInfo.collectMoneyTotal = (this.state.totalInfo.collectMoneyTotal).add(item.collectMoney);
                        this.state.totalInfo.discountMoneyTotal = (this.state.totalInfo.discountMoneyTotal).add(item.discountMoney);
                        this.state.totalInfo.balanceArrearsTotal = (this.state.totalInfo.balanceArrearsTotal).add(balanceArrears);
                        return(<div style={{display:'flex',flexDirection:'row'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%'})}>{stringUtil.subStr(item.customerName,8)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.beforeArrears)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.wholesaleMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(balanceArrears)}</span>
                        </div>)
                    })}
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{stringUtil.language(890)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.beforeArrearsTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.wholesaleMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.collectMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.discountMoneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.balanceArrearsTotal)}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTitle:{
        width:'16%',
        height:arithUtil.pxToDp(35),
        fontSize:12,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    tableTd:{
        width:'16%',
        height:arithUtil.pxToDp(35),
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};