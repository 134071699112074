import React,{Component} from 'react';
import {PropTypes} from 'prop-types';
import * as arithUtil from '../utils/arithUtil.js';

export default class TabTopActivity extends Component {
    constructor(props){
        super(props);
        this.state={};
    }

    render(){
        return(<div style={{width:this.props.viewWidth,height:this.props.viewHeight,backgroundColor:'#ECECEC',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                {this.props.isBackPrevPage==1?<i className="iconfontIndexCss" style={{fontSize:20,color:'#808080',cursor:'pointer'}} onClick={()=>{
                    console.log(this)
                    this.props.context.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                    this.props.context.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks,paramsPage:'参数值'}});
                }}>&#xe72e;&nbsp;&nbsp;</i>:<i className="iconfontIndexCss" style={{fontSize:20,color:'#808080'}}>&#xe602;&nbsp;&nbsp;</i>}
                {this.props.navigationStacks.map((item,key)=>{
                    let mainStacksTemp = [];
                    for(let i=0;i<key+1;i++){
                        mainStacksTemp.push(this.props.navigationStacks[i]);
                    }
                    return(<span key={key}>{item.title}{key!=this.props.navigationStacks.length-1?'  |  ':''}</span>);
                })}
            </div>
            <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                {this.props.onPressOneStr!=null?<div style={{height:'100%',paddingLeft:5,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.props.onPressOne}>
                    <span style={{color:'#52A056'}}>{this.props.onPressOneStr}</span>
                </div>:''}
                {this.props.onPressTwoStr!=null?<div style={{height:'100%',paddingLeft:5,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.props.onPressTwo}>
                    <span style={{color:'#52A056'}}>{this.props.onPressTwoStr}</span>
                </div>:''}
                {this.props.onPressThreeStr!=null?<div style={{height:'100%',paddingLeft:5,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.props.onPressThree}>
                    <span style={{color:'#52A056'}}>{this.props.onPressThreeStr}</span>
                </div>:''}
                {this.props.onPressFourStr!=null?<div style={{height:'100%',paddingLeft:5,paddingRight:10,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={this.props.onPressFour}>
                    <span style={{color:'#52A056'}}>{this.props.onPressFourStr}</span>
                </div>:''}
            </div>
        </div>);
    }
}

TabTopActivity.propsTypes={
    navigationStacks:PropTypes.array,//选项
    viewWidth:PropTypes.any,//宽度
    viewHeight:PropTypes.any,//高度
    onPressOneStr:PropTypes.string,
    onPressOne: PropTypes.func, // stringUtil.language(1198)击事stringUtil.language(1173)
    onPressTwoStr:PropTypes.string,
    onPressTwo: PropTypes.func, // stringUtil.language(1198)击事stringUtil.language(1173)
    onPressThreeStr:PropTypes.string,
    onPressThree: PropTypes.func, // stringUtil.language(1198)击事stringUtil.language(1173)
    onPressFourStr:PropTypes.string,
    onPressFour: PropTypes.func, // stringUtil.language(1198)击事stringUtil.language(1173)
};
TabTopActivity.defaultProps = {
    navigationStacks:[],
    viewWidth:'100%',
    viewHeight:45,
};

const styles = {};