import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

//{stringUtil.language(248)}
  export default class OwnerExpendAddView extends Component{
    constructor(props) {
      super(props);
      this.state={
        ownerExpendInsertItem:this.props.paramsInfo?{
            time:arithUtil.getCurrDateTime(0),
            clauseId:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).clauseId,
            clauseName:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).clauseName,
            cargoId:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).cargoId,
            targetType:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).targetType,
            targetId:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).targetId,
            targetName:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).targetName,
            cargoNo:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).cargoNo,
            money:(JSON.parse(this.props.paramsInfo.ownerExpendInfo)).money,
            describe:''
        }:{time:arithUtil.getCurrDateTime(0),describe:''},
        wholesaleCargosData:[],
        ownerExpendsAll:[],
        accountsAll:[],
        companysAll:[],
        inputTextTagVal:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OWNER_EXPEND_CLAUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({ownerExpendsAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:-1,
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownerCargosResultObj= successResponse.data;
                this.state.wholesaleCargosData = ownerCargosResultObj.items;
                this.setState({wholesaleCargosData: this.state.wholesaleCargosData});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({accountsAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_COMPANY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.companysAll=successResponse.data;
                this.state.companysAll.unshift({id:0,noteName:stringUtil.language(310)});
                this.setState({companysAll:this.state.companysAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.props.successEvent(this.state.ownerExpendInsertItem);
    }

    render(){
        return(<div style={{width:'98.5%',height:'100%'}}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span style={{width:100}}>{stringUtil.language(311)}</span><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.ownerExpendInsertItem.time)} onChange={(date) => {
                    this.state.ownerExpendInsertItem.time = arithUtil.formatToTimeStr(date,'Y-M-D h:m:s');
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                    this.setState({ownerExpendInsertItem:this.state.ownerExpendInsertItem});
                }} />
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(312)}</span><select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                    this.state.ownerExpendInsertItem.cargoId = JSON.parse(event.target.value).id;
                    this.state.ownerExpendInsertItem.targetType = JSON.parse(event.target.value).cargoMode;
                    this.state.ownerExpendInsertItem.targetId = JSON.parse(event.target.value).targetId;
                    this.state.ownerExpendInsertItem.targetName = JSON.parse(event.target.value).targetName;
                    this.state.ownerExpendInsertItem.cargoNo = JSON.parse(event.target.value).cargoNo;
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                    }}><option style={{display:'none'}}></option>
                    {this.state.wholesaleCargosData.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} selected={item.id==this.state.ownerExpendInsertItem.cargoId?true:false} key={key}>{item.cargoMode==1?stringUtil.language(426)+'_'+item.targetId:item.targetName} / {item.cargoNo}</option>)
                    })}
                </select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(313)}</span><select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                    this.state.ownerExpendInsertItem.clauseId = JSON.parse(event.target.value).id;
                    this.state.ownerExpendInsertItem.clauseName = JSON.parse(event.target.value).name;
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                    }}><option style={{display:'none'}}></option>
                    {this.state.ownerExpendsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} selected={item.id==this.state.ownerExpendInsertItem.clauseId?true:false} key={key}>{item.name}</option>)
                    })}
                </select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(314)}</span>
                <Select showSearch defaultValue={stringUtil.language(310)} style={{width:150,height:30,fontSize:10}} onChange={(event)=>{
                    this.state.ownerExpendInsertItem.companyId = JSON.parse(event).id;
                    this.state.ownerExpendInsertItem.companyName = JSON.parse(event).noteName;
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                }}>
                    {this.state.companysAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                    })}
                </Select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(315)}</span><input style={arithUtil.stylesAdd(styles.textInput,{width:200})} type='number' placeholder='0' onChange={(event)=>{
                    this.state.ownerExpendInsertItem.money = arithUtil.yuanToFen(event.target.value);
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                }}/><span> {stringUtil.currency(1)}</span>
            </div>
            <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(316)}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.ownerExpendInsertItem.payState = 0;
                        this.props.successEvent(this.state.ownerExpendInsertItem);
                        this.setState({ownerExpendInsertItem:this.state.ownerExpendInsertItem});
                    }}>{!this.state.ownerExpendInsertItem.payState?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(531)}</i>:
                    <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(531)}</i>}</div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,cursor:'pointer'}} onClick={()=>{
                        this.state.ownerExpendInsertItem.payState = 1;
                        this.props.successEvent(this.state.ownerExpendInsertItem);
                        this.setState({ownerExpendInsertItem:this.state.ownerExpendInsertItem});
                    }}>{this.state.ownerExpendInsertItem.payState?<i className="iconfontIndexCss" style={{fontSize:15,color:'#FF8C00'}}>&#xe71f; {stringUtil.language(532)}</i>:
                    <i className="iconfontIndexCss" style={{fontSize:15,color:'#C0C0C0'}}>&#xe641; {stringUtil.language(532)}</i>}</div>
                </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(317)}</span><select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                    this.state.ownerExpendInsertItem.accountId = JSON.parse(event.target.value).id;
                    this.state.ownerExpendInsertItem.accountName = JSON.parse(event.target.value).name;
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                    }}><option style={{display:'none'}}></option>
                    {this.state.accountsAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                    })}
                </select>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                <span>{stringUtil.language(318)}</span><input style={arithUtil.stylesAdd(styles.inputText,{width:'70%',height:arithUtil.pxToDp(70),textAlign:'left',textAlignVertical:'top'})} placeholder={stringUtil.language(1010)+stringUtil.language(1033)+'..（非必填）'} onChange={(event)=>{
                    this.state.ownerExpendInsertItem.describe = event.target.value;
                    this.props.successEvent(this.state.ownerExpendInsertItem);
                }}/>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
};