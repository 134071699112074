import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import CustomerAddActivityView from './CustomerAddActivityView';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';
import weixinJsSdk from 'weixin-js-sdk';

 export default class OrdersAddActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,
        // owners:JSON.parse(paramsPage.owners),
        choiceCommoditys:JSON.parse(paramsPage.choiceCommoditys),
        buyerItem:JSON.parse(paramsPage.buyerItemInfo),
        currXY:{x:-1,y:-1},
        customerAll:[],
        customerItemAll:[],
        buyersAll:[],
        datas:{time:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),pushTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime()),commodities:[],customers:[]},
        addShowPopIndex:0,editTotalShowPopIndex:0,editShowPopIndex:0,
        wholesaleCargosData:[],
        inputTextTagVal:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        this.initCustomers();
        this.state.datas.buyerId=this.state.buyerItem.id;
        this.state.datas.buyerCode=this.state.buyerItem.code;
        this.state.datas.buyerName=this.state.buyerItem.name;
        this.state.datas.commodities=[];
        for(let i=0;i<this.state.choiceCommoditys.length;i++){
            this.state.choiceCommoditys[i].unitPriceChenpinglong=0;
            this.state.choiceCommoditys[i].mode=0;
            this.state.datas.commodities.push(this.state.choiceCommoditys[i]);
        }
        // stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
        //     if (successResponse.status==200){
        //         let commodities=successResponse.data;
        //         this.state.datas.commodities=[];
        //         for(let i=0;i<commodities.length;i++){
        //             for(let j=0;j<this.state.owners.length;j++){
        //                 if(commodities[i].targetType==this.state.owners[j].targetType&&commodities[i].targetId==this.state.owners[j].targetId){
        //                     commodities[i].unitPriceChenpinglong=0;
        //                     commodities[i].mode=0;
        //                     this.state.datas.commodities.push(commodities[i]);
        //                     break;
        //                 }
        //             }
        //         }
        //         this.setState({datas:this.state.datas});
        //     }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        // },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_BUYER_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({buyersAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.CARGO_SEARCH_URL,{
            state:1,//-1stringUtil.language(1097) 0 待接 1 售卖 2 stringUtil.language(1053)
            cargoMode:-1,
            currPage:0,
            pageSize:1000
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownerCargosResultObj= successResponse.data;
                this.state.wholesaleCargosData = ownerCargosResultObj.items;
                this.setState({wholesaleCargosData: this.state.wholesaleCargosData});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        document.onkeydown=(e)=>{
            console.log('keyCode：',e,this.state.regIndex)
            if(e.keyCode===38){//上
                if(this.updateCurrXY(0)){
                    this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
                    if(!this.state.currEditCommodity.unitPrice){
                        this.state.currEditCommodity.mode=this.state.datas.commodities[this.state.currXY.x].mode;
                        this.state.currEditCommodity.unitPrice=this.state.datas.commodities[this.state.currXY.x].unitPriceChenpinglong;
                    }
                    this.setState({currEditCommodity:this.state.currEditCommodity});
                }
            }else if(e.keyCode===40){//下
                if(this.updateCurrXY(1)){
                    this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
                    if(!this.state.currEditCommodity.unitPrice){
                        this.state.currEditCommodity.mode=this.state.datas.commodities[this.state.currXY.x].mode;
                        this.state.currEditCommodity.unitPrice=this.state.datas.commodities[this.state.currXY.x].unitPriceChenpinglong;
                    }
                    this.setState({currEditCommodity:this.state.currEditCommodity});
                }
            }else if(e.keyCode===37){//左
                if(this.updateCurrXY(2)){
                    this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
                    if(!this.state.currEditCommodity.unitPrice){
                        this.state.currEditCommodity.mode=this.state.datas.commodities[this.state.currXY.x].mode;
                        this.state.currEditCommodity.unitPrice=this.state.datas.commodities[this.state.currXY.x].unitPriceChenpinglong;
                    }
                    this.setState({currEditCommodity:this.state.currEditCommodity});
                }
            }else if(e.keyCode===39){//右
                if(this.updateCurrXY(3)){
                    this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
                    if(!this.state.currEditCommodity.unitPrice){
                        this.state.currEditCommodity.mode=this.state.datas.commodities[this.state.currXY.x].mode;
                        this.state.currEditCommodity.unitPrice=this.state.datas.commodities[this.state.currXY.x].unitPriceChenpinglong;
                    }
                    this.setState({currEditCommodity:this.state.currEditCommodity});
                }
            }else if(e.keyCode===48||e.keyCode===96){//0
                this.editNums(0);
            }else if(e.keyCode===49||e.keyCode===97){//1
                this.editNums(1);
            }else if(e.keyCode===50||e.keyCode===98){//2
                this.editNums(2);
            }else if(e.keyCode===51||e.keyCode===99){//3
                this.editNums(3);
            }else if(e.keyCode===52||e.keyCode===100){//4
                this.editNums(4);
            }else if(e.keyCode===53||e.keyCode===101){//5
                this.editNums(5);
            }else if(e.keyCode===54||e.keyCode===102){//6
                this.editNums(6);
            }else if(e.keyCode===55||e.keyCode===103){//7
                this.editNums(7);
            }else if(e.keyCode===56||e.keyCode===104){//8
                this.editNums(8);
            }else if(e.keyCode===57||e.keyCode===105){//9
                this.editNums(9);
            }else if(e.keyCode===8){//清除
                if(!this.checkXY(this.state.currXY.x,this.state.currXY.y)){return;}
                if(this.state.showChoiceCusPop||this.state.submitShowPop||this.state.editTotalShowPop||this.state.editShowPop||this.state.customerAddShowPop){return;}
                this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
                if(this.state.currEditCommodity.number>=10){
                    this.state.currEditCommodity.number =  (this.state.currEditCommodity.number-this.state.currEditCommodity.number%10)/10;
                }else{
                    this.state.currEditCommodity.number = 0;
                }
                if(this.state.currEditCommodity.standard){
                    this.state.currEditCommodity.weight = (this.state.currEditCommodity.number).mul(this.state.currEditCommodity.standard);
                }
                this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]=this.state.currEditCommodity;
                this.setState({currEditCommodity:this.state.currEditCommodity});
            }
        }
    }
    editNums(centent){
        if(!this.checkXY(this.state.currXY.x,this.state.currXY.y)){return;}
        if(this.state.showChoiceCusPop||this.state.submitShowPop||this.state.editTotalShowPop||this.state.editShowPop||this.state.customerAddShowPop){return;}
        this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
        this.state.currEditCommodity.number = parseInt(this.state.currEditCommodity.number+''+centent);
        if(this.state.currEditCommodity.standard){
            this.state.currEditCommodity.weight = (this.state.currEditCommodity.number).mul(this.state.currEditCommodity.standard);
        }
        this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]=this.state.currEditCommodity;
        this.setState({currEditCommodity:this.state.currEditCommodity});
    }
    updateCurrXY(mode){
        if((this.state.currXY.x < 0 ||  this.state.currXY.y <0) &&  this.checkXY(0,0) ){
            this.state.currXY.x = 0;
            this.state.currXY.y = 0;
            return true;
        }
        if(mode == 0){
            if(this.checkXY(this.state.currXY.x,this.state.currXY.y - 1)){
                this.state.currXY.y  = this.state.currXY.y - 1;
                return true;
            }
        }else if(mode == 1){
            if(this.checkXY(this.state.currXY.x,this.state.currXY.y + 1)){
                this.state.currXY.y  = this.state.currXY.y + 1;
                return true;
            }
        }else if(mode == 2){
            if(this.checkXY(this.state.currXY.x-1,this.state.currXY.y)){
                this.state.currXY.x  = this.state.currXY.x - 1;
                return true;
            }
        }else if(mode == 3){
            if(this.checkXY(this.state.currXY.x+1,this.state.currXY.y)){
                this.state.currXY.x  = this.state.currXY.x + 1;
                return true;
            }
        }
        return false;
    }
    checkXY(x,y){
        if(!(y < this.state.datas.customers.length && y >= 0)){
            return false;
        }
        if(!(this.state.datas.customers[y].items && x < this.state.datas.customers[y].items.length && x>=0)){
            return false;
        }
        return true;
    }

    initCustomers(){
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
            if(successResponse.status==200){
                this.state.customerAll=successResponse.data;
                stringUtil.httpRequest(urlUtil.BASIC_BUYER_CUSTOMER_URL+'/'+this.state.buyerItem.id,null,['key','get',1],(successResponse)=>{
                    if(successResponse.status==200){
                        let buyerCustomers=successResponse.data;
                        for(let j=0;j<buyerCustomers.length;j++){
                            let orderItem={
                                customerId:buyerCustomers[j].customerId,
                                customerName:buyerCustomers[j].customerName,
                                items:[]
                            };
                            for(let i=0;i<this.state.datas.commodities.length;i++){
                                orderItem.items.push({
                                    commodityId:this.state.datas.commodities[i].id,
                                    commodityName:this.state.datas.commodities[i].name,
                                    code:this.state.datas.commodities[i].code,
                                    standard:this.state.datas.commodities[i].standard,
                                    unitPrice:0,//this.state.datas.commodities[i].unitPriceChenpinglong,
                                    mode:1,//this.state.datas.commodities[i].mode,   20240809
                                    number:0,
                                    weight:0,
                                })
                            }
                            this.state.datas.customers.push(orderItem);
                        }
                        this.delChoiceCustomer();
                        this.setState({datas:this.state.datas});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    delChoiceCustomer(){
        this.state.customerItemAll=[];
        for(let i=0;i<this.state.customerAll.length;i++){let source=this.state.customerAll[i];let isChoice=false;
            for(let j=0;j<this.state.datas.customers.length;j++){let choiced=this.state.datas.customers[j];
                if(source.id==choiced.customerId){isChoice=true;break;}
            }
            if(!isChoice)this.state.customerItemAll.push(source);
        }
        this.searchCustomers();
    }
    searchCustomers(){
        this.state.customerItemDatas = [];
        if (this.state.keySearchCus!=null&&this.state.keySearchCus!='') {
            for(let i=0;i<this.state.customerItemAll.length;i++){
                if ((this.state.customerItemAll[i].noteName).indexOf(this.state.keySearchCus)!=-1) {
                    this.state.customerItemDatas.push(this.state.customerItemAll[i]);
                }
            }
        }else{this.state.customerItemDatas = this.state.customerItemAll;}
        this.setState({customerItemDatas:this.state.customerItemDatas});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <div style={{width:'100%',height:'6%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#ECECEC'}}>
                <TabTopActivity viewHeight='100%' viewWidth='50%' context={this} navigationStacks={this.props.navigationStacks} isBackPrevPage={1}/>
                <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                    <DatePicker locale='zhCN' selected={new Date(this.state.datas.time)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                        this.state.datas.time = date.valueOf();
                        this.setState({datas:this.state.datas});
                    }} />
                    <div style={{marginLeft:40,cursor:'pointer'}} onClick={()=>{
                        let result={commodities:[],customers:[],state:0,
                            buyerId:this.state.datas.buyerId,
                            buyerCode:this.state.datas.buyerCode,
                            cargoId:this.state.datas.cargoId?this.state.datas.cargoId:''
                        };
                        if(this.state.datas.time)result.time = this.state.datas.time;
                        if(this.state.datas.pushTime)result.pushTime = this.state.datas.pushTime;
                        for(let i=0;i<this.state.datas.commodities.length;i++){
                            result.commodities.push({
                                commodityId:this.state.datas.commodities[i].id,
                                unitPrice:this.state.datas.commodities[i].unitPriceChenpinglong,
                                mode:this.state.datas.commodities[i].mode,
                            })
                        }
                        for(let i=0;i<this.state.datas.customers.length;i++){
                            let cusItem={items:[],
                                customerId:this.state.datas.customers[i].customerId,
                            };
                            for(let j=0;j<this.state.datas.customers[i].items.length;j++){
                                cusItem.items.push({
                                    commodityId:this.state.datas.customers[i].items[j].commodityId,
                                    unitPrice:this.state.datas.customers[i].items[j].unitPrice,
                                    mode:this.state.datas.customers[i].items[j].mode,
                                    number:this.state.datas.customers[i].items[j].number,
                                    weight:this.state.datas.customers[i].items[j].weight,
                                })
                            }
                            result.customers.push(cusItem)
                        }
                        stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_URL,result,['json','post',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success(stringUtil.language(388));
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}><span style={{color:'#009A61',fontSize:16,whiteSpace:'nowrap'}}>保存</span></div>
                </div>
            </div>
            <div style={{height:'94%',display:'flex',flexDirection:'column',backgroundColor:'#FFF',position:'relative'}}>
                <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:120,height:40,backgroundColor:'#1B912A',borderRadius:3,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.delChoiceCustomer();
                        this.setState({showChoiceCusPop:true});//customerItemDatas:this.state.customerItemAll,
                    }}><span style={{color:'#FFF',fontSize:18,fontWeight:'bold'}}>添加客户</span></div>
                    <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                        <span>业务员:{this.state.datas.buyerName}{this.state.datas.buyerCode?'('+stringUtil.language(1156)+':'+this.state.datas.buyerCode+')':''}</span>
                        {/* <Select showSearch style={{width:200}} onChange={(event)=>{
                            this.state.datas.buyerId=JSON.parse(event).id;
                            this.state.datas.buyerCode=JSON.parse(event).code;
                            this.state.datas.buyerName=JSON.parse(event).name;
                            this.setState({datas:this.state.datas});
                        }}><option style={{display:'none'}}></option>
                            {this.state.buyersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name} ({stringUtil.language(1156)}:{item.code})</option>)
                            })}
                        </Select> */}
                    </div>
                    <select style={{width:250,height:32,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12,marginRight:20}} onChange={(event)=>{
                        this.state.datas.cargoId = JSON.parse(event.target.value).id;
                        // this.state.searchs.targetType = JSON.parse(event.target.value).cargoMode;
                        // this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                        // this.state.searchs.targetName = JSON.parse(event.target.value).targetName;
                        // this.state.searchs.cargoNo = JSON.parse(event.target.value).cargoNo;
                    }}><option value={JSON.stringify({id:'',cargoNo:null})}>{stringUtil.language(571)}</option>
                        {this.state.wholesaleCargosData.map((item,key)=>{
                            return(<option selected={this.state.datas.cargoId==item.id?'selected':''} value={JSON.stringify(item)} key={key}>{item.cargoNo?(item.targetName?item.targetName:item.cargoMode==1?stringUtil.language(1051):'')+' 第'+item.cargoNo+'批':stringUtil.language(571)}</option>)
                        })}
                    </select>
                    <div style={{width:150,height:40,backgroundColor:'#1B912A',borderRadius:3,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        let result={commodities:[],customers:[],state:1,
                            buyerId:this.state.datas.buyerId,
                            buyerCode:this.state.datas.buyerCode,
                            cargoId:this.state.datas.cargoId?this.state.datas.cargoId:''
                        };
                        if(this.state.datas.time)result.time = this.state.datas.time;
                        if(this.state.datas.pushTime)result.pushTime = this.state.datas.pushTime;
                        for(let i=0;i<this.state.datas.commodities.length;i++){
                            result.commodities.push({
                                commodityId:this.state.datas.commodities[i].id,
                                unitPrice:this.state.datas.commodities[i].unitPriceChenpinglong,
                                mode:this.state.datas.commodities[i].mode,
                            })
                        }
                        for(let i=0;i<this.state.datas.customers.length;i++){
                            let cusItem={items:[],
                                customerId:this.state.datas.customers[i].customerId,
                            };
                            for(let j=0;j<this.state.datas.customers[i].items.length;j++){
                                if(this.state.datas.customers[i].items[j].number||this.state.datas.customers[i].items[j].weight){
                                    if(!this.state.datas.customers[i].items[j].unitPrice){
                                        ToastBox.error('('+this.state.datas.customers[i].customerName+')的('+(this.state.datas.customers[i].items[j].code?this.state.datas.customers[i].items[j].code:this.state.datas.customers[i].items[j].commodityName)+')未报价');return;
                                    }
                                    if (!this.state.datas.customers[i].items[j].mode&&!this.state.datas.customers[i].items[j].weight) {
                                        ToastBox.error('('+this.state.datas.customers[i].customerName+')的('+(this.state.datas.customers[i].items[j].code?this.state.datas.customers[i].items[j].code:this.state.datas.customers[i].items[j].commodityName)+')'+stringUtil.language(1010)+stringUtil.language(1022));return;
                                    }
                                    if (this.state.datas.customers[i].items[j].mode&&!this.state.datas.customers[i].items[j].number) {
                                        ToastBox.error('('+this.state.datas.customers[i].customerName+')的('+(this.state.datas.customers[i].items[j].code?this.state.datas.customers[i].items[j].code:this.state.datas.customers[i].items[j].commodityName)+')'+stringUtil.language(1010)+stringUtil.language(1021));return;
                                    }
                                    cusItem.items.push({
                                        commodityId:this.state.datas.customers[i].items[j].commodityId,
                                        unitPrice:this.state.datas.customers[i].items[j].unitPrice,
                                        mode:this.state.datas.customers[i].items[j].mode,
                                        number:this.state.datas.customers[i].items[j].number,
                                        weight:this.state.datas.customers[i].items[j].weight,
                                    })
                                }
                            }
                            if(cusItem.items.length)result.customers.push(cusItem);
                        }
                        if(!result.customers.length){ToastBox.error('没有客户不能生成销售单');return;}
                        this.state.resultDataInfo=result;
                        this.setState({submitShowPop:true});
                    }}><span style={{color:'#FFF',fontSize:18,fontWeight:'bold'}}>确订下推出货单</span></div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}} id='printArea'><div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#009A61'}}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div style={{width:120,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#FFF'}}>品名代码</span>
                            </div>
                            <div style={{width:120,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#FFF'}}>库存结余</span>
                            </div>
                            <div style={{width:120,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#FFF'}}>客户报价</span>
                            </div>
                        </div>
                        {(()=>{this.state.totalCommodities={stockNumber:0,stockWeight:0};
                            this.state.totalInfo=[];
                            this.state.totalInfoTotal={number:0,balanceNumber:0};
                        })()}
                        {this.state.datas.commodities.map((item,key)=>{
                            this.state.totalInfo.push({
                                number:0,weight:0
                            })
                            this.state.totalCommodities.stockNumber=(this.state.totalCommodities.stockNumber).add(item.stockNumber);
                            this.state.totalCommodities.stockWeight=(this.state.totalCommodities.stockWeight).add(item.stockWeight);
                            return(<div style={{display:'flex',flexDirection:'column'}} key={key}>
                                <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{width:'100%',color:'#FFF',textAlign:'center',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.code?item.code:item.name}</span>
                                </div>
                                <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#FFF'}}>{item.stockNumber?item.stockNumber:''}</span>
                                </div>
                                <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currXY.x=key;
                                    this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.commodities[this.state.currXY.x]));
                                    this.setState({editTotalShowPopIndex:this.state.editTotalShowPopIndex+1,editTotalShowPop:true});
                                }}><span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>{item.unitPriceChenpinglong?(item.mode?arithUtil.fenToYuan(item.unitPriceChenpinglong,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPriceChenpinglong,2),0,this.state.weightMode)):''}</span>
                                </div>
                            </div>);
                        })}
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#FFF'}}>小计</span>
                            </div>
                            <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#FFF'}}>{this.state.totalCommodities.stockNumber}</span>
                            </div>
                            <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#F00',fontSize:18,fontWeight:'bold'}}>-</span>
                            </div>
                        </div>
                    </div>
                    <div style={{height:0,flexGrow:1,overflowY:'auto'}}>
                        {this.state.datas.customers.map((item,key)=>{
                            let totalInfoCus={number:0,weight:0};
                            return(<div style={{display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <div style={{width:120,height:35,backgroundColor:this.state.currXY.y==key?'#FFDEAD':'#DCDCDC',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <div style={{width:30,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                        this.state.datas.customers.splice(key, 1);
                                        this.delChoiceCustomer();
                                        this.state.currXY={x:-1,y:-1};
                                        this.setState({datas:this.state.datas});
                                    }}><span style={{fontFamily:'iconfont',fontSize:15,color:'#F00'}}>&#xe612;</span>
                                    </div>
                                    <div style={{width:0,flexGrow:1,height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                        this.state.currXY.y=key;
                                        this.setState({currXY:this.state.currXY});
                                    }}><span style={{width:'100%',color:'#000',fontWeight:'bold',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.customerName}</span>
                                    </div>
                                </div>
                                {item.items.map((itemb,keyb)=>{
                                    this.state.totalInfo[keyb].number=(this.state.totalInfo[keyb].number).add(itemb.number);
                                    this.state.totalInfo[keyb].weight=(this.state.totalInfo[keyb].weight).add(itemb.weight);
                                    totalInfoCus.number=(totalInfoCus.number).add(itemb.number);
                                    totalInfoCus.weight=(totalInfoCus.weight).add(itemb.weight);
                                    let isChangeUnitPrice=false;
                                    if(this.state.datas.commodities[keyb].unitPriceChenpinglong!=itemb.unitPrice)isChangeUnitPrice=true;
                                    return(<div style={{width:85,height:35,backgroundColor:this.state.currXY.y==key&&this.state.currXY.x==keyb?'#FFDEAD':'transparent',borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center',position:'relative',cursor:'pointer'}} key={keyb} onClick={()=>{
                                        this.state.currXY.x=keyb;
                                        this.state.currXY.y=key;
                                        this.state.currEditCommodity=JSON.parse(JSON.stringify(this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]));
                                        if(!this.state.currEditCommodity.unitPrice){
                                            this.state.currEditCommodity.mode=this.state.datas.commodities[this.state.currXY.x].mode;
                                            this.state.currEditCommodity.unitPrice=this.state.datas.commodities[this.state.currXY.x].unitPriceChenpinglong;
                                        }
                                        this.setState({editShowPopIndex:this.state.editShowPopIndex+1,editShowPop:true});
                                    }}><span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{itemb.number?itemb.number:itemb.weight?arithUtil.keToWeight(itemb.weight,this.state.weightMode):''}</span>
                                        {isChangeUnitPrice?<span style={{color:'#F00',position:'absolute',top:0,right:0}}>{itemb.unitPrice?(itemb.mode?arithUtil.fenToYuan(itemb.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)):0}</span>:''}
                                    </div>);
                                })}
                                <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#000',fontSize:15,fontWeight:'bold'}}>{totalInfoCus.number}</span>
                                </div>
                            </div>)
                        })}
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#D3D3D3'}}>
                        <div style={{width:120,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                            <span style={{color:'#000',fontWeight:'bold'}}>订货合计</span>
                        </div>
                        {this.state.datas.commodities.map((item,key)=>{
                            this.state.totalInfoTotal.number=(this.state.totalInfoTotal.number).add(this.state.totalInfo[key].number);
                            return(<div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}} key={key}>
                                <span style={{color:'#000',fontWeight:'bold'}}>{this.state.totalInfo[key].number?this.state.totalInfo[key].number:''}</span>
                            </div>);
                        })}
                        <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end'}}>
                            <span style={{color:'#000',fontWeight:'bold'}}>{this.state.totalInfoTotal.number}</span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#D3D3D3'}}>
                        <div style={{width:120,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <span style={{color:'#000',fontWeight:'bold'}}>剩余可售</span>
                        </div>
                        {this.state.datas.commodities.map((item,key)=>{
                            this.state.totalInfoTotal.balanceNumber=(this.state.totalInfoTotal.balanceNumber).add((item.stockNumber).sub(this.state.totalInfo[key].number,0));
                            return(<div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}} key={key}>
                                <span style={{color:'#000',fontWeight:'bold'}}>{(item.stockNumber).sub(this.state.totalInfo[key].number,0)?(item.stockNumber).sub(this.state.totalInfo[key].number,0):''}</span>
                            </div>);
                        })}
                        <div style={{width:85,height:35,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:0.5,display:'flex',flexDirection:'row',justifyContent:'center'}}>
                            <span style={{color:'#000',fontWeight:'bold'}}>{this.state.totalInfoTotal.balanceNumber}</span>
                        </div>
                    </div>
                </div></div>
                {this.state.showChoiceCusPop?<div style={{width:'33%',height:arithUtil.ProportionTo(document.body.clientHeight,0.6),borderStyle:'solid',borderColor:'#696969',borderWidth:1,backgroundColor:'#FFF',position:'absolute',left:0,top:'8%',paddingLeft:5,paddingRight:5}}>
                    <div style={{height:'7%',display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                        <div style={{width:0,flexGrow:1,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <input style={{width:'50%',height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.keySearchCus = event.target.value;
                                if(this.state.keySearchCus==null||this.state.keySearchCus=='')this.setState({customerItemDatas:this.state.customerItemAll});
                                else this.searchCustomers();
                            }} placeholder={stringUtil.language(32)}/>
                        </div>
                        <div style={{width:40,height:25,color:'#FFF',borderRadius:3,backgroundColor:'#52A056',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currItem = null;
                            this.state.currDistrictA = null;
                            this.state.currDistrictB = null;
                            this.state.currDistrictC = null;
                            this.setState({addShowPopIndex:this.state.addShowPopIndex+1,customerAddShowPop:true});
                        }}>{stringUtil.language(1129)}</div>
                        <div style={{width:40,height:25,borderStyle:'solid',borderColor:'#808080',borderWidth:1,borderRadius:3,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:20,cursor:'pointer'}} onClick={()=>{
                            this.setState({showChoiceCusPop:false});
                        }}><span style={{fontFamily:'iconfont',fontSize:15,color:'#696969'}}>&#xe631;</span></div>
                    </div>
                    <div style={{height:'91%',overflow:'auto'}}>
                        {(this.state.customerItemDatas?this.state.customerItemDatas:this.state.customerItemAll).map((item,key)=>{
                            return(<div style={{height:60,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                                if (item.warning!=2) {
                                    let currCustomerItem = JSON.parse(JSON.stringify(item));
                                    let orderItem={
                                        customerId:currCustomerItem.id,
                                        customerName:currCustomerItem.noteName,
                                        items:[]
                                    };
                                    for(let i=0;i<this.state.datas.commodities.length;i++){
                                        orderItem.items.push({
                                            commodityId:this.state.datas.commodities[i].id,
                                            commodityName:this.state.datas.commodities[i].name,
                                            code:this.state.datas.commodities[i].code,
                                            standard:this.state.datas.commodities[i].standard,
                                            unitPrice:0,//this.state.datas.commodities[i].unitPriceChenpinglong,
                                            mode:1,//this.state.datas.commodities[i].mode,   20240809
                                            number:0,
                                            weight:0,
                                        })
                                    }
                                    this.state.datas.customers.push(orderItem);
                                    this.state.showChoiceCusPop=false;
                                    this.setState({datas:this.state.datas});
                                }else{ToastBox.error(stringUtil.language(50));}
                            }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <img style={{width:40,height:40,borderRadius:20}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                    <div style={{marginLeft:5,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                        <span style={{color:item.warning!=2?'#696969':'#D3D3D3',fontWeight:item.warning!=2?'bold':'normal',fontSize:17}}>{(key+1)+'.'+item.noteName}</span>
                                        <span style={{color:'#FF8C00',fontSize:14}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                    </div>
                                </div>
                                <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                            </div>)
                        })}
                    </div>
                </div>:''}
            </div>
            <MaskModal visible={this.state.submitShowPop?true:false} width={400} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <div><DatePicker locale='zhCN' selected={new Date(this.state.datas.pushTime)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                        this.state.datas.pushTime = date.valueOf();
                        this.setState({datas:this.state.datas});
                    }} /></div>
                    <span style={{color:'#CD5C5C',fontSize:18}}>订货表确订下推后</span>
                    <span style={{color:'#CD5C5C',fontSize:18,fontWeight:'bold'}}>将变成完成状态</span>
                    <span style={{color:'#CD5C5C'}}>是否确订？</span>
                </div>} okBnEvents={(event) => {
                    if(this.state.datas.pushTime)this.state.resultDataInfo.pushTime = this.state.datas.pushTime;
                    stringUtil.httpRequest(urlUtil.SALE_ADVANCE_ORDER_URL,this.state.resultDataInfo,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            let choiceWholesales=successResponse.data;
                            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_LIST_URL,choiceWholesales,['json','post',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    let wholesales=successResponse.data;
                                    for(let i=0;i<wholesales.length;i++){
                                        if((localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).isRound==1)||wholesales[i].customerType!=1) wholesales[i].billMoney=0;
                                        printUtil.toComputerPrint(otherUtil.wholesaleNull(wholesales[i]));
                                    }
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                            ToastBox.success(stringUtil.language(388));
                            this.setState({submitShowPop:false},()=>{
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:this.props.navigationStacks[this.props.navigationStacks.length-1].navigatePage,mainStacks:this.props.navigationStacks}});
                            });
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({ submitShowPop: false});}} />
            <MaskModal title='客户报价' visible={this.state.editTotalShowPop?true:false} width={400} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',paddingLeft:10,paddingRight:10}} key={this.state.editTotalShowPopIndex}>
                        <span style={{width:'100%',fontSize:36,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.datas.commodities[this.state.currXY.x]?this.state.datas.commodities[this.state.currXY.x].code:''}</span>
                        <span style={{width:'100%',fontSize:36,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.datas.commodities[this.state.currXY.x]?this.state.datas.commodities[this.state.currXY.x].name:''}</span>
                        <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>模式: </span>
                            <div style={{width:0,height:'100%',display:'flex',flexDirection:'row',alignItems:'center',flexGrow:1,cursor:'pointer'}} onClick={()=>{
                                this.state.currEditCommodity.mode = 0;
                                this.setState({currEditCommodity:this.state.currEditCommodity});
                            }}>{!this.state.currEditCommodity||!this.state.currEditCommodity.mode?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                                <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                                {!this.state.currEditCommodity||!this.state.currEditCommodity.mode?<span style={{color:'#2E8B57',fontSize:17}}>{stringUtil.language(1114)}</span>:
                                <span style={{color:'#808080',fontSize:17}}>{stringUtil.language(1114)}</span>}
                            </div>
                            <div style={{width:0,height:'100%',display:'flex',flexDirection:'row',alignItems:'center',flexGrow:1,cursor:'pointer'}} onClick={()=>{
                                this.state.currEditCommodity.mode = 1;
                                this.setState({currEditCommodity:this.state.currEditCommodity});
                            }}>{this.state.currEditCommodity&&this.state.currEditCommodity.mode==1?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                                <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                                {this.state.currEditCommodity&&this.state.currEditCommodity.mode==1?<span style={{color:'#2E8B57',fontSize:17}}>{stringUtil.language(1115)}</span>:
                                <span style={{color:'#808080',fontSize:17}}>{stringUtil.language(1115)}</span>}
                            </div>
                        </div>
                        <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>报价: </span>
                            <input style={{height:30,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,display:'flex',flexGrow:1,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.currEditCommodity&&this.state.currEditCommodity.unitPriceChenpinglong?(this.state.currEditCommodity.mode?arithUtil.fenToYuan(this.state.currEditCommodity.unitPriceChenpinglong,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currEditCommodity.unitPriceChenpinglong,2),0,this.state.weightMode)):''} onChange={(event)=>{
                                this.state.keyTagVal=this.state.currXY.x;
                                if(this.state.currEditCommodity&&this.state.currEditCommodity.mode){
                                    this.state.currEditCommodity.unitPriceChenpinglong = arithUtil.yuanToFen(event.target.value);
                                }else{
                                    this.state.currEditCommodity.unitPriceChenpinglong = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                }
                            }}/>
                            <span style={{marginLeft:5}}>{stringUtil.currency(1)}/{this.state.currEditCommodity&&this.state.currEditCommodity.mode?'件':(this.state.weightMode?'斤':'公斤')}</span>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    this.state.editTotalShowPop=false;
                    this.state.datas.commodities[this.state.currXY.x]=this.state.currEditCommodity;
                    for(let i=0;i<this.state.datas.customers.length;i++){let cusItem=this.state.datas.customers[i];
                        if(cusItem.items[this.state.keyTagVal]&&!cusItem.items[this.state.keyTagVal].unitPrice){
                            cusItem.items[this.state.keyTagVal].mode=this.state.currEditCommodity&&this.state.currEditCommodity.mode?this.state.currEditCommodity.mode:0;
                            cusItem.items[this.state.keyTagVal].unitPrice=this.state.currEditCommodity.unitPriceChenpinglong?this.state.currEditCommodity.unitPriceChenpinglong:0;
                        }
                    }
                    this.setState({datas:this.state.datas});
                }} closeBnEvents={() => {this.setState({ editTotalShowPop: false});}} />
            <MaskModal title={this.state.datas.customers[this.state.currXY.y]?this.state.datas.customers[this.state.currXY.y].customerName:''} visible={this.state.editShowPop?true:false} width={500} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',paddingLeft:10,paddingRight:10}} key={this.state.editShowPopIndex}>
                        <span style={{width:'100%',fontSize:36,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.datas.commodities[this.state.currXY.x]?this.state.datas.commodities[this.state.currXY.x].code:''}</span>
                        <span style={{width:'100%',fontSize:36,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{this.state.datas.commodities[this.state.currXY.x]?this.state.datas.commodities[this.state.currXY.x].name:''}</span>
                        <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>模式: </span>
                            <div style={{width:0,height:'100%',display:'flex',flexDirection:'row',alignItems:'center',flexGrow:1,cursor:'pointer'}} onClick={()=>{
                                this.state.currEditCommodity.mode = 0;
                                this.setState({currEditCommodity:this.state.currEditCommodity});
                            }}>{!this.state.currEditCommodity||!this.state.currEditCommodity.mode?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                                <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                                {!this.state.currEditCommodity||!this.state.currEditCommodity.mode?<span style={{color:'#2E8B57',fontSize:17}}>{stringUtil.language(1114)}</span>:
                                <span style={{color:'#808080',fontSize:17}}>{stringUtil.language(1114)}</span>}
                            </div>
                            <div style={{width:0,height:'100%',display:'flex',flexDirection:'row',alignItems:'center',flexGrow:1,cursor:'pointer'}} onClick={()=>{
                                this.state.currEditCommodity.mode = 1;
                                this.setState({currEditCommodity:this.state.currEditCommodity});
                            }}>{this.state.currEditCommodity&&this.state.currEditCommodity.mode==1?<span style={{fontFamily:'iconfont',fontSize:15,color:'#088718',marginLeft:7,marginRight:3}}>&#xe698;</span>:
                                <span style={{fontFamily:'iconfont',fontSize:15,color:'#A9A9A9',marginLeft:7,marginRight:3}}>&#xe6a7;</span>}
                                {this.state.currEditCommodity&&this.state.currEditCommodity.mode==1?<span style={{color:'#2E8B57',fontSize:17}}>{stringUtil.language(1115)}</span>:
                                <span style={{color:'#808080',fontSize:17}}>{stringUtil.language(1115)}</span>}
                            </div>
                        </div>
                        <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>数量: </span>
                            <input style={{width:150,height:30,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.currEditCommodity&&this.state.currEditCommodity.number?this.state.currEditCommodity.number:''} onChange={(event)=>{
                                this.state.currEditCommodity.number = parseInt(event.target.value);
                                if(this.state.currEditCommodity.standard){
                                    this.state.currEditCommodity.weight = (this.state.currEditCommodity.number).mul(this.state.currEditCommodity.standard);
                                    this.refs.customerCommodityWeight.value=this.state.currEditCommodity?arithUtil.keToWeight(this.state.currEditCommodity.weight,this.state.weightMode):0;
                                }
                            }}/>
                            <span style={{marginLeft:5}}>件</span>
                            <input ref='customerCommodityWeight' style={{width:150,height:30,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,marginLeft:20,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.currEditCommodity&&this.state.currEditCommodity.weight?arithUtil.keToWeight(this.state.currEditCommodity.weight,this.state.weightMode):''} onChange={(event)=>{
                                this.state.currEditCommodity.weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                            }}/>
                            <span style={{marginLeft:5}}>{this.state.weightMode?'斤':'公斤'}</span>
                        </div>
                        <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>报价: </span>
                            <input style={{width:150,height:30,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.currEditCommodity?this.state.currEditCommodity.mode?arithUtil.fenToYuan(this.state.currEditCommodity.unitPrice,2):arithUtil.unitPriceTo(arithUtil.fenToYuan(this.state.currEditCommodity.unitPrice,2),0,this.state.weightMode):''} onChange={(event)=>{
                                if(this.state.currEditCommodity&&this.state.currEditCommodity.mode){
                                    this.state.currEditCommodity.unitPrice = arithUtil.yuanToFen(event.target.value);
                                }else{
                                    this.state.currEditCommodity.unitPrice = arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0);
                                }
                            }}/>
                            <span>{stringUtil.currency(1)}/{this.state.currEditCommodity&&this.state.currEditCommodity.mode?'件':(this.state.weightMode?'斤':'公斤')}</span>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    if (!this.state.currEditCommodity.mode&&!this.state.currEditCommodity.weight) {this.state.currEditCommodity.weight=0;}
                    if (this.state.currEditCommodity.mode&&!this.state.currEditCommodity.number) {this.state.currEditCommodity.number=0;}
                    this.state.editShowPop=false;
                    this.state.datas.customers[this.state.currXY.y].items[this.state.currXY.x]=this.state.currEditCommodity;
                    this.setState({datas:this.state.datas});
                }} closeBnEvents={() => {this.setState({ editShowPop: false});}} />
            <MaskModal title={stringUtil.language(635)} visible={this.state.customerAddShowPop?true:false} width={580} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
              content={<div style={{width:'100%',height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex}>
                    <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                        <CustomerAddActivityView context={this} successEvent={(result1,result2)=>{
                            this.state.currItem = result1;
                            this.state.currDistrictA = result2.currDistrictA;
                            this.state.currDistrictB = result2.currDistrictB;
                            this.state.currDistrictC = result2.currDistrictC;
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.currItem==null||this.state.currItem.noteName==null || this.state.currItem.noteName=='') {
                        ToastBox.error(stringUtil.language(198));return;
                    }
                    let shengStr = this.state.currDistrictA!=null?this.state.currDistrictA.name:'';
                    let shiStr = this.state.currDistrictB!=null?this.state.currDistrictB.name:'';
                    let xianStr = this.state.currDistrictC!=null?this.state.currDistrictC.name:'';
                    if (shengStr!='' || shiStr!='' || xianStr!='') {
                        if (this.state.currDistrictB!=null) {
                            if (this.state.currDistrictC!=null) {
                                this.state.currItem.adcode = this.state.currDistrictC.adcode;
                            }else{this.state.currItem.adcode = this.state.currDistrictB.adcode;}
                        }else{this.state.currItem.adcode = this.state.currDistrictA.adcode;}
                        this.state.currItem.adname = shengStr+shiStr+xianStr;
                    }
                    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,this.state.currItem,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.state.customerAddShowPop = false;
                            this.initCustomers();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({customerAddShowPop:false})}}/>
        </div>)
    }
}

const styles = {};