import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as printUtil from '../utils/printUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabSettingPrintSet extends Component{
  constructor(props){
    super(props);
    this.state={
      printAboutSetInitInfo:{
        way:0,//0电脑，1云服务stringUtil.language(1045)，2USBstringUtil.language(1045)
        wayComputer:{},//终端
        wayCloud:{},//云
        wayUSB:{},//USB
      },
      printAboutSetInfo:{
        way:0,//0电脑，1云服务stringUtil.language(1045)，2USBstringUtil.language(1045)
        wayComputer:{},//终端
        wayCloud:{},//云
        wayUSB:{},//USB
      },
      cloudDeviceStatus:{msg:stringUtil.language(361)},
    };
  }
  complete(printAboutSetInfo){
    if(!printAboutSetInfo.wayComputer)printAboutSetInfo.wayComputer={};
    if(!printAboutSetInfo.wayCloud)printAboutSetInfo.wayCloud={};
    if(!printAboutSetInfo.wayUSB)printAboutSetInfo.wayUSB={};
  }
  componentDidMount(){
    let printAboutSetInfo = JSON.parse(localStorage.getItem('printAboutSetInfo'));//取缓存，null不存在
    if(printAboutSetInfo!=null){this.complete(printAboutSetInfo);
      this.state.printAboutSetInfo = printAboutSetInfo;
      if(this.state.printAboutSetInfo.way==1&&this.state.printAboutSetInfo.wayCloud.deviceNo!=null){//云stringUtil.language(1045)
      }else{this.setState({printAboutSetInfo:this.state.printAboutSetInfo});}
    }else{
      localStorage.setItem("printAboutSetInfo", JSON.stringify(this.state.printAboutSetInitInfo));//设置缓存
      this.setState({printAboutSetInfo:this.state.printAboutSetInitInfo});
    }
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#99CDFF',paddingLeft:10}}><span style={{color:'#FFF'}}>{stringUtil.language(251)}：</span></div>
        <div style={{height:70,display:'flex',flexDirection:'row',alignItems:'center'}}>
          <span style={{width:80,fontSize:15}}>{stringUtil.language(1155)}：</span>
          <input style={{width:300,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
              this.complete(this.state.printAboutSetInfo);
              this.state.printAboutSetInfo.wayComputer.deviceNoEdit = event.target.value;
          }} placeholder={stringUtil.language(126)+'..'}/>
          <span style={{width:120,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
            if(this.state.printAboutSetInfo.wayComputer!=null&&this.state.printAboutSetInfo.wayComputer.deviceNoEdit!=null&&this.state.printAboutSetInfo.wayComputer.deviceNoEdit!=''){
              let printAboutSetInfo = JSON.parse(localStorage.getItem('printAboutSetInfo'));//取缓存，null不存在
              if(printAboutSetInfo!=null) this.complete(this.state.printAboutSetInfo);
              else printAboutSetInfo = JSON.parse(JSON.stringify(this.state.printAboutSetInitInfo));
              printAboutSetInfo.wayComputer.deviceNo = this.state.printAboutSetInfo.wayComputer.deviceNoEdit;
              localStorage.setItem("printAboutSetInfo", JSON.stringify(printAboutSetInfo));//设置缓存
              this.setState({printAboutSetInfo:printAboutSetInfo});
            }else{ToastBox.error(stringUtil.language(126));}
          }}>{stringUtil.language(362)}</span>
        </div>
        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#99CDFF',paddingLeft:10}}><span style={{color:'#FFF'}}>{stringUtil.language(127)}：</span></div>
        <br/><span style={{fontSize:15}}>{stringUtil.language(1155)}：{this.state.printAboutSetInfo.wayComputer!=null&&this.state.printAboutSetInfo.wayComputer.deviceNo!=null?this.state.printAboutSetInfo.wayComputer.deviceNo:stringUtil.language(252)}</span>
      </div>)
  }
}

const styles = {
};