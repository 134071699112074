import React,{Component} from 'react';
import QRCode  from 'qrcode.react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ShareWholesaleConfirmInfo extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
        weightMode:stringUtil.getUrlParamsVal('weightMode',search)?stringUtil.getUrlParamsVal('weightMode',search):0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        isShowOwnerCargo:stringUtil.getUrlParamsVal('isShowOwnerCargo',search)?stringUtil.getUrlParamsVal('isShowOwnerCargo',search):0,//0stringUtil.language(1045)stringUtil.language(519)
        searchs:{
            wholesaleId:stringUtil.getUrlParamsVal('wholesaleId',search)?otherUtil.encDec(stringUtil.getUrlParamsVal('wholesaleId',search),1):0,
            stallId:stringUtil.getUrlParamsVal('stallId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('stallId',search),1)):0,
            customerId:stringUtil.getUrlParamsVal('customerId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('customerId',search),1)):0,
        },
        wholesaleInfo:null,
      }
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SHARE_WHOLESALE_INFO_URL+'/'+this.state.searchs.wholesaleId,{
            sign:otherUtil.encDec(this.state.searchs.stallId+','+this.state.searchs.customerId),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({wholesaleInfo:otherUtil.wholesaleNull(successResponse.data)});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        if(this.state.wholesaleInfo==null) return(<div/>);
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
            <div style={{width:'100%',height:65,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#52A056'}}>
                <span style={{color:'#FFF',fontSize:25,fontWeight:'bold'}}>{this.state.wholesaleInfo.shopName}{stringUtil.language(917)}</span><span style={{color:'#FFF',fontSize:15}}>{this.state.wholesaleInfo.marketName}</span>
            </div>
            <div style={{height:55,display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:10,paddingRight:10}}>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <span style={{fontSize:20,fontWeight:'bold'}}>{stringUtil.language(903)}{this.state.wholesaleInfo.customerName}</span>
                    <span style={{color:'#FF8C00',fontSize:14}}>{arithUtil.formatToTimeStr(new Date(this.state.wholesaleInfo.createTime),'Y.M.D h:m:s')}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{fontSize:12}}>{stringUtil.language(911)}{this.state.wholesaleInfo.customerAddress}</span>
                    <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>KD.{this.state.wholesaleInfo.wholesaleNo}</span>
                </div>
            </div>
            <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'32%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(518)}</span>
                <span style={{width:'12%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                <span style={{width:'13%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                <span style={{width:'17%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1068)}</span>
                <span style={{width:'11%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1069)}</span>
            </div>
            <div style={{position:'absolute',left:0,top:150,right:0,bottom:180,paddingLeft:10,paddingRight:10,overflow:'auto'}}>
                {(()=>{this.state.collectionInfo={xj:0,vx:0,zfb:0,yhk:0,collection:0,discount:0};
                    for(let i=0;i<this.state.wholesaleInfo.wholesaleMoneys.length;i++){
                        for(let j=0;j<this.state.wholesaleInfo.wholesaleMoneys[i].wholesaleCollects.length;j++){
                            let wholesaleCollectsTemp = this.state.wholesaleInfo.wholesaleMoneys[i].wholesaleCollects[j];
                            if(wholesaleCollectsTemp.accountType==0){//stringUtil.language(1093)
                                this.state.collectionInfo.xj = (this.state.collectionInfo.xj).add(wholesaleCollectsTemp.collectMoney, 0);
                            }else if (wholesaleCollectsTemp.accountType==1) {//stringUtil.language(1094)
                                this.state.collectionInfo.vx = (this.state.collectionInfo.vx).add(wholesaleCollectsTemp.collectMoney, 0);
                            }else if (wholesaleCollectsTemp.accountType==2) {//stringUtil.language(924)
                                this.state.collectionInfo.zfb = (this.state.collectionInfo.zfb).add(wholesaleCollectsTemp.collectMoney, 0);
                            }else if (wholesaleCollectsTemp.accountType==3) {//stringUtil.language(925)
                                this.state.collectionInfo.yhk = (this.state.collectionInfo.yhk).add(wholesaleCollectsTemp.collectMoney, 0);
                            }
                            this.state.collectionInfo.discount = (this.state.collectionInfo.discount).add(wholesaleCollectsTemp.discountMoney, 0);
                        }
                    }
                    this.state.collectionInfo.collection = this.state.collectionInfo.xj+this.state.collectionInfo.vx+this.state.collectionInfo.zfb+this.state.collectionInfo.yhk;
                    let tempStallBef = (this.state.wholesaleInfo.customerArrears).sub(this.state.wholesaleInfo.money);//3{stringUtil.language(1178)}2{stringUtil.language(1179)}11:22{stringUtil.language(1027)}不加{stringUtil.language(1038)}
                    let stallsTotalArrearsV = (this.state.wholesaleInfo.customerArrears).add(this.state.wholesaleInfo.billMoney);//{stringUtil.language(1127)}
                    let shouhouqian = (this.state.wholesaleInfo.customerArrears).add(this.state.wholesaleInfo.billMoney).sub((this.state.collectionInfo.collection).add(this.state.collectionInfo.discount));//{stringUtil.language(1127)}-本{stringUtil.language(1189)}{stringUtil.language(1038)}
                    this.state.arrearsList=[];
                    if(this.state.wholesaleInfo.state!=0&&this.state.wholesaleInfo.customerType!=1){
                        this.state.arrearsList.push({name:'本'+stringUtil.language(1189)+stringUtil.language(1081),money:arithUtil.fenToYuan(tempStallBef)});
                        this.state.arrearsList.push({name:'本'+stringUtil.language(1189)+stringUtil.language(1038),money:arithUtil.fenToYuan((this.state.collectionInfo.collection).add(this.state.collectionInfo.discount))});
                        this.state.arrearsList.push({name:stringUtil.language(1185)+'后'+stringUtil.language(1090),money:arithUtil.fenToYuan(shouhouqian)});
                        this.state.arrearsList.push({name:'最新'+stringUtil.language(1127),money:arithUtil.fenToYuan(this.state.wholesaleInfo.newestArrears)});
                    }
                    this.state.totalNumber=0;
                    this.state.totalWeight=0;
                    this.state.totalGoodMoney=0;
                    this.state.totalTotalOtherMoney=0;
                })()}
                {this.state.wholesaleInfo.commodities?this.state.wholesaleInfo.commodities.map((item,key)=>{
                    let totalOtherMoney = 0;
                    for(let i=0;i<item.costs.length;i++){
                        totalOtherMoney = totalOtherMoney.add(item.costs[i].money);
                    }
                    this.state.totalNumber = (this.state.totalNumber).add(item.number);
                    this.state.totalWeight = (this.state.totalWeight).add(item.weight);
                    this.state.totalGoodMoney = (this.state.totalGoodMoney).add(item.goodsMoney);
                    this.state.totalTotalOtherMoney = (this.state.totalTotalOtherMoney).add(totalOtherMoney);
                    return(<div style={{width:'100%'}} key={key}>
                        <div style={{width:'100%',height:28,display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                            <span style={{width:'32%',fontSize:15,fontWeight:'bold'}}>{key+1}.{stringUtil.subStr(item.commodityName,7)}</span>
                            <span style={{width:'12%',fontSize:15,fontWeight:'bold',textAlign:'right'}}>{item.number}</span>
                            <span style={{width:'15%',fontSize:15,fontWeight:'bold',textAlign:'right'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            {item.mode==0?<span style={{width:'13%',fontSize:15,textAlign:'right'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)}</span>:
                                <span style={{width:'13%',fontSize:15,textAlign:'right'}}>{arithUtil.fenToYuan(item.unitPrice,2)}</span>}
                            <span style={{width:'17%',fontSize:15,fontWeight:'bold',textAlign:'right'}}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                            <span style={{width:'11%',fontSize:15,textAlign:'right'}}>{arithUtil.fenToYuan(totalOtherMoney)}</span>
                        </div>
                        <div style={{width:'100%',height:22,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                            <div style={{width:'59%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10}}>
                                {this.state.isShowOwnerCargo==1?<span style={{color:'#A9A9A9',fontSize:10}}></span>:<span style={{color:'#A9A9A9',fontSize:10}}>{(item.targetType==1?stringUtil.language(1051)+(item.targetName?'_':''):'')+stringUtil.subStr(item.targetName,5)+' '+stringUtil.language(1175)+item.cargoNo+stringUtil.language(1176)}</span>}
                                {item.mode==0?<span style={{color:'#A9A9A9',fontSize:10}}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>:
                                <span style={{color:'#A9A9A9',fontSize:10}}>{item.number+'X'+arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>}
                            </div>
                            <span style={{width:'41%',color:'#A9A9A9',fontSize:10,textAlign:'right'}}>
                                {item.costs!=null?item.costs.map((itemb,key)=>{
                                    if(key<item.costs.length) return(itemb.costClauseName+arithUtil.fenToYuan(itemb.money)+'  ');
                                    else return(itemb.costClauseName+arithUtil.fenToYuan(itemb.money));
                                }):''}
                            </span>
                        </div>
                    </div>)
                }):''}
            </div>
            <div style={{width:'96%',position:'absolute',bottom:0,paddingLeft:'2%',paddingRight:'2%'}}>
                <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'32%',color:'#D2855D',fontSize:15}}>{stringUtil.language(890)}</span>
                    <span style={{width:'12%',color:'#D2855D',fontSize:15,textAlign:'right'}}>{this.state.totalNumber}{stringUtil.language(1173)}</span>
                    <span style={{width:'15%',color:'#D2855D',fontSize:15,textAlign:'right'}}>{arithUtil.keToWeight(this.state.totalWeight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                    <span style={{width:'13%',color:'#D2855D'}}></span>
                    <span style={{width:'17%',color:'#D2855D',fontSize:15,textAlign:'right'}}>{arithUtil.fenToYuan(this.state.totalGoodMoney)}</span>
                    <span style={{width:'11%',color:'#D2855D',fontSize:15,textAlign:'right'}}>{arithUtil.fenToYuan(this.state.totalTotalOtherMoney)}</span>
                </div>
                <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                    <div style={{display:'flex',flexGrow:1,height:'100%',flexDirection:'row',alignItems:'center',overflow:'auto'}}>
                        {this.state.wholesaleInfo.costs!=null?this.state.wholesaleInfo.costs.map((item,key)=>{
                            return(<span style={{fontSize:14,marginLeft:key!=0?10:0}} key={key}>{item.costClauseName}:{arithUtil.fenToYuan(item.money)}</span>)
                        }):''}
                        <span style={{fontSize:14,marginLeft:10}}>{stringUtil.language(1161)}:{arithUtil.fenToYuan(this.state.wholesaleInfo.advanceMoney)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(880)}</span><span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.wholesaleInfo.money).add(this.state.wholesaleInfo.billMoney))}</span>
                    </div>
                </div>
                <div style={{height:120,display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    {
                        // <div style={{display:'flex',flexDirection:'row'}}>
                        //     <QRCode value={urlUtil.IP_ADDRESS_URL+'/share/ShareLookSource?weightMode='+this.state.wholesaleInfo.weightMode+'&wholesaleId='+this.state.wholesaleInfo.wholesaleId+'&stallId='+this.state.wholesaleInfo.shopId+'&customerId='+this.state.wholesaleInfo.customerId}/*二维{stringUtil.language(1184)}的链接*/ size={90}/*宽高尺寸*/ fgColor="#000"/*颜色*/ />
                        //     <div style={{marginLeft:5}}>
                        //         <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>{stringUtil.language(1033)}:{stringUtil.subStr(this.state.wholesaleInfo.describe,8)}</span><br/>
                        //         <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>{stringUtil.language(1030)}:{stringUtil.subStr(this.state.wholesaleInfo.shopName,8)}</span><br/>
                        //         <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>{stringUtil.language(1122)}:{(this.state.wholesaleInfo.workerId==0?'stringUtil.language(431)':this.state.wholesaleInfo.workerName)+(this.state.wholesaleInfo.workerMobile?this.state.wholesaleInfo.workerMobile:'')}</span><br/>
                        //         <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>地址:{stringUtil.subStrEnd(this.state.wholesaleInfo.shopAdname+this.state.wholesaleInfo.shopAddress,9)}</span>
                        //     </div>
                        // </div> 
                    }
                    <div>
                        <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>{stringUtil.language(1033)}:{stringUtil.subStr(this.state.wholesaleInfo.describe,8)}</span><br/>
                        <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>{stringUtil.language(1030)}:{stringUtil.subStr(this.state.wholesaleInfo.shopName,8)}</span><br/>
                        <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>{stringUtil.language(1122)}:{(otherUtil.encDec(this.state.wholesaleInfo.workerId,1)==0?stringUtil.language(431):this.state.wholesaleInfo.workerName)+(this.state.wholesaleInfo.workerMobile?this.state.wholesaleInfo.workerMobile:'')}</span><br/>
                        <span style={{height:25,fontSize:14,textAlignVertical:'center'}}>地址:{stringUtil.subStrEnd(this.state.wholesaleInfo.shopAdname+this.state.wholesaleInfo.shopAddress,9)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        {/* (this.state.collectionInfo.collection).add(this.state.collectionInfo.discount)>=this.state.wholesaleInfo.money?"stringUtil.language(1141)":"stringUtil.language(1087)："+arithUtil.fenToYuan(((this.state.wholesaleInfo.money).add(this.state.wholesaleInfo.billMoney)).sub(this.state.collectionInfo.collection).sub(this.state.collectionInfo.discount))
                        <span style={{height:25,fontSize:15,fontWeight:'bold',textAlignVertical:'center'}}>stringUtil.language(1141):{arithUtil.fenToYuan((this.state.collectionInfo.collection).add(this.state.collectionInfo.discount))}</span> */}
                        {this.state.arrearsList.map((item,key)=>{
                            return(<span style={{fontSize:15,fontWeight:'bold'}} key={key}>{item.name+'：'+item.money}</span>);
                        })}
                    </div>
                    <div style={{height:40,position:'absolute',right:0,bottom:0,display:'flex',flexDirection:'row',alignItems:'center',paddingRight:15,cursor:'pointer'}}>
                        <span style={{color:'#00F',fontSize:18,fontWeight:'bold',borderBottomStyle:'solid',borderBottomColor:'#00F',borderBottomWidth:1}} onClick={()=>{
                            this.props.history.push({ pathname: "/ShareBusinessCus", search:'?weightMode='+this.state.weightMode+'&stallId='+otherUtil.encDec(this.state.searchs.stallId)+'&customerId='+this.state.wholesaleInfo.customerId});
                        }}>{stringUtil.language(1097)}交易对账&gt;&gt;</span>
                    </div>
                </div>
            </div>
        </div>);
    }
}

const styles = {};