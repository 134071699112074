import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabSettingExpendItem extends Component{
  constructor(props){
    super(props);
    this.state={
      ownerExpendsAll:[],//stringUtil.language(442)
      shopExpendsAll:[],//stringUtil.language(443)
      addShowPop:false,
      currExpendItem:{},
      editShowPop:false,
      delShowPop:false,
    };
  }
  componentDidMount(){
      this.initOwnerExpends();
      this.initShopExpends();
  }
  initOwnerExpends(){
      stringUtil.httpRequest(urlUtil.OWNER_EXPEND_CLAUSE_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.ownerExpendsAll= successResponse.data;
              this.setState({ownerExpendsAll: this.state.ownerExpendsAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initShopExpends(){
      stringUtil.httpRequest(urlUtil.SHOP_EXPEND_CLAUSE_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.shopExpendsAll= successResponse.data;
              this.setState({shopExpendsAll: this.state.shopExpendsAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
            <span style={{width:'14%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1104)}</span>
            <span style={{width:'40%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1089)}</span>
            <span style={{width:'20%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(997)}</span>
            <span style={{width:'26%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
        </div>
        <div style={{height:'44%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
            {this.state.ownerExpendsAll.map((item,key)=>{
                return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:'14%',textAlign:'center'}}>{key+1}</span>
                    <span style={{width:'40%'}}>{item.name}</span>
                    <span style={{width:'20%',textAlign:'center'}}>{stringUtil.language(1049)}</span>
                    <div style={{width:'26%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        {otherUtil.authorityFindCode("010802")!=null&&item.type==0?<i className="iconfontIndexCss" style={{fontSize:20,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.currExpendItem = {id:item.id,name:item.name,pageExpendTypeTag:0};
                            this.setState({
                                currExpendItem:this.state.currExpendItem,
                                editShowPop:!this.state.editShowPop
                            });
                        }}>&#xe63a;</i>:<div style={{position:'absolute'}}/>}
                        {otherUtil.authorityFindCode("010803")!=null&&item.type==0?<i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currExpendItem = {id:item.id,name:item.name,pageExpendTypeTag:0};
                            this.setState({
                                currExpendItem:this.state.currExpendItem,
                                delShowPop:!this.state.delShowPop
                            });
                        }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                    </div>
                </div>)
            })}
        </div>
        <div style={{height:'44%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
            {this.state.shopExpendsAll.map((item,key)=>{
                return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                    <span style={{width:'14%',textAlign:'center'}}>{key+1}</span>
                    <span style={{width:'40%'}}>{item.name}</span>
                    <span style={{width:'20%',textAlign:'center'}}>{stringUtil.language(1030)}</span>
                    <div style={{width:'26%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
                        {otherUtil.authorityFindCode("010902")!=null&&item.type==0?<i className="iconfontIndexCss" style={{fontSize:20,color:'#696969',cursor:'pointer'}} onClick={()=>{
                            this.state.currExpendItem = {id:item.id,name:item.name,pageExpendTypeTag:1};
                            this.setState({
                                currExpendItem:this.state.currExpendItem,
                                editShowPop:!this.state.editShowPop
                            });
                        }}>&#xe63a;</i>:<div style={{position:'absolute'}}/>}
                        {otherUtil.authorityFindCode("010903")!=null&&item.type==0?<i className="iconfontIndexCss" style={{fontSize:20,color:'#F00',cursor:'pointer'}} onClick={()=>{
                            this.state.currExpendItem = {id:item.id,name:item.name,pageExpendTypeTag:1};
                            this.setState({
                                currExpendItem:this.state.currExpendItem,
                                delShowPop:!this.state.delShowPop
                            });
                        }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                    </div>
                </div>)
            })}
        </div>
        {(otherUtil.authorityFindCode("010801")!=null||otherUtil.authorityFindCode("010901")!=null)?<div style={{height:'7%',display:'flex',flexDirection:'row',alignItems:'center'}}>
            <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  this.state.currExpendItem = {};
                  this.setState({addShowPop:!this.state.addShowPop});
                }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#FFF'}}>&#xe601;</i>&nbsp;&nbsp;{stringUtil.language(998)}</span>
        </div>:<div style={{position:'absolute'}}/>}
        <MaskModal visible={this.state.addShowPop} title={stringUtil.language(358)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.38)}
          content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                    名&nbsp;&nbsp;&nbsp;&nbsp;称&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} placeholder={stringUtil.language(174)} onChange={(event)=>{
                      this.state.currExpendItem.name = event.target.value;
                    }}/>
                </div>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    {stringUtil.language(997)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{otherUtil.authorityFindCode("010901")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.currExpendItem.expendClass = 0;
                        this.setState({currExpendItem:this.state.currExpendItem});
                    }}>
                        <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currExpendItem.expendClass==0?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(1049)}
                    </div>:<div style={{position:'absolute'}}/>}
                    {otherUtil.authorityFindCode("010801")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(40),cursor:'pointer'}} onClick={()=>{
                        this.state.currExpendItem.expendClass = 1;
                        this.setState({currExpendItem:this.state.currExpendItem});
                    }}>
                        <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currExpendItem.expendClass==1?'#D2855D':'#D3D3D3'}}>&#xe614;</i>&nbsp;{stringUtil.language(1030)}
                    </div>:<div style={{position:'absolute'}}/>}
                </div>
            </div>}
          okBnEvents={(event) => {
              if (typeof(this.state.currExpendItem.name)=='undefined' || this.state.currExpendItem.name==null || this.state.currExpendItem.name=='') {
                  ToastBox.error(stringUtil.language(107));return;
              }
              if (typeof(this.state.currExpendItem.expendClass)=='undefined' || this.state.currExpendItem.expendClass==null) {
                  ToastBox.error(stringUtil.language(250)+'！');return;
              }
              this.setState({addShowPop: false});
              if (this.state.currExpendItem.expendClass==0) {
                stringUtil.httpRequest(urlUtil.OWNER_EXPEND_CLAUSE_URL,{name:this.state.currExpendItem.name},['key','post',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initOwnerExpends();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }else if (this.state.currExpendItem.expendClass==1) {
                stringUtil.httpRequest(urlUtil.SHOP_EXPEND_CLAUSE_URL,{name:this.state.currExpendItem.name},['key','post',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initShopExpends();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }
          }} closeBnEvents={() => {this.setState({addShowPop: false});}}/>
        <MaskModal visible={this.state.editShowPop} title={stringUtil.language(360)} width={arithUtil.pxToDp(320)} height={arithUtil.ProportionTo(document.body.clientHeight,0.3)}
          content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currExpendItem.id}>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                    名&nbsp;&nbsp;&nbsp;&nbsp;称&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} defaultValue={this.state.currExpendItem.name} placeholder={stringUtil.language(174)} onChange={(event)=>{
                      this.state.currExpendItem.name = event.target.value;
                    }}/>
                </div>
            </div>}
          okBnEvents={(event) => {
              if (typeof(this.state.currExpendItem.name)=='undefined' || this.state.currExpendItem.name==null || this.state.currExpendItem.name=='') {
                  ToastBox.error(stringUtil.language(107));return;
              }
              this.setState({editShowPop: false});
              if (this.state.currExpendItem.pageExpendTypeTag==0) {
                stringUtil.httpRequest(urlUtil.OWNER_EXPEND_CLAUSE_URL+'/'+this.state.currExpendItem.id,{name:this.state.currExpendItem.name},['key','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initOwnerExpends();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }else if (this.state.currExpendItem.pageExpendTypeTag==1) {
                stringUtil.httpRequest(urlUtil.SHOP_EXPEND_CLAUSE_URL+'/'+this.state.currExpendItem.id,{name:this.state.currExpendItem.name},['key','put',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        ToastBox.success(stringUtil.language(388));
                        this.initShopExpends();
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }
          }} closeBnEvents={() => {this.setState({editShowPop: false});}}/>
        <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(125)}？
                    </div>}
            okBnEvents={(event) => {
                this.setState({delShowPop: false});
                if (this.state.currExpendItem.pageExpendTypeTag==0) {
                    stringUtil.httpRequest(urlUtil.OWNER_EXPEND_CLAUSE_URL+'/'+this.state.currExpendItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initOwnerExpends();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }else if (this.state.currExpendItem.pageExpendTypeTag==1) {
                    stringUtil.httpRequest(urlUtil.SHOP_EXPEND_CLAUSE_URL+'/'+this.state.currExpendItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initShopExpends();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                  }
            }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  inputText:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5)
  },
  tableTitle:{
      borderStyle:'solid',
      borderColor:'#F0F0F0',
      borderWidth: 1,
      backgroundColor:'#F2F2F2',
      display:'flex',
      flexDirection:'row',
      alignItems:'center'
  },
};