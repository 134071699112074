import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

 //{stringUtil.language(1051)}{stringUtil.language(1129)}
 export default class AddSelfBatchActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        defaultCargoNo:{},
        keySearch:'',
        currCommodityTypeItem:{},
        commodityTypesAll:[],
        cargoSelfLineItemAll:[],
        currBatchItem:{receiveTime:arithUtil.timeToNumber(arithUtil.getCurrDateTime())},
        currBatchCommodityItem:{},
        currBatchCommodityIndex:-1,
        addVegetableShowPop:false,
        editVegetableShowPop:false,
        selectDiglogTagEntCost:false,
        commoditysAll:[],
        commoditysData:[],
        currCommodities:[],
        costsAll:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_NO_URL,{cargoMode:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({defaultCargoNo:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let commoditysAllTemp = successResponse.data;
                this.state.commoditysAll = [];
                for(let i=0;i<commoditysAllTemp.length;i++){
                    this.state.searchNeeds = (commoditysAllTemp[i].targetType==1)?{// && commoditysAllTemp[i].systemCommodityId!=0
                        is:true,targetType:1,targetId:localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).id:0
                    }:{is:false,targetType:1,targetId:localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).id:0};
                    if(this.state.searchNeeds!=null&&this.state.searchNeeds.is) this.state.commoditysAll.push(commoditysAllTemp[i]);
                }
                this.setState({commoditysData: this.state.commoditysAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.cargoSelfLineItemAll = successResponse.data;
                this.state.cargoSelfLineItemAll.unshift({id:0,name:stringUtil.language(870)});
                this.setState({cargoSelfLineItemAll:this.state.cargoSelfLineItemAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.BASIC_SHOP_COMMODITY_TYPE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.commodityTypesAll= successResponse.data;
                this.state.commodityTypesAll.unshift({id:0,name:stringUtil.language(854)});
                this.state.commodityTypesAll.unshift({id:-1,name:stringUtil.language(389)});
                this.setState({commodityTypesAll: this.state.commodityTypesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    searchCommoditys(){this.state.commoditysData = [];
        this.state.commoditysData = [];
        for(let i=0;i<this.state.commoditysAll.length;i++){
            let commodityItem = this.state.commoditysAll[i];
            if (commodityItem.name.indexOf(this.state.keySearch)!=-1||(commodityItem.code?commodityItem.code:'').indexOf(this.state.keySearch)!=-1) {
                if (this.state.currCommodityTypeItem.id==null || this.state.currCommodityTypeItem.id==-1 || commodityItem.shopCommodityTypeId==this.state.currCommodityTypeItem.id) {
                    this.state.commoditysData.push(commodityItem);
                }
            }
        }
        this.setState({commoditysData:this.state.commoditysData});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:8,paddingRight:8,backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
                <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(871)}<select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} onChange={(event)=>{
                                this.state.currBatchItem.targetId = JSON.parse(event.target.value).id;
                                this.state.currBatchItem.name = JSON.parse(event.target.value).name;
                                this.state.currBatchItem.cargoNo = this.state.defaultCargoNo[JSON.parse(event.target.value).id]?this.state.defaultCargoNo[JSON.parse(event.target.value).id]:1;
                                this.setState({currBatchItem:this.state.currBatchItem});
                            }}><option style={{display:'none'}}></option>
                            {this.state.cargoSelfLineItemAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                        </select>
                    </div>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(872)}<div style={{height:'100%',color:this.state.deliveryMode!=1?'#FF8C00':'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({deliveryMode:0});
                        }}>{this.state.deliveryMode!=1?<i className="iconfontIndexCss" style={{fontSize:'27px',cursor:'pointer'}}>&#xe71f;</i>:
                            <i className="iconfontIndexCss" style={{fontSize:'27px'}}>&#xe641;</i>}
                            <span>{stringUtil.language(397)}</span>
                        </div>
                        <div style={{height:'100%',color:this.state.deliveryMode==1?'#FF8C00':'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({deliveryMode:1});
                        }}>{this.state.deliveryMode==1?<i className="iconfontIndexCss" style={{fontSize:'27px',cursor:'pointer'}}>&#xe71f;</i>:
                            <i className="iconfontIndexCss" style={{fontSize:'27px'}}>&#xe641;</i>}
                            <span>{stringUtil.language(398)}</span>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        {stringUtil.language(856)}<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} type='number' value={this.state.currBatchItem.cargoNo?this.state.currBatchItem.cargoNo+'':''} onChange={(event)=>{
                            if(/(^[1-9]\d*$)/.test(event.target.value+'')){//正整数
                                this.state.currBatchItem.cargoNo = event.target.value;
                            }
                            this.setState({currBatchItem:this.state.currBatchItem});
                        }} placeholder={stringUtil.language(87)}/>
                    </div>
                    <div><DatePicker locale='zhCN' selected={new Date(this.state.currBatchItem.receiveTime)} onChange={(date) => {//showTimeSelect timeFormat="HH:mm" timeIntervals={15} 
                        this.state.currBatchItem.receiveTime = date.valueOf();
                        this.setState({currBatchItem:this.state.currBatchItem});
                    }} /></div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(857)}</span>
                        <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                            this.state.currBatchItem.carNumber = event.target.value;
                        }} placeholder={stringUtil.language(88)}/>
                    </div>
                </div>
                <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'30%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                    <span style={{width:'20%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1024)}</span>
                    <span style={{width:'20%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1025)}</span>
                    <span style={{width:'30%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
                </div>
                <div style={{display:'flex',flexGrow:1,flexDirection:'column',overflow:'auto'}}>
                    {this.state.currBatchItem.commodities!=null?this.state.currBatchItem.commodities.map((item,key)=>{
                        return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'30%'}}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?'('+item.commodityCode+')':''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                            <span style={{width:'20%',textAlign:'center'}}>{arithUtil.keToWeight(item.standard,this.state.weightMode)}</span>
                            <span style={{width:'20%',textAlign:'center'}}>{arithUtil.keToWeight(item.reduce,this.state.weightMode)}</span>
                            <div style={{width:'30%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <i className="iconfontIndexCss" style={{fontSize:'27px',color:'#696969',cursor:'pointer'}} onClick={()=>{
                                    this.state.currBatchCommodityItem = JSON.parse(JSON.stringify(item));
                                    this.state.currBatchCommodityIndex = key;
                                    this.setState({editVegetableShowPop: !this.state.editVegetableShowPop});
                                }}>&#xe63a;</i>
                                <i className="iconfontIndexCss" style={{fontSize:'30px',color:'#F00',marginLeft:arithUtil.pxToDp(35),cursor:'pointer'}} onClick={()=>{
                                    this.state.currBatchItem.commodities.splice(key,1);
                                    this.setState({currBatchItem:this.state.currBatchItem});
                                }}>&#xe612;</i>
                            </div>
                        </div>)}):''}
                </div>
                <div style={{height:40,borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span style={{width:230,height:'30px',color:'#696969',lineHeight:'30px',borderRadius:3,borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',backgroundColor:'#EDEDED',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.state.currCommodities = [];
                        this.setState({addVegetableShowPop:true});
                        }}><i className="iconfontIndexCss" style={{fontSize:'17px',color:'#C0C0C0'}}>&#xe601; </i>{stringUtil.language(60)}</span>
                    <div style={{height:'100%',display:'flex',flexDirection:'row'}}>
                        <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginRight:20}}>
                            <span>{stringUtil.language(858)}</span><input style={{height:'100%',padding:0}} onChange={(event)=>{
                                this.state.currBatchItem.receiveDescribe = event.target.value;
                            }} placeholder={stringUtil.language(12)}/>
                        </div>
                        <span style={{width:arithUtil.pxToDp(200),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            if (this.state.currBatchItem.cargoNo==null || this.state.currBatchItem.cargoNo=='') {
                                ToastBox.error(stringUtil.language(136));return;
                            }
                            let cargoInsertVo = {commodities:[]};
                            if(this.state.currBatchItem.receiveTime)cargoInsertVo.receiveTime = this.state.currBatchItem.receiveTime;
                            cargoInsertVo.cargoMode = 1;//0代销 1{stringUtil.language(1051)} 2个人炒货
                            cargoInsertVo.targetId = this.state.currBatchItem.targetId!=null?this.state.currBatchItem.targetId:0;
                            cargoInsertVo.cargoNo = this.state.currBatchItem.cargoNo!=null?this.state.currBatchItem.cargoNo:0;
                            cargoInsertVo.carNumber = this.state.currBatchItem.carNumber!=null?this.state.currBatchItem.carNumber:'';
                            cargoInsertVo.deliveryMode = this.state.deliveryMode?this.state.deliveryMode:0;
                            if(this.state.currBatchItem.receiveDescribe)cargoInsertVo.receiveDescribe = this.state.currBatchItem.receiveDescribe;
                            if(this.state.currBatchItem.commodities==null)this.state.currBatchItem.commodities=[];
                            for(let i=0;i<this.state.currBatchItem.commodities.length;i++){
                                let commodityTemp = {costs:[]};
                                commodityTemp.commodityId = this.state.currBatchItem.commodities[i].commodityId!=null?this.state.currBatchItem.commodities[i].commodityId:0;
                                commodityTemp.standard = this.state.currBatchItem.commodities[i].standard!=null?this.state.currBatchItem.commodities[i].standard:0;
                                commodityTemp.reduce = this.state.currBatchItem.commodities[i].reduce!=null?this.state.currBatchItem.commodities[i].reduce:0;
                                commodityTemp.sendNumber = this.state.currBatchItem.commodities[i].sendNumber!=null?this.state.currBatchItem.commodities[i].sendNumber:0;
                                commodityTemp.sendWeight = this.state.currBatchItem.commodities[i].sendWeight!=null?this.state.currBatchItem.commodities[i].sendWeight:0;
                                commodityTemp.transferNumber = this.state.currBatchItem.commodities[i].transferNumber!=null?this.state.currBatchItem.commodities[i].transferNumber:0;
                                commodityTemp.transferWeight = this.state.currBatchItem.commodities[i].transferWeight!=null?this.state.currBatchItem.commodities[i].transferWeight:0;
                                commodityTemp.transferMoney = this.state.currBatchItem.commodities[i].transferMoney!=null?this.state.currBatchItem.commodities[i].transferMoney:0;
                                if (this.state.currBatchItem.commodities[i].costs==null)this.state.currBatchItem.commodities[i].costs=[];
                                for(let j=0;j<this.state.currBatchItem.commodities[i].costs.length;j++){
                                    let costsSource = JSON.parse(JSON.stringify(this.state.currBatchItem.commodities[i].costs[j]));
                                    commodityTemp.costs.push({
                                        costClauseId:costsSource.costClauseId!=null?costsSource.costClauseId:0,
                                        money:costsSource.money!=null?costsSource.money:0,
                                    });
                                }
                                cargoInsertVo.commodities.push(commodityTemp);
                            }
                            stringUtil.httpRequest(urlUtil.SALE_CARGO_URL,cargoInsertVo,['json','post',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    ToastBox.success(stringUtil.language(388));
                                    this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                    this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>{stringUtil.language(859)}</span>
                    </div>
                </div>
            </div>
            {this.state.addVegetableShowPop?<div style={{position:'absolute',left:0,top:0,right:0,bottom:0,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'rgba(128,128,128,0.5)'}}>
                <div style={{width:500,height:arithUtil.ProportionTo(document.body.clientHeight,0.75),paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                    <div style={{width:'100%',height:40,display:'flex',justifyContent:'space-between',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomColor:'#A9A9A9',borderBottomWidth:1}}>
                        <h1>&nbsp;&nbsp;{stringUtil.language(392)}</h1>
                        <div style={{width:50,height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'flex-end',cursor:'pointer'}} onClick={()=>{this.setState({ addVegetableShowPop: false, });}}>
                            <span style={{fontSize:'20px',fontWeight:'bold',color:'#aaa'}}>✖</span>
                        </div>
                    </div>
                    <div style={{height:'100%',height:arithUtil.ProportionTo(document.body.clientHeight,0.75)-40-45,paddingLeft:10,paddingRight:10}}>
                        <div style={{height:'11%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                            <input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                                this.state.keySearch = event.target.value;
                                this.searchCommoditys();
                            }} placeholder={stringUtil.language(32)}/>
                            <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(100)})} onChange={(event)=>{
                                this.state.currCommodityTypeItem = JSON.parse(event.target.value);
                                this.searchCommoditys();
                            }}>
                                {this.state.commodityTypesAll.map((item,key)=>{
                                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                                })}
                            </select>
                            <div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                                this.state.currCommodities=[];
                                for(let i=0;i<this.state.commoditysData.length;i++){let item=this.state.commoditysData[i];
                                    this.state.currCommodities.push({
                                        commodityId:item.id,
                                        commodityName:item.name,
                                        commodityCode:item.code,
                                        standard:item.standard,
                                        reduce:item.reduce,
                                        sendNumber:0,
                                        sendWeight:0,
                                        transferNumber:0,
                                        transferWeight:0,
                                        transferMoney:0,
                                        costs:item.costs,//stringUtil.language(1063)对应stringUtil.language(1037)stringUtil.language(886)目
                                    });
                                }
                                this.setState({currCommodities:this.state.currCommodities});
                            }}><span style={{color:'#009A61',fontSize:16,borderBottomStyle:'solid',borderBottomColor:'#009A61',borderBottomWidth:1}}>{stringUtil.language(1036)}</span></div>
                        </div>
                        <div style={{height:'89%',overflow:'auto'}}>
                            {this.state.commoditysData.map((item,key)=>{
                                return(<div key={key} style={{height:arithUtil.pxToDp(55),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#DCDCDC',paddingLeft:10,paddingRight:10,cursor:'pointer'}} onClick={()=>{
                                    let isHave = false;
                                    for(let i=0;i<this.state.currCommodities.length;i++){
                                        let comItem = this.state.currCommodities[i];
                                        if (comItem.commodityId==item.id) {
                                            isHave = true;
                                            this.state.currCommodities.splice(i,1);break;
                                        }
                                    }
                                    if (!isHave) {
                                        this.state.currCommodities.push({
                                            commodityId:item.id,
                                            commodityName:item.name,
                                            commodityCode:item.code,
                                            standard:item.standard,
                                            reduce:item.reduce,
                                            sendNumber:0,
                                            sendWeight:0,
                                            transferNumber:0,
                                            transferWeight:0,
                                            transferMoney:0,
                                            costs:item.costs,//stringUtil.language(1063)对应stringUtil.language(1037)stringUtil.language(886)目
                                        });
                                    }
                                    this.setState({currCommodities:this.state.currCommodities});
                                }}><div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <div style={{width:50,position:'relative',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                            <span style={{width:50,color:'#F00',fontWeight:'bold',textAlign:'center',textOverflow:'ellipsis',overflow:'hidden',backgroundColor:'rgba(255,255,255,0.2)',position:'absolute'}}>{stringUtil.showCode()==2?(item.code?item.code:''):''}</span>
                                            {localStorage.getItem("currentStallItem")&&JSON.parse(localStorage.getItem("currentStallItem")).showImg?'':<img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(item.thumbnail,1)} title={stringUtil.language(84)}/>}
                                        </div>
                                        <span style={{fontSize:17,marginLeft:8}}>{!stringUtil.showCode()||stringUtil.showCode()==2?item.name:stringUtil.showCode()==1?(item.code?item.code:''):''}</span>
                                    </div>
                                    {this.state.currCommodities.map((itemCurr,key)=>{
                                        if (itemCurr.commodityId == item.id) {
                                            return(<i className="iconfontIndexCss" style={{fontSize:20,color:'#52A056'}} key={key}>&#xe614;</i>);
                                        }
                                    })}
                                </div>)
                            })}
                        </div>
                    </div>
                    <div style={{width:'100%',height:45,display:'flex',justifyContent:'space-around',alignItems:'center'}}>
                        <span style={{width:120,height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',textAlign:'center',cursor:'pointer'}} onClick={()=>{
                            if(this.state.currBatchItem.commodities==null)this.state.currBatchItem.commodities = [];
                            for(let i=0;i<this.state.currCommodities.length;i++){
                                this.state.currBatchItem.commodities.push(this.state.currCommodities[i]);
                            }
                            this.setState({addVegetableShowPop: false});
                        }}>{stringUtil.language(1027)}</span>
                        <span style={{width:120,height:'30px',color:'#696969',lineHeight:'30px',borderRadius:5,backgroundColor:'#FFF',textAlign:'center',cursor:'pointer',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1}} onClick={() => {this.setState({ addVegetableShowPop: false, });}}>{stringUtil.language(1028)}</span>
                    </div>
                </div>
            </div>:''}
        <MaskModal title={stringUtil.language(191)+this.state.currBatchCommodityItem.commodityName} visible={this.state.editVegetableShowPop} width={arithUtil.pxToDp(500)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
            content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.currBatchCommodityItem.commodityId}>
                <div style={{height:'18%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(863)}</span>
                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='' defaultValue={arithUtil.keToWeight(this.state.currBatchCommodityItem.standard,this.state.weightMode)} onChange={(event)=>{
                            this.state.currBatchCommodityItem.standard = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                        }}/>
                        <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span>{stringUtil.language(864)}</span>
                        <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='' defaultValue={arithUtil.keToWeight(this.state.currBatchCommodityItem.reduce,this.state.weightMode)} onChange={(event)=>{
                            this.state.currBatchCommodityItem.reduce = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                        }}/>
                        <span> {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}/{stringUtil.language(1173)}</span>
                    </div>
                </div>
                <div style={{height:'14%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    <span>{stringUtil.language(89)}</span>
                    <i className="iconfontIndexCss" style={{fontSize:'25px',color:'#696969',cursor:'pointer'}} onClick={()=>{
                        if (this.state.costsAll.length==0) {
                            stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:1},['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.state.costsAll = successResponse.data;
                                    this.setState({selectDiglogTagEntCost:true});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }else{this.setState({selectDiglogTagEntCost:true});}
                    }}>&#xe601;</i>
                </div>
                <div style={{height:'50%',overflow:'auto'}}>
                    {this.state.currBatchCommodityItem.costs!=null?this.state.currBatchCommodityItem.costs.map((item,key)=>{
                        return(<div key={key} style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                            <span>{key+1+'、'+item.costClauseName}</span>
                            <span>{item.costClauseCalMode==0?stringUtil.language(868):item.costClauseCalMode==1?stringUtil.language(869):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                            <span>{(item.costClausePayee==0?stringUtil.language(1049):item.costClausePayee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                            {item.costClauseCalMode==0?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='0' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                        this.state.currBatchCommodityItem.costs[key].money = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                                }}/>
                                <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                            item.costClauseCalMode==1?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='0' defaultValue={item.money?arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode):''} onChange={(event)=>{
                                        this.state.currBatchCommodityItem.costs[key].money = event.target.value?arithUtil.unitPriceTo(arithUtil.yuanToFen(event.target.value),this.state.weightMode,0):0;
                                }}/>
                                <span style={{color:'#D2855D'}}> {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                            item.costClauseCalMode==2?<div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='0' defaultValue={item.money?item.money:''} onChange={(event)=>{
                                        this.state.currBatchCommodityItem.costs[key].money = event.target.value?parseFloat(event.target.value):0;
                                }}/>
                                <span style={{color:'#D2855D'}}> %</span></div>:
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(60)})} type='number' placeholder='0' defaultValue={item.money?arithUtil.fenToYuan(item.money):''} onChange={(event)=>{
                                        this.state.currBatchCommodityItem.costs[key].money = event.target.value?arithUtil.yuanToFen(event.target.value):0;
                                }}/></div>}
                            <i className="iconfontIndexCss" style={{fontSize:'20px',color:'#F00',cursor:'pointer'}} onClick={()=>{
                                this.state.currBatchCommodityItem.costs.splice(key,1);
                                this.setState({currBatchCommodityItem:this.state.currBatchCommodityItem})
                            }}>&#xe612;</i>
                        </div>)
                    }):<div/>}
                </div>
            </div>}
            okBnEvents={(event) => {
                this.state.currBatchItem.commodities[this.state.currBatchCommodityIndex] = JSON.parse(JSON.stringify(this.state.currBatchCommodityItem));
                this.setState({editVegetableShowPop: false});
            }} closeBnEvents={() => {this.setState({ editVegetableShowPop: false, })}}/>
        <MaskModal title={stringUtil.language(33)} visible={this.state.selectDiglogTagEntCost} width={arithUtil.pxToDp(450)} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                {this.state.costsAll.map((item,key)=>{
                    return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} onClick={()=>{
                        this.state.currBatchCommodityItem.costs.push({
                            costClauseId:item.id,
                            costClauseName:item.name,
                            costClausePayee:item.payee,
                            costClauseCalMode:item.calMode,
                            money:0
                        });
                        this.setState({selectDiglogTagEntCost:false});
                        }}>
                            <span>{item.name}</span>
                            <span>{item.scope==0?stringUtil.language(865):item.scope==1?stringUtil.language(393):item.scope==2?stringUtil.language(866):item.scope==3?stringUtil.language(394):item.scope==4?stringUtil.language(867):item.scope==5?stringUtil.language(395):stringUtil.language(278)}</span>
                            <span>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                            <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                    </div>);
                })}
            </div>} closeBnTopEvents={()=>{this.setState({selectDiglogTagEntCost:false});}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:35,
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:35,
        borderColor:'#d0d0d0',
        borderStyle:'solid',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
};