import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(229)}
 export default class DateCollectExpendActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        workersAll:[],
        currWorkerItem:{},
        settleInfo:{},
        dateTimeStr:arithUtil.getCurrDateTime(1),
        titleObjAll:[]
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.workersAll = successResponse.data;
                this.state.workersAll.unshift({id:0,noteName:stringUtil.language(431)});
                this.state.workersAll.unshift({id:-1,noteName:stringUtil.language(472)});
                this.state.currWorkerItem = JSON.parse(JSON.stringify(this.state.workersAll[0]));
                this.initData();
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SETTLE_URL,{
            date:this.state.dateTimeStr,
            workerId:this.state.currWorkerItem.id
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.settleInfo = successResponse.data;
                this.setState({settleInfo: this.state.settleInfo});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initTodaySaleData();
    }
    initTodaySaleData(){
        let titleAllObj = {};
        if (this.state.settleInfo.saleSettle!=null) {
            for(let i=0;i<this.state.settleInfo.saleSettle.length;i++){
                let row = this.state.settleInfo.saleSettle[i];
                for(let j=0;j<row.costs.length;j++){
                    let rol = row.costs[j];
                    if (!titleAllObj.hasOwnProperty(rol.costClauseId)) {
                        titleAllObj[rol.costClauseId] = rol.costClauseName;
                    }
                }
            }
        }
        this.state.titleObjAll = [];
        for(var key in titleAllObj){
            this.state.titleObjAll.push({
                costClauseId:key,
                costClauseName:titleAllObj[key],
                moneyTotal:0
            });
        }
        this.setState({titleObjAll:this.state.titleObjAll});
    }

    render(){
		this.state.settleTotalInfo = {stallCollection:0,stallPay:0,stallHand:0};
        if (this.state.settleInfo.accountInSettle!=null) {
            for(let i=0;i<this.state.settleInfo.accountInSettle.length;i++){
                let item = this.state.settleInfo.accountInSettle[i];
                if(item.accountType==1){
                    let totalMoney = (item.xjMoney).add(item.wxMoney).add(item.zfbMoney).add(item.yhkMoney);
                    this.state.settleTotalInfo.stallCollection = (this.state.settleTotalInfo.stallCollection).add(totalMoney);
                }
            }
        }
        if (this.state.settleInfo.accountOutSettle!=null) {
            for(let i=0;i<this.state.settleInfo.accountOutSettle.length;i++){
                let item = this.state.settleInfo.accountOutSettle[i];
                if(item.accountType==1){
                    let totalMoney = (item.xjMoney).add(item.wxMoney).add(item.zfbMoney).add(item.yhkMoney);
                    this.state.settleTotalInfo.stallPay = (this.state.settleTotalInfo.stallPay).add(totalMoney);
                }
            }
        }
        if(this.state.settleInfo.handMoneySettle!=null){
            for(let i=0;i<this.state.settleInfo.handMoneySettle.length;i++){
                let item = this.state.settleInfo.handMoneySettle[i];
                this.state.settleTotalInfo.stallHand = (this.state.settleTotalInfo.stallHand).add(item.money);
            }
        }             
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                                this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(15)}}>
                            <span style={{width:50}}>{stringUtil.language(887)}</span>
                            <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(150)})} onChange={(event)=>{
                                this.state.currWorkerItem = JSON.parse(event.target.value);
                                this.initData();
                            }}>
                            <option style={{display:'none'}}></option>
                            {this.state.workersAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </select>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>【{this.state.currWorkerItem.noteName}】{stringUtil.language(473)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr}</span>
                    </div>:''}
                    {(()=>{
                        this.state.collectionTotal = {discountMoney:0,nowCollectMoney:0,beforeCollectMoney:0,recordArrears:0,other:0};
                        if (this.state.settleInfo.customerCollectSettle!=null) {
                            for(let i=0;i<this.state.settleInfo.customerCollectSettle.length;i++){
                                let item = this.state.settleInfo.customerCollectSettle[i];
                                if (this.state.collectionTotal!=null) {
                                    this.state.collectionTotal.discountMoney = (this.state.collectionTotal.discountMoney).add(item.discountMoney);
                                    this.state.collectionTotal.nowCollectMoney = (this.state.collectionTotal.nowCollectMoney).add(item.nowCollectMoney);
                                    this.state.collectionTotal.beforeCollectMoney = (this.state.collectionTotal.beforeCollectMoney).add(item.beforeCollectMoney);
                                    this.state.collectionTotal.recordArrears = (this.state.collectionTotal.recordArrears).add(item.recordArrears);
                                }
                            }
                        }
                        if (this.state.settleInfo.shopCollectSettle!=null) {
                            for(let i=0;i<this.state.settleInfo.shopCollectSettle.length;i++){
                                let item = this.state.settleInfo.shopCollectSettle[i];
                                if (this.state.collectionTotal!=null) this.state.collectionTotal.other = (this.state.collectionTotal.other).add(item.money);
                            }
                        }
                    })()}
                    <span style={styles.totalTitle}>{stringUtil.language(201)}{arithUtil.fenToYuan((this.state.collectionTotal.nowCollectMoney).add(this.state.collectionTotal.beforeCollectMoney).add(this.state.collectionTotal.other))}    （{stringUtil.language(291)}{arithUtil.fenToYuan((this.state.collectionTotal.nowCollectMoney).add(this.state.collectionTotal.beforeCollectMoney))}{stringUtil.language(202)}{arithUtil.fenToYuan(this.state.collectionTotal.other)}）</span>
                    {this.state.settleInfo.collectAccounts!=null&&this.state.settleInfo.collectAccounts.length>0?<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        <span style={{height:'25px',lineHeight:'25px',fontSize:15}}>{stringUtil.language(1083)}&nbsp;&nbsp;</span>
                        {this.state.settleInfo.collectAccounts.map((item,key)=>{
                            return(<span style={{height:'25px',lineHeight:'25px',fontSize:15,marginRight:25}} key={key}>{item.accountName}：{arithUtil.fenToYuan(item.money)}</span>);
                        })}
                    </div>:''}
                    <div style={{borderStyle:'solid',borderColor:'#000',borderWidth:'1px',backgroundColor:'#E9F1F7'}}>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                            <div style={{width:'84%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'24%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1049)}</span>
                                <span style={{width:'12.6667%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(474)}</span>
                                <span style={{width:'12.6667%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1079)}</span>
                                <span style={{width:'12.6667%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(915)}</span>
                                <span style={{width:'12.6667%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(475)}</span>
                                <span style={{width:'12.6667%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(916)}</span>
                                <span style={{width:'12.6667%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(476)}</span>
                            </div>
                            <span style={{width:'16%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(477)}</span>
                        </div>
                        {(()=>{this.state.collectionTotal = {saleMoneyTotals:0,discountMoney:0,nowCollectMoney:0,beforeCollectMoney:0,recordArrears:0,newArrears:0,other:0};})()}
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <div style={{width:'84%',display:'flex',flexDirection:'column'}}>
                            {this.state.settleInfo.customerCollectSettle!=null?this.state.settleInfo.customerCollectSettle.map((item,key)=>{
                                let saleMoneyTotals=0;
                                if(this.state.settleInfo.saleSettle==null)this.state.settleInfo.saleSettle=[];
                                for(let i=0;i<this.state.settleInfo.saleSettle.length;i++){
                                    let rowMoneyTotal = this.state.settleInfo.saleSettle[i].goodsMoney;
                                    for(let j=0;j<this.state.settleInfo.saleSettle[i].costs.length;j++){
                                        rowMoneyTotal = (rowMoneyTotal).add(this.state.settleInfo.saleSettle[i].costs[j].money);
                                    }
                                    if(item.targetType==1){
                                        if(this.state.settleInfo.saleSettle[i].targetType==item.targetType)saleMoneyTotals=saleMoneyTotals.add(rowMoneyTotal);
                                    }else{
                                        if(this.state.settleInfo.saleSettle[i].targetType==item.targetType&&this.state.settleInfo.saleSettle[i].targetId==item.targetId)saleMoneyTotals=saleMoneyTotals.add(rowMoneyTotal);
                                    }
                                }
                                if (this.state.collectionTotal!=null) {
                                    this.state.collectionTotal.saleMoneyTotals = (this.state.collectionTotal.saleMoneyTotals).add(saleMoneyTotals);
                                    this.state.collectionTotal.discountMoney = (this.state.collectionTotal.discountMoney).add(item.discountMoney);
                                    this.state.collectionTotal.nowCollectMoney = (this.state.collectionTotal.nowCollectMoney).add(item.nowCollectMoney);
                                    this.state.collectionTotal.beforeCollectMoney = (this.state.collectionTotal.beforeCollectMoney).add(item.beforeCollectMoney);
                                    this.state.collectionTotal.newArrears = (this.state.collectionTotal.newArrears).add(saleMoneyTotals.sub(item.nowCollectMoney));
                                    this.state.collectionTotal.recordArrears = (this.state.collectionTotal.recordArrears).add(item.recordArrears);
                                }
                                return(<div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'24%',justifyContent:'flex-start',borderWidth:0})}>&nbsp;&nbsp;{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+item.targetName:''):item.targetName}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12.6667%',borderWidth:0})}>{arithUtil.fenToYuan(saleMoneyTotals)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12.6667%',borderWidth:0})}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12.6667%',borderWidth:0})}>{arithUtil.fenToYuan(item.nowCollectMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12.6667%',borderWidth:0})}>{arithUtil.fenToYuan(saleMoneyTotals.sub(item.nowCollectMoney))}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12.6667%',borderWidth:0})}>{arithUtil.fenToYuan(item.beforeCollectMoney)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12.6667%',borderWidth:0})}>{arithUtil.fenToYuan(item.recordArrears)}</span>
                                </div>)
                            }):<div style={{width:'100%',display:'flex',flexDirection:'row'}}/>}
                            </div>
                            <div style={{width:'16%'}}>
                                {this.state.settleInfo.shopCollectSettle!=null?this.state.settleInfo.shopCollectSettle.map((item,key)=>{
                                    if (this.state.collectionTotal!=null) this.state.collectionTotal.other = (this.state.collectionTotal.other).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderBottomColor:'#FFF',borderTopColor:'#FFF'})} key={key}>{stringUtil.subStr(item.clauseName,5)}：{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div/>}
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',fontWeight:'bold'})}>{stringUtil.language(1072)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10.6667%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.saleMoneyTotals)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10.6667%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.discountMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10.6667%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.nowCollectMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10.6667%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.newArrears)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10.6667%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.beforeCollectMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10.6667%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.recordArrears)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%'})}>{arithUtil.fenToYuan(this.state.collectionTotal.other)}</span>
                        </div>
                    </div>
                    {(()=>{
                        this.state.paymentTotal = {ownerExpendSettle:0,shopExpendSettle:0,supplierPaySettle:0,workerPaySettle:0,ownerPaySettle:0,totalMoney:0};
                        if (this.state.settleInfo.ownerExpendSettle!=null) {
                            for(let i=0;i<this.state.settleInfo.ownerExpendSettle.length;i++){
                                let item = this.state.settleInfo.ownerExpendSettle[i];
                                this.state.paymentTotal.ownerExpendSettle = (this.state.paymentTotal.ownerExpendSettle).add(item.money);
                            }
                        }
                        if (this.state.settleInfo.shopExpendSettle!=null) {
                            for(let i=0;i<this.state.settleInfo.shopExpendSettle.length;i++){
                                let item = this.state.settleInfo.shopExpendSettle[i];
                                this.state.paymentTotal.shopExpendSettle = (this.state.paymentTotal.shopExpendSettle).add(item.money);
                            }
                        }
                        if (this.state.settleInfo.supplierPaySettle!=null) {
                            for(let i=0;i<this.state.settleInfo.supplierPaySettle.length;i++){
                                let item = this.state.settleInfo.supplierPaySettle[i];
                                this.state.paymentTotal.supplierPaySettle = (this.state.paymentTotal.supplierPaySettle).add(item.money);
                            }
                        }
                        if (this.state.settleInfo.workerPaySettle!=null) {
                            for(let i=0;i<this.state.settleInfo.workerPaySettle.length;i++){
                                let item = this.state.settleInfo.workerPaySettle[i];
                                this.state.paymentTotal.workerPaySettle = (this.state.paymentTotal.workerPaySettle).add(item.money);
                            }
                        }
                        if (this.state.settleInfo.ownerPaySettle!=null) {
                            for(let i=0;i<this.state.settleInfo.ownerPaySettle.length;i++){
                                let item = this.state.settleInfo.ownerPaySettle[i];
                                this.state.paymentTotal.ownerPaySettle = (this.state.paymentTotal.ownerPaySettle).add(item.money);
                            }
                        }
                        this.state.paymentTotal.totalMoney = (this.state.paymentTotal.ownerExpendSettle).add(this.state.paymentTotal.shopExpendSettle).add(this.state.paymentTotal.supplierPaySettle).add(this.state.paymentTotal.workerPaySettle).add(this.state.paymentTotal.ownerPaySettle);
                        this.state.paymentTotal = {ownerExpendSettle:0,shopExpendSettle:0,supplierPaySettle:0,workerPaySettle:0,ownerPaySettle:0,totalMoney:this.state.paymentTotal.totalMoney};
                        
                        this.state.paymentTotal.xjMoney=0;
                        this.state.paymentTotal.wxMoney=0;
                        this.state.paymentTotal.zfbMoney=0;
                        this.state.paymentTotal.yhkMoney=0;
                        if(this.state.settleInfo.accountOutSettle!=null){
                            for(let i=0;i<this.state.settleInfo.accountOutSettle.length;i++){
                                // let totalMoneyOne = (item.xjMoney).add(item.wxMoney).add(item.zfbMoney).add(item.yhkMoney);
                                this.state.paymentTotal.xjMoney=(this.state.paymentTotal.xjMoney).add(this.state.settleInfo.accountOutSettle[i].xjMoney);
                                this.state.paymentTotal.wxMoney=(this.state.paymentTotal.wxMoney).add(this.state.settleInfo.accountOutSettle[i].wxMoney);
                                this.state.paymentTotal.zfbMoney=(this.state.paymentTotal.zfbMoney).add(this.state.settleInfo.accountOutSettle[i].zfbMoney);
                                this.state.paymentTotal.yhkMoney=(this.state.paymentTotal.yhkMoney).add(this.state.settleInfo.accountOutSettle[i].yhkMoney);
                            }
                        }
                    })()}
                    <span style={styles.totalTitle}>{stringUtil.language(203)}{arithUtil.fenToYuan(this.state.paymentTotal.totalMoney)}</span>
                    {this.state.settleInfo.payAccounts!=null&&this.state.settleInfo.payAccounts.length>0?<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
                        <span style={{height:'25px',lineHeight:'25px',fontSize:15}}>{stringUtil.language(1083)}&nbsp;&nbsp;</span>
                        {this.state.settleInfo.payAccounts.map((item,key)=>{
                            return(<span style={{height:'25px',lineHeight:'25px',fontSize:15,marginRight:25}} key={key}>{item.accountName}：{arithUtil.fenToYuan(item.money)}</span>);
                        })}
                    </div>:''}
                    <div style={{borderStyle:'solid',borderColor:'#000',borderWidth:'1px',backgroundColor:'#E9F1F7'}}>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                            <span style={{width:'20%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(442)}</span>
                            <span style={{width:'20%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(443)}</span>
                            <span style={{width:'20%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(444)}</span>
                            <span style={{width:'20%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(446)}</span>
                            <span style={{width:'20%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(445)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <div style={{width:'20%'}}>
                                {this.state.settleInfo.ownerExpendSettle!=null?this.state.settleInfo.ownerExpendSettle.map((item,key)=>{
                                    this.state.paymentTotal.ownerExpendSettle = (this.state.paymentTotal.ownerExpendSettle).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{(item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+item.targetName:''):item.targetName)+item.clauseName}：{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                            <div style={{width:'20%'}}>
                                {this.state.settleInfo.shopExpendSettle!=null?this.state.settleInfo.shopExpendSettle.map((item,key)=>{
                                    this.state.paymentTotal.shopExpendSettle = (this.state.paymentTotal.shopExpendSettle).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.clauseName}：{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                            <div style={{width:'20%'}}>
                                {this.state.settleInfo.supplierPaySettle!=null?this.state.settleInfo.supplierPaySettle.map((item,key)=>{
                                    this.state.paymentTotal.supplierPaySettle = (this.state.paymentTotal.supplierPaySettle).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.supplierName}：{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                            <div style={{width:'20%'}}>
                                {this.state.settleInfo.workerPaySettle!=null?this.state.settleInfo.workerPaySettle.map((item,key)=>{
                                    this.state.paymentTotal.workerPaySettle = (this.state.paymentTotal.workerPaySettle).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.workerName}：{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                            <div style={{width:'20%'}}>
                                {this.state.settleInfo.ownerPaySettle!=null?this.state.settleInfo.ownerPaySettle.map((item,key)=>{
                                    this.state.paymentTotal.ownerPaySettle = (this.state.paymentTotal.ownerPaySettle).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.ownerName}：{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%'})}>{arithUtil.fenToYuan(this.state.paymentTotal.ownerExpendSettle)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%'})}>{arithUtil.fenToYuan(this.state.paymentTotal.shopExpendSettle)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%'})}>{arithUtil.fenToYuan(this.state.paymentTotal.supplierPaySettle)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%'})}>{arithUtil.fenToYuan(this.state.paymentTotal.workerPaySettle)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%'})}>{arithUtil.fenToYuan(this.state.paymentTotal.ownerPaySettle)}</span>
                        </div>
                    </div>
                    {(()=>{this.state.handMoneyTotal = {shopToShopMoney:0,bossToBossMoney:0,bossToShopMoney:0,handMoneyTotal:0};})()}
                    {/* <span style={styles.totalTitle}>{stringUtil.language(1030)}{stringUtil.language(447)}：{arithUtil.fenToYuan(this.state.handMoneyTotal.handMoneyTotal)}</span>
                    <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1}}>
                        {this.state.settleInfo.handMoneySettle!=null?this.state.settleInfo.handMoneySettle.map((item,key)=>{
                            return(<span style={{width:'33%',height:arithUtil.pxToDp(30),textAlignVertical:'center'}} key={key}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}：{arithUtil.fenToYuan(item.money)}</span>)
                        }):<div />}
                    </div> */}
                    <span style={styles.totalTitle}>{stringUtil.language(478)}</span>
                    <div style={{borderStyle:'solid',borderColor:'#000',borderWidth:'1px',backgroundColor:'#E9F1F7',position:'relative'}}>
                        <div style={{width:'1px',borderLeftWidth:'1px',borderLeftStyle:'solid',borderLeftColor:'#000',position:'absolute',top:0,bottom:0,left:'33.33333%'}}/>
                        <div style={{width:'1px',borderLeftWidth:'1px',borderLeftStyle:'solid',borderLeftColor:'#000',position:'absolute',top:0,bottom:0,left:'66.66666%'}}/>
                        <div style={{height:'1px',borderBottomWidth:'1px',borderBottomStyle:'solid',borderBottomColor:'#000',position:'absolute',left:0,right:0,bottom:'30px'}}/>
                        <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                            <span style={{width:'33.33333%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(204)}</span>
                            <span style={{width:'33.33333%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(143)}</span>
                            <span style={{width:'33.33333%',fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(94)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',minHeight:'30px'}}>
                            <div style={{width:'33.33333%'}}>
                                {this.state.settleInfo.shopToShopMoneySettle!=null?this.state.settleInfo.shopToShopMoneySettle.map((item,key)=>{
                                    this.state.handMoneyTotal.shopToShopMoney = (this.state.handMoneyTotal.shopToShopMoney).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}:{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                            <div style={{width:'33.33333%'}}>
                                {this.state.settleInfo.bossToShopMoneySettle!=null?this.state.settleInfo.bossToShopMoneySettle.map((item,key)=>{
                                    this.state.handMoneyTotal.bossToShopMoney = (this.state.handMoneyTotal.bossToShopMoney).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}:{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                            <div style={{width:'33.33333%'}}>
                                {this.state.settleInfo.handMoneySettle!=null?this.state.settleInfo.handMoneySettle.map((item,key)=>{
                                    this.state.handMoneyTotal.handMoneyTotal = (this.state.handMoneyTotal.handMoneyTotal).add(item.money);
                                    return(<span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',justifyContent:'flex-start',borderWidth:0})} key={key}>{item.accountOutName}{stringUtil.language(1181)}{item.accountInName}:{arithUtil.fenToYuan(item.money)}</span>)
                                }):<div />}
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.33333%'})}>{arithUtil.fenToYuan(this.state.handMoneyTotal.shopToShopMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.33333%'})}>{arithUtil.fenToYuan(this.state.handMoneyTotal.bossToShopMoney)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33.33333%'})}>{arithUtil.fenToYuan(this.state.handMoneyTotal.handMoneyTotal)}</span>
                        </div>
                    </div>
                    <div style={{height:arithUtil.pxToDp(60),display:'flex',flexDirection:'row',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,marginTop:arithUtil.pxToDp(10),marginBottom:arithUtil.pxToDp(10)}}>
                        <div style={{width:'70%',display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:arithUtil.pxToDp(10)}}>
                            <span>{arithUtil.fenToYuan(this.state.settleTotalInfo.stallCollection)} - {arithUtil.fenToYuan(this.state.settleTotalInfo.stallPay)} + {arithUtil.fenToYuan(this.state.handMoneyTotal.bossToShopMoney)} - {arithUtil.fenToYuan(this.state.settleTotalInfo.stallHand)}  =  {arithUtil.fenToYuan((this.state.settleTotalInfo.stallCollection).sub(this.state.settleTotalInfo.stallPay).add(this.state.handMoneyTotal.bossToShopMoney).sub(this.state.settleTotalInfo.stallHand))}</span>
                            <span>{stringUtil.language(3)}</span>
                        </div>
                        <span style={{width:'30%',color:'#F00',fontSize:17,fontWeight:'bold',display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center',backgroundColor:'#F5F5F5'}}>{stringUtil.language(479)}<br/>{arithUtil.fenToYuan((this.state.settleTotalInfo.stallCollection).sub(this.state.settleTotalInfo.stallPay).sub(this.state.settleTotalInfo.stallHand))}</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
    tableTd:{
        width:arithUtil.pxToDp(90),
        height:arithUtil.pxToDp(30),
        fontSize:14,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
    totalTitle:{
        height:arithUtil.pxToDp(40),
        color:'#F00',
        fontSize:17,
        fontWeight:'bold',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
};