import React, { Component } from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import PropTypes from 'prop-types';

export default class TabBarBn extends Component{
    //属性声名
    static propTypes = {
        viewHeight:PropTypes.number,//高度
        changePages:PropTypes.func,//子组stringUtil.language(1173)向父组stringUtil.language(1173)传递值
    };
    //默认属性
    static defaultProps = {
        viewHeight:'100%',
    }
    //构造函数
    constructor(props) {
        super(props);
        this.state = {
            positionVal: 0,
        }
    }
    selectedOneView(positionVal){
        this.setState({ positionVal: positionVal });
    }
    render(){
        let tabItems = [
            {name:stringUtil.language(829),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe615;</i>},
            {name:stringUtil.language(830),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe622;</i>},
            {name:stringUtil.language(831),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe643;</i>},
            {name:stringUtil.language(832),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe7d4;</i>},
            {name:stringUtil.language(833),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe621;</i>},
            {name:stringUtil.language(834),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe60a;</i>},
            {name:stringUtil.language(835),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe63b;</i>},
            {name:stringUtil.language(836),icon:<i className="iconfontIndexCss" style={{fontSize:'22px',color:'#FFF',marginLeft:10}}>&#xe60d;</i>}
        ];
        if (otherUtil.authorityFindCode("02")==null) {//stringUtil.language(1084)stringUtil.language(1171)020101
            delete tabItems[2];
        }
        if (otherUtil.authorityFindCode("0203")==null) {//stringUtil.language(1056)stringUtil.language(1171)020301
            delete tabItems[3];
        }
        if (otherUtil.authorityFindCode("0204")==null) {//stringUtil.language(1037)stringUtil.language(1171)020401
            delete tabItems[4];
        }
        if (otherUtil.authorityFindCode("03")==null) {//财务模块
            delete tabItems[5];
        }
        if(localStorage.getItem("currentStallItem")!=null&&JSON.parse(localStorage.getItem("currentStallItem")).shopRole!=0){//0老板
            delete tabItems[tabItems.length-1];
        }
        return(<div style={arithUtil.stylesAdd(styles.container,{width:150,height:this.props.viewHeight})}>
            <div style={arithUtil.stylesAdd(styles.userView,{width:'100%',backgroundColor:this.state.positionVal === -1 ? '#52a056':'#383838',cursor:'pointer'})}
                key={-1} onClick={()=>{this.props.changePages(-1);this.selectedOneView(-1);}}>
                <img style={{width:'40px',height:'40px',borderRadius:'20px'}} loading="lazy" src={arithUtil.imageShow(localStorage.getItem("currentUserItem")!=null?JSON.parse(localStorage.getItem("currentUserItem")).avatar:null)} title={stringUtil.language(84)}/>
                <span style={{color:'#FFF',fontSize:21,marginLeft:5}}>{localStorage.getItem("currentUserItem")?JSON.parse(localStorage.getItem("currentUserItem")).noteName:''}</span>
            </div>
            {tabItems.map((item,key)=>{
                if (typeof(item) != undefined) {
                    return(<div key={key} style={{width:'100%',height:'9.5%',backgroundColor:this.state.positionVal === key ? '#52a056':'#383838',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} 
                        onClick={()=>{this.props.changePages(key);this.selectedOneView(key);}}>
                        {item.icon}<span style={{color:'#FFF',fontSize:21,marginLeft:5}}>{item.name}</span>
                    </div>)
                }
            })}
        </div>)
    }
}

const styles = {
    container: {
        backgroundColor:'#383838',
        display:'flex',
        flexDirection:'column',
        flexWrap:'nowrap',
        justifyContent:'flex-start',
        alignItems:'flex-start',
    },
    userView:{
        height:arithUtil.pxToDp(70),
        display:'flex',
        flexDirection:'row',
        flexWrap:'nowrap',
        alignItems:'center',
        paddingLeft:5
    },
};