import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

 //{stringUtil.language(1051)}{stringUtil.language(1129)}
 export default class AddQuarrelBatchActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        defaultCargoNo:{},
        currBatchItem:{},
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SALE_CARGO_NO_URL,{cargoMode:2},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.defaultCargoNo = successResponse.data;
                this.state.currBatchItem.cargoNo = this.state.defaultCargoNo[JSON.parse(localStorage.getItem("currentUserItem")).id]?this.state.defaultCargoNo[JSON.parse(localStorage.getItem("currentUserItem")).id]:1;
                this.setState({defaultCargoNo:this.state.defaultCargoNo});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',padding:arithUtil.pxToDp(8),paddingLeft:50,backgroundColor:'#FFF'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:20}}>
                    {stringUtil.language(856)}<input style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(200)})} type='number' value={this.state.currBatchItem.cargoNo?this.state.currBatchItem.cargoNo+'':''} onChange={(event)=>{
                        if(/(^[1-9]\d*$)/.test(event.target.value+'')){//正整数
                            this.state.currBatchItem.cargoNo = event.target.value;
                        }
                        this.setState({currBatchItem:this.state.currBatchItem});
                    }} placeholder={stringUtil.language(87)}/>
                </div><br/>
                <span style={{color:'#F00'}}>{stringUtil.language(7)}</span>
                <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(858)}</span><input style={{height:'100%',padding:0}} onChange={(event)=>{
                        this.state.currBatchItem.receiveDescribe = event.target.value;
                    }} placeholder={stringUtil.language(12)}/>
                </div>
                <span style={{width:arithUtil.pxToDp(200),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    if (this.state.currBatchItem.cargoNo==null || this.state.currBatchItem.cargoNo=='') {
                        ToastBox.error(stringUtil.language(136));return;
                    }
                    let cargoInsertVo = {commodities:[]};
                    cargoInsertVo.cargoMode = 2;//0代销 1{stringUtil.language(1051)} 2个人炒货
                    cargoInsertVo.cargoNo = this.state.currBatchItem.cargoNo!=null?this.state.currBatchItem.cargoNo:0;
                    if(this.state.currBatchItem.receiveDescribe)cargoInsertVo.receiveDescribe = this.state.currBatchItem.receiveDescribe;
                    stringUtil.httpRequest(urlUtil.SALE_CARGO_URL,cargoInsertVo,['json','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}>{stringUtil.language(859)}</span>
            </div>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
};