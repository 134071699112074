import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

  export default class CompanyUpdateActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        currItem:this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage,
      };
    }
    componentDidMount(){}

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:30,paddingTop:30,backgroundColor:'#FFF'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{width:arithUtil.pxToDp(60),height:arithUtil.pxToDp(60),borderStyle:'solid',borderWidth:1,borderColor:'#C0C0C0',display:'flex',justifyContent:'center',alignItems:'center'}}>
                        <img style={{width:'45px',height:'45px'}} loading="lazy" src={arithUtil.imageShow(this.state.currItem.headIcon)} title={stringUtil.language(84)}/>
                    </div>
                    <input style={arithUtil.stylesAdd(styles.phoneInputText,{marginLeft:arithUtil.pxToDp(40)})} defaultValue={this.state.currItem.noteName} placeholder={stringUtil.language(93)} onChange={(event)=>{
                        this.state.currItem.noteName = event.target.value;
                    }}/>
                </div>
                <div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:arithUtil.pxToDp(100),fontSize:18}}>{stringUtil.language(382)}</span>
                    <input style={styles.phoneInputText} type='number' defaultValue={this.state.currItem.mobile} placeholder={stringUtil.language(56)} onChange={(event)=>{
                        this.state.currItem.mobile = event.target.value;
                    }}/>
                </div>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:arithUtil.pxToDp(20)}}>
                    <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if (this.state.currItem.noteName==null || this.state.currItem.noteName=='') {
                            ToastBox.error(stringUtil.language(93));return;
                        }
                        stringUtil.httpRequest(urlUtil.OAUTH_COMPANY_URL+'/'+this.state.currItem.id,{noteName:this.state.currItem.noteName,mobile:this.state.currItem.mobile},['key','put',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                ToastBox.success(stringUtil.language(388));
                                this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                                this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(859)}</span>
                    <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#808080',lineHeight:'30px',borderRadius:3,backgroundColor:'#DCDCDC',marginLeft:arithUtil.pxToDp(10),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.props.navigationStacks.splice(this.props.navigationStacks.length-1,1);
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'',mainStacks:this.props.navigationStacks}});
                        }}>{stringUtil.language(860)}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    phoneInputText:{
        width:arithUtil.pxToDp(330),
        height:arithUtil.pxToDp(40),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:5,
        color:'#808080',
        padding:0,
        paddingLeft:arithUtil.pxToDp(10),
    },
};