import * as stringUtil from '../utils/stringUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';

//import * as printUtil from '../utils/printUtil.js';

//{stringUtil.language(1186)}送info给电脑{stringUtil.language(1048)}端。info:{stringUtil.language(1045)}内容对象 mode:0{stringUtil.language(1037)},1{stringUtil.language(1038)}{stringUtil.language(1189)}..
export function toComputerPrint(info,mode=0,successEvent){
    var printAboutSetInfo = JSON.parse(localStorage.getItem('printAboutSetInfo'));//取缓存，null不存在
    if(printAboutSetInfo!=null&&printAboutSetInfo.wayComputer!=null&&printAboutSetInfo.wayComputer.deviceNo!=null&&printAboutSetInfo.wayComputer.deviceNo!='') {
        stringUtil.httpRequest(urlUtil.PRINT_URL,{
            topic:printAboutSetInfo.wayComputer.deviceNo,
            data:JSON.stringify({mode:mode,info:info})
        },['key','post',1],(successResponse)=>{
            if (successResponse.status==200) {
                ToastBox.success(stringUtil.language(134));
                if(successEvent)successEvent(successResponse);
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }else {ToastBox.error(stringUtil.language(380));};
}