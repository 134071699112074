import React, { Component,Fragment } from 'react'
import ReactDOM from 'react-dom'
import * as stringUtil from '../utils/stringUtil.js';
import './toastBox.css';

class ToastBoxView extends Component {
    constructor() {
        super()
        this.transitionTime = 300
        this.state = { notices: [] }
        this.removeNotice = this.removeNotice.bind(this)
    }

    getNoticeKey() {
        const { notices } = this.state
        return `notice-${new Date().getTime()}-${notices.length}`
    }

    addNotice(notice) {
        const { notices } = this.state
        notice.key = this.getNoticeKey()
        // notices.push(notice);//展示所有的提示
        notices[0] = notice;//仅展示最后一个提示
        this.setState({ notices })
        if (notice.duration > 0) {
            setTimeout(() => {
                this.removeNotice(notice.key)
            }, notice.duration)
        }
        return () => { this.removeNotice(notice.key) }
    }

    removeNotice(key) {
        const { notices } = this.state
        this.setState({
            notices: notices.filter((notice) => {
                if (notice.key === key) {
                    if (notice.onClose) setTimeout(notice.onClose, this.transitionTime)
                    return false
                }
                return true
            })
        })
    }

    render() {
        const { notices } = this.state
        const icons = {
            info: 'toast_info',
            success: 'toast_success',
            error: 'toast_error',
            loading: 'toast_loading'
        }
        return (<div className="toast_one">
            {notices.map(notice => (
                <div className="toast_bg_one" key={notice.key}>
                    <div className='toast_box_one'>
                        <div className={`toast_icon_one ${icons[notice.type]}`}></div>
                        <div className='toast_text_one'>{notice.content}</div> 
                    </div>
                </div>
            ))}
        </div>)
    }
}

function createNotification() {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const notification = ReactDOM.render(<ToastBox />, div);
    return {
        addNotice(notice) {
            return notification.addNotice(notice)
        },
        destroy() {
            ReactDOM.unmountComponentAtNode(div)
            document.body.removeChild(div)
        }
    }
}

let notification
const notice = (type, content, duration = 2000, onClose) => {
    if (!notification) notification = createNotification()
    return notification.addNotice({ type, content, duration, onClose })
}

/*import ToastBox from '../components/ToastBox';在使用的文{stringUtil.language(1173)}里导{stringUtil.language(1195)}此文{stringUtil.language(1173)}，调用：ToastBox.success("成功",1000,() => {console.log('回调方法')})
ToastBox.success('成功')
ToastBox.error('{stringUtil.language(1162)}', 3000)
ToastBox.loading(true,'id标识')打开加载id标识
ToastBox.loading(false,'id标识')关闭加载id标识*/
let timer = null
let _scrollTop = 0
export default class ToastBox extends React.Component{
    constructor(props){
        super(props)
    }
    /*content 提示内容 string（loading方法为可选）
    duration 提示持续{stringUtil.language(1039)} number，{stringUtil.language(1046)}ms（可选）
    onClose 提示关闭时的回调函数（可选）*/
    static infoOne(content, duration, onClose) {
        return notice('info', content, duration, onClose)
    }
    static successOne(content = stringUtil.language(837), duration, onClose) {
        return notice('success', content, duration, onClose)
    }
    static errorOne(content, duration , onClose) {
        return notice('error', content, duration, onClose)
    }
    //开始请求：window.ToastLoding = ToastBox.loadingOne();
    //请求{stringUtil.language(1162)}：ToastBox.errorOne('网络异常',3000);
    //请求成功：setTimeout(window.ToastLoding);
    /*const hideLoading = ToastBox.loadingOne('{stringUtil.language(263)}', 0, () => {
        ToastBox.successOne('加载完成')
    })
    setTimeout(hideLoading, 2000) */
    static loadingOne(content = stringUtil.language(263), duration = 0, onClose) {
        return notice('loading', content, duration, onClose)
    }

    static info(mes='info', duration = 2500){
        initView('info',mes,duration);
    }
    static success(mes='success', duration = 2000){
        initView('success',mes,duration);
    }
    static error(mes='fail', duration = 2500){
        initView('error',mes,duration);
    }
    static loading(status,tag, mes=stringUtil.language(263)){
        initLoading(tag)
        setLoading(status,tag)
        ReactDOM.render(
            <Fragment>
                <div className='toast'>
                    <div className='toast_box'>
                        <div className='toast_icon toast_loading'></div>
                        <span style={{color:'#FFF',textAlign:'center'}}>{mes}</span>
                    </div>
                </div>
            </Fragment>,
            document.getElementById('toast'+tag)
        )
    }
    static closeloading(){//关闭所有加载框
        let toast = document.getElementsByName('toast');
        for(var i=0;i<toast.length;i++){
            let toastItem = toast[i];
            toastItem.style.display = 'none'
            popupClose()
        }
    }
}

function initView(type,mes, duration) {
    const icons = {
        info: 'toast_info',
        success: 'toast_success',
        error: 'toast_error',
        loading: 'toast_loading'
    }
    init()
    setTime(duration)
    ReactDOM.render(
        <Fragment>
            <div className='toast'>
                <div className='toast_box'> 
                    <div className={`toast_icon ${icons[type]}`}></div>
                    <span style={{color:'#FFF',textAlign:'center'}}>{mes}</span>
                </div>
            </div>
        </Fragment>,
        document.getElementById('toast')
    )
}
//弹层{stringUtil.language(1194)}现时设置body类名，防止{stringUtil.language(1199)}面滚动
function popupOpen(){
    _scrollTop = document.scrollingElement.scrollTop
    document.body.classList.add('body-popup')
    document.body.style.top = - _scrollTop + 'px'
}
//弹层隐藏时恢复滚动
function popupClose(){
    document.body.classList.remove('body-popup')
    document.scrollingElement.scrollTop = _scrollTop
}
//设置loading
function setLoading(status,tag){
    let toast = document.getElementById('toast'+tag)
    if(status){
        toast.style.display = 'block'
        popupOpen()
    }else{
        toast.style.display = 'none'
        popupClose()
    }
}
//初始化toast
function init(){
    clearTimeout(timer)
    let toast = document.getElementById('toast')
    if(toast){
        toast.style.display = 'block'
    }else{ 
       let div = document.createElement('div')
        div.setAttribute('id', 'toast')
        document.body.appendChild(div)
    }
    popupOpen()
}
//初始化toast，tag加载标识
function initLoading(tag){
    // clearTimeout(timer)
    let toast = document.getElementById('toast'+tag)
    if(toast){
        toast.style.display = 'block'
    }else{ 
       let div = document.createElement('div')
        div.setAttribute('id', 'toast'+tag)
        div.setAttribute('name', 'toast')
        document.body.appendChild(div)
    }
    popupOpen()
}
//设置定时器
function setTime(duration){
    timer = setTimeout(()=>{
        let toast = document.getElementById('toast')
        if(toast){
            toast.style.display = 'none'
            popupClose()
        }
    }, duration)
}
