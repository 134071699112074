import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ShareWxPromitConcern extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
        wxPromitConcernUrl:'https://mp.weixin.qq.com/s/E5_Oq4ImBDkEtDQZ5BzOfw',//用于关stringUtil.language(1187)公众号的文章链接
        code:stringUtil.getUrlParamsVal('code',search)?stringUtil.getUrlParamsVal('code',search):'',
        state:stringUtil.getUrlParamsVal('state',search)?JSON.parse(stringUtil.getUrlParamsVal('state',search)):{
            shopId:'',customerId:''
        },
        bingState:{},
      }
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_BIND_STATE_URL,{
            shopId:parseInt(otherUtil.encDec(this.state.state.shopId,1)),
            customerId:parseInt(otherUtil.encDec(this.state.state.customerId,1)),
            code:this.state.code
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                var needRefresh = sessionStorage.getItem('wxPromitConcern');
                if(needRefresh==null){
                    this.state.bingState = successResponse.data;
                }else{
                    this.state.bingState = needRefresh;
                    if(this.state.bingState.userInfo==null)this.state.bingState.userInfo={};
                    this.state.bingState.userInfo.isBind=true;
                }
                this.setState({bingState:this.state.bingState});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        var needRefresh = sessionStorage.getItem('wxPromitConcern');
        if(needRefresh!=null){
            if(this.state.bingState.userInfo==null)this.state.bingState.userInfo={};
            this.state.bingState.userInfo.isBind=true;
        }
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF',display:'flex',flexDirection:'column'}}>
            <div style={{width:'100%',height:50,backgroundColor:'#52A056',display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}}>
                <span style={{color:'#FFF',fontSize:18,fontWeight:'bold'}}>{this.state.bingState.customerName?stringUtil.subStr(this.state.bingState.customerName,10)+'，':''}您好！</span>
            </div>
            {this.state.bingState.userInfo!=null&&this.state.bingState.userInfo.isBind?<div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                <span style={{color:'#52A056',fontSize:20,fontWeight:'bold',marginTop:'20%'}}>您已绑定{this.state.bingState.shopName?'：'+this.state.bingState.shopName:''}</span>
                <span style={{color:'#52A056',fontSize:13,textAlign:'center',paddingLeft:10,paddingRight:10}}>自动接{stringUtil.language(1185)}信息需要关{stringUtil.language(1187)}公众号，如已关{stringUtil.language(1187)}请忽略，没有关{stringUtil.language(1187)}请{stringUtil.language(1198)}击下方按钮{stringUtil.language(1026)}！</span>
                <div style={{width:'60%',height:40,borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:'13%',cursor:'pointer'}} onClick={()=>{
                    window.location.href=this.state.wxPromitConcernUrl;
                }}>
                    <span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>去关{stringUtil.language(1187)}</span>
                </div>
            </div>:<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <span style={{color:'#F00',fontSize:20,fontWeight:'bold',marginTop:'20%'}}>您尚未绑定：{this.state.bingState.shopName}</span>
                <span style={{color:'#52A056',fontSize:13,textAlign:'center',paddingLeft:10,paddingRight:10}}>自动接{stringUtil.language(1185)}信息需要关{stringUtil.language(1187)}公众号，如已关{stringUtil.language(1187)}请忽略，没有关{stringUtil.language(1187)}请{stringUtil.language(1198)}击下方按钮{stringUtil.language(1026)}！</span>
                <div style={{width:'60%',height:40,borderRadius:5,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginTop:'13%',cursor:'pointer'}} onClick={()=>{
                    stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_BIND_URL,{
                        shopId:parseInt(otherUtil.encDec(this.state.state.shopId,1)),
                        customerId:parseInt(otherUtil.encDec(this.state.state.customerId,1)),
                        openid:this.state.bingState.userInfo.openid,
                        nickname:this.state.bingState.userInfo.nickname,
                        headimgurl:this.state.bingState.userInfo.headimgurl
                    },['key','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success('绑定成功！');
                            if(this.state.bingState.userInfo){this.state.bingState.userInfo.isBind=true;
                            }else{this.state.bingState.userInfo={isBind:true};}
                            this.setState({},()=>{
                                sessionStorage.setItem('wxPromitConcern',this.state.bingState);
                                window.location.href=this.state.wxPromitConcernUrl;
                            });
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }}>
                    <span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>绑定并关{stringUtil.language(1187)}</span>
                </div>
            </div>}
        </div>)
    }
}

const styles = {};