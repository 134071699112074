import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
 
export default class MeInfo extends Component{
	constructor(props){
        super(props);
        this.state={
            userInfoItem:{},
            getImageWayPop: false,
            showNickNamePop: false,
            showPasswordPop: false,
            stallApplyShowPop: false,
            workerApplyShowPop: false,
            exitShowPop:false,
            oldpassword:'',
            newpassword:'',
            surepassword:'',
            aboutMeStalls:[],
            currMeStallItem:{},
            versionNew:{},
            downloadShowPop:false,
        };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_USER_INFO_URL,null,['key','get',0],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.userInfoItem = successResponse.data;
                localStorage.setItem("currentUserItem", JSON.stringify(this.state.userInfoItem));//设置缓存
                this.setState({userInfoItem:this.state.userInfoItem});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initShopMineData();
    }
    initShopMineData(){
        stringUtil.httpRequest(urlUtil.SHOP_MINE_URL,null,['key','get',0],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.aboutMeStalls = successResponse.data;
                this.setState({aboutMeStalls:this.state.aboutMeStalls});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

	render(){
		return(<div style={{width:'100%',height:'100vh',backgroundColor:'#ECECEC',display:'flex',flexDirection:'column'}}>
            <div style={{width:'100%',height:75,backgroundColor:'#FFF',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(15)}}>
                  <img style={{width:'50px',height:'50px'}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
                  <span style={{fontSize:'25px',color:'#D2855D',marginLeft:arithUtil.pxToDp(10)}}>{stringUtil.language(84)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer',marginRight:20,cursor:'pointer'}} onClick={()=>{
                    this.setState({exitShowPop:true});
                }}>
                    <i className="iconfontIndexCss" style={{fontSize:'20px',color:'#808080'}}>&#xe652;</i>
                    <span style={{color:'#808080',marginLeft:arithUtil.pxToDp(5)}}>{stringUtil.language(523)}</span>
                </div>
            </div>
            <div style={{display:'flex',flexGrow:1,flexDirection:'row'}}>
                <div style={{width:'22%',display:'flex',flexDirection:'column',paddingLeft:10,paddingTop:5,paddingRight:10}}>
                    <span style={{height:30,fontSize:'17px',textAlignVertical:'center'}}>{stringUtil.language(524)}</span>
                    <div style={{width:'100%',display:'flex',flexGrow:1,flexDirection:'column',backgroundColor:'#FFF',borderRadius:5,paddingLeft:10,paddingRight:10}}>
                        <div style={arithUtil.stylesAdd(styles.meInfoItem,{height:arithUtil.pxToDp(70)})}>
                            {stringUtil.language(1098)}<img style={{width:'70px',height:'70px'}} loading="lazy" src={arithUtil.imageShow(this.state.userInfoItem.avatar)} title={stringUtil.language(84)}/>
                        </div>
                        <div style={arithUtil.stylesAdd(styles.meInfoItem,{height:'40px',cursor:'pointer'})} onClick={()=>{
                            this.setState({showNickNamePop: !this.state.showNickNamePop});
                        }}>
                            {stringUtil.language(1099)}<span>{this.state.userInfoItem.noteName}</span>
                        </div>
                        <div style={arithUtil.stylesAdd(styles.meInfoItem,{height:'40px'})}>
                            {stringUtil.language(934)}<span>{this.state.userInfoItem.mobile}</span>
                        </div>
                        <div style={arithUtil.stylesAdd(styles.meInfoItem,{height:'40px',cursor:'pointer'})} onClick={()=>{
                            this.setState({showPasswordPop: !this.state.showPasswordPop});
                        }}>
                            {stringUtil.language(525)}
                        </div>
                    </div>
                </div>
                <div style={{width:'73%',display:'flex',flexDirection:'column',paddingTop:5,paddingRight:10,marginLeft:'1.5%'}}>
                    <span style={{height:30,fontSize:'17px',textAlignVertical:'center'}}>{stringUtil.language(399)}</span>
                    <div style={{width:'100%',height:'97%',backgroundColor:'#FFF',borderRadius:5,paddingLeft:10,paddingRight:10,overflow:'auto'}}>
                        {this.state.aboutMeStalls.map((item,key)=>{
                            let stallStateView = <div/>;
                            if (item.state==0 || item.state==1) {
                                stallStateView = <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:28}}>
                                    <sapn style={{color:'#52A056'}}>{stringUtil.language(1100)}</sapn>
                                    <sapn style={arithUtil.stylesAdd(styles.myButton,{backgroundColor:'#52A056',cursor:'pointer'})} onClick={()=>{
                                        stringUtil.httpRequest(urlUtil.SHOP_TOKEN_URL,{
                                            shopId:item.shopId,
                                            clientType:1
                                        },['key','post',0],(successResponse)=>{
                                            if (successResponse.status==200) {
                                              let authorizationStall = successResponse.data;//stringUtil.language(1030)token
                                              localStorage.setItem("authorizationStall", authorizationStall);//设置缓存
                                              stringUtil.httpRequest(urlUtil.OAUTH_SHOP_INFO_URL,null,['key','get',1],(successResponse)=>{
                                                    if (successResponse.status==200) {
                                                        let shopInfoItem = successResponse.data;
                                                        if(shopInfoItem.warehouseId)localStorage.setItem("currentWarehouseItem", JSON.stringify({id:shopInfoItem.warehouseId,name:shopInfoItem.warehouseName}));//id为0{stringUtil.language(975)}默认{stringUtil.language(1064)}
                                                        localStorage.setItem("currentStallItem", JSON.stringify(shopInfoItem));//设置缓存
                                                        localStorage.setItem("authorityDatas", JSON.stringify(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).authorities:[]));//设置缓存
                                                        localStorage.setItem("wholesaleLocalInfo",JSON.stringify({wholesaleWay:shopInfoItem.wholesaleWay}));//设置缓存 0传统{stringUtil.language(796)}，1零{stringUtil.language(1176)}售卖方式，2{stringUtil.language(1084)}售卖方式
                                                        this.props.history.push({ pathname: "/Main", state: { id:'6666',name:'参数值。。' } });
                                                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                    }}>{stringUtil.language(1101)}</sapn>
                                </div>;
                            }else if (item.state==2) {
                                stallStateView = <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:28}}>
                                    <span style={{color:'#D2855D'}}>{stringUtil.language(937)}</span>
                                    <span style={arithUtil.stylesAdd(styles.myButton,{width:60,height:30,backgroundColor:'#D2855D',cursor:'pointer'})} onClick={()=>{
                                        this.state.currMeStallItem = JSON.parse(JSON.stringify(item));
                                        this.setState({workerApplyShowPop:!this.state.workerApplyShowPop});
                                    }}>{stringUtil.language(1102)}</span>
                                </div>;
                            }else if (item.state==3) {
                                stallStateView = null;//<div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:28}}><span style={{color:'#52A056'}}>停职</span></div>;
                            }else if (item.state==4) {
                                stallStateView = <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:28}}>
                                    <span style={{color:'#D2855D'}}>{stringUtil.language(938)}</span>
                                    <span style={arithUtil.stylesAdd(styles.myButton,{width:60,height:30,backgroundColor:'#D2855D',cursor:'pointer'})} onClick={()=>{
                                        this.state.currMeStallItem = JSON.parse(JSON.stringify(item));
                                        this.setState({stallApplyShowPop:!this.state.stallApplyShowPop});
                                    }}>{stringUtil.language(1102)}</span>
                                </div>;
                            }
                            if(stallStateView)return(<div style={styles.stallsItem} key={key}>
                                <div style={styles.stallsItemInfo}>
                                    <img style={{width:'60px',height:'60px'}} loading="lazy" src={arithUtil.imageShow(item.shopLogo,2)} title={stringUtil.language(84)}/>
                                    <div>{stringUtil.subStr(item.shopName,9)}<br/><span style={{fontSize:'12px',color:'#A9A9A9',textAlign:'right'}}>{item.state==0?stringUtil.language(431):item.state==1?stringUtil.language(400):''}</span></div>
                                </div>
                                {stallStateView}
                            </div>);
                            else return(<div style={{position:'absolute'}}/>);
                        })}
                        {/* <div style={styles.stallsItem}>
                            <div style={styles.stallsItemInfo}>
                                <i className="iconfontIndexCss" style={{fontSize:'60px',color:'#1E8FBF'}}>&#xe60b;</i>{stringUtil.language(680)}<br/>{stringUtil.language(681)}
                            </div>
                            <div style={arithUtil.stylesAdd(styles.addStallsBn,{cursor:'pointer'})} onClick={() =>{
                                this.props.history.push({ pathname: "/AddBossStall", state: { id:'6666',name:'参数值。。' } });
                            }}>{stringUtil.language(1019)}</div>
                        </div>
                        <div style={styles.stallsItem}>
                            <div style={styles.stallsItemInfo}>
                                <i className="iconfontIndexCss" style={{fontSize:'60px',color:'#52A056'}}>&#xe67a;</i>{stringUtil.language(682)}<br/>stringUtil.language(400)
                            </div>
                            <div style={arithUtil.stylesAdd(styles.addStallsBn,{cursor:'pointer'})} onClick={()=>{
                                this.props.history.push({ pathname: "/AddStallEmployee", state: {}});
                            }}>{stringUtil.language(1035)}</div>
                        </div> */}
                    </div>
                    <div style={{width:'73%',height:60,display:'flex',flexDirection:'row',justifyContent:'space-around',position:'absolute',bottom:0}}>
                        <div style={{width:'35%',height:40,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#22B14C',borderRadius:5,cursor:'pointer'}} onClick={() =>{
                            this.props.history.push({ pathname: "/AddBossStall", state: { id:'6666',name:'参数值。。' } });
                        }}><span style={{fontFamily:'iconfont',fontSize:20,color:'#FFF',}}>&#xe617;</span>
                            <span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>&nbsp;&nbsp;{stringUtil.language(305)}</span>
                        </div>
                        <div style={{width:'35%',height:40,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',backgroundColor:'#FFC90E',borderRadius:5,cursor:'pointer'}} onClick={()=>{
                            this.props.history.push({ pathname: "/AddStallEmployee", state: {}});
                        }}><span style={{fontFamily:'iconfont',fontSize:20,color:'#696969',}}>&#xe62f;</span>
                            <span style={{color:'#696969',fontSize:20,fontWeight:'bold'}}>&nbsp;&nbsp;{stringUtil.language(212)}</span>
                        </div>
                    </div>
                </div>
            </div>
            <MaskModal visible={this.state.showNickNamePop} title={stringUtil.language(526)} height={arithUtil.pxToDp(200)} context={this}
                content={<div><input style={arithUtil.stylesAdd(styles.meInputText,{width:250})} placeholder={stringUtil.language(154)} defaultValue={this.state.userInfoItem.noteName} onChange={(event)=>{
                    this.state.userInfoItem.noteNameUpdate = event.target.value;
                }}/></div>}
                okBnEvents={(event) => {
                    this.setState({showNickNamePop: false});
                    stringUtil.httpRequest(urlUtil.OAUTH_USER_NAME_URL,{noteName:(this.state.userInfoItem.noteNameUpdate!=null&&this.state.userInfoItem.noteNameUpdate!='')?this.state.userInfoItem.noteNameUpdate:this.state.userInfoItem.noteName},['key','put',0],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.state.userInfoItem.noteName = successResponse.data;
                            this.setState({userInfoItem:this.state.userInfoItem});
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({showNickNamePop: false});}}/>
            <MaskModal visible={this.state.showPasswordPop} title={stringUtil.language(527)} context={this}
                content={<div style={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:arithUtil.pxToDp(15)}}>
                        <span style={{width:80}}>{stringUtil.language(939)} </span>
                        <input style={styles.mePasswordText} type='password' placeholder={stringUtil.language(101)} onChange={(event)=>{
                            this.state.oldpassword = event.target.value;
                        }}/>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:arithUtil.pxToDp(15)}}>
                        <span style={{width:80}}>{stringUtil.language(940)} </span>
                        <input style={styles.mePasswordText} type='password' placeholder={stringUtil.language(102)} onChange={(event)=>{
                            this.state.newpassword = event.target.value; 
                        }}/>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:arithUtil.pxToDp(15)}}>
                        <span style={{width:80}}>{stringUtil.language(528)}</span>
                        <input style={styles.mePasswordText} type='password' placeholder={stringUtil.language(36)} onChange={(event)=>{
                            this.state.surepassword = event.target.value;
                        }}/>
                    </div>
                </div>}
                okBnEvents={(event) => {
                    if (this.state.newpassword.trim()=='') {
                        ToastBox.error(stringUtil.language(103));return;
                    }
                    if (this.state.newpassword.length < 6) {
                        ToastBox.error(stringUtil.language(37));return;
                    }
                    if (this.state.newpassword.indexOf(" ") != -1) {
                        ToastBox.error(stringUtil.language(38));return;
                    }
                    if (this.state.surepassword!=this.state.newpassword) {
                        ToastBox.error(stringUtil.language(23));return;
                    }
                    stringUtil.httpRequest(urlUtil.OAUTH_USER_PWD_URL,{oldPwd:this.state.oldpassword,newPwd:this.state.newpassword},['key','put',0],(successResponse)=>{
                        if (successResponse.status==200) {
                            localStorage.clear();//清除stringUtil.language(1097)缓存
                            this.setState({showPasswordPop: false},()=>{
                                this.props.history.push({pathname:"/"});
                            });
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {
                    this.setState({
                        showPasswordPop: false,
                        oldpassword:'',
                        newpassword:'',
                        surepassword:'',
                    });
                }}/>
            <MaskModal visible={this.state.exitShowPop} context={this} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(155)}
                    </div>}
                okBnEvents={(event) => {
                    //localStorage.clear();//清除stringUtil.language(1097)缓存
                    localStorage.removeItem('authorizationUser');
                    localStorage.removeItem('authorizationStall');
                    this.setState({exitShowPop: false},()=>{
                        this.props.history.push({pathname:"/"});
                    });
                }} closeBnEvents={() => {this.setState({exitShowPop: false})}}/>
            <MaskModal visible={this.state.stallApplyShowPop} context={this} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(63)}
                    </div>}
                okBnEvents={(event) => {
                    this.setState({stallApplyShowPop: false});
                    stringUtil.httpRequest(urlUtil.OAUTH_SHOP_APPLY_REVOKE_URL,null,['key','delete',0],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initShopMineData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({stallApplyShowPop: false})}}/>
            <MaskModal visible={this.state.workerApplyShowPop} context={this} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(64)}
                    </div>}
                okBnEvents={(event) => {
                    this.setState({workerApplyShowPop: false});
                    stringUtil.httpRequest(urlUtil.OAUTH_WORKER_REVOKE_URL,{shopId:this.state.currMeStallItem.id},['key','delete',0],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initShopMineData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({workerApplyShowPop: false})}}/>
        </div>);
	}
}

const styles = {
    myButton:{
        width:arithUtil.pxToDp(60),
        height:'30px',
        color:'#FFF',
        lineHeight:'30px',
        borderRadius:5,
        backgroundColor:'#d2855d',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        cursor:'pointer'
    },
    meInfoItem:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:arithUtil.pxToDp(15),
        marginRight:arithUtil.pxToDp(15),
        borderBottomStyle:'solid',
        borderBottomWidth:1,
        borderBottomColor:'#DCDCDC'
    },
    meInputText:{
        height:arithUtil.pxToDp(45),
        marginLeft:arithUtil.pxToDp(10),
        marginRight:arithUtil.pxToDp(10),
        marginTop:arithUtil.pxToDp(30),
        paddingLeft:arithUtil.pxToDp(10),
        borderLeftWidth:0,
        borderTopWidth:0,
        borderRightWidth:0,
        borderBottomStyle:'solid',
        borderBottomWidth:1,
        borderBottomColor:'#d0d0d0',
    },
    mePasswordText:{
        width:'60%',
        height:35,
        marginLeft:arithUtil.pxToDp(10),
        marginRight:arithUtil.pxToDp(10),
        paddingLeft:arithUtil.pxToDp(10),
        borderLeftWidth:0,
        borderTopWidth:0,
        borderRightWidth:0,
        borderBottomStyle:'solid',
        borderBottomWidth:1,
        borderBottomColor:'#d0d0d0',
    },
    stallsItem:{
        width:'21.8%',
        // height:120,
        borderStyle:'solid',
        borderColor:'#DCDCDC',
        borderWidth: 1,
        borderRadius: 7,
        padding:arithUtil.pxToDp(10),
        marginTop:arithUtil.pxToDp(10),
        marginLeft:arithUtil.pxToDp(5),
        float: 'left'
    },
    stallsItemInfo:{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        alignItems:'center',
    },
    addStallsBn:{
        height:'30px',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#E3E3E3',
        marginTop:arithUtil.pxToDp(20),
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    },
  };