import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //{stringUtil.language(213)}
 export default class OwnerSaleStatisticsActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        saleDailyDO:null,
        searchs:{
            startTime:arithUtil.getCurrDateTime(1),
            endTime:arithUtil.getCurrDateTime(1),
            targetType:-1,
            targetId:-1,
            targetName:stringUtil.language(425)
        },
        personsAll:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll = [];
                this.state.personsAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(425)});
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                        this.setState({personsAll:this.state.personsAll});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_DAILY_RANGE_URL,{
            startDate:this.state.searchs.startTime,
            endDate:this.state.searchs.endTime,
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.saleDailyDO=successResponse.data;
                this.state.saleDailyDO.ownerCostsAllChenPingLong=[];
                for(let i=0;i<this.state.saleDailyDO.targetDailies.length;i++){
                    let targetDailiesItem = this.state.saleDailyDO.targetDailies[i];
                    for(let j=0;j<targetDailiesItem.costs.length;j++){
                        let isHava=0;
                        for(let k=0;k<this.state.saleDailyDO.ownerCostsAllChenPingLong.length;k++){
                            if(targetDailiesItem.costs[j].costClauseId==this.state.saleDailyDO.ownerCostsAllChenPingLong[k].costClauseId){isHava=1;continue;}
                        }
                        if(!isHava)this.state.saleDailyDO.ownerCostsAllChenPingLong.push(targetDailiesItem.costs[j]);
                    }
                }
                this.setState({saleDailyDO:this.state.saleDailyDO});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    getWidthSize(scal,sourScal){
        let result;
        if(sourScal!=null)result=((100).div(scal+sourScal.length,1))+'%';
        else result=((100).div(scal,1))+'%';
        return result;
    }

    render(){
        if(this.state.saleDailyDO==null)return(<div/>);
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(888)}</span><div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endTime)} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(918)}</span><Select showSearch defaultValue={stringUtil.language(425)}  style={{width:200,height:30}} onChange={(value)=>{
                                this.state.searchs.targetType = JSON.parse(value).targetType;
                                this.state.searchs.targetId = JSON.parse(value).targetId;
                                this.state.searchs.targetName = JSON.parse(value).noteName;
                                this.initData();
                            }}>
                            {this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </Select>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'column',justifyContent:'flex-start',paddingTop:5,overflow:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(213)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.startTime} {stringUtil.language(1174)} {this.state.searchs.endTime}</span>
                    </div>:''}
                    {(()=>{this.state.totalInfo={wholesaleNumber:0,number:0,weight:0,goodsMoney:0,entrustMoney:0,disburdenMoney:0,diffGoodsMoney:0};
                    if(this.state.saleDailyDO.targetDailies){
                        for(let i=0;i<this.state.saleDailyDO.targetDailies.length;i++){
                            this.state.totalInfo.diffGoodsMoney = this.state.totalInfo.diffGoodsMoney.add(this.state.saleDailyDO.targetDailies[i].diffGoodsMoney);
                        }
                    }
                    this.state.totalOwnerOther=0;})()}
                    <div style={{height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(533)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(944)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(534)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(535)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(536)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(537)}</span>
                        {this.state.totalInfo.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(538)}</span>:""}
                        {this.state.saleDailyDO.ownerCostsAllChenPingLong?this.state.saleDailyDO.ownerCostsAllChenPingLong.map((item,key)=>{
                            return(<span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}} key={key}>{item.costClauseName}</span>);
                        }):''}
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(941)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#A9A9A9',textAlign:'center'}}>{JSON.parse(localStorage.getItem("currentStallItem")).disburdenText}</span>
                    </div>
                    <div style={{width:'100%'}}>
                        {this.state.saleDailyDO.targetDailies?this.state.saleDailyDO.targetDailies.map((item,key)=>{
                            this.state.totalInfo.wholesaleNumber = this.state.totalInfo.wholesaleNumber+item.wholesaleNumber;
                            this.state.totalInfo.number = this.state.totalInfo.number+item.number;
                            this.state.totalInfo.weight = (this.state.totalInfo.weight).add(item.weight);
                            this.state.totalInfo.goodsMoney = (this.state.totalInfo.goodsMoney).add(item.goodsMoney.sub(item.diffGoodsMoney));
                            this.state.totalInfo.entrustMoney = (this.state.totalInfo.entrustMoney).add(item.entrustMoney);
                            this.state.totalInfo.disburdenMoney = (this.state.totalInfo.disburdenMoney).add(item.disburdenMoney);
                            return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{item.targetType==1?stringUtil.language(1051)+(item.targetName!=null?'_'+stringUtil.subStr(item.targetName,7):''):stringUtil.subStr(item.targetName,9)}</span>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{item.cargoNo}</span>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{item.wholesaleNumber}</span>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{item.number}</span>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{arithUtil.fenToYuan(item.goodsMoney.sub(item.diffGoodsMoney))}</span>
                                {this.state.totalInfo.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{arithUtil.fenToYuan(item.diffGoodsMoney)}</span>:""}
                                {this.state.saleDailyDO.ownerCostsAllChenPingLong?this.state.saleDailyDO.ownerCostsAllChenPingLong.map((itemcpl,key)=>{
                                    let moneyVal=0;
                                    for(let i=0;i<item.costs.length;i++){
                                        if(item.costs[i].costClauseId==itemcpl.costClauseId)moneyVal=item.costs[i].money;
                                    }
                                    this.state.totalInfo['cpl'+itemcpl.costClauseId] = (this.state.totalInfo['cpl'+itemcpl.costClauseId]!=null?this.state.totalInfo['cpl'+itemcpl.costClauseId]:0).add(moneyVal);
                                    return(<span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}} key={key}>{arithUtil.fenToYuan(moneyVal)}</span>);
                                }):''}
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{arithUtil.fenToYuan(item.entrustMoney)}</span>
                                <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),textAlign:'center'}}>{arithUtil.fenToYuan(item.disburdenMoney)}</span>
                            </div>)
                        }):<div />}
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{stringUtil.language(890)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}></span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{this.state.totalInfo.wholesaleNumber}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{this.state.totalInfo.number}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.weight,this.state.weightMode)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}</span>
                        {this.state.totalInfo.diffGoodsMoney!=0?<span style={{width:this.getWidthSize(8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.diffGoodsMoney)}</span>:""}
                        {this.state.saleDailyDO.ownerCostsAllChenPingLong?this.state.saleDailyDO.ownerCostsAllChenPingLong.map((item,key)=>{
                            this.state.totalOwnerOther=(this.state.totalOwnerOther).add(this.state.totalInfo['cpl'+item.costClauseId]);
                            return(<span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}} key={key}>{arithUtil.fenToYuan(this.state.totalInfo['cpl'+item.costClauseId])}</span>);
                        }):''}
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.entrustMoney)}</span>
                        <span style={{width:this.getWidthSize(this.state.totalInfo.diffGoodsMoney!=0?9:8,this.state.saleDailyDO.ownerCostsAllChenPingLong),color:'#FF8C00',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.disburdenMoney)}</span>
                    </div>
                    <div style={{marginTop:20,display:'flex',flexDirection:'column'}}>
                        {this.state.saleDailyDO.advanceMoney?<span>{stringUtil.language(942)}:{arithUtil.fenToYuan(this.state.saleDailyDO.advanceMoney)}</span>:''}
                        {(()=>{this.state.dataSaleMoney=(this.state.totalInfo.goodsMoney).add(this.state.totalOwnerOther);
                            for(let i=0;i<this.state.saleDailyDO.wholesaleDailies.length;i++){
                                this.state.dataSaleMoney=(this.state.dataSaleMoney).add(this.state.saleDailyDO.wholesaleDailies[i].money);
                            }
                        })()}
                        <span>{stringUtil.language(889)}：{arithUtil.fenToYuan(this.state.dataSaleMoney)}{stringUtil.currency(1)} ({stringUtil.language(1068)}{arithUtil.fenToYuan(this.state.totalInfo.goodsMoney)}{stringUtil.currency(1)}{stringUtil.language(539)}{arithUtil.fenToYuan(this.state.totalOwnerOther)}{stringUtil.currency(1)}
                        {(()=>{
                            this.state.collectMoney=(this.state.saleDailyDO.xjCollect).add(this.state.saleDailyDO.wxCollect).add(this.state.saleDailyDO.zfbCollect).add(this.state.saleDailyDO.yhkCollect);
                            let strVal='';
                            for(let i=0;i<this.state.saleDailyDO.wholesaleDailies.length;i++){
                                strVal=strVal+'，'+this.state.saleDailyDO.wholesaleDailies[i].costClauseName+arithUtil.fenToYuan(this.state.saleDailyDO.wholesaleDailies[i].money)+stringUtil.currency(1);
                            }
                            return strVal;
                        })()})</span>
                        <span>{stringUtil.language(943)}：{arithUtil.fenToYuan(this.state.collectMoney)}{stringUtil.currency(1)} ({stringUtil.language(1093)}{arithUtil.fenToYuan(this.state.saleDailyDO.xjCollect)}{stringUtil.currency(1)}，{stringUtil.language(1094)}{arithUtil.fenToYuan(this.state.saleDailyDO.wxCollect)}{stringUtil.currency(1)}，{stringUtil.language(924)}{arithUtil.fenToYuan(this.state.saleDailyDO.zfbCollect)}{stringUtil.currency(1)}，{stringUtil.language(925)}{arithUtil.fenToYuan(this.state.saleDailyDO.yhkCollect)}{stringUtil.currency(1)})</span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};