import React,{Component} from 'react';
import DatePicker from 'react-mobile-datepicker';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ShareBusinessDetailCus extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
          weightMode:stringUtil.getUrlParamsVal('weightMode',search)?stringUtil.getUrlParamsVal('weightMode',search):0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
          searchs:{
            stallId:stringUtil.getUrlParamsVal('stallId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('stallId',search),1)):0,
            customerId:stringUtil.getUrlParamsVal('customerId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('customerId',search),1)):0,
            startDate:arithUtil.getCurrDateTime(1),//-1stringUtil.language(1097)，0stringUtil.language(1087)完，1stringUtil.language(1185)完
            endDate:arithUtil.getCurrDateTime(1),//数据总stringUtil.language(1021)
          },
          startTimePop:false,
          endTimePop:false,
          shareReconciliationDO:null,
      }
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SHARE_RECONCILIATION_URL,{
            sign:otherUtil.encDec(this.state.searchs.stallId+','+this.state.searchs.customerId),
            startDate:this.state.searchs.startDate,
            endDate:this.state.searchs.endDate,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({shareReconciliationDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        if(this.state.shareReconciliationDO==null) return(<div/>);
        this.state.itemTempDebtVal = this.state.shareReconciliationDO.beforeMoney;
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
            <div style={{height:40,backgroundColor:'#1EAE73',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>{stringUtil.subStr(this.state.shareReconciliationDO.shopName,9)}{stringUtil.language(461)}:{stringUtil.subStr(this.state.shareReconciliationDO.customerName,6)}</span>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>{stringUtil.language(1190)}&nbsp;
                    <span style={{color:'#008B8B',padding:5,borderStyle:'solid',borderColor:'#008B8B',borderWidth:1}} onClick={()=>{
                        this.setState({startTimePop:true});
                    }}>{this.state.searchs.startDate}</span>&nbsp;{stringUtil.language(1174)}&nbsp;
                    <span style={{color:'#008B8B',padding:5,borderStyle:'solid',borderColor:'#008B8B',borderWidth:1}} onClick={()=>{
                        this.setState({endTimePop:true});
                    }}>{this.state.searchs.endDate}</span>
                    <DatePicker isOpen={this.state.startTimePop} value={new Date(Date.parse(this.state.searchs.startDate))} onCancel={()=>{this.setState({startTimePop:false});}}
                        onSelect={(time)=>{
                            this.state.searchs.startDate = time.Format("yyyy-MM-dd");
                            this.state.startTimePop = false;
                            this.initData();}} />
                    <DatePicker isOpen={this.state.endTimePop} value={new Date(Date.parse(this.state.searchs.endDate))} onCancel={()=>{this.setState({endTimePop:false});}}
                        onSelect={(time)=>{
                            this.state.searchs.endDate = time.Format("yyyy-MM-dd");
                            this.state.endTimePop = false;
                            this.initData();}} />
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{color:'#008B8B',fontSize:14}}>起始{stringUtil.language(1081)}:</span>
                    <span style={{color:'#F00',fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.shareReconciliationDO.beforeMoney)}</span>
                </div>
            </div>
            <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                <span style={{width:'26%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1039)}</span>
                <span style={{width:'22%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(929)}</span>
                <span style={{width:'12%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1119)}{stringUtil.language(1040)}</span>
                <span style={{width:'20%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                <span style={{width:'20%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(476)}</span>
            </div>
            <div style={{position:'absolute',left:0,top:110,right:0,bottom:80,overflow:'auto'}}>
                {this.state.shareReconciliationDO.items.map((item,key)=>{
                    if(item.type==0){
                        this.state.itemTempDebtVal = (this.state.itemTempDebtVal).add(item.money);
                        this.state.shareReconciliationDO.items[key].itemTempDebtVal = this.state.itemTempDebtVal;
                        return(<div style={{height:45,display:'flex',flexDirection:'column',justifyContent:'center',marginTop:3,marginBottom:3,paddingLeft:5,paddingRight:5}} key={key} onClick={()=>{
                            this.props.history.push({ pathname: "/ShareWholesaleConfirmInfo", search:'?weightMode='+this.state.weightMode+'&wholesaleId='+otherUtil.encDec(item.docmentId)+'&stallId='+otherUtil.encDec(this.state.searchs.stallId)+'&customerId='+otherUtil.encDec(this.state.searchs.customerId)});
                        }}>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                <span style={{width:'26%',fontSize:16,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                <span style={{width:'22%',fontSize:16}}>{item.workerId==0?stringUtil.language(431):stringUtil.subStr(item.workerName,5)}</span>
                                <span style={{width:'12%',fontSize:16,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1037)}</span>
                                <span style={{width:'20%',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={{width:'20%',color:'#EC8232',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{arithUtil.fenToYuan(item.itemTempDebtVal)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'60%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <span style={{color:'#52A056',fontSize:10}}>{arithUtil.formatToTimeStr(new Date(item.time),'h:m:s')}</span>
                                    <span style={{color:'#52A056',fontSize:10}}>KD.{item.docmentNo}</span>
                                </div>
                                <span style={{width:'40%',color:'#A9A9A9',fontSize:10,textAlign:'right'}}>加本{stringUtil.language(1189)}后欠</span>
                            </div>
                        </div>);
                    }else if(item.type==1){
                        this.state.itemTempDebtVal = (this.state.itemTempDebtVal).sub(item.money);
                        this.state.shareReconciliationDO.items[key].itemTempDebtVal = this.state.itemTempDebtVal;
                        return(<div style={{height:45,display:'flex',flexDirection:'column',justifyContent:'center',backgroundColor:'#FFED8E',marginTop:3,marginBottom:3,paddingLeft:5,paddingRight:5}} key={key}>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                <span style={{width:'26%',fontSize:16,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                <span style={{width:'22%',fontSize:16}}>{item.workerId==0?stringUtil.language(431):stringUtil.subStr(item.workerName,5)}</span>
                                <span style={{width:'12%',fontSize:16,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1038)}</span>
                                <span style={{width:'20%',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={{width:'20%',color:'#EC8232',fontSize:16,fontWeight:'bold',textAlign:'right'}}>{arithUtil.fenToYuan(item.itemTempDebtVal)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'60%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                                    <span style={{color:'#52A056',fontSize:10}}>{arithUtil.formatToTimeStr(new Date(item.time),'h:m:s')}</span>
                                    <span style={{color:'#52A056',fontSize:10}}>SK.{item.docmentNo}</span>
                                </div>
                                <span style={{width:'20%',color:'#52A056',fontSize:10,textAlign:'right'}}>{stringUtil.language(1042)}{arithUtil.fenToYuan(item.discount)}</span>
                                <span style={{width:'20%',color:'#A9A9A9',fontSize:10,textAlign:'right'}}>{stringUtil.language(1193)}本次后欠</span>
                            </div>
                        </div>);
                    }
                })}
            </div>
            <div style={{width:'100%',height:80,position:'absolute',bottom:0,display:'flex',flexDirection:'column',justifyContent:'space-around',backgroundColor:'#94EBC8'}}>
                <span style={{paddingLeft:5}}>{stringUtil.language(890)}{this.state.shareReconciliationDO.number} {stringUtil.language(1173)}，{arithUtil.keToWeight(this.state.shareReconciliationDO.weight,this.state.weightMode)+' '+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{stringUtil.language(1041)} {arithUtil.fenToYuan(this.state.shareReconciliationDO.saleMoney)} {stringUtil.currency(1)}</span>
                <span style={{marginLeft:40}}>{stringUtil.language(1080)} {arithUtil.fenToYuan(this.state.shareReconciliationDO.collectMoney)} {stringUtil.currency(1)}{stringUtil.language(906)} {arithUtil.fenToYuan(this.state.shareReconciliationDO.discountMoney)} {stringUtil.currency(1)}</span>
                <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',paddingRight:5}}>
                    <span style={{color:'#F00',fontSize:22,fontWeight:'bold'}}>{stringUtil.language(476)}：{arithUtil.fenToYuan((this.state.shareReconciliationDO.beforeMoney).add(this.state.shareReconciliationDO.saleMoney).sub(this.state.shareReconciliationDO.collectMoney).sub(this.state.shareReconciliationDO.discountMoney))}</span>
                </div>
            </div>
        </div>)
    }
}

const styles = {};