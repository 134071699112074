import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import TabTopActivity from '../components/TabTopActivity';

 //{stringUtil.language(705)}列表
 export default class FundAccountActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        accountsAll:[],
        currAccountItem:{},
        addShowPop:false,
        editShowPop:false,
        infoShowPop:false,
        delShowPop:false,
        accountCollectPayItemAll:[],
        searchsCollectExpend:{
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        this.initAccountsData();
    }
    initAccountsData(){
        stringUtil.httpRequest(urlUtil.BASIC_ACCOUNT_DETAILED_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.accountsAll= successResponse.data;
                this.setState({accountsAll: this.state.accountsAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'99%',height:'99%',display:'flex',flexDirection:'row'}}>
            <div style={{width:'70%',backgroundColor:'#FFF',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),overflow:'auto'}}>
                {(()=>{this.state.totalInfo = {beforeBlanceMoneyTotal:0,todayCollectMoneyTotal:0,todayPayMoneyTotal:0,bossCollectMoneyTotal:0,stallCollectMoneyTotal:0,bossPayMoneyTotal:0,stallPayMoneyTotal:0};})()}
                {this.state.accountsAll.map((item,key)=>{
                    if (item.isDel==0) {
                        let beforeBlanceMoney = (item.balance).add(item.todayPayMoney).sub(item.todayCollectMoney);
                        this.state.totalInfo.beforeBlanceMoneyTotal = this.state.totalInfo.beforeBlanceMoneyTotal.add(beforeBlanceMoney);
                        this.state.totalInfo.todayCollectMoneyTotal = this.state.totalInfo.todayCollectMoneyTotal.add(item.todayCollectMoney);
                        this.state.totalInfo.todayPayMoneyTotal = this.state.totalInfo.todayPayMoneyTotal.add(item.todayPayMoney);
                        if (item.ascription==0) {//stringUtil.language(414)
                            this.state.totalInfo.bossCollectMoneyTotal = this.state.totalInfo.bossCollectMoneyTotal.add(item.todayCollectMoney);
                            this.state.totalInfo.bossPayMoneyTotal = this.state.totalInfo.bossPayMoneyTotal.add(item.todayPayMoney);
                        }else{
                            this.state.totalInfo.stallCollectMoneyTotal = this.state.totalInfo.stallCollectMoneyTotal.add(item.todayCollectMoney);
                            this.state.totalInfo.stallPayMoneyTotal = this.state.totalInfo.stallPayMoneyTotal.add(item.todayPayMoney);
                        }
                        return(<div style={arithUtil.stylesAdd(styles.leftItem,{cursor:'pointer'})} key={key} onClick={()=>{
                            this.state.currAccountItem = JSON.parse(JSON.stringify(item));
                            stringUtil.httpRequest(urlUtil.SUMM_ACCOUNT_COLLECT_PAY_URL+'/'+this.state.currAccountItem.id,null,['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.state.accountCollectPayItemAll= successResponse.data;
                                    if(this.state.editShowPop) this.state.infoShowPop = false;else this.state.infoShowPop = true;
                                    this.setState({accountCollectPayItemAll:this.state.accountCollectPayItemAll});
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}>
                            <div style={{display:'flex',flexDirection:'row'}}>
                                <span style={{width:'30%',color:'#A9A9A9'}}>{item.ascription==0?stringUtil.language(414):stringUtil.language(415)}</span>
                                <span>{stringUtil.language(295)}{arithUtil.fenToYuan(beforeBlanceMoney)}</span>
                            </div>
                            <div style={{height:50,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    {item.type==0?<i className="iconfontIndexCss" style={{fontSize:30,color:'#F4B015'}}>&#xe632;</i>:
                                    item.type==1?<i className="iconfontIndexCss" style={{fontSize:30,color:'#3BCA72'}}>&#xe605;</i>:
                                    item.type==2?<i className="iconfontIndexCss" style={{fontSize:30,color:'#5A9EF7'}}>&#xe65f;</i>:
                                    item.type==3?<i className="iconfontIndexCss" style={{fontSize:30,color:'#CC4B46'}}>&#xe607;</i>:<div/>}
                                    <span> {item.name}</span>
                                </div>
                                <div style={{width:'70%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:'33%',color:'#F00'}}>{stringUtil.language(296)}{arithUtil.fenToYuan(item.todayPayMoney)}</span>
                                    <span style={{width:'33%',color:'#52A056'}}>{stringUtil.language(297)}{arithUtil.fenToYuan(item.todayCollectMoney)}</span>
                                    <div style={{width:'33%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                        <span style={{color:'#D2855D',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.balance)}{stringUtil.currency(1)}</span>
                                        {item.initBalance!=0?<span style={{color:'#D2855D',fontSize:10}}>{stringUtil.language(495)}:{arithUtil.fenToYuan(item.initBalance)}</span>:''}
                                    </div>
                                </div>
                            </div>
                            {otherUtil.authorityFindCode("011202")!=null||otherUtil.authorityFindCode("011203")!=null?<div style={{position:'absolute',right:0,top:-15,cursor:'pointer'}} onClick={()=>{
                                this.state.currAccountItem = JSON.parse(JSON.stringify(item));
                                this.setState({editShowPop:true});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:50,color:'#A9A9A9'}}>&#xe653;</i>
                                <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:25,transform: ['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
                            </div>:<div/>}
                        </div>)
                    }
                })}
            </div>
            <div style={{width:'27%',marginLeft:'1%'}}>
                <div style={arithUtil.stylesAdd(styles.rightTopContainer,{height:'20%'})}>
                    <div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <span>{stringUtil.language(496)}&nbsp;&nbsp;&nbsp;</span><span style={{color:'#52A056'}}>{arithUtil.fenToYuan(this.state.totalInfo.beforeBlanceMoneyTotal)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row'}}>
                            <span>{stringUtil.language(497)}&nbsp;&nbsp;&nbsp;</span><span style={{color:'#F00'}}>{arithUtil.fenToYuan((this.state.totalInfo.todayCollectMoneyTotal).sub(this.state.totalInfo.todayPayMoneyTotal))}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(498)}&nbsp;&nbsp;&nbsp;</span><span style={{color:'#D2855D',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.totalInfo.beforeBlanceMoneyTotal).add((this.state.totalInfo.todayCollectMoneyTotal).sub(this.state.totalInfo.todayPayMoneyTotal)))}</span>
                        </div>
                    </div>
                </div>
                <div style={arithUtil.stylesAdd(styles.rightBottomContainer,{height:'78.2%',position:'relative'})}>
                    <div style={{paddingLeft:arithUtil.pxToDp(15),marginTop:arithUtil.pxToDp(10),paddingTop:arithUtil.pxToDp(20)}}>
                        <span style={{fontSize:15,fontWeight:'bold'}}>{stringUtil.language(201)}{arithUtil.fenToYuan(this.state.totalInfo.todayCollectMoneyTotal)}</span><br/>
                        <span style={{color:'#808080',fontSize:14}}>{stringUtil.language(298)}{arithUtil.fenToYuan(this.state.totalInfo.stallCollectMoneyTotal)}</span><br/>
                        <span style={{color:'#808080',fontSize:14}}>{stringUtil.language(299)}{arithUtil.fenToYuan(this.state.totalInfo.bossCollectMoneyTotal)}</span><br/><br/>
                        <span style={{fontSize:15,fontWeight:'bold',marginTop:arithUtil.pxToDp(20)}}>{stringUtil.language(203)}{arithUtil.fenToYuan(this.state.totalInfo.todayPayMoneyTotal)}</span><br/>
                        <span style={{color:'#808080',fontSize:14}}>{stringUtil.language(300)}{arithUtil.fenToYuan(this.state.totalInfo.stallPayMoneyTotal)}</span><br/>
                        <span style={{color:'#808080',fontSize:14}}>{stringUtil.language(301)}{arithUtil.fenToYuan(this.state.totalInfo.bossPayMoneyTotal)}</span>
                    </div>
                    <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',position:'absolute',bottom:arithUtil.pxToDp(25)}}>
                    {otherUtil.authorityFindCode("011201")!=null?<span style={{width:arithUtil.pxToDp(200),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.state.currAccountItem = {};
                            this.setState({addShowPop:!this.state.addShowPop});
                        }}>{stringUtil.language(499)}</span>:<div/>}
                    </div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(500)} visible={this.state.addShowPop} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.55)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                        <div style={styles.modalRow}>
                            <span>{stringUtil.language(922)}</span>
                            <input style={{width:180,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,paddingLeft:5}} placeholder={stringUtil.language(93)} onChange={(event)=>{
                                this.state.currAccountItem.name = event.target.value;
                            }}/>
                        </div>
                        <div style={styles.modalRow}>
                            <span>{stringUtil.language(923)}</span>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.state.currAccountItem.ascription = 0;
                                this.setState({currAccountItem:this.state.currAccountItem});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:20,color:(this.state.currAccountItem.ascription==null||this.state.currAccountItem.ascription==0)?'#52A056':'#D3D3D3'}}>&#xe614;</i>
                                <span> {stringUtil.language(414)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:arithUtil.pxToDp(15),cursor:'pointer'}} onClick={()=>{
                                this.state.currAccountItem.ascription = 1;
                                this.setState({currAccountItem:this.state.currAccountItem});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:20,color:this.state.currAccountItem.ascription==1?'#52A056':'#D3D3D3'}}>&#xe614;</i>
                                <span> {stringUtil.language(415)}</span>
                            </div>
                        </div><br/>
                        <span style={{color:'#A9A9A9'}}>{stringUtil.language(148)}</span>
                        <div style={styles.modalRow}>
                            <div style={arithUtil.stylesAdd(styles.modalAccountItem,{cursor:'pointer'})} onClick={()=>{
                                this.state.currAccountItem.type = 0;
                                this.setState({currAccountItem:this.state.currAccountItem});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:30,color:this.state.currAccountItem.type==null||this.state.currAccountItem.type==0?'#F4B015':'#A9A9A9'}}>&#xe632;</i>
                                <span style={{color:this.state.currAccountItem.type==null||this.state.currAccountItem.type==0?'#696969':'#A9A9A9',fontSize:12}}>{stringUtil.language(1093)}</span>
                            </div>
                            <div style={arithUtil.stylesAdd(styles.modalAccountItem,{cursor:'pointer'})} onClick={()=>{
                                this.state.currAccountItem.type = 1;
                                this.setState({currAccountItem:this.state.currAccountItem});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:30,color:this.state.currAccountItem.type==1?'#3BCA72':'#A9A9A9'}}>&#xe605;</i>
                                <span style={{color:this.state.currAccountItem.type==1?'#696969':'#A9A9A9',fontSize:12}}>{stringUtil.language(1094)}</span>
                            </div>
                            <div style={arithUtil.stylesAdd(styles.modalAccountItem,{cursor:'pointer'})} onClick={()=>{
                                this.state.currAccountItem.type = 2;
                                this.setState({currAccountItem:this.state.currAccountItem});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:30,color:this.state.currAccountItem.type==2?'#5A9EF7':'#A9A9A9'}}>&#xe65f;</i>
                                <span style={{color:this.state.currAccountItem.type==2?'#696969':'#A9A9A9',fontSize:12}}>{stringUtil.language(924)}</span>
                            </div>
                            <div style={arithUtil.stylesAdd(styles.modalAccountItem,{borderRightStyle:'solid',borderRightWidth:1,borderRightColor:'#DCDCDC',cursor:'pointer'})} onClick={()=>{
                                this.state.currAccountItem.type = 3;
                                this.setState({currAccountItem:this.state.currAccountItem});
                            }}>
                                <i className="iconfontIndexCss" style={{fontSize:30,color:this.state.currAccountItem.type==3?'#CC4B46':'#A9A9A9'}}>&#xe607;</i>
                                <span style={{color:this.state.currAccountItem.type==3?'#696969':'#A9A9A9',fontSize:12}}>{stringUtil.language(925)}</span>
                            </div>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    if (this.state.currAccountItem.name==null || this.state.currAccountItem.name=='') {
                        ToastBox.error(stringUtil.language(149));return;
                    }
                    if (this.state.currAccountItem.ascription==null) this.state.currAccountItem.ascription = 0;
                    if (this.state.currAccountItem.type==null) this.state.currAccountItem.type = 0;
                    this.setState({ addShowPop: false });
                    stringUtil.httpRequest(urlUtil.ACCOUNT_URL,this.state.currAccountItem,['key','post',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initAccountsData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({ addShowPop: false, });}}/>
            <MaskModal title={stringUtil.language(501)} visible={this.state.editShowPop} width={300}
                content={<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}} key={this.state.currAccountItem.id}>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                        <span>{stringUtil.language(302)}</span><input style={{display:'flex',flexGrow:1,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,textAlign:'center'}} defaultValue={this.state.currAccountItem.name} placeholder={stringUtil.language(93)} onChange={(event)=>{
                            this.state.currAccountItem.name = event.target.value;
                        }}/>
                    </div>
                    <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                        <span>{stringUtil.language(303)}</span><input style={{height:30,color:'#F00',fontWeight:'bold',borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,display:'flex',flexGrow:1,textAlign:'center'}} type='number' placeholder='0' defaultValue={this.state.currAccountItem.initBalance!=0?arithUtil.fenToYuan(this.state.currAccountItem.initBalance):''} onChange={(event)=>{
                            this.state.currAccountItem.initBalance = arithUtil.yuanToFen(event.target.value);
                        }}/>
                    </div>
                </div>} okBnTitle={stringUtil.language(502)} closeBnTitle={stringUtil.language(503)} closeBnColor={'#F00'}
                okBnEvents={(event) => {
                    if (otherUtil.authorityFindCode("011202")==null) {
                        ToastBox.error(stringUtil.language(504));return;
                    }
                    if (this.state.currAccountItem.name==null || this.state.currAccountItem.name=='') {
                        ToastBox.error(stringUtil.language(149));return;
                    }
                    this.setState({ editShowPop: false });
                    if(this.state.currAccountItem.initBalance==null||isNaN(this.state.currAccountItem.initBalance)||this.state.currAccountItem.initBalance=='')this.state.currAccountItem.initBalance=0;
                    stringUtil.httpRequest(urlUtil.BASIC_ACCOUNT_NAME_BALANCE_URL+'/'+this.state.currAccountItem.id,{
                        name:this.state.currAccountItem.name,
                        initBalance:this.state.currAccountItem.initBalance
                    },['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initAccountsData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {
                    if (otherUtil.authorityFindCode("011203")==null) {
                        ToastBox.error(stringUtil.language(504));return;
                    }
                    this.setState({ editShowPop: false,delShowPop:true });
                }} closeBnTopEvents={() => { this.setState({ editShowPop: false, }); }} />
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                                {stringUtil.language(505)}{this.state.currAccountItem.name}？
                        </div>}
                okBnEvents={(event) => {
                    this.setState({delShowPop: false});
                    stringUtil.httpRequest(urlUtil.ACCOUNT_URL+'/'+this.state.currAccountItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.initAccountsData();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={()=>{this.setState({delShowPop: false});}}/>
            <MaskModal title={stringUtil.language(506)} visible={this.state.infoShowPop} width={1000} height={arithUtil.ProportionTo(document.body.clientHeight,0.9)}
                content={<div style={{height:'100%'}}>
                    <div style={{height:'15%',display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                        <div style={{width:'35%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            {this.state.currAccountItem.type==0?<i className="iconfontIndexCss" style={{fontSize:40,color:'#F4B015'}}>&#xe632;</i>:
                            this.state.currAccountItem.type==1?<i className="iconfontIndexCss" style={{fontSize:40,color:'#3BCA72'}}>&#xe605;</i>:
                            this.state.currAccountItem.type==2?<i className="iconfontIndexCss" style={{fontSize:40,color:'#5A9EF7'}}>&#xe65f;</i>:
                            this.state.currAccountItem.type==3?<i className="iconfontIndexCss" style={{fontSize:40,color:'#CC4B46'}}>&#xe607;</i>:<div/>}
                            <div style={{marginLeft:arithUtil.pxToDp(5)}}>
                                <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.currAccountItem.balance)}</span><br/>
                                <span>{this.state.currAccountItem.name}</span>
                            </div>
                        </div>
                        <div style={{width:'65%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:'33.333%',color:'#808080'}}>{stringUtil.language(926)}{arithUtil.fenToYuan(this.state.currAccountItem.initBalance)}</span>
                                <span style={{width:'33.333%',color:'#808080'}}>{stringUtil.language(927)}{arithUtil.fenToYuan(this.state.currAccountItem.collectMoney)}</span>
                                <span style={{width:'33.333%',color:'#808080'}}>{stringUtil.language(928)}{arithUtil.fenToYuan(this.state.currAccountItem.payMoney)}</span>
                            </div>
                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <div style={{color:'#009A61',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#009A61',cursor:'pointer'}} onClick={()=>{
                                    this.setState({infoExcelShowPop:!this.state.infoExcelShowPop});
                                }}>{stringUtil.language(1071)}Excel</div>
                            </div>
                        </div>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'6%'})}>
                        <span style={{width:'22%',fontSize:13,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1067)}</span>
                        <span style={{width:'10%',fontSize:13,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(929)}</span>
                        <span style={{width:'25%',fontSize:13,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(507)}</span>
                        <span style={{width:'15%',fontSize:13,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1032)}</span>
                        <span style={{width:'13%',fontSize:13,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1095)}</span>
                        <span style={{width:'15%',fontSize:13,color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(508)}</span>
                    </div>
                    <div style={{width:'100%',position:'absolute',left:0,top:'21%',right:0,bottom:0,overflow:'auto'}}>
                        {this.state.accountCollectPayItemAll.map((item,key)=>{
                            this.state.accountCollectPayItemAll[key].balance = (this.state.currAccountItem.initBalance).add(this.state.currAccountItem.collectMoney).sub(this.state.currAccountItem.payMoney);
                            if(key!=0){
                                this.state.accountCollectPayItemAll[key].balance = (this.state.accountCollectPayItemAll[key-1].balance).sub(this.state.accountCollectPayItemAll[key-1].collect).add(this.state.accountCollectPayItemAll[key-1].pay);
                            }
                            return(<div style={{borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#D3D3D3'}} key={key}>
                                <div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    stringUtil.httpRequest(urlUtil.SUMM_ACCOUNT_STREAM_DATE_URL+'/'+this.state.currAccountItem.id,{date:item.date},['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.accountCollectPayItemAll[key].isChoose = item.isChoose?false:true;
                                            this.state.accountCollectPayItemAll[key].accountStreamAll = successResponse.data;
                                            this.setState({accountCollectPayItemAll:this.state.accountCollectPayItemAll});
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}><span style={{width:'22%',fontSize:13,textAlign:'center'}}>{item.date}</span>
                                    <span style={{width:'10%',fontSize:13,color:'#808080',textAlign:'center'}}>-</span>
                                    <span style={{width:'25%',fontSize:13,color:'#808080',textAlign:'center'}}>-</span>
                                    <span style={{width:'15%',fontSize:13,color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(item.collect)}</span>
                                    <span style={{width:'13%',fontSize:13,color:'#F00',textAlign:'center'}}>{arithUtil.fenToYuan(item.pay)}</span>
                                    <span style={{width:'15%',fontSize:13,color:'#52A056',textAlign:'center'}}>{arithUtil.fenToYuan(item.balance)}</span>
                                </div>
                                {item.isChoose?<div>{item.accountStreamAll?item.accountStreamAll.map((item,key)=>{
                                        return(<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                            <span style={{width:'22%',color:'#696969',fontSize:13,textAlign:'center',textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.type==0?stringUtil.language(509):''}
                                                {item.type==1?stringUtil.language(477):''}
                                                {item.type==2?stringUtil.language(510):''}
                                                {item.type==3?stringUtil.language(443):''}
                                                {item.type==4?stringUtil.language(304):''}
                                                {item.type==5?stringUtil.language(445):''}
                                                {item.type==6?stringUtil.language(446):''}
                                                {item.type==7?stringUtil.language(511):''}
                                                {item.type==8?stringUtil.language(512):''}
                                            </span>
                                            <span style={{width:'10%',color:'#696969',fontSize:13,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.workerId?item.workerName:stringUtil.language(431)}</span>
                                            <span style={{width:'25%',color:'#696969',fontSize:13,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.targetName?item.targetName:item.targetType==1?stringUtil.language(426):''}</span>
                                            <span style={{width:'15%',color:'#52A056',fontSize:13,textAlign:'center'}}>{(item.type==0||item.type==1||item.type==8)?arithUtil.fenToYuan(item.money):0}</span>
                                            <span style={{width:'13%',color:'#F00',fontSize:13,textAlign:'center'}}>{(item.type==0||item.type==1||item.type==8)?0:arithUtil.fenToYuan(item.money)}</span>
                                            <span style={{width:'15%',color:'#52A056',fontSize:13,textAlign:'center'}}></span>
                                        </div>)}):''}
                                </div>:<div />}
                            </div>)
                        })}
                    </div>
                </div>} closeBnTopEvents={() => { this.setState({ infoShowPop: false, }); }} />
            <MaskModal title={stringUtil.language(207)} visible={this.state.infoExcelShowPop?true:false} width={500} height={400}
                content={<div style={{height:'100%'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                        <span>{stringUtil.language(513)}:</span><div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:3}}>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCollectExpend.startTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchsCollectExpend.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                            this.setState({searchsCollectExpend:this.state.searchsCollectExpend});
                        }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderWidth:1,borderColor:'#A9A9A9',borderRadius:3}}>
                        <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCollectExpend.endTime,1)),'Y-M-D'))} onChange={(date) => {
                            this.state.searchsCollectExpend.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                            this.setState({searchsCollectExpend:this.state.searchsCollectExpend});
                        }} /></div>
                    </div>
                </div>} okBnEvents={(event) => {
                    this.setState({infoExcelShowPop: false});
                    stringUtil.httpRequest(urlUtil.SUMM_ACCOUNT_STREAM_EXCEL_URL+'/'+this.state.currAccountItem.id,{
                        startTime:arithUtil.settleSub(this.state.searchsCollectExpend.startTime),
                        endTime:arithUtil.settleSub(this.state.searchsCollectExpend.endTime)
                    },['key','get',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            let accountStreamVO=successResponse.data;
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="7"><b><font size="3">${this.state.currAccountItem.name}${stringUtil.language(506)}（${arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCollectExpend.startTime,1)),'Y-M-D')}${stringUtil.language(1174)}${arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCollectExpend.endTime,1)),'Y-M-D')}）</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1067)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(929)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(514)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(507)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(515)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(516)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1096)}</font></b></td></tr>`);
                            let totalInfo = {add:0,sub:0,balance:0};
                            for(let key=0;key<accountStreamVO.items.length;key++){
                                let balance=accountStreamVO.balance;
                                if(key>0){
                                    let beforeSub=accountStreamVO.items[key-1].type==2||accountStreamVO.items[key-1].type==3||accountStreamVO.items[key-1].type==4||accountStreamVO.items[key-1].type==5||accountStreamVO.items[key-1].type==6||accountStreamVO.items[key-1].type==7?accountStreamVO.items[key-1].money:0;
                                    let beforeAdd=accountStreamVO.items[key-1].type==1||accountStreamVO.items[key-1].type==8||!accountStreamVO.items[key-1].type?accountStreamVO.items[key-1].money:0;
                                    balance=accountStreamVO.balance+beforeSub-beforeAdd;
                                }
                                totalInfo.add=(totalInfo.add).add(accountStreamVO.items[key].type==1||accountStreamVO.items[key].type==8||!accountStreamVO.items[key].type?accountStreamVO.items[key].money:0);
                                totalInfo.sub=(totalInfo.sub).add(accountStreamVO.items[key].type==2||accountStreamVO.items[key].type==3||accountStreamVO.items[key].type==4||accountStreamVO.items[key].type==5||accountStreamVO.items[key].type==6||accountStreamVO.items[key].type==7?accountStreamVO.items[key].money:0);
                                totalInfo.balance=(totalInfo.balance).add(balance);
                                resArr.push(`<tr><td align="left"><font size="3">${arithUtil.formatToTimeStr(new Date(accountStreamVO.items[key].time),'Y-M-D')}</font></td>
                                <td align="left"><font size="3">${accountStreamVO.items[key].workerId?accountStreamVO.items[key].workerName:stringUtil.language(431)}</font></td>
                                <td align="left"><font size="3">${accountStreamVO.items[key].type==1?stringUtil.language(477):accountStreamVO.items[key].type==2?stringUtil.language(442):accountStreamVO.items[key].type==3?stringUtil.language(443):accountStreamVO.items[key].type==4?stringUtil.language(444):accountStreamVO.items[key].type==5?stringUtil.language(930):accountStreamVO.items[key].type==6?stringUtil.language(931):accountStreamVO.items[key].type==7?stringUtil.language(511):accountStreamVO.items[key].type==8?stringUtil.language(512):stringUtil.language(509)}</font></td>
                                <td align="left"><font size="3">${accountStreamVO.items[key].targetName?accountStreamVO.items[key].targetName:accountStreamVO.items[key].targetType==1?stringUtil.language(426):''}</font></td>
                                <td align="right"><font size="3">${accountStreamVO.items[key].type==1||accountStreamVO.items[key].type==8||!accountStreamVO.items[key].type?arithUtil.fenToYuan(accountStreamVO.items[key].money):''}</font></td>
                                <td align="right"><font size="3">${accountStreamVO.items[key].type==2||accountStreamVO.items[key].type==3||accountStreamVO.items[key].type==4||accountStreamVO.items[key].type==5||accountStreamVO.items[key].type==6||accountStreamVO.items[key].type==7?arithUtil.fenToYuan(accountStreamVO.items[key].money):''}</font></td>
                                <td align="right"><font size="3">${arithUtil.fenToYuan(balance)}</font></td></tr>`);
                            }
                            resArr.push(`<tr><td align="left" colspan="4"><b><font size="3">${stringUtil.language(890)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.add)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.sub)}</font></b></td>
                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(totalInfo.balance)}</font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),this.state.currAccountItem.name+stringUtil.language(506)+'（'+arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCollectExpend.startTime,1)),'Y-M-D')+stringUtil.language(1174)+arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchsCollectExpend.endTime,1)),'Y-M-D')+'）');
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={()=>{this.setState({infoExcelShowPop: false});}}/>
        </div>)
    }
}

const styles = {
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    leftItem:{
        height:70,
        marginTop:7,
        marginBottom:7,
        paddingLeft:10,
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#DCDCDC',
        borderRadius:5,
        display:'flex',
        flexDirection:'column',
        position:'relative'
    },
    rightTopContainer:{
        borderTopLeftRadius:arithUtil.pxToDp(3),
        borderTopRightRadius:arithUtil.pxToDp(3),
        backgroundColor:'#FFF',
        display:'flex',
        alignItems:'center',
        paddingLeft:arithUtil.pxToDp(15)
    },
    rightBottomContainer:{
        borderBottomLeftRadius:arithUtil.pxToDp(3),
        borderBottomRightRadius:arithUtil.pxToDp(3),
        borderTopStyle:'solid',
        borderTopWidth:arithUtil.pxToDp(7),
        borderTopColor:'#DCDCDC',
        backgroundColor:'#FFF'
    },
    modalRow:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        marginTop:arithUtil.pxToDp(10),
    },
    modalAccountItem:{
        width:70,
        height:70,
        borderLeftStyle:'solid',
        borderLeftWidth:1,
        borderLeftColor:'#DCDCDC',
        borderTopStyle:'solid',
        borderTopWidth:1,
        borderTopColor:'#DCDCDC',
        borderBottomStyle:'solid',
        borderBottomWidth:1,
        borderBottomColor:'#DCDCDC',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
};