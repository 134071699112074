import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //{stringUtil.language(232)}
 export default class SaleStatisticsActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            targetType:-1,
            targetId:-1,
            commodityName:'',
            startTime:arithUtil.getCurrDateTime(1),
            endTime:arithUtil.getCurrDateTime(1)
        },
        saleSituationDOAll:[],
        targetsAll:[],
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                for(let i=0;i<ownersAll.length;i++){
                    this.state.targetsAll.push({targetType:0,targetId:ownersAll[i].id,targetName:ownersAll[i].noteName});
                }
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:5},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.targetsAll.push({targetType:2,targetId:workersAll[i].id,targetName:workersAll[i].noteName});
                        }
                        stringUtil.httpRequest(urlUtil.BASIC_CARGO_SELF_LINE_URL,null,['key','get',1],(successResponse)=>{
                            if (successResponse.status==200) {
                                let linesAll = successResponse.data;
                                this.state.targetsAll.push({targetType:1,targetId:0,targetName:stringUtil.language(426)+'_'+stringUtil.language(870)});
                                for(let i=0;i<linesAll.length;i++){
                                    this.state.targetsAll.push({targetType:1,targetId:linesAll[i].id,targetName:stringUtil.language(426)+'_'+linesAll[i].name});
                                }
                                this.state.targetsAll.unshift({targetType:2,targetId:0,targetName:stringUtil.language(431)});
                                this.state.targetsAll.unshift({targetType:-1,targetId:-1,targetName:stringUtil.language(425)});
                                this.setState({targetsAll:this.state.targetsAll});
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_SITUATION_URL,{
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            commodityName:this.state.searchs.commodityName,
            startDate:this.state.searchs.startTime,
            endDate:this.state.searchs.endTime,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({saleSituationDOAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                            this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endTime)} onChange={(date) => {
                            this.state.searchs.endTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                        <Select showSearch defaultValue={stringUtil.language(425)} style={{width:200,height:30}} onChange={(value)=>{
                            this.state.searchs.targetId = JSON.parse(value).targetId;
                            this.state.searchs.targetType = JSON.parse(value).targetType;
                            this.initData();
                        }}>
                            {this.state.targetsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.targetName}</option>)
                            })}
                        </Select>
                        <div style={{width:200,height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF',marginLeft:5}}>
                            <SearchInput onChangeEvents={(value)=>{
                                this.state.searchs.commodityName = value;
                                this.initData();
                            }} placeholder={stringUtil.language(35)}/>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(659)}</span>
                        <span style={{fontSize:17}}>{this.state.searchs.startTime+' '+stringUtil.language(1174)+' '+this.state.searchs.endTime}</span>
                    </div>:''}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:'18%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1049)}</span>
                        <span style={{width:'18%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:'16%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'16%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'16%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                        <span style={{width:'16%',fontSize:12,textAlign:'center'}}>{stringUtil.language(968)}</span>
                    </div>
                    {(()=>{
                        this.state.totalInfo = {numberTotal:0,weightTotal:0,moneyTotal:0}
                    })()}
                    {this.state.saleSituationDOAll.map((item,key)=>{
                        this.state.totalInfo.numberTotal = (this.state.totalInfo.numberTotal).add(item.number);
                        this.state.totalInfo.weightTotal = (this.state.totalInfo.weightTotal).add(item.weight);
                        this.state.totalInfo.moneyTotal = (this.state.totalInfo.moneyTotal).add(item.money);
                        let avgPrice = 0;
                        if (item.mode==0) {//stringUtil.language(1114)
                            if(item.weight!=0)avgPrice = (arithUtil.fenToYuan(item.money)).div(arithUtil.keToWeight(item.weight,this.state.weightMode),2)+'';
                            avgPrice = avgPrice+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172));
                        }else{
                            if(item.number!=0)avgPrice = (arithUtil.fenToYuan(item.money)).div(item.number,2)+'';
                            avgPrice = avgPrice+'/'+((item.commodityUnit!=null&&item.commodityUnit!='')?item.commodityUnit:stringUtil.language(1173));
                        }
                        return(<div style={{display:'flex',flexDirection:'row'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{item.targetType==1?stringUtil.language(1051)+(item.targetName?'_'+stringUtil.subStr(item.targetName,5):''):stringUtil.subStr(item.targetName,7)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{stringUtil.subStr(item.commodityName,7)}</span>
                            <span style={styles.tableTd}>{item.number}</span>
                            <span style={styles.tableTd}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <span style={styles.tableTd}>{arithUtil.fenToYuan(item.money)}</span>
                            <span style={styles.tableTd}>{avgPrice}</span>
                        </div>)
                    })}
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{stringUtil.language(890)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{this.state.totalInfo.numberTotal}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.keToWeight(this.state.totalInfo.weightTotal,this.state.weightMode)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}>{arithUtil.fenToYuan(this.state.totalInfo.moneyTotal)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1})}></span>
                    </div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    tableTd:{
        width:'16%',
        height:30,
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};