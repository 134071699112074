import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput';
import { Select } from 'antd';
import style from 'antd/dist/antd.css';

 //{stringUtil.language(239)}
 export default class OrdersWarehouseActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            warehouseId:-1,
            targetType:-1,
            targetId:-1,
            startDate:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
            endDate:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        warehousesAll:[],warehousesDatas:[],
        personsAll:[],
        stockSumm:{items:[],buyerNames:[]},
      };
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll = JSON.parse(JSON.stringify(successResponse.data));
                this.state.warehousesDatas = JSON.parse(JSON.stringify(successResponse.data));
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        stringUtil.httpRequest(urlUtil.OAUTH_OWNER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let ownersAll = successResponse.data;
                this.state.personsAll = [];
                for(let i=0;i<ownersAll.length;i++){
                    this.state.personsAll.push({targetType:0,targetId:ownersAll[i].id,noteName:ownersAll[i].noteName});
                }
                this.state.personsAll.push({targetType:1,targetId:-1,noteName:stringUtil.language(426)});
                stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
                    if (successResponse.status==200) {
                        let workersAll = successResponse.data;
                        for(let i=0;i<workersAll.length;i++){
                            this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
                        }
                        this.state.personsAll.push({targetType:-1,targetId:-1,noteName:stringUtil.language(425)});
                        this.setState({personsAll:this.state.personsAll});
                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SALE_STOCK_SETTLE_URL,{
            warehouseId:this.state.searchs.warehouseId,
            targetType:this.state.searchs.targetType,
            targetId:this.state.searchs.targetId,
            startDate:arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D'),
            endDate:arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({stockSumm:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%',position:'relative'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(892)}</span>
                            <select style={{width:170,height:30,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} onChange={(event)=>{
                                this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                                this.state.searchs.warehouseName = JSON.parse(event.target.value).name;
                                this.initData();
                            }}>
                            {(this.state.warehousesAll?this.state.warehousesAll:[]).map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                            <span style={{width:85}}>{stringUtil.language(1214)}</span>
                            <Select showSearch defaultValue={this.state.searchs.targetName} style={{width:180,height:30}} onChange={(value)=>{
                                this.state.searchs.targetType = JSON.parse(value).targetType;
                                this.state.searchs.targetId = JSON.parse(value).targetId;
                                this.state.searchs.targetName = JSON.parse(value).noteName;
                                this.initData();
                            }}>
                            {this.state.personsAll.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:20}}>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startDate = date.valueOf();
                                this.initData();
                            }} /></div><span style={{marginLeft:5,marginRight:5}}>{stringUtil.language(1174)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endDate = date.valueOf();
                                this.initData();
                            }} /></div>
                        </div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:15,cursor:'pointer'}} onClick={()=>{
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ReceivedSendAddActivity',mainStacks:[
                                {title:stringUtil.language(1128),navigatePage:''},
                                {title:stringUtil.language(1203),navigatePage:'OrdersWarehouseActivity',paramsPage:'参数内容'},
                                {title:stringUtil.language(582),navigatePage:'ReceivedSendAddActivity',paramsPage:{mode:1}}
                            ]}});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe61e;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1208)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:15,cursor:'pointer'}} onClick={()=>{
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ReceivedSendAddActivity',mainStacks:[
                                {title:stringUtil.language(1128),navigatePage:''},
                                {title:stringUtil.language(1203),navigatePage:'OrdersWarehouseActivity',paramsPage:'参数内容'},
                                {title:stringUtil.language(582),navigatePage:'ReceivedSendAddActivity',paramsPage:{mode:2}}
                            ]}});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe61e;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1225)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginRight:15,cursor:'pointer'}} onClick={()=>{
                            this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CountsOrderAddActivity',mainStacks:[
                                {title:stringUtil.language(1128),navigatePage:''},
                                {title:stringUtil.language(1203),navigatePage:'OrdersWarehouseActivity',paramsPage:'参数内容'},
                                {title:stringUtil.language(454),navigatePage:'CountsOrderAddActivity',paramsPage:{mode:3}}
                            ]}});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe61e;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1234)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}} id='printArea'>
                    <div>{this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{stringUtil.language(1203)}</span>
                        <div style={{width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:17}}>{arithUtil.formatToTimeStr(new Date(this.state.searchs.startDate),'Y-M-D')}至{arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D')}</span>
                            <span style={{fontSize:17,marginLeft:20}}>{this.state.searchs.warehouseName?this.state.searchs.warehouseName:'全部仓库'}</span>
                            <span style={{fontSize:17,marginLeft:20}}>{this.state.searchs.targetName?this.state.searchs.targetName:this.state.searchs.targetType==1?'档口自营':'全部产地'}</span>
                        </div>
                    </div>:''}
                    {(()=>{this.state.totalInfo={beforeNumber:0,beforeGoodNumber:0,beforeRejectNumber:0,inNumber:0,saleNumber:0,saleWeight:0,saleMoney:0,otherNumber:0,
                        diffNumber:0,takeNumber:0,badNumber:0,number:0,goodNumber:0,rejectNumber:0}})()}
                    <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:80,height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1156)}</span>
                        <span style={{width:120,height:'45px',lineHeight:'45px',borderStyle:'solid',borderWidth:0.1,fontSize:15,textAlign:'center'}}>{stringUtil.language(1089)}</span>
                        {/* <div style={{width:180,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1204)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'50%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1212)}</span>
                                <span style={{width:'50%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1213)}</span>
                            </div>
                        </div> */}
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1204)}</span>
                        </div>
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1205)}</span>
                        </div>
                        {(this.state.stockSumm.buyerNames?this.state.stockSumm.buyerNames:[]).map((item,key)=>{
                            this.state.totalInfo['buyer'+key] = 0;
                            return(<div key={key} style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                <span style={{color:'#F00',fontSize:15,textAlign:'center'}}>{item}</span>
                            </div>);
                        })}
                        <div style={{width:370,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1206)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'15%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:'20%',fontSize:12,textAlign:'center'}}>{stringUtil.language(547)}</span>
                                <span style={{width:'25%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:'20%',fontSize:12,textAlign:'center'}}>{stringUtil.language(961)}</span>
                                <span style={{width:'20%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1207)}</span>
                        </div>
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1208)}</span>
                        </div>
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1209)}</span>
                        </div>
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1211)}</span>
                        </div>
                        <div style={{width:90,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1210)}</span>
                        </div>
                        {/* <div style={{width:180,height:45,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'column',justifyContent:'space-around',alignItems:'center'}}>
                            <span style={{fontSize:15,textAlign:'center'}}>{stringUtil.language(1210)}</span>
                            <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
                                <span style={{width:'50%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1212)}</span>
                                <span style={{width:'50%',fontSize:12,textAlign:'center'}}>{stringUtil.language(1213)}</span>
                            </div>
                        </div> */}
                    </div>
                    {(this.state.stockSumm.items?this.state.stockSumm.items:[]).map((item,key)=>{
                        let beforeGoodNumber=item.beforeNumber-item.beforeRejectNumber;
                        let goodNumber=item.number-item.rejectNumber;
                        this.state.totalInfo.beforeNumber=this.state.totalInfo.beforeNumber+item.beforeNumber;
                        this.state.totalInfo.beforeRejectNumber=this.state.totalInfo.beforeRejectNumber+item.beforeRejectNumber;
                        this.state.totalInfo.beforeGoodNumber=this.state.totalInfo.beforeGoodNumber+beforeGoodNumber;
                        this.state.totalInfo.inNumber=this.state.totalInfo.inNumber+item.inNumber;
                        this.state.totalInfo.saleNumber=this.state.totalInfo.saleNumber+item.saleNumber;
                        this.state.totalInfo.saleWeight=(this.state.totalInfo.saleWeight).add(item.saleWeight);
                        this.state.totalInfo.saleMoney=(this.state.totalInfo.saleMoney).add(item.saleMoney);
                        this.state.totalInfo.otherNumber=this.state.totalInfo.otherNumber+item.otherNumber;
                        this.state.totalInfo.diffNumber=this.state.totalInfo.diffNumber+item.diffNumber;
                        this.state.totalInfo.takeNumber=this.state.totalInfo.takeNumber+item.takeNumber;
                        this.state.totalInfo.badNumber=this.state.totalInfo.badNumber+item.badNumber;
                        this.state.totalInfo.number=this.state.totalInfo.number+item.number;
                        this.state.totalInfo.rejectNumber=this.state.totalInfo.rejectNumber+item.rejectNumber;
                        this.state.totalInfo.goodNumber=this.state.totalInfo.goodNumber+goodNumber;
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:80,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:16,textAlign:'center'}}>{item.commodityCode}</span>
                            <span style={{width:120,height:'30px',lineHeight:'30px',borderStyle:'solid',borderWidth:0.1,fontSize:16,textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{item.commodityName}</span>
                            {/* <div style={{width:180}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'50%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{beforeGoodNumber}</span>
                                </div>
                                <div style={{width:'50%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{item.beforeRejectNumber?item.beforeRejectNumber:''}</span>
                                </div>
                            </div></div> */}
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{beforeGoodNumber}</span>
                            </div>
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{item.inNumber?item.inNumber:''}</span>
                            </div>
                            {item.buyerSales.map((itemb,keyb)=>{
                                this.state.totalInfo['buyer'+keyb]=this.state.totalInfo['buyer'+keyb]+itemb.number;
                                return(<div key={keyb} style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{color:'#F00',fontSize:16}}>{itemb.number?itemb.number:''}</span>
                                </div>)
                            })}
                            <div style={{width:370}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'15%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{item.saleNumber?item.saleNumber:''}</span>
                                </div>
                                <div style={{width:'20%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{arithUtil.keToWeight(item.saleNumber?(item.saleWeight).div(item.saleNumber,2):0,this.state.weightMode)?arithUtil.keToWeight(item.saleNumber?(item.saleWeight).div(item.saleNumber,2):0,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:'25%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{item.saleWeight?arithUtil.keToWeight(item.saleWeight,this.state.weightMode):''}</span>
                                </div>
                                <div style={{width:'20%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{arithUtil.fenToYuan(item.saleWeight?(item.saleMoney).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode)):0)?arithUtil.fenToYuan(item.saleWeight?(item.saleMoney).div(arithUtil.keToWeight(item.saleWeight,this.state.weightMode)):0):''}</span>
                                </div>
                                <div style={{width:'20%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{item.saleMoney?arithUtil.fenToYuan(item.saleMoney):''}</span>
                                </div>
                            </div></div>
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{item.otherNumber?item.otherNumber:''}</span>
                            </div>
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{item.diffNumber?item.diffNumber:''}</span>
                            </div>
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{item.takeNumber?item.takeNumber:''}</span>
                            </div>
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{item.badNumber?item.badNumber:''}</span>
                            </div>
                            <div style={{width:90,height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                <span style={{fontSize:16}}>{goodNumber}</span>
                            </div>
                            {/* <div style={{width:180}}><div style={{display:'flex',flexDirection:'row'}}>
                                <div style={{width:'50%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{goodNumber}</span>
                                </div>
                                <div style={{width:'50%',height:30,borderStyle:'solid',borderWidth:0.1,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                    this.state.currRejectItem={
                                        date:arithUtil.formatToTimeStr(new Date(this.state.searchs.endDate),'Y-M-D'),
                                        warehouseId:this.state.searchs.warehouseId,
                                        commodityId:item.commodityId,
                                        commodityName:item.commodityName,
                                        commodityCode:item.commodityCode,
                                        standard:item.standard,
                                        number:0,weight:0,
                                    };
                                    this.setState({addShowPopIndex:(this.state.addShowPopIndex?this.state.addShowPopIndex:0)+1,rejectShowPop:true})
                                }}><span style={{fontSize:16}}>{item.rejectNumber?item.rejectNumber:''}</span>
                                </div>
                            </div></div> */}
                        </div>)
                    })}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:200,fontSize:16,color:'#D89573'}}>{stringUtil.language(890)}</span>
                        {/* <div style={{width:180,display:'flex',flexDirection:'row'}}>
                            <span style={{width:'50%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeGoodNumber}</span>
                            <span style={{width:'50%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeRejectNumber}</span>
                        </div> */}
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.beforeGoodNumber}</span>
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.inNumber}</span>
                        {(this.state.stockSumm.buyerNames?this.state.stockSumm.buyerNames:[]).map((item,key)=>{
                            return(<span key={key} style={{width:90,color:'#F00',fontSize:16,textAlign:'center'}}>{this.state.totalInfo['buyer'+key]}</span>);
                        })}
                        <div style={{width:370,display:'flex',flexDirection:'row'}}>
                            <span style={{width:'33%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.saleNumber}</span>
                            <span style={{width:'33%',fontSize:16,color:'#D89573',textAlign:'center'}}></span>
                            <span style={{width:'34%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode)}</span>
                            <span style={{width:'33%',fontSize:16,color:'#D89573',textAlign:'center'}}></span>
                            <span style={{width:'34%',fontSize:16,color:'#D89573',textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleMoney)}</span>
                        </div>
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.otherNumber}</span>
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.diffNumber}</span>
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.takeNumber}</span>
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.badNumber}</span>
                        <span style={{width:90,fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.goodNumber}</span>
                        {/* <div style={{width:180,display:'flex',flexDirection:'row'}}>
                            <span style={{width:'50%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.goodNumber}</span>
                            <span style={{width:'50%',fontSize:16,color:'#D89573',textAlign:'center'}}>{this.state.totalInfo.rejectNumber}</span>
                        </div> */}
                    </div></div>
                </div>
            </div>
            <MaskModal title={stringUtil.language(1221)} visible={this.state.rejectShowPop?true:false} width={arithUtil.pxToDp(400)} height={arithUtil.ProportionTo(document.body.clientHeight,0.6)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}} key={this.state.addShowPopIndex?this.state.addShowPopIndex:0}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                            <span>所在仓库：</span>
                            <Select showSearch style={{width:200,height:35}} onChange={(event)=>{
                                this.state.currRejectItem.warehouseId = JSON.parse(event).id;
                            }}><option style={{display:'none'}}></option>
                            {this.state.warehousesDatas.map((item,key)=>{
                                return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                            })}
                            </Select>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                            <span style={{width:100}}>{stringUtil.language(704)}：</span>
                            <DatePicker locale='zhCN' selected={this.state.currRejectItem?arithUtil.strToDate(this.state.currRejectItem.date):null} onChange={(date) => {
                                this.state.currRejectItem.date = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.setState({currRejectItem:this.state.currRejectItem});
                            }} />
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                            <span>{stringUtil.language(1021)+stringUtil.language(1022)}：</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} type='number' placeholder='0' onChange={(event)=>{
                                this.state.currRejectItem.number = parseInt(event.target.value+'');
                                if(this.state.currRejectItem.standard){
                                    this.state.currRejectItem.weight=(this.state.currRejectItem.standard).mul(this.state.currRejectItem.number);
                                    this.refs.rejectInputVal.value=arithUtil.keToWeight(this.state.currRejectItem.weight,this.state.weightMode);
                                }
                            }}/><span style={{marginLeft:5,marginRight:5}}>件</span>
                            <input style={arithUtil.stylesAdd(styles.textInput,{width:arithUtil.pxToDp(100)})} ref='rejectInputVal' type='number' placeholder='0' onChange={(event)=>{
                                this.state.currRejectItem.weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);
                            }}/><span style={{marginLeft:5}}>{this.state.weightMode?'斤':'公斤'}</span>
                        </div>
                    </div>}
                okBnEvents={(event) => {
                    let result = {
                        date:this.state.currRejectItem.date,
                        warehouseId:this.state.currRejectItem.warehouseId,
                        commodityId:this.state.currRejectItem.commodityId,
                        number:this.state.currRejectItem.number,
                        weight:this.state.currRejectItem.weight,
                    }
                    this.setState({ rejectShowPop: false });
                    stringUtil.httpRequest(urlUtil.SALE_STOCK_REJECT_URL,result,['json','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.initData();
                            ToastBox.success(stringUtil.language(388));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({ rejectShowPop: false, });}}/>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
    textInput:{
        height:arithUtil.pxToDp(35),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5),
        textAlign:'center',
        textAlignVertical:'center'
    },
};