import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(233)}
 export default class SaleProfitLossActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        saleProfitLossItemAll:[],
        dateTimeStr:arithUtil.getCurrDateTime(1),
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.initData();
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_SALE_PROFIT_LOSS_URL,{
            date:this.state.dateTimeStr,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({saleProfitLossItemAll:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:10,paddingRight:10,backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:50}}>{stringUtil.language(888)}</span>
                        <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                            this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                            this.initData();
                        }} /></div>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1045)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',paddingTop:5,overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}} id='printArea'>
                    <div>{this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}{stringUtil.language(598)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr}</span>
                    </div>:''}
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                        <span style={{width:120,height:'45px',lineHeight:'45px',fontSize:12,textAlign:'center'}}>{stringUtil.language(1063)}</span>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',borderRightStyle:'solid',borderRightColor:'#DCDCDC',borderRightWidth:0.5,borderLeftStyle:'solid',borderLeftColor:'#DCDCDC',borderLeftWidth:0.5}}>
                            <span style={{width:270,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(964)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',borderRightStyle:'solid',borderRightColor:'#DCDCDC',borderRightWidth:0.5}}>
                            <span style={{width:270,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(1121)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',borderRightStyle:'solid',borderRightColor:'#DCDCDC',borderRightWidth:0.5}}>
                            <span style={{width:270,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(1056)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',borderRightStyle:'solid',borderRightColor:'#DCDCDC',borderRightWidth:0.5}}>
                            <span style={{width:180,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(599)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                            </div>
                        </div>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',borderRightStyle:'solid',borderRightColor:'#DCDCDC',borderRightWidth:0.5}}>
                            <span style={{width:270,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(1122)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                        <div style={{height:'100%',display:'flex',flexDirection:'column',borderRightStyle:'solid',borderRightColor:'#DCDCDC',borderRightWidth:0.5}}>
                            <span style={{width:270,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(598)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(559)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(600)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1120)}</span>
                            </div>
                        </div>
                        <div style={{height:'100%',display:'flex',flexDirection:'column'}}>
                            <span style={{width:270,height:'18px',lineHeight:'18px',fontSize:10,textAlign:'center'}}>{stringUtil.language(965)}</span>
                            <div style={{height:27,display:'flex',flexDirection:'row',alignItems:'center'}}>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1021)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1022)}</span>
                                <span style={{width:90,fontSize:13,textAlign:'center'}}>{stringUtil.language(1041)}</span>
                            </div>
                        </div>
                    </div>
                    {(()=>{this.state.totalInfo={beforeNumTotal:0,beforeWeightTotal:0,beforeMoneyTotal:0,intoNumberTotal:0,intoWeightTotal:0,intoMoneyTotal:0,processNumberTotal:0,processWeightTotal:0,
                        processMoneyTotal:0,inOutNumberTotal:0,inOutWeightTotal:0,saleNumberTotal:0,saleWeightTotal:0,saleMoneyTotal:0,benrinumberTotal:0,benriweightTotal:0,benrimoneyTotal:0,zongchengbenTotal:0,profitLossTotal:0}})()}
                    {this.state.saleProfitLossItemAll.map((item,key)=>{
                        let benrinumber = (item.intoNum).add(item.processNumber).add(item.inOutNumber).add(item.beforeNum).sub(item.saleNumber);
                        let benriweight = (item.intoWeight).add(item.processWeight).add(item.inOutWeight).add(item.beforeWeight).sub(item.saleWeight);
                        let chengbenAvg=0,aa=0,bb=0;
                        if (item.processWeight>0){
                            aa = (item.intoWeight).add(item.processWeight).add(item.inOutWeight).add(item.beforeWeight);
                            bb = (item.intoMoney).add(item.processMoney).add(item.beforeMoney);
                        }else{
                            aa = (item.intoWeight).add(item.inOutWeight).add(item.beforeWeight);
                            bb = (item.intoMoney).add(item.beforeMoney);
                        }
                        if(aa != 0) chengbenAvg = bb.div(aa);
                        let benrimoney = benriweight.mul(chengbenAvg);
                        let profitLoss = (item.saleMoney).sub(chengbenAvg.mul(item.saleWeight));
                        this.state.totalInfo.beforeNumTotal=(this.state.totalInfo.beforeNumTotal).add(item.beforeNum);
                        this.state.totalInfo.beforeWeightTotal=(this.state.totalInfo.beforeWeightTotal).add(item.beforeWeight);
                        this.state.totalInfo.beforeMoneyTotal=(this.state.totalInfo.beforeMoneyTotal).add(item.beforeMoney);
                        this.state.totalInfo.intoNumberTotal=(this.state.totalInfo.intoNumberTotal).add(item.intoNum);
                        this.state.totalInfo.intoWeightTotal=(this.state.totalInfo.intoWeightTotal).add(item.intoWeight);
                        this.state.totalInfo.intoMoneyTotal=(this.state.totalInfo.intoMoneyTotal).add(item.intoMoney);
                        this.state.totalInfo.processNumberTotal=(this.state.totalInfo.processNumberTotal).add(item.processNumber);
                        this.state.totalInfo.processWeightTotal=(this.state.totalInfo.processWeightTotal).add(item.processWeight);
                        this.state.totalInfo.processMoneyTotal=(this.state.totalInfo.processMoneyTotal).add(item.processWeight>0?item.processMoney:(chengbenAvg.mul(item.processWeight)));
                        this.state.totalInfo.inOutNumberTotal=(this.state.totalInfo.inOutNumberTotal).add(item.inOutNumber);
                        this.state.totalInfo.inOutWeightTotal=(this.state.totalInfo.inOutWeightTotal).add(item.inOutWeight);
                        this.state.totalInfo.saleNumberTotal=(this.state.totalInfo.saleNumberTotal).add(item.saleNumber);
                        this.state.totalInfo.saleWeightTotal=(this.state.totalInfo.saleWeightTotal).add(item.saleWeight);
                        this.state.totalInfo.saleMoneyTotal=(this.state.totalInfo.saleMoneyTotal).add(item.saleMoney);
                        this.state.totalInfo.zongchengbenTotal=(this.state.totalInfo.zongchengbenTotal).add(chengbenAvg.mul(item.saleWeight));
                        this.state.totalInfo.profitLossTotal=(this.state.totalInfo.profitLossTotal).add(profitLoss);
                        this.state.totalInfo.benrinumberTotal=(this.state.totalInfo.benrinumberTotal).add(benrinumber);
                        this.state.totalInfo.benriweightTotal=(this.state.totalInfo.benriweightTotal).add(benriweight);
                        this.state.totalInfo.benrimoneyTotal=(this.state.totalInfo.benrimoneyTotal).add(benrimoney);
                        return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:120,fontSize:15}}>{stringUtil.subStr(item.commodityName,7)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{item.beforeNum}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(item.beforeWeight,this.state.weightMode)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(item.beforeMoney)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{item.intoNum}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(item.intoWeight,this.state.weightMode)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(item.intoMoney)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{item.processNumber}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(item.processWeight,this.state.weightMode)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{item.processWeight>0?arithUtil.fenToYuan(item.processMoney):(arithUtil.fenToYuan(chengbenAvg.mul(item.processWeight)))}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{item.inOutNumber}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(item.inOutWeight,this.state.weightMode)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{item.saleNumber}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(item.saleWeight,this.state.weightMode)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleMoney)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(chengbenAvg.mul(this.state.weightMode==0?1000:500))}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(chengbenAvg.mul(item.saleWeight))}</span>
                            <span style={{width:90,color:profitLoss<0?'#F00':'#696969',fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(profitLoss)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{benrinumber}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.keToWeight(benriweight,this.state.weightMode)}</span>
                            <span style={{width:90,fontSize:15,textAlign:'center'}}>{arithUtil.fenToYuan(benrimoney)}</span>
                        </div>)
                    })}
                    <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:120,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1}}>{stringUtil.language(890)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{this.state.totalInfo.beforeNumTotal}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.beforeWeightTotal,this.state.weightMode)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.beforeMoneyTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{this.state.totalInfo.intoNumberTotal}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.intoWeightTotal,this.state.weightMode)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.intoMoneyTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{this.state.totalInfo.processNumberTotal}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.processWeightTotal,this.state.weightMode)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.processMoneyTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{this.state.totalInfo.inOutNumberTotal}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.inOutWeightTotal,this.state.weightMode)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleNumberTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.saleWeightTotal,this.state.weightMode)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleMoneyTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}></span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.zongchengbenTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.profitLossTotal)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{this.state.totalInfo.benrinumberTotal}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.keToWeight(this.state.totalInfo.benriweightTotal,this.state.weightMode)}</span>
                        <span style={{width:90,color:'#D89573',borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth: 1,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.benrimoneyTotal)}</span>
                    </div></div>
                </div>
            </div>
        </div>)
    }
}

const styles = {
    time:{
        height:30,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};