import React,{Component} from 'react';
import QRCode  from 'qrcode.react';
import wx from 'weixin-js-sdk';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ShareCargoInfo extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
        showNumberWeightMode:0,//0stringUtil.language(1021)，1stringUtil.language(1022)，2stringUtil.language(432)
        weightMode:stringUtil.getUrlParamsVal('weightMode',search)?stringUtil.getUrlParamsVal('weightMode',search):0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            stallId:stringUtil.getUrlParamsVal('stallId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('stallId',search),1)):0,
            cargoId:stringUtil.getUrlParamsVal('cargoId',search)?otherUtil.encDec(stringUtil.getUrlParamsVal('cargoId',search),1):0,
        },
        cargoInfo:null,
      }
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SHARE_CARGO_URL+'/'+this.state.searchs.cargoId,{
            sign:otherUtil.encDec(this.state.searchs.stallId),
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({cargoInfo:successResponse.data});//cargoMode:0代销 1{stringUtil.language(1051)} 2个人炒货
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        if (false){//不在stringUtil.language(1094)浏览器内 (window.navigator.userAgent.toLowerCase()).indexOf('micromessenger')>=0
            wx.config({
                debug: true, // 开启调试stringUtil.language(1152),调用的所有api的返回值会在stringUtil.language(1048)端alertstringUtil.language(1194)来，若要stringUtil.language(1170)传stringUtil.language(1195)的参数，可以在pc端打开，参数信息会通过log打stringUtil.language(1194)，仅在pc端时才会stringUtil.language(1045)。
                appId: '', // 必填，公众号的唯一标识
                timestamp: 1000000, // 必填，生成签名的stringUtil.language(1039)戳
                nonceStr: '', // 必填，生成签名的随机串
                signature: '',// 必填，签名，见附录1
                jsApiList: [
                    'onMenuShareAppMessage',
                    'onMenuShareTimeline'
                ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            })
            wx.ready(() => {
                wx.onMenuShareAppMessage({//分享给朋友
                    title: '', // 分享stringUtil.language(1117)
                    desc: '', // 分享描述
                    link: '', // 分享链接，该链接域名或路径必须与当前stringUtil.language(1199)面对应的公众号JS安全域名一致
                    imgUrl: '', // 分享图标
                    type: '', // 分享stringUtil.language(1135),music、video或link，不填默认为link
                    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                    success: function () {// 用户确认分享后执行的回调函数
                    },
                    cancel: function () {// 用户stringUtil.language(1028)分享后执行的回调函数
                    }
                })
                wx.onMenuShareTimeline({//分享到朋友圈
                    title: '', // 分享stringUtil.language(1117)
                    link: '', // 分享链接，该链接域名或路径必须与当前stringUtil.language(1199)面对应的公众号JS安全域名一致
                    imgUrl: '', // 分享图标
                    success: function () {// 用户确认分享后执行的回调函数
                    },
                    cancel: function () {// 用户stringUtil.language(1028)分享后执行的回调函数
                    }
                })
            })
        }
    }

    render(){
        if(this.state.cargoInfo==null) return(<div/>);
        return(<div style={{width:'100%',height:'100%',display:'flex',flexDirection:'column',backgroundColor:'#FFF'}}>
            <div style={{height:40,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',backgroundColor:'#1EAE73'}}>
                <span style={{color:'#FFF',fontSize:15,fontWeight:'bold'}}>{(this.state.cargoInfo.cargoMode==0||this.state.cargoInfo.cargoMode==2?stringUtil.subStr(this.state.cargoInfo.targetName,10):stringUtil.language(1051)+'_'+stringUtil.subStr(this.state.cargoInfo.targetName,10))+' '+stringUtil.language(1175)+this.state.cargoInfo.cargoNo+stringUtil.language(1176)}</span>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span style={{fontSize:13}}>{stringUtil.language(1052)}：{this.state.cargoInfo.workerName}</span>
                <div>
                    <span style={{color:'#FF8C00',fontSize:12,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(this.state.cargoInfo.receiveTime),'Y-M-D h:m')}{stringUtil.language(1052)}</span>
                    {this.state.cargoInfo.state==2?<span style={{color:'#00F',fontSize:12,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(this.state.cargoInfo.settleTime),'Y-M-D h:m')}{stringUtil.language(1053)}</span>:''}
                </div>
            </div>
            <div style={{height:25,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span style={{fontSize:10}}>{this.state.cargoInfo.warehouseName}</span>
                <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.setState({showNumberWeightMode:0});
                    }}><i className="iconfontIndexCss" style={{fontSize:this.state.showNumberWeightMode==0?16:13,color:this.state.showNumberWeightMode==0?'#52A056':'#C0C0C0'}}>&#xe614;</i><span style={{color:this.state.showNumberWeightMode==0?'#52A056':'#C0C0C0',fontSize:this.state.showNumberWeightMode==0?16:13,fontWeight:this.state.showNumberWeightMode==0?'bold':'normal'}}>{stringUtil.language(1021)}</span></div>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                        this.setState({showNumberWeightMode:1});
                    }}><i className="iconfontIndexCss" style={{fontSize:this.state.showNumberWeightMode==1?16:13,color:this.state.showNumberWeightMode==1?'#52A056':'#C0C0C0'}}>&#xe614;</i><span style={{color:this.state.showNumberWeightMode==1?'#52A056':'#C0C0C0',fontSize:this.state.showNumberWeightMode==1?16:13,fontWeight:this.state.showNumberWeightMode==1?'bold':'normal'}}>{stringUtil.language(1022)}</span></div>
                    <div style={{height:'100%',display:'flex',flexDirection:'row',alignItems:'center',marginLeft:10,cursor:'pointer'}} onClick={()=>{
                        this.setState({showNumberWeightMode:2});
                    }}><i className="iconfontIndexCss" style={{fontSize:this.state.showNumberWeightMode==2?16:13,color:this.state.showNumberWeightMode==2?'#52A056':'#C0C0C0'}}>&#xe614;</i><span style={{color:this.state.showNumberWeightMode==2?'#52A056':'#C0C0C0',fontSize:this.state.showNumberWeightMode==2?16:13,fontWeight:this.state.showNumberWeightMode==2?'bold':'normal'}}>{stringUtil.language(432)}</span></div>
                </div>
            </div>
            {this.state.showNumberWeightMode!=2?(this.state.cargoInfo.cargoMode==0?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                <span style={{width:'15%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'14.2%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1054)}</span>
                <span style={{width:'14.2%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'14%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'14.2%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1059)}</span>
                <span style={{width:'14.2%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1058)}</span>
                <span style={{width:'14.2%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</span>
            </div>:this.state.cargoInfo.cargoMode==1?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                <span style={{width:'15%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1056)}</span>
                <span style={{width:'12.4%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1044)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1059)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1058)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</span>
            </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                <span style={{width:'15%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1055)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1056)}</span>
                <span style={{width:'12.4%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1044)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1057)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1059)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1058)}</span>
                <span style={{width:'12.1%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1068)}</span>
            </div>):<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                <span style={{width:'20%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1020)}</span>
                <div style={{width:'80%',display:'flex',flexDirection:'row'}}>
                  <span style={{width:'25%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                  <span style={{width:'15%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1021)}</span>
                  <span style={{width:'17%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1022)}</span>
                  <span style={{width:'25%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1050)}</span>
                  <span style={{width:'18%',color:'#DAA520',fontSize:12,fontWeight:'bold',textAlign:'center'}}>{stringUtil.language(1041)}</span>
                </div>
            </div>}
            <div style={{position:'absolute',left:0,top:145,right:0,bottom:0,overflow:'auto'}}>
                {(()=>{this.state.totalInfo={sendNumber:0,sendWeight:0,transferNumber:0,transferWeight:0,processNumber:0,processWeight:0,purchaseNumber:0,purchaseWeight:0,
                stockInOutNumber:0,stockInOutWeight:0,saleNumber:0,saleWeight:0,balanceNumber:0,balanceWeight:0,saleGoodsMoney:0}})()}
                {this.state.cargoInfo.commodities!=null?this.state.cargoInfo.commodities.map((item,key)=>{
                    this.state.totalInfo.sendNumber = (this.state.totalInfo.sendNumber).add(item.sendNumber);
                    this.state.totalInfo.sendWeight = (this.state.totalInfo.sendWeight).add(item.sendWeight);
                    this.state.totalInfo.transferNumber = (this.state.totalInfo.transferNumber).add(item.transferNumber);
                    this.state.totalInfo.transferWeight = (this.state.totalInfo.transferWeight).add(item.transferWeight);
                    this.state.totalInfo.processNumber = (this.state.totalInfo.processNumber).add(item.processNumber);
                    this.state.totalInfo.processWeight = (this.state.totalInfo.processWeight).add(item.processWeight);
                    this.state.totalInfo.purchaseNumber = (this.state.totalInfo.purchaseNumber).add(item.purchaseNumber);
                    this.state.totalInfo.purchaseWeight = (this.state.totalInfo.purchaseWeight).add(item.purchaseWeight);
                    this.state.totalInfo.stockInOutNumber = (this.state.totalInfo.stockInOutNumber).add(item.stockInOutNumber);
                    this.state.totalInfo.stockInOutWeight = (this.state.totalInfo.stockInOutWeight).add(item.stockInOutWeight);
                    this.state.totalInfo.saleNumber = (this.state.totalInfo.saleNumber).add(item.saleNumber);
                    this.state.totalInfo.saleWeight = (this.state.totalInfo.saleWeight).add(item.saleWeight);
                    this.state.totalInfo.balanceNumber = (this.state.totalInfo.balanceNumber).add(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber);
                    this.state.totalInfo.balanceWeight = (this.state.totalInfo.balanceWeight).add((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight));
                    this.state.totalInfo.saleGoodsMoney = (this.state.totalInfo.saleGoodsMoney).add(item.saleGoodsMoney);
                    let send = this.state.showNumberWeightMode==0?item.sendNumber:arithUtil.keToWeight(item.sendWeight,this.state.weightMode);
                    let transfer = this.state.showNumberWeightMode==0?item.transferNumber:arithUtil.keToWeight(item.transferWeight,this.state.weightMode);
                    let process = this.state.showNumberWeightMode==0?item.processNumber:arithUtil.keToWeight(item.processWeight,this.state.weightMode);
                    let purchase = this.state.showNumberWeightMode==0?item.purchaseNumber:arithUtil.keToWeight(item.purchaseWeight,this.state.weightMode);
                    let stockInOut = this.state.showNumberWeightMode==0?item.stockInOutNumber:arithUtil.keToWeight(item.stockInOutWeight,this.state.weightMode);
                    let sale = this.state.showNumberWeightMode==0?item.saleNumber:arithUtil.keToWeight(item.saleWeight,this.state.weightMode);
                    let balance = this.state.showNumberWeightMode==0?(item.sendNumber+item.transferNumber+item.processNumber+item.purchaseNumber+item.stockInOutNumber-item.saleNumber):arithUtil.keToWeight((item.sendWeight).add(item.transferWeight).add(item.processWeight).add(item.purchaseWeight).add(item.stockInOutWeight).sub(item.saleWeight),this.state.weightMode);
                    if(this.state.showNumberWeightMode!=2)return(this.state.cargoInfo.cargoMode==0?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'15%',fontSize:12,paddingLeft:5}}>{stringUtil.subStr(item.commodityName,5)}</span>
                        <span style={{width:'14.2%',fontSize:12,textAlign:'center'}}>{send}</span>
                        <span style={{width:'14.2%',fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'14%',fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'14.2%',fontSize:12,textAlign:'center'}}>{sale}</span>
                        <span style={{width:'14.2%',fontSize:12,textAlign:'center'}}>{balance}</span>
                        <span style={{width:'14.2%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                    </div>:this.state.cargoInfo.cargoMode==1?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'15%',fontSize:12,paddingLeft:5}}>{stringUtil.subStr(item.commodityName,5)}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{process}</span>
                        <span style={{width:'12.4%',fontSize:12,textAlign:'center'}}>{purchase}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{sale}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{balance}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                    </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                        <span style={{width:'15%',fontSize:12,paddingLeft:5}}>{stringUtil.subStr(item.commodityName,5)}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{transfer}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{process}</span>
                        <span style={{width:'12.4%',fontSize:12,textAlign:'center'}}>{purchase}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{stockInOut}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{sale}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{balance}</span>
                        <span style={{width:'12.1%',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(item.saleGoodsMoney)}</span>
                    </div>);
                }):''}
                {(()=>{this.state.send = this.state.showNumberWeightMode==0?this.state.totalInfo.sendNumber:arithUtil.keToWeight(this.state.totalInfo.sendWeight,this.state.weightMode);
                    this.state.transfer = this.state.showNumberWeightMode==0?this.state.totalInfo.transferNumber:arithUtil.keToWeight(this.state.totalInfo.transferWeight,this.state.weightMode);
                    this.state.process = this.state.showNumberWeightMode==0?this.state.totalInfo.processNumber:arithUtil.keToWeight(this.state.totalInfo.processWeight,this.state.weightMode);
                    this.state.purchase = this.state.showNumberWeightMode==0?this.state.totalInfo.purchaseNumber:arithUtil.keToWeight(this.state.totalInfo.purchaseWeight,this.state.weightMode);
                    this.state.stockInOut = this.state.showNumberWeightMode==0?this.state.totalInfo.stockInOutNumber:arithUtil.keToWeight(this.state.totalInfo.stockInOutWeight,this.state.weightMode);
                    this.state.sale = this.state.showNumberWeightMode==0?this.state.totalInfo.saleNumber:arithUtil.keToWeight(this.state.totalInfo.saleWeight,this.state.weightMode);
                    this.state.balance = this.state.showNumberWeightMode==0?this.state.totalInfo.balanceNumber:arithUtil.keToWeight(this.state.totalInfo.balanceWeight,this.state.weightMode);})()}
                {this.state.showNumberWeightMode!=2?(this.state.cargoInfo.cargoMode==0?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'15%',color:'#FF8C00',fontSize:12,paddingLeft:5}}>{stringUtil.language(890)}</span>
                    <span style={{width:'14.2%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.send}</span>
                    <span style={{width:'14.2%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'14%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'14.2%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.sale}</span>
                    <span style={{width:'14.2%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                    <span style={{width:'14.2%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</span>
                </div>:this.state.cargoInfo.cargoMode==1?<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'15%',color:'#FF8C00',fontSize:12,paddingLeft:5}}>{stringUtil.language(890)}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.process}</span>
                    <span style={{width:'12.4%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.purchase}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.sale}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</span>
                </div>:<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'15%',color:'#FF8C00',fontSize:12,paddingLeft:5}}>{stringUtil.language(890)}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.transfer}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.process}</span>
                    <span style={{width:'12.4%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.purchase}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.stockInOut}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.sale}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{this.state.balance}</span>
                    <span style={{width:'12.1%',color:'#FF8C00',fontSize:12,textAlign:'center'}}>{arithUtil.fenToYuan(this.state.totalInfo.saleGoodsMoney)}</span>
                </div>):''}
                {this.state.showNumberWeightMode==2&&this.state.cargoInfo.detailed!=null?this.state.cargoInfo.detailed.map((item,key)=>{
                    let totalItemInfo={number:0,weight:0,money:0};
                    if(item.items==null) item.items=[];
                    for(let i=0;i<item.items.length;i++){
                      totalItemInfo.number = (totalItemInfo.number).add(item.items[i].number);
                      totalItemInfo.weight = (totalItemInfo.weight).add(item.items[i].weight);
                      item.items[i].money = item.items[i].goodsMoney;
                    //   for(let j=0;j<item.items[i].costs.length;j++){
                    //     item.items[i].money = (item.items[i].money).add(item.items[i].costs[j].money);
                    //   }
                      totalItemInfo.money = (totalItemInfo.money).add(item.items[i].money);
                    }
                    return(<div style={{display:'flex',flexDirection:'row',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#F2F2F2',paddingLeft:5}} key={key}>
                        <span style={{width:'20%',color:'#000',fontSize:12}}>{item.commodityName}</span>
                        <div style={{width:'80%',display:'flex',flexDirection:'column'}}>
                            <span style={{height:30,color:'#52A056',fontSize:14,fontWeight:'bold',textAlignVertical:'center'}}>{stringUtil.language(1182)}{totalItemInfo.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(totalItemInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}，{arithUtil.fenToYuan(totalItemInfo.money,2)}{stringUtil.currency(1)}</span>
                            {item.items.map((itemb,keyb)=>{
                                return(<div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                                    <span style={{width:'25%',color:'#808080',fontSize:12}}>{itemb.customerName}</span>
                                    <span style={{width:'15%',color:'#808080',fontSize:13,textAlign:'center'}}>{itemb.number}</span>
                                    <span style={{width:'17%',color:'#808080',fontSize:13,textAlign:'center'}}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                                    <span style={{width:'25%',color:'#808080',fontSize:12,textAlign:'center'}}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(itemb.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                                    <span style={{width:'18%',color:'#808080',fontSize:13,textAlign:'center'}}>{arithUtil.fenToYuan(itemb.money)}</span>
                                </div>);
                            })}
                        </div>
                    </div>);
                }):''}
                <br/><br/>
                {(()=>{this.state.docMoney = this.state.totalInfo.saleGoodsMoney;
                    this.state.ownerMoney = this.state.totalInfo.saleGoodsMoney;
                    this.state.saleCostsTemp=[];this.state.purchaseCostsTemp=[];this.state.processCostsTemp=[];
                    if(this.state.cargoInfo.saleCosts==null)this.state.cargoInfo.saleCosts=[];
                    for(let i=0;i<this.state.cargoInfo.saleCosts.length;i++){
                        if((this.state.cargoInfo.cargoMode==0&&this.state.cargoInfo.saleCosts[i].payee==0)||(this.state.cargoInfo.cargoMode==2&&this.state.cargoInfo.saleCosts[i].payee==2)){
                            this.state.saleCostsTemp.push(this.state.cargoInfo.saleCosts[i]);
                            this.state.docMoney = (this.state.docMoney).add(this.state.cargoInfo.saleCosts[i].money);
                        }
                    }
                    this.state.saleCostsTemp.push({costClauseName:stringUtil.language(592),money:this.state.docMoney});
                    if(this.state.cargoInfo.cargoMode==2)this.state.saleCostsTemp.push({costClauseName:stringUtil.language(594),money:this.state.ownerMoney});
                    if((this.state.cargoInfo.cargoMode==1||this.state.cargoInfo.cargoMode==2)&&this.state.cargoInfo.purchaseCosts!=null&&this.state.cargoInfo.purchaseCosts.length>0){
                        for(let i=0;i<this.state.cargoInfo.purchaseCosts.length;i++){
                            if((this.state.cargoInfo.cargoMode==0&&this.state.cargoInfo.purchaseCosts[i].payee==0)||(this.state.cargoInfo.cargoMode==2&&this.state.cargoInfo.purchaseCosts[i].payee==2)){
                                this.state.purchaseCostsTemp.push(this.state.cargoInfo.purchaseCosts[i]);
                            }
                        }
                    }
                    if((this.state.cargoInfo.cargoMode==1||this.state.cargoInfo.cargoMode==2)&&this.state.cargoInfo.processCosts!=null&&this.state.cargoInfo.processCosts.length>0){
                        for(let i=0;i<this.state.cargoInfo.processCosts.length;i++){
                            if((this.state.cargoInfo.cargoMode==0&&this.state.cargoInfo.processCosts[i].payee==0)||(this.state.cargoInfo.cargoMode==2&&this.state.cargoInfo.processCosts[i].payee==2)){
                                this.state.processCostsTemp.push(this.state.cargoInfo.processCosts[i]);
                            }
                        }
                    }
                    if(this.state.cargoInfo.expends==null) this.state.cargoInfo.expends=[];
                    if(this.state.cargoInfo.rebateMoney!=0)(this.state.cargoInfo.expends).unshift({ownerExpendClauseName:stringUtil.language(1150),money:this.state.cargoInfo.rebateMoney});
                    if(this.state.cargoInfo.entrustMoney!=0)(this.state.cargoInfo.expends).unshift({ownerExpendClauseName:stringUtil.language(941),money:this.state.cargoInfo.entrustMoney});
                })()}
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                    {this.state.saleCostsTemp.map((item,key)=>{
                        return(<div style={{height:25,width:'33.3%',display:'flex',flexDirection:'row',alignItems:'center',fontSize:12}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                    })}
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                    {this.state.purchaseCostsTemp.map((item,key)=>{
                        return(<div style={{height:25,width:'33.3%',display:'flex',flexDirection:'row',alignItems:'center',fontSize:12}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                    })}
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                    {this.state.processCostsTemp.map((item,key)=>{
                        return(<div style={{height:25,width:'33.3%',display:'flex',flexDirection:'row',alignItems:'center',fontSize:12}} key={key}>{item.costClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                    })}
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:5}}>
                    {this.state.cargoInfo.expends.map((item,key)=>{
                        return(<div style={{height:25,width:'33.3%',display:'flex',flexDirection:'row',alignItems:'center',fontSize:12}} key={key}>{item.ownerExpendClauseName+':'+arithUtil.fenToYuan(item.money)}</div>);
                    })}
                </div>
                <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',color:'#F00',fontSize:25,fontWeight:'bold',paddingRight:10}}>{stringUtil.language(749)}：{arithUtil.fenToYuan(this.state.cargoInfo.balance)}</div>
            </div>
        </div>)
    }
}

const styles = {};