'use strict';
import React,{Component} from 'react';
 import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

 export default class AddBossStall extends Component{
    constructor(props) {
      super(props);
      this.state={
        marketItemAll:[],
        districtItemAll:[],
        currDistrictA:null,currDistrictB:null,currDistrictC:null,
        addStallItem:{},
      };
    }
    componentDidMount(){
      stringUtil.httpRequest(urlUtil.SYSTEM_MARKET_URL,null,['key','get',0],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.marketItemAll= successResponse.data;
              this.setState({marketItemAll: this.state.marketItemAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      stringUtil.httpRequest(urlUtil.SYSTEM_DISTRICT_URL,null,['key','get',0],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.districtItemAll= successResponse.data;
              this.setState({districtItemAll: this.state.districtItemAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:window.screen.width,height:document.body.clientHeight,backgroundColor:'#FFF'}}>
            <div style={{height:arithUtil.pxToDp(60),display:'flex',alignItems:'center',marginLeft:'15%'}}>
                <img style={{width:'40px',height:'40px'}} loading="lazy" src={require('../images/app_logo.png').default} title={stringUtil.language(84)}/>
                <span style={{fontSize:'25px',color:'#D2855D',marginLeft:arithUtil.pxToDp(10)}}>{stringUtil.language(11)}</span>
            </div>
            <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(381)}</span>
                <input style={styles.phoneInputText} onChange={(event)=>{this.state.addStallItem.shortName = event.target.value;}} placeholder={stringUtil.language(55)+'..'}/>
              </div>
              <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(382)}</span>
                <input style={styles.phoneInputText} type='number' onChange={(event)=>{this.state.addStallItem.mobile = event.target.value;}} placeholder={stringUtil.language(56)}/>
              </div>
              <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(383)}</span>
                <input style={styles.phoneInputText} onChange={(event)=>{this.state.addStallItem.shopName = event.target.value;}} placeholder={stringUtil.language(57)}/>
              </div>
              <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(384)}</span>
                <select style={styles.selectStyle} onChange={(event)=>{
                      this.state.addStallItem.marketId = JSON.parse(event.target.value).id;
                      this.setState({currMarket:JSON.parse(event.target.value)});
                  }}>
                    <option style={{display:'none'}}></option>
                  {this.state.marketItemAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                  })}
                </select>
              </div>
              <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(385)}</span>
                <select style={styles.selectStyle} onChange={(event)=>{
                      this.state.addStallItem.marketRegionId = JSON.parse(event.target.value).id;
                  }}>
                    <option style={{display:'none'}}></option>
                  {(this.state.currMarket!=null?this.state.currMarket.children:[]).map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                  })}
                </select>
              </div>
              <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(386)}</span>
                <select style={styles.selectStyle} onChange={(event)=>{
                    this.state.currDistrictA = JSON.parse(event.target.value);
                    if (this.state.currDistrictA==null||this.state.currDistrictA.districts.length==0){
                        this.state.currDistrictB=null;this.state.currDistrictC=null;
                    }
                    this.setState({currDistrictA:this.state.currDistrictA});
                }}>
                  <option style={{display:'none'}}></option>
                  {this.state.districtItemAll.map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                  })}
                </select>
                <select style={styles.selectStyle} onChange={(event)=>{
                    this.state.currDistrictB = JSON.parse(event.target.value);
                    if (this.state.currDistrictB==null||this.state.currDistrictB.districts.length==0) {
                      this.state.currDistrictC=null;
                    }
                    this.setState({currDistrictB:this.state.currDistrictB});
                }}>
                  <option style={{display:'none'}}></option>
                  {(this.state.currDistrictA!=null?this.state.currDistrictA.districts:[]).map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                  })}
                </select>
                <select style={styles.selectStyle} onChange={(event)=>{
                    this.state.currDistrictC = JSON.parse(event.target.value);
                }}>
                  <option style={{display:'none'}}></option>
                  {(this.state.currDistrictB!=null?this.state.currDistrictB.districts:[]).map((item,key)=>{
                    return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                  })}
                </select>
              </div>
              <div style={styles.mainItem}>
                <span style={{width:arithUtil.pxToDp(100)}}>{stringUtil.language(387)}</span>
                <input style={arithUtil.stylesAdd(styles.phoneInputText,{width:'78%'})} onChange={(event)=>{this.state.addStallItem.address = event.target.value;}} placeholder={stringUtil.language(58)}/>
              </div>
              <div style={arithUtil.stylesAdd(styles.mainItem,{justifyContent:'center'})}>
                    <span style={{width:arithUtil.pxToDp(150),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#52A056',marginTop:arithUtil.pxToDp(35),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        if (this.state.addStallItem.shortName==null||this.state.addStallItem.shortName=='') {
                            ToastBox.error(stringUtil.language(85));return;
                        }
                        if (this.state.addStallItem.mobile==null||this.state.addStallItem.mobile=='') {
                            ToastBox.error(stringUtil.language(31));return;
                        }
                        let resultBool = (this.state.addStallItem.mobile).phoneCheck();
                        if(!resultBool){
                            ToastBox.error(stringUtil.language(59));return;
                        }
                        if (this.state.addStallItem.shopName==null||this.state.addStallItem.shopName=='') {
                            ToastBox.error(stringUtil.language(86));return;
                        }
                        if (this.state.addStallItem.marketId==null) {
                            ToastBox.error(stringUtil.language(187));return;
                        }
                        if (this.state.addStallItem.marketRegionId==null) {
                            ToastBox.error(stringUtil.language(188));return;
                        }
                        let shengStr = this.state.currDistrictA!=null?this.state.currDistrictA.name:'';
                        let shiStr = this.state.currDistrictB!=null?this.state.currDistrictB.name:'';
                        let xianStr = this.state.currDistrictC!=null?this.state.currDistrictC.name:'';
                        if (shengStr!='' || shiStr!='' || xianStr!='') {
                            if (this.state.currDistrictB!=null) {
                                if (this.state.currDistrictC!=null) {
                                    this.state.addStallItem.adcode = this.state.currDistrictC.adcode;
                                }else{
                                    this.state.addStallItem.adcode = this.state.currDistrictB.adcode;
                                }
                            }else{
                                this.state.addStallItem.adcode = this.state.currDistrictA.adcode;
                            }
                            this.state.addStallItem.adname = shengStr+shiStr+xianStr;
                        }
                        stringUtil.httpRequest(urlUtil.OAUTH_SHOP_APPLY_URL,this.state.addStallItem,['json','post',0],(successResponse)=>{
                            if (successResponse.status==200) {
                              ToastBox.success(stringUtil.language(388));
                              this.props.history.push({ pathname: "/MeInfo", state: { id:'6666',name:'参数值。。' } });
                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                    }}>{stringUtil.language(1019)}</span>
              </div>
          </div>)
    }
 }

 const styles = {
    mainItem:{
      width:'50%',
      height:arithUtil.pxToDp(40),
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
      marginLeft:'15%'
    },
    phoneInputText:{
      width:arithUtil.pxToDp(270),
      height:arithUtil.pxToDp(30),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:5,
      color:'#808080',
      padding:0,
      paddingLeft:arithUtil.pxToDp(10),
    },
    mainDownSelect:{
      width:arithUtil.pxToDp(270),
      height:arithUtil.pxToDp(40),
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:5,
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'center',
      paddingLeft:arithUtil.pxToDp(10),
      paddingRight:arithUtil.pxToDp(10)
    },
    mainClearBn:{
      width:arithUtil.pxToDp(150),
      height:arithUtil.pxToDp(40),
      borderRadius:5,
      backgroundColor:'#FFF',
      borderStyle:'solid',
      borderColor:'#D3D3D3',
      borderWidth: 1,
      marginLeft:arithUtil.pxToDp(30)
    },
    selectStyle:{
        width: '180px',
        height: '25px',
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth:1,
        borderRadius: '2px',
        paddingLeft: '5px',
        fontSize: '12px'
    }
 };