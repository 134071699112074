import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';

export default class TabLog extends Component{
  constructor(props) {
      super(props);
      this.state = {
          weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
          searchs:{
                mode:-1,//-1stringUtil.language(1097)，0stringUtil.language(1122)，1财务
                type:-1,//-1stringUtil.language(1097)，0stringUtil.language(1037)，1stringUtil.language(1044)，2stringUtil.language(1056)，3stringUtil.language(1134)，4stringUtil.language(661)，5stringUtil.language(509)，6stringUtil.language(477)，7stringUtil.language(442)，8stringUtil.language(662)，9stringUtil.language(663)，10stringUtil.language(304)，11stringUtil.language(445)，12stringUtil.language(446)
                startTime:arithUtil.getCurrDateTime(1),//arithUtil.getCurrBefourDateTime(30,arithUtil.getCurrDateTime(2)),//起始stringUtil.language(1039) 0或不传为stringUtil.language(1097)
                endTime:arithUtil.getCurrDateTime(1),//截止stringUtil.language(1039) 0或不传为stringUtil.language(1097)
                workerId:-1,//stringUtil.language(1031)stringUtil.language(1155) -1或不传为stringUtil.language(1097)
                workerName:stringUtil.language(472)
            },
            types:[{type:-1,name:stringUtil.language(583)},{type:0,name:stringUtil.language(1037)},{type:1,name:stringUtil.language(1044)},{type:2,name:stringUtil.language(1056)},{type:3,name:stringUtil.language(1134)},
                {type:4,name:stringUtil.language(661)},{type:5,name:stringUtil.language(509)},{type:6,name:stringUtil.language(477)},{type:7,name:stringUtil.language(442)},{type:8,name:stringUtil.language(662)},
                {type:9,name:stringUtil.language(663)},{type:10,name:stringUtil.language(304)},{type:11,name:stringUtil.language(445)},{type:12,name:stringUtil.language(446)}],
          workersAll:[],
          logDO:null,
          documentInfo:{after:{},before:{},log:{}},
          infoShowPopWhole:false,
          infoShowPopPurch:false,
          infoShowPopProcess:false,
          infoShowPopAllot:false,
          infoShowPopReSend:false,
      };
  }
  componentDidMount(){
      stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.workersAll = successResponse.data;
              this.state.workersAll.unshift({id:-1,noteName:stringUtil.language(1097)});
              this.setState({workersAll:this.state.workersAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
      this.getDataList();
  }
  getDataList(){
      stringUtil.httpRequest(urlUtil.SUMM_LOG_SEARCH_URL,{
        mode:this.state.searchs.mode,
          type:this.state.searchs.type,
          startTime:arithUtil.settleSub(arithUtil.timeToNumber(this.state.searchs.startTime,0)),
          endTime:arithUtil.settleSub(arithUtil.timeToNumber(this.state.searchs.endTime)),
          workerId:this.state.searchs.workerId,
      },['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
            this.setState({logDO:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
    if(!this.state.logDO)return(<div/>);
    return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
      <div style={{height:'19.5%',backgroundColor:'#FFF',display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:'2%'}}>
          <div style={{width:'10%',height:'85%',backgroundColor:this.state.searchs.mode==-1?'#D2855D':'#A9A9A9',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
            this.state.searchs.mode=-1;
            this.getDataList();
          }}><i className="iconfontIndexCss" style={{fontSize:30,color:'#FFF'}}>&#xe61b;</i>
              <span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(664)}</span>
          </div>
          <div style={{width:'10%',height:'85%',backgroundColor:this.state.searchs.mode==0?'#D2855D':'#A9A9A9',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
            this.state.searchs.mode=0;
            this.getDataList();
          }}><i className="iconfontIndexCss" style={{fontSize:30,color:'#FFF'}}>&#xe61b;</i>
              <span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(665)}</span>
          </div>
          <div style={{width:'10%',height:'85%',backgroundColor:this.state.searchs.mode==1?'#D2855D':'#A9A9A9',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
            this.state.searchs.mode=1;
            this.getDataList();
          }}><i className="iconfontIndexCss" style={{fontSize:30,color:'#FFF'}}>&#xe60a;</i>
              <span style={{color:'#FFF',fontSize:18}}>{stringUtil.language(666)}</span>
          </div>
          <div style={{width:'0.2%',height:'100%',backgroundColor:'#DCDCDC',marginLeft:'2%'}}/>
          <div style={{width:'77.8%',height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',paddingLeft:10}}>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(887)}</span>
                    <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(180)})} onChange={(event)=>{
                        this.state.searchs.workerId = JSON.parse(event.target.value).id;
                        this.state.searchs.workerName = JSON.parse(event.target.value).noteName;
                        this.getDataList();
                    }}><option style={{display:'none'}}></option>
                        {this.state.workersAll.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                        })}
                    </select>
                    <span style={{marginLeft:10}}>{stringUtil.language(875)}</span>
                    <select style={arithUtil.stylesAdd(styles.inputText,{width:arithUtil.pxToDp(180)})} onChange={(event)=>{
                        this.state.searchs.type = JSON.parse(event.target.value).type;
                        this.getDataList();
                    }}><option style={{display:'none'}}></option>
                        {this.state.types.map((item,key)=>{
                            return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:10}}>
                    <span>{stringUtil.language(904)}</span><div style={styles.time}>
                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.startTime)} onChange={(date) => {
                        this.state.searchs.startTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                        this.getDataList();
                    }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.searchs.endTime)} onChange={(date) => {
                        this.state.searchs.endTime = arithUtil.formatToTimeStr(date,'Y-M-D');
                        this.getDataList();
                    }} /></div>
                </div>
          </div>
      </div>
      <div style={{height:'80%',backgroundColor:'#FFF',marginTop:'0.5%'}}>
            <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1039)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9'})}>{stringUtil.language(1031)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%',color:'#A9A9A9'})}>{stringUtil.language(1135)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%',color:'#A9A9A9'})}>{stringUtil.language(1040)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',color:'#A9A9A9'})}>{stringUtil.language(667)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%',color:'#A9A9A9'})}>{stringUtil.language(668)}</span>
                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'8%',color:'#A9A9A9'})}></span>
            </div>
            <div style={{height:this.state.totalPage>1?'85%':'95%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                {this.state.logDO?this.state.logDO.map((item,key)=>{
                    return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} key={key}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.workerId==0?stringUtil.language(431):item.workerName}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%'})}>{item.mode==0?stringUtil.language(1092):stringUtil.language(1113)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'11%'})}>{item.type==0?stringUtil.language(1037):item.type==1?stringUtil.language(1044):item.type==2?stringUtil.language(1056):item.type==3?stringUtil.language(1134):item.type==4?stringUtil.language(599):item.type==5?stringUtil.language(509):item.type==6?stringUtil.language(477):item.type==7?stringUtil.language(442):item.type==8?stringUtil.language(662):item.type==9?stringUtil.language(663):item.type==10?stringUtil.language(304):item.type==11?stringUtil.language(445):item.type==12?stringUtil.language(446):''}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'15%'})}>{item.documentNo}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%'})}>{item.beforeMoney!=null?arithUtil.fenToYuan(item.beforeMoney):''}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'13%'})}>{item.AfterMoney!=null?arithUtil.fenToYuan(item.AfterMoney):''}</span>
                        <div style={arithUtil.stylesAdd(styles.tableTd,{width:'8%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                            <i className="iconfontIndexCss" style={{fontSize:25,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                stringUtil.httpRequest(urlUtil.SUMM_LOG_URL+'/'+item.id,{type:item.type},['key','get',1],(successResponse)=>{
                                    if (successResponse.status==200) {
                                        this.state.documentInfo = JSON.parse(JSON.stringify(successResponse.data));
                                        if(item.type==0){this.setState({infoShowPopWhole: !this.state.infoShowPopWhole});
                                        }else if(item.type==1){this.setState({infoShowPopPurch: !this.state.infoShowPopPurch});
                                        }else if(item.type==2){this.setState({infoShowPopProcess: !this.state.infoShowPopProcess});
                                        }else if(item.type==3){this.setState({infoShowPopAllot: !this.state.infoShowPopAllot});
                                        }else if(item.type==4){this.setState({infoShowPopReSend: !this.state.infoShowPopReSend});
                                        }else if(item.type==5){this.setState({infoShowPopCustomerCollect: !this.state.infoShowPopCustomerCollect});
                                        }else if(item.type==6){this.setState({infoShowPopOtherCollect: !this.state.infoShowPopOtherCollect});
                                        }else if(item.type==7){this.setState({infoShowPopOwnerExp: !this.state.infoShowPopOwnerExp});
                                        }else if(item.type==8){this.setState({infoShowPopOtherExp: !this.state.infoShowPopOtherExp});
                                        }else if(item.type==9){this.setState({infoShowPopChange: !this.state.infoShowPopChange});
                                        }else if(item.type==10){this.setState({infoShowPopPurchase: !this.state.infoShowPopPurchase});
                                        }else if(item.type==11){this.setState({infoShowPopPuyOwner: !this.state.infoShowPopPuyOwner});
                                        }else if(item.type==12){this.setState({infoShowPopPuyWorker: !this.state.infoShowPopPuyWorker});}
                                    }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                            }}>&#xe64c;</i>
                        </div>
                    </div>);
                }):''}
            </div>
      </div>
      <MaskModal title={stringUtil.language(446)} visible={this.state.infoShowPopPuyWorker?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.workerPayNo}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1031)}: {this.state.documentInfo.before.targetName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1136)}: {this.state.documentInfo.before.accountName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
            </div>}closeBnTopEvents={() => {this.setState({ infoShowPopPuyWorker: false, });}}/>
        <MaskModal title={stringUtil.language(445)} visible={this.state.infoShowPopPuyOwner?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.ownerPayNo}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1049)}: {this.state.documentInfo.before.ownerName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1136)}: {this.state.documentInfo.before.accountName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
            </div>}closeBnTopEvents={() => {this.setState({ infoShowPopPuyOwner: false, });}}/>
        <MaskModal title={stringUtil.language(304)} visible={this.state.infoShowPopPurchase?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.supplierPayNo}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(921)}: {this.state.documentInfo.before.supplierName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1136)}: {this.state.documentInfo.before.accountName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
            </div>}closeBnTopEvents={() => {this.setState({ infoShowPopPurchase: false, });}}/>
        <MaskModal title={stringUtil.language(663)} visible={this.state.infoShowPopChange?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.transferAccountNo}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1137)}: {this.state.documentInfo.before.accountOutName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1138)}: {this.state.documentInfo.before.accountInName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
            </div>}closeBnTopEvents={() => {this.setState({ infoShowPopChange: false, });}}/>
        <MaskModal title={stringUtil.language(662)} visible={this.state.infoShowPopOtherExp?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.shopExpendNo}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1139)}: {this.state.documentInfo.before.clauseName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(976)}: {this.state.documentInfo.before.workerId?this.state.documentInfo.before.workerName:stringUtil.language(431)}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1140)}: {this.state.documentInfo.before.accountName}</span>
                </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
            </div>}closeBnTopEvents={() => {this.setState({ infoShowPopOtherExp: false, });}}/>
        <MaskModal title={stringUtil.language(442)} visible={this.state.infoShowPopOwnerExp?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
            content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.ownerExpendNo}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1139)}: {this.state.documentInfo.before.clauseName}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1084)}: {this.state.documentInfo.before.targetName?this.state.documentInfo.before.targetName:this.state.documentInfo.before.targetType==1?stringUtil.language(426):''} {stringUtil.language(1175)}{this.state.documentInfo.before.cargoNo}{stringUtil.language(1176)}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(976)}: {this.state.documentInfo.before.workerId?this.state.documentInfo.before.workerName:stringUtil.language(431)}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                    <span>{stringUtil.language(1140)}: {this.state.documentInfo.before.accountName}</span>
                </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
            </div>}closeBnTopEvents={() => {this.setState({ infoShowPopOwnerExp: false, });}}/>
      <MaskModal title={stringUtil.language(477)} visible={this.state.infoShowPopOtherCollect?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
            <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                <span>{stringUtil.language(1040)}: {this.state.documentInfo.before.shopCollectNo}</span>
            </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                <span>{stringUtil.language(1039)}: {arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m')}</span>
            </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                <span>{stringUtil.language(1139)}: {this.state.documentInfo.before.clauseName}</span>
            </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                <span>{stringUtil.language(977)}: {this.state.documentInfo.before.workerId?this.state.documentInfo.before.workerName:stringUtil.language(431)}</span>
            </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                <span>{stringUtil.language(1041)}: {arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
            </div><div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}} numberOfLines={1}>
                <span>{stringUtil.language(1140)}: {this.state.documentInfo.before.accountName}</span>
            </div><span>{this.state.documentInfo.before.describe?stringUtil.language(1033)+': '+this.state.documentInfo.before.describe:''}</span>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopOtherCollect: false, });}}/>
      <MaskModal title={stringUtil.language(509)} visible={this.state.infoShowPopCustomerCollect?true:false} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%',overflow:'auto'}}>
            <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(669)}：</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m:s')}</span>
            </div>
            <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(670)}：</span><span>{this.state.documentInfo.before.workerId==0?stringUtil.language(431):this.state.documentInfo.before.workerName}</span>
            </div>
            <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(671)}：</span><span style={{color:'#F00'}}>SK.{this.state.documentInfo.before.customerCollectNo}</span>
            </div>
            <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(672)}：</span><span>{this.state.documentInfo.before.customerName}</span>
            </div>
            <div style={{width:'100%',height:30,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(673)}：</span><span>{this.state.documentInfo.before.accountName}</span>
            </div>
            <div style={{width:'100%',height:45,display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span>{stringUtil.language(674)}：</span><div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'flex-end'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.documentInfo.before.collectMoney?this.state.documentInfo.before.collectMoney:0).add(this.state.documentInfo.before.discountMoney?this.state.documentInfo.before.discountMoney:0))}</span><span>{stringUtil.currency(1)}</span>
                    </div>
                    <span style={{color:'#A9A9A9',fontSize:10}}>{stringUtil.language(1080)}{arithUtil.fenToYuan(this.state.documentInfo.before.collectMoney)}{stringUtil.currency(1)}{stringUtil.language(906)}{arithUtil.fenToYuan(this.state.documentInfo.before.discountMoney)}{stringUtil.currency(1)}</span>
                </div>
            </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopCustomerCollect: false, });}}/>
      <MaskModal title={stringUtil.language(416)} visible={this.state.infoShowPopWhole} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%'}}>
            {this.state.documentInfo.log.mode==0?<div style={{width:'100%',height:'8%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFFFE0'}}>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(978)}：</span>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(979)}：</span>
            </div>:<span style={{width:'100%',height:'8%',color:'#F00',fontWeight:'bold',backgroundColor:'#FFFFE0',textAlignVertical:'center'}}>  {stringUtil.language(978)}：</span>}
            <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              {/* {stringUtil.language(978)} */}
              <div style={{width:this.state.documentInfo.log.mode==0?'50%':'100%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{width:'100%',height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span style={{fontWeight:'bold',fontSize:15}}>{stringUtil.language(903)}{this.state.documentInfo.before.customerName}</span>
                      <span style={{color:'#A9A9A9',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.createTime),'Y-M-D h:m:s')}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span></span>
                      <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>NO.{this.state.documentInfo.before.wholesaleNo}</span>
                      </div>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(518)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1021)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1022)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1050)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1068)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1069)}</span>
                  </div>
                  <div style={{width:'100%',height:'70%',overflow:'auto'}}>
                      {(()=>{this.state.totalWholesaleInfo={totalNumber:0,totalWeight:0,totalGoodMoney:0,totalTotalOtherMoney:0};})()}
                      {this.state.documentInfo.before.commodities!=null?this.state.documentInfo.before.commodities.map((item,key)=>{
                      let totalOtherMoney = 0;
                      if(item.costs!=null){
                          for(let i=0;i<item.costs.length;i++){
                              let costsItem = item.costs[i].money;
                              totalOtherMoney = totalOtherMoney.add(costsItem);
                          }
                      }
                      this.state.totalWholesaleInfo.totalNumber = (this.state.totalWholesaleInfo.totalNumber).add(item.number);
                      this.state.totalWholesaleInfo.totalWeight = (this.state.totalWholesaleInfo.totalWeight).add(item.weight);
                      this.state.totalWholesaleInfo.totalGoodMoney = (this.state.totalWholesaleInfo.totalGoodMoney).add(item.goodsMoney);
                      this.state.totalWholesaleInfo.totalTotalOtherMoney = (this.state.totalWholesaleInfo.totalTotalOtherMoney).add(totalOtherMoney);
                      return(<div style={{height:arithUtil.pxToDp(40)}} key={key}>
                          <div style={{height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={styles.tableTd}>{item.number}</span>
                              <span style={styles.tableTd}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%'})}>{(item.mode==0)?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+stringUtil.language(1173))}</span>
                              <span style={styles.tableTd}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                              <span style={styles.tableTd}>{arithUtil.fenToYuan(totalOtherMoney)}</span>
                          </div>
                          <div style={{height:arithUtil.pxToDp(15),display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'35%',color:'#52A056',fontSize:10,textAlign:'left',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,5)}/{item.cargoNo!=null?item.cargoNo:stringUtil.language(675)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'23%',color:'#52A056',fontSize:10,textAlign:'right'})}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'42%'})}></span>
                          </div>
                      </div>)
                      }):<div/>}
                  </div>
                  <div style={{width:'100%',height:'15%',paddingBottom:5}}>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',color:'#D2855D',textAlign:'left'})}>{stringUtil.language(890)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{this.state.totalWholesaleInfo.totalNumber}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.keToWeight(this.state.totalWholesaleInfo.totalWeight,this.state.weightMode)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#D2855D'})}></span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalGoodMoney)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalTotalOtherMoney)}</span>
                      </div>
                      <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'}}>
                          {this.state.documentInfo.before.costs!=null?this.state.documentInfo.before.costs.map((item,key)=>{
                              return(<span style={{fontSize:12,marginLeft:key!=0?arithUtil.pxToDp(30):0}} key={key}>{item.costClauseName}：{arithUtil.fenToYuan(item.money)}</span>)
                          }):<div/>}
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span style={{fontSize:12}}>{stringUtil.language(858)}{this.state.documentInfo.before.describe}</span>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                          <span style={{fontSize:12}}>{stringUtil.language(880)}</span>
                          <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.documentInfo.before.money)}</span>
                      </div>
                      </div>
                  </div>
              </div>
              <div style={{width:1,height:'100%',backgroundColor:this.state.documentInfo.log.mode==0?'#F00':'transparent'}}/>
              {/* {stringUtil.language(979)} */
              this.state.documentInfo.log.mode==0?<div style={{width:'50%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{width:'100%',height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span style={{fontWeight:'bold',fontSize:15}}>{stringUtil.language(903)}{this.state.documentInfo.after.customerName}</span>
                      <span style={{color:'#A9A9A9',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.after.createTime),'Y-M-D h:m:s')}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span></span>
                      <span style={{color:'#F00',fontSize:15,fontWeight:'bold'}}>NO.{this.state.documentInfo.after.wholesaleNo}</span>
                      </div>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(518)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1021)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1022)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1050)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1068)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#A9A9A9',textAlign:'center'})}>{stringUtil.language(1069)}</span>
                  </div>
                  <div style={{width:'100%',height:'70%',overflow:'auto'}}>
                      {(()=>{this.state.totalWholesaleInfo={totalNumber:0,totalWeight:0,totalGoodMoney:0,totalTotalOtherMoney:0};})()}
                      {this.state.documentInfo.after.commodities!=null?this.state.documentInfo.after.commodities.map((item,key)=>{
                      let totalOtherMoney = 0;
                      for(let i=0;i<item.costs.length;i++){
                          let costsItem = item.costs[i].money;
                          totalOtherMoney = totalOtherMoney.add(costsItem);
                      }
                      this.state.totalWholesaleInfo.totalNumber = (this.state.totalWholesaleInfo.totalNumber).add(item.number);
                      this.state.totalWholesaleInfo.totalWeight = (this.state.totalWholesaleInfo.totalWeight).add(item.weight);
                      this.state.totalWholesaleInfo.totalGoodMoney = (this.state.totalWholesaleInfo.totalGoodMoney).add(item.goodsMoney);
                      this.state.totalWholesaleInfo.totalTotalOtherMoney = (this.state.totalWholesaleInfo.totalTotalOtherMoney).add(totalOtherMoney);
                      return(<div style={{height:arithUtil.pxToDp(40)}} key={key}>
                          <div style={{height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={styles.tableTd}>{item.number}</span>
                              <span style={styles.tableTd}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%'})}>{(item.mode==0)?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172))):(arithUtil.fenToYuan(item.unitPrice)+'/'+stringUtil.language(1173))}</span>
                              <span style={styles.tableTd}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                              <span style={styles.tableTd}>{arithUtil.fenToYuan(totalOtherMoney)}</span>
                          </div>
                          <div style={{height:arithUtil.pxToDp(15),display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'35%',color:'#52A056',fontSize:10,textAlign:'left',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,5)}/{item.cargoNo!=null?item.cargoNo:stringUtil.language(675)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'23%',color:'#52A056',fontSize:10,textAlign:'right'})}>{arithUtil.keToWeight(item.totalWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.skinWeight,this.state.weightMode)}-{arithUtil.keToWeight(item.reduceWeight,this.state.weightMode)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'42%'})}></span>
                          </div>
                      </div>)
                      }):<div/>}
                  </div>
                  <div style={{width:'100%',height:'15%',paddingBottom:5}}>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'flex-end',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',color:'#D2855D',textAlign:'left'})}>{stringUtil.language(890)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{this.state.totalWholesaleInfo.totalNumber}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.keToWeight(this.state.totalWholesaleInfo.totalWeight,this.state.weightMode)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'12%',color:'#D2855D'})}></span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalGoodMoney)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{color:'#D2855D'})}>{arithUtil.fenToYuan(this.state.totalWholesaleInfo.totalTotalOtherMoney)}</span>
                      </div>
                      <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'}}>
                          {this.state.documentInfo.after.costs!=null?this.state.documentInfo.after.costs.map((item,key)=>{
                              return(<span style={{fontSize:12,marginLeft:key!=0?arithUtil.pxToDp(30):0}} key={key}>{item.costClauseName}：{arithUtil.fenToYuan(item.money)}</span>)
                          }):<div/>}
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <span style={{fontSize:12}}>{stringUtil.language(858)}{this.state.documentInfo.after.describe}</span>
                      <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                          <span style={{fontSize:12}}>{stringUtil.language(880)}</span>
                          <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.documentInfo.after.money)}</span>
                      </div>
                      </div>
                  </div>
              </div>:<div/>}
          </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopWhole: false, });}}/>
      <MaskModal title={stringUtil.language(421)+'（'+this.state.documentInfo.before.purchaseNo+'）'} visible={this.state.infoShowPopPurch} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%'}}>
            {this.state.documentInfo.log.mode==0?<div style={{width:'100%',height:'8%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFFFE0'}}>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(978)}：</span>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(979)}：</span>
            </div>:<span style={{width:'100%',height:'8%',color:'#F00',fontWeight:'bold',backgroundColor:'#FFFFE0',textAlignVertical:'center'}}>  {stringUtil.language(978)}：</span>}
            <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              {/* {stringUtil.language(978)} */}
              <div style={{width:this.state.documentInfo.log.mode==0?'50%':'100%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span style={{fontSize:15}}>{stringUtil.language(422)}{this.state.documentInfo.before.supplierName}</span>
                          <span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m:s')}</span>
                      </div>
                      <span style={{fontSize:15}}>{stringUtil.language(955)}：{this.state.documentInfo.before.workerName}</span>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1050)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1041)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                  </div>
                  <div style={{height:'56%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                      {this.state.documentInfo.before.commodities!=null?this.state.documentInfo.before.commodities.map((item,key)=>{
                          let otherMoneyTotal = 0;
                          if(item.costs!=null){
                              for(let i=0;i<item.costs.length;i++){
                                  otherMoneyTotal = otherMoneyTotal.add(item.costs[i].money);
                              }
                          }
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{item.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/kg':'/g')):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+item.unitMaster)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{otherMoneyTotal}</span>
                          </div>)
                      }):<div/>}
                  </div>
                  <div style={{height:'29%'}}>
                      <div style={{height:'60%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                          <div style={{width:'72%',height:'100%',overflow:'auto'}}>
                              {this.state.documentInfo.before.costs!=null?this.state.documentInfo.before.costs.map((item,key)=>{
                                  return(<div style={{height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}} key={key}>
                                      <span style={{width:'38%',color:'#808080'}}>{key+1+'、'+item.costClauseName}</span>
                                      <span style={{width:'26%',color:'#808080'}}>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                      {item.calMode==0?<div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{arithUtil.fenToYuan(item.money,2)} {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                      item.calMode==1?<div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode)} {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                                      item.calMode==2?<div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{item.money} %</span></div>:
                                      <div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{arithUtil.fenToYuan(item.money)}</span></div>}
                                  </div>)
                              }):<div/>}
                          </div>
                          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                              <span>{stringUtil.language(1112)}：</span><span style={{color:'#D2855D',fontSize:16,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.documentInfo.before.money)}{stringUtil.currency(1)}</span>
                          </div>
                      </div>
                      <div style={{height:'40%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                          <span>{stringUtil.language(858)}{this.state.documentInfo.before.describe}</span>
                      </div>
                  </div>
              </div>
              <div style={{width:1,height:'100%',backgroundColor:this.state.documentInfo.log.mode==0?'#F00':'transparent'}}/>
              {/* {stringUtil.language(979)} */
              this.state.documentInfo.log.mode==0?<div style={{width:'50%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span style={{fontSize:15}}>{stringUtil.language(422)}{this.state.documentInfo.after.supplierName}</span>
                          <span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.after.time),'Y-M-D h:m:s')}</span>
                      </div>
                      <span style={{fontSize:15}}>{stringUtil.language(955)}：{this.state.documentInfo.after.workerName}</span>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1050)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1041)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',color:'#A9A9A9'})}>{stringUtil.language(1069)}({stringUtil.currency(1)})</span>
                  </div>
                  <div style={{height:'56%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                      {this.state.documentInfo.after.commodities!=null?this.state.documentInfo.after.commodities.map((item,key)=>{
                          let otherMoneyTotal = 0;
                          for(let i=0;i<item.costs.length;i++){
                              otherMoneyTotal = otherMoneyTotal.add(item.costs[i].money);
                          }
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'28%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{item.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/kg':'/g')):(arithUtil.fenToYuan(item.unitPrice,2)+'/'+item.unitMaster)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{arithUtil.fenToYuan(item.goodsMoney)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'18%'})}>{otherMoneyTotal}</span>
                          </div>)
                      }):<div/>}
                  </div>
                  <div style={{height:'29%'}}>
                      <div style={{height:'60%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                          <div style={{width:'72%',height:'100%',overflow:'auto'}}>
                              {this.state.documentInfo.after.costs!=null?this.state.documentInfo.after.costs.map((item,key)=>{
                                  return(<div style={{height:arithUtil.pxToDp(25),display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:10}} key={key}>
                                      <span style={{width:'38%',color:'#808080'}}>{key+1+'、'+item.costClauseName}</span>
                                      <span style={{width:'26%',color:'#808080'}}>{item.calMode==0?stringUtil.language(868):item.calMode==1?stringUtil.language(869):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                                      {item.calMode==0?<div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{arithUtil.fenToYuan(item.money,2)} {stringUtil.currency(1)}/{stringUtil.language(1173)}</span></div>:
                                      item.calMode==1?<div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(item.money,2),0,this.state.weightMode)} {stringUtil.currency(1)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span></div>:
                                      item.calMode==2?<div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{item.money} %</span></div>:
                                      <div style={{width:'36%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                                          <span style={{color:'#D2855D'}}>{arithUtil.fenToYuan(item.money)}</span></div>}
                                  </div>)
                              }):<div/>}
                          </div>
                          <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                              <span>{stringUtil.language(1112)}：</span><span style={{color:'#D2855D',fontSize:16,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.documentInfo.after.money)}{stringUtil.currency(1)}</span>
                          </div>
                      </div>
                      <div style={{height:'40%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                          <span>{stringUtil.language(858)}{this.state.documentInfo.after.describe}</span>
                      </div>
                  </div>
              </div>:<div/>}
          </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopPurch: false, });}}/>
      <MaskModal title={stringUtil.language(566)+'（'+this.state.documentInfo.before.processNo+'）'} visible={this.state.infoShowPopProcess} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%'}}>
            {this.state.documentInfo.log.mode==0?<div style={{width:'100%',height:'8%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFFFE0'}}>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(978)}：</span>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(979)}：</span>
            </div>:<span style={{width:'100%',height:'8%',color:'#F00',fontWeight:'bold',backgroundColor:'#FFFFE0',textAlignVertical:'center'}}>  {stringUtil.language(978)}：</span>}
            <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              {/* {stringUtil.language(978)} */}
              <div style={{width:this.state.documentInfo.log.mode==0?'50%':'100%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'5%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                      <span style={{fontSize:11}}>{stringUtil.language(322)}{stringUtil.subStr(this.state.documentInfo.before.warehouseName,20)}/{this.state.documentInfo.before.cargoNo!=null?this.state.documentInfo.before.cargoNo:stringUtil.language(675)}</span>
                      <span style={{fontSize:11}}>{stringUtil.language(950)}{this.state.documentInfo.before.workerName}</span>
                      <span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m:s')}</span>
                  </div>
                  <div style={{width:'100%',height:'5%',display:'flex',flexDirection:'row',backgroundColor:'#DCDCDC'}}>
                      <div style={{width:'40%',display:'flex',flexDirection:'row'}}>
                          <span style={styles.modalTableTitleItem}>{stringUtil.language(951)}</span>
                          <span style={styles.modalTableTitleItem}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                          <span style={styles.modalTableTitleItem}>{stringUtil.language(952)}</span>
                      </div>
                      <div style={{width:'40%',display:'flex',flexDirection:'row'}}>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(953)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1048)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1060)}</span>
                      </div>
                      <div style={{width:'20%',display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'70%'})}>{stringUtil.language(886)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'30%'})}>{stringUtil.language(1041)}</span>
                      </div>
                  </div>
                  <div style={{width:'100%',height:'85%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <div style={{width:'40%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                          {this.state.documentInfo.before.processCommodities!=null?this.state.documentInfo.before.processCommodities.map((item,key)=>{
                              return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',paddingLeft:arithUtil.pxToDp(5)}} key={key}>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{textAlign:'left'})}>{item.commodityName}</span>
                                  <span style={styles.modalTableTitleItem}>{item.number}/{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                  <span style={styles.modalTableTitleItem}>{(item.lossRate).mul(100)}%</span>
                              </div>)
                          }):<div/>}
                      </div>
                      <div style={{width:'40%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                          {this.state.documentInfo.before.processFinishCommodities!=null?this.state.documentInfo.before.processFinishCommodities.map((item,key)=>{
                              return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',paddingLeft:arithUtil.pxToDp(5)}} key={key}>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%',textAlign:'left'})}>{item.commodityName}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%',textAlign:'left'})}>{item.customerName}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{item.number}/{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{arithUtil.fenToYuan(item.money)}</span>
                              </div>)
                          }):<div/>}
                      </div>
                      <div style={{width:'20%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                          {this.state.documentInfo.before.costs!=null?this.state.documentInfo.before.costs.map((item,key)=>{
                              return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}} key={key}>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'70%',textAlign:'left'})}>{item.costClauseName}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'30%'})}>{arithUtil.fenToYuan(item.money)}</span>
                              </div>)
                          }):<div/>}
                      </div>
                  </div>
                  <div style={{height:'5%'}}><span>{stringUtil.language(858)}{this.state.documentInfo.before.describe}</span></div>
              </div>
              <div style={{width:1,height:'100%',backgroundColor:this.state.documentInfo.log.mode==0?'#F00':'transparent'}}/>
              {/* {stringUtil.language(979)} */
              this.state.documentInfo.log.mode==0?<div style={{width:'50%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'5%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                      <span style={{fontSize:11}}>{stringUtil.language(322)}{stringUtil.subStr(this.state.documentInfo.after.warehouseName,20)}/{this.state.documentInfo.after.cargoNo!=null?this.state.documentInfo.after.cargoNo:stringUtil.language(675)}</span>
                      <span style={{fontSize:11}}>{stringUtil.language(950)}{this.state.documentInfo.after.workerName}</span>
                      <span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.after.time),'Y-M-D h:m:s')}</span>
                  </div>
                  <div style={{width:'100%',height:'5%',display:'flex',flexDirection:'row',backgroundColor:'#DCDCDC'}}>
                      <div style={{width:'40%',display:'flex',flexDirection:'row'}}>
                          <span style={styles.modalTableTitleItem}>{stringUtil.language(951)}</span>
                          <span style={styles.modalTableTitleItem}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                          <span style={styles.modalTableTitleItem}>{stringUtil.language(952)}</span>
                      </div>
                      <div style={{width:'40%',display:'flex',flexDirection:'row'}}>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(953)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1048)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{stringUtil.language(1060)}</span>
                      </div>
                      <div style={{width:'20%',display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'70%'})}>{stringUtil.language(886)}</span>
                          <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'30%'})}>{stringUtil.language(1041)}</span>
                      </div>
                  </div>
                  <div style={{width:'100%',height:'85%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                      <div style={{width:'40%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                          {this.state.documentInfo.after.processCommodities!=null?this.state.documentInfo.after.processCommodities.map((item,key)=>{
                              return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',paddingLeft:arithUtil.pxToDp(5)}} key={key}>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{textAlign:'left'})}>{item.commodityName}</span>
                                  <span style={styles.modalTableTitleItem}>{item.number}/{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                  <span style={styles.modalTableTitleItem}>{(item.lossRate).mul(100)}%</span>
                              </div>)
                          }):<div/>}
                      </div>
                      <div style={{width:'40%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                          {this.state.documentInfo.after.processFinishCommodities!=null?this.state.documentInfo.after.processFinishCommodities.map((item,key)=>{
                              return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',paddingLeft:arithUtil.pxToDp(5)}} key={key}>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%',textAlign:'left'})}>{item.commodityName}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%',textAlign:'left'})}>{item.customerName}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{item.number}/{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'25%'})}>{arithUtil.fenToYuan(item.money)}</span>
                              </div>)
                          }):<div/>}
                      </div>
                      <div style={{width:'20%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                          {this.state.documentInfo.after.costs!=null?this.state.documentInfo.after.costs.map((item,key)=>{
                              return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}} key={key}>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'70%',textAlign:'left'})}>{item.costClauseName}</span>
                                  <span style={arithUtil.stylesAdd(styles.modalTableTitleItem,{width:'30%'})}>{arithUtil.fenToYuan(item.money)}</span>
                              </div>)
                          }):<div/>}
                      </div>
                  </div>
                  <div style={{height:'5%'}}><span>{stringUtil.language(858)}{this.state.documentInfo.after.describe}</span></div>
              </div>:<div/>}
          </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopProcess: false, });}}/>
      <MaskModal title={stringUtil.language(827)+'（'+this.state.documentInfo.before.allotNo+'）'} visible={this.state.infoShowPopAllot} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%'}}>
            {this.state.documentInfo.log.mode==0?<div style={{width:'100%',height:'8%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFFFE0'}}>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(978)}：</span>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(979)}：</span>
            </div>:<span style={{width:'100%',height:'8%',color:'#F00',fontWeight:'bold',backgroundColor:'#FFFFE0',textAlignVertical:'center'}}>  {stringUtil.language(978)}：</span>}
            <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              {/* {stringUtil.language(978)} */}
              <div style={{width:this.state.documentInfo.log.mode==0?'50%':'100%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span style={{fontSize:15}}>{stringUtil.language(677)}：{this.state.documentInfo.before.warehouseOutName}</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m:s')}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span style={{fontSize:15}}>{stringUtil.language(678)}：{this.state.documentInfo.before.warehouseInName}</span><span>{stringUtil.language(679)}：{this.state.documentInfo.before.workerName}</span>
                      </div>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',color:'#A9A9A9'})}>{stringUtil.language(1084)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                  </div>
                  <div style={{height:'80%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                      {(()=>{this.state.currItemAllotTotal = {variety:0,number:0,weight:0};})()}
                      {this.state.documentInfo.before.commodities!=null?this.state.documentInfo.before.commodities.map((item,key)=>{
                          this.state.currItemAllotTotal = {
                              variety:this.state.currItemAllotTotal.variety+1,
                              number:(this.state.currItemAllotTotal.number).add(item.number),
                              weight:(this.state.currItemAllotTotal.weight).add(item.weight)
                          };
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',textAlign:'left'})}>{item.targetName} / {item.cargoNo!=null?item.cargoNo:stringUtil.language(675)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                          </div>)
                      }):<div/>}
                  </div>
                  <span style={{height:'5%',textAlign:'right',textAlignVertical:'center',color:'#D2855D'}}>{stringUtil.language(890)}{this.state.currItemAllotTotal.variety}{stringUtil.language(1177)}，{this.state.currItemAllotTotal.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(this.state.currItemAllotTotal.weight,this.state.weightMode)}{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
              </div>
              <div style={{width:1,height:'100%',backgroundColor:this.state.documentInfo.log.mode==0?'#F00':'transparent'}}/>
              {/* {stringUtil.language(979)} */
              this.state.documentInfo.log.mode==0?<div style={{width:'50%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span style={{fontSize:15}}>{stringUtil.language(677)}：{this.state.documentInfo.after.warehouseOutName}</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.after.time),'Y-M-D h:m:s')}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span style={{fontSize:15}}>{stringUtil.language(678)}：{this.state.documentInfo.after.warehouseInName}</span><span>{stringUtil.language(679)}：{this.state.documentInfo.after.workerName}</span>
                      </div>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',color:'#A9A9A9'})}>{stringUtil.language(1084)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                  </div>
                  <div style={{height:'85%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                      {(()=>{this.state.currItemAllotTotals = {variety:0,number:0,weight:0};})()}
                      {this.state.documentInfo.after.commodities!=null?this.state.documentInfo.after.commodities.map((item,key)=>{
                          this.state.currItemAllotTotals = {
                              variety:this.state.currItemAllotTotals.variety+1,
                              number:(this.state.currItemAllotTotals.number).add(item.number),
                              weight:(this.state.currItemAllotTotals.weight).add(item.weight)
                          };
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%',textAlign:'left'})}>{item.targetName} / {item.cargoNo!=null?item.cargoNo:stringUtil.language(675)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'33%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                          </div>)
                      }):<div/>}
                  </div>
                  <span style={{height:'5%',textAlign:'right',textAlignVertical:'center',color:'#D2855D'}}>{stringUtil.language(890)}{this.state.currItemAllotTotals.variety}{stringUtil.language(1177)}，{this.state.currItemAllotTotals.number}{stringUtil.language(1173)}，{arithUtil.keToWeight(this.state.currItemAllotTotals.weight,this.state.weightMode)}{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
              </div>:<div/>}
          </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopAllot: false, });}}/>
      <MaskModal title={stringUtil.language(584)+'（'+this.state.documentInfo.before.title+'）'} visible={this.state.infoShowPopReSend} width={arithUtil.ProportionTo(document.body.clientWidth,this.state.documentInfo.log.mode==0?0.95:0.6)} height={arithUtil.ProportionTo(document.body.clientHeight,0.92)}
        content={<div style={{width:'100%',height:'100%'}}>
            {this.state.documentInfo.log.mode==0?<div style={{width:'100%',height:'8%',display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFFFE0'}}>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(978)}：</span>
                <span style={{width:'50%',color:'#F00',fontWeight:'bold'}}>  {stringUtil.language(979)}：</span>
            </div>:<span style={{width:'100%',height:'8%',color:'#F00',fontWeight:'bold',backgroundColor:'#FFFFE0',textAlignVertical:'center'}}>  {stringUtil.language(978)}：</span>}
            <div style={{width:'100%',height:'92%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
              {/* {stringUtil.language(978)} */}
              <div style={{width:this.state.documentInfo.log.mode==0?'50%':'100%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span>{stringUtil.language(882)}{this.state.documentInfo.before.stockInOutNo}</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.before.time),'Y-M-D h:m:s')} {this.state.documentInfo.before.sign==0?stringUtil.language(1185):stringUtil.language(1186)}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span>{stringUtil.language(1118)}：{this.state.documentInfo.before.workerName}</span><span>{stringUtil.language(892)}{this.state.documentInfo.before.warehouseName}</span>
                      </div>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'50%',color:'#A9A9A9'})}>{stringUtil.language(456)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                  </div>
                  <div style={{height:'75%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                      {this.state.documentInfo.before.commodities!=null?this.state.documentInfo.before.commodities.map((item,key)=>{
                          this.state.documentInfo.before.numberTotal = (this.state.documentInfo.before.numberTotal!=null?this.state.documentInfo.before.numberTotal:0).add(item.number);
                          this.state.documentInfo.before.weightTotal = (this.state.documentInfo.before.weightTotal!=null?this.state.documentInfo.before.weightTotal:0).add(item.weight);
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'50%',textAlign:'left',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.targetType==0?stringUtil.subStr(item.targetName,10):item.targetType==1?stringUtil.language(426):stringUtil.language(438)} / {item.cargoNo!=null?item.cargoNo:stringUtil.language(675)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                          </div>)
                      }):<div/>}
                  </div>
                  <div style={{height:'10%'}}>
                      <span style={{textAlignVertical:'center',color:'#D2855D',fontSize:12}}>{stringUtil.language(326)} {this.state.documentInfo.before.commodities!=null?this.state.documentInfo.before.commodities.length:0} {stringUtil.language(1177)} {this.state.documentInfo.before.numberTotal!=null?this.state.documentInfo.before.numberTotal:0} {stringUtil.language(1173)} {this.state.documentInfo.before.weightTotal!=null?arithUtil.keToWeight(this.state.documentInfo.before.weightTotal,this.state.weightMode):0} {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:arithUtil.pxToDp(10)}}>
                          <span>{stringUtil.language(858)}{this.state.documentInfo.before.describe}</span>
                      </div>
                  </div>
              </div>
              <div style={{width:1,height:'100%',backgroundColor:this.state.documentInfo.log.mode==0?'#F00':'transparent'}}/>
              {/* {stringUtil.language(979)} */
              this.state.documentInfo.log.mode==0?<div style={{width:'50%',height:'100%',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5)}}>
                  <div style={{height:'10%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span>{stringUtil.language(882)}{this.state.documentInfo.after.stockInOutNo}</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.documentInfo.after.time),'Y-M-D h:m:s')} {this.state.documentInfo.after.sign==0?stringUtil.language(1185):stringUtil.language(1186)}</span>
                      </div>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          <span>{stringUtil.language(1118)}：{this.state.documentInfo.after.workerName}</span><span>{stringUtil.language(892)}{this.state.documentInfo.after.warehouseName}</span>
                      </div>
                  </div>
                  <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'50%',color:'#A9A9A9'})}>{stringUtil.language(456)}</span>
                      <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',color:'#A9A9A9'})}>{stringUtil.language(1021)}/{stringUtil.language(1022)}</span>
                  </div>
                  <div style={{height:'75%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                      {this.state.documentInfo.after.commodities!=null?this.state.documentInfo.after.commodities.map((item,key)=>{
                          this.state.documentInfo.after.numberTotals = (this.state.documentInfo.after.numberTotals!=null?this.state.documentInfo.after.numberTotals:0).add(item.number);
                          this.state.documentInfo.after.weightTotals = (this.state.documentInfo.after.weightTotals!=null?this.state.documentInfo.after.weightTotals:0).add(item.weight);
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%',textAlign:'left'})}>{item.commodityName}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'50%',textAlign:'left',overflow:'hidden',whiteSpace:'nowrap',textOverflow:'ellipsis'})}>{item.targetType==0?stringUtil.subStr(item.targetName,10):item.targetType==1?stringUtil.language(426):stringUtil.language(438)} / {item.cargoNo!=null?item.cargoNo:stringUtil.language(675)}</span>
                              <span style={arithUtil.stylesAdd(styles.tableTd,{width:'25%'})}>{item.number} / {arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                          </div>)
                      }):<div/>}
                  </div>
                  <div style={{height:'10%'}}>
                      <span style={{textAlignVertical:'center',color:'#D2855D',fontSize:12}}>{stringUtil.language(326)} {this.state.documentInfo.after.commodities!=null?this.state.documentInfo.after.commodities.length:0} {stringUtil.language(1177)} {this.state.documentInfo.after.numberTotals!=null?this.state.documentInfo.after.numberTotals:0} {stringUtil.language(1173)} {this.state.documentInfo.after.weightTotals!=null?arithUtil.keToWeight(this.state.documentInfo.after.weightTotals,this.state.weightMode):0} {this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>
                      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:arithUtil.pxToDp(10)}}>
                          <span>{stringUtil.language(858)}{this.state.documentInfo.after.describe}</span>
                      </div>
                  </div>
              </div>:<div/>}
          </div>
        </div>}closeBnTopEvents={() => {this.setState({ infoShowPopReSend: false, });}}/>
    </div>)
  }
}

const styles = {
  inputText:{
      height:arithUtil.pxToDp(30),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5)
  },
  tableTitle:{
      borderStyle:'solid',
      borderColor:'#F0F0F0',
      borderWidth: 1,
      backgroundColor:'#F2F2F2',
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
  },
  tableTd:{
      width:'12%',
      fontSize:15,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
  },
  modalTableTitleItem:{
      width:'33%',
      fontSize:11,
      color:'#808080',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
  },
  time:{
      height:30,
      display:'flex',
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      borderStyle:'solid',
      borderWidth:1,
      borderColor:'#A9A9A9',
      borderRadius:3,
  },
};