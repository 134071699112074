import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
 import TabTopActivity from '../components/TabTopActivity';
 import MaskModal from '../components/MaskModal';
 import Pagination from '../components/Pagination';

  export default class CustomerCollectInfoView extends Component{
    constructor(props) {
      super(props);
      this.state={
        currCustomerCollectItem:{},//cargoMode，-1库存 0代销 1{stringUtil.language(1051)} 2炒货
        inputTextTagVal:'',
        keyTagVal:-1,
      };
    }
    componentDidMount(){
        console.log('=====',this.props.collectItem)
        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL+'/'+this.props.collectItem.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({currCustomerCollectItem:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'99%',backgroundColor:'#FFF'}}>
            <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span style={{fontSize:16}}>{stringUtil.language(903)}{this.state.currCustomerCollectItem.customerName}</span>
                <div style={{display:'flex',flexDirection:'column',alignItems:'flex-end'}}>
                    <span style={{color:'#D2855D',fontSize:12}}>{arithUtil.formatToTimeStr(new Date(this.state.currCustomerCollectItem.time),'Y-M-D h:m:s')}</span>
                    <span style={{color:'#F00',fontSize:12,fontWeight:'bold'}}>SK.{this.state.currCustomerCollectItem.customerCollectNo}</span>
                </div>
            </div>
            <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10}}>
                <span style={{fontSize:16}}>{stringUtil.language(884)}{this.state.currCustomerCollectItem.accountName}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span>{stringUtil.language(199)}</span><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>({arithUtil.fenToYuan(this.state.currCustomerCollectItem.collectMoney)}+{arithUtil.fenToYuan(this.state.currCustomerCollectItem.discountMoney)}){stringUtil.currency(1)}</span>
                </div>
            </div>
            <div style={{width:'100%',height:30,backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                <span style={{width:'25%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(471)}</span>
                <span style={{width:'37%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1049)}</span>
                <span style={{width:'17%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1079)}</span>
                <span style={{width:'21%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1080)}</span>
            </div>
            <div style={{paddingLeft:10,paddingRight:10}}>
                {this.state.currCustomerCollectItem.collects!=null?this.state.currCustomerCollectItem.collects.map((item,key)=>{
                    return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,paddingRight:5}} key={key}>
                        <span style={{width:'28%',color:item.collectMode==0?'#007ACC':'#696969',fontSize:16}}>{item.collectMode==0?stringUtil.language(913):item.wholesaleNo}</span>
                        <span style={{width:'35%',fontSize:16}}>{item.targetType==1?stringUtil.language(426):item.targetName}</span>
                        <span style={{width:'17%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.discountMoney)}</span>
                        <span style={{width:'20%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.collectMoney)}</span>
                    </div>)
                }):''}
            </div>
            <span style={{paddingLeft:10}}>{stringUtil.language(858)}{this.state.currCustomerCollectItem.describe}</span>
        </div>)
    }
}

const styles = {}