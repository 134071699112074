import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';

  export default class ProcessMaterialView extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        processVoItem:this.props.paramsInfo?JSON.parse(this.props.paramsInfo.processVoItem):null,
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
    }

    submitItem(){
        this.props.successEvent('传递的值');
    }

    render(){
        return(<div style={{width:'98.5%',height:'100%',position:'relative'}}>
            <div style={{height:30,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#F2F2F2'}}>
                <span style={{width:arithUtil.ProportionTo(document.body.clientWidth,0.1),color:'#808080',fontSize:12,textAlign:'center'}}>{stringUtil.language(546)}</span>
                <span style={{width:arithUtil.ProportionTo(document.body.clientWidth,0.06),color:'#808080',fontSize:12,textAlign:'center'}}>{stringUtil.language(547)}</span>
                {(this.state.processVoItem.processCommodities?this.state.processVoItem.processCommodities:[]).map((item,key)=>{
                    return(<span style={{width:70,fontSize:11,textAlign:'center'}} key={key}>{item.commodityName}</span>);
                })}
            </div>
            <div style={{width:'100%',height:'100%',overflow:'auto'}}>
                {(this.state.processVoItem.processFinishCommodities?this.state.processVoItem.processFinishCommodities:[]).map((itemb,keyb)=>{
                    let membersTotalWeight=0;
                    for(let i=0;i<(itemb.members?itemb.members:[]).length;i++){
                        membersTotalWeight=membersTotalWeight.add(itemb.members[i].weight);
                    }
                    return(<div style={{height:35,display:'flex',flexDirection:'row',alignItems:'center'}} key={keyb}>
                        <div style={{width:arithUtil.ProportionTo(document.body.clientWidth,0.1),height:'100%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                            <span style={{fontSize:11,paddingLeft:5}} numberOfLines={1}>{itemb.commodityName}</span>
                            <span style={{color:'#A9A9A9',fontSize:7,paddingLeft:5,position:'absolute',bottom:1}}>{itemb.number}{itemb.commodityUnitMaster?itemb.commodityUnitMaster:stringUtil.language(1173)}</span>
                        </div>
                        <span ref={'comFinishShishiRef'+keyb} style={{width:arithUtil.ProportionTo(document.body.clientWidth,0.06),fontSize:12,textAlign:'center'}}>{arithUtil.keToWeight(membersTotalWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023))}</span>
                        {(this.state.processVoItem.processCommodities?this.state.processVoItem.processCommodities:[]).map((item,key)=>{
                            for(let i=0;i<(itemb.members?itemb.members:[]).length;i++){
                                if(itemb.members[i].memberId==item.commodityId){
                                    return(<div style={{width:70,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#A9A9A9',borderWidth:0.1}} key={key}>
                                        <input style={{width:'95%',height:30,fontSize:itemb.members[i].weight?14:10,color:itemb.members[i].weight?'#52A056':'#A9A9A9',fontWeight:itemb.members[i].weight?'bold':'normal',textAlign:'center'}} type='number' onChange={(event)=>{
                                            let isFinishHava=0;
                                            for(let i=0;i<(this.state.processVoItem.processFinishCommodities[keyb].members?this.state.processVoItem.processFinishCommodities[keyb].members:[]).length;i++){
                                                if(this.state.processVoItem.processFinishCommodities[keyb].members[i].memberId==this.state.processVoItem.processCommodities[key].commodityId){
                                                    this.state.processVoItem.processFinishCommodities[keyb].members[i].weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);isFinishHava=1;break;
                                                }
                                            }
                                            if(!isFinishHava){this.state.processVoItem.processFinishCommodities[keyb].members.push({
                                                memberId:this.state.processVoItem.processCommodities[key].commodityId,
                                                memberName:this.state.processVoItem.processCommodities[key].commodityName,
                                                weight:arithUtil.weightToKe(event.target.value,this.state.weightMode),
                                            });}
                                            this.props.successEvent(this.state.processVoItem);
                                            let membersTotalWeight=0;
                                            for(let i=0;i<(this.state.processVoItem.processFinishCommodities[keyb].members?this.state.processVoItem.processFinishCommodities[keyb].members:[]).length;i++){
                                                membersTotalWeight=membersTotalWeight.add(this.state.processVoItem.processFinishCommodities[keyb].members[i].weight);
                                            }
                                            (this.refs)['comFinishShishiRef'+key].value=arithUtil.keToWeight(membersTotalWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023));
                                        }} defaultValue={itemb.members[i].weight?arithUtil.keToWeight(itemb.members[i].weight,this.state.weightMode):''} placeholder={stringUtil.language(1103)+'/'+(itemb.commodityUnitMaster?itemb.commodityUnitMaster:stringUtil.language(1173))}/>
                                    </div>);
                                }
                            }
                            return(<div style={{width:70,height:'100%',display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#A9A9A9',borderWidth:0.1}} key={key}>
                                <input style={{width:'95%',height:30,fontSize:10,color:'#A9A9A9',textAlign:'center'}} type='number' onChange={(event)=>{
                                    let isFinishHava=0;
                                    for(let i=0;i<(this.state.processVoItem.processFinishCommodities[keyb].members?this.state.processVoItem.processFinishCommodities[keyb].members:[]).length;i++){
                                        if(this.state.processVoItem.processFinishCommodities[keyb].members[i].memberId==this.state.processVoItem.processCommodities[key].commodityId){
                                            this.state.processVoItem.processFinishCommodities[keyb].members[i].weight = arithUtil.weightToKe(event.target.value,this.state.weightMode);isFinishHava=1;break;
                                        }
                                    }
                                    if(!isFinishHava){this.state.processVoItem.processFinishCommodities[keyb].members.push({
                                        memberId:this.state.processVoItem.processCommodities[key].commodityId,
                                        memberName:this.state.processVoItem.processCommodities[key].commodityName,
                                        weight:arithUtil.weightToKe(event.target.value,this.state.weightMode),
                                    });}
                                    this.props.successEvent(this.state.processVoItem);
                                    let membersTotalWeight=0;
                                    for(let i=0;i<(this.state.processVoItem.processFinishCommodities[keyb].members?this.state.processVoItem.processFinishCommodities[keyb].members:[]).length;i++){
                                        membersTotalWeight=membersTotalWeight.add(this.state.processVoItem.processFinishCommodities[keyb].members[i].weight);
                                    }
                                    (this.refs)['comFinishShishiRef'+key].value=arithUtil.keToWeight(membersTotalWeight,this.state.weightMode)+(this.state.weightMode?stringUtil.language(1172):stringUtil.language(1023));
                                }} placeholder={stringUtil.language(1103)+'/'+(itemb.commodityUnitMaster?itemb.commodityUnitMaster:stringUtil.language(1173))}/>
                            </div>);
                        })}
                    </div>);
                })}
            </div>
        </div>)
    }
}

const styles = {};