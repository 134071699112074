import React,{Component} from 'react';
import PaginateUpload from 'iscroll-luo';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 export default class ShareBusinessCus extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
          tag:1,//0stringUtil.language(331)，1stringUtil.language(1143)stringUtil.language(866)，2已stringUtil.language(1043)信息
          weightMode:stringUtil.getUrlParamsVal('weightMode',search)?stringUtil.getUrlParamsVal('weightMode',search):0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
          customerInfoDO:{},
          searchWholesale:{
            stallId:stringUtil.getUrlParamsVal('stallId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('stallId',search),1)):0,
            customerId:stringUtil.getUrlParamsVal('customerId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('customerId',search),1)):0,
            collection:0,//-1stringUtil.language(1097)，0stringUtil.language(1087)完，1stringUtil.language(1185)完
            totalCount:0,//数据总stringUtil.language(1021)
            page:0,//stringUtil.language(1175)几stringUtil.language(1199)
          },
          wholesaleList:[],
          searchCollectPayment:{
            stallId:stringUtil.getUrlParamsVal('stallId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('stallId',search),1)):0,
            customerId:stringUtil.getUrlParamsVal('customerId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('customerId',search),1)):0,
            totalCount:0,//数据总stringUtil.language(1021)
            page:0,//stringUtil.language(1175)几stringUtil.language(1199)
          },
          collectPaymentList:[],
      }
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.SHARE_CUSTOMER_INFO_URL,{sign:otherUtil.encDec(this.state.searchWholesale.stallId+','+this.state.searchWholesale.customerId)},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({customerInfoDO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
        this.initWholesale();
        this.initCollectPayment();
    }
    initWholesale(){
        stringUtil.httpRequest(urlUtil.SHARE_WHOLESALE_URL,{
            sign:otherUtil.encDec(this.state.searchWholesale.stallId+','+this.state.searchWholesale.customerId),
            collection:this.state.searchWholesale.collection,
            page:this.state.searchWholesale.page,
            size:20,//每stringUtil.language(1199)20条
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.searchWholesale.totalCount = successResponse.data.count;
                this.state.searchWholesale.page = successResponse.data.currPage;
                if(this.state.searchWholesale.page==0) this.setState({wholesaleList:successResponse.data.items});
                else{this.setState({wholesaleList:[...this.state.wholesaleList,successResponse.data.items]});}
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initCollectPayment(){
        stringUtil.httpRequest(urlUtil.SHARE_COLLECT_URL,{
            sign:otherUtil.encDec(this.state.searchCollectPayment.stallId+','+this.state.searchCollectPayment.customerId),
            page:this.state.searchCollectPayment.page,
            size:20,//每stringUtil.language(1199)20条
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.searchCollectPayment.totalCount = successResponse.data.count;
                this.state.searchCollectPayment.page = successResponse.data.currPage;
                if(this.state.searchCollectPayment.page==0) this.setState({collectPaymentList:successResponse.data.items});
                else{this.setState({collectPaymentList:[...this.state.collectPaymentList,successResponse.data.items]});}
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#F0F0F0'}}>
            <div style={{height:120,backgroundColor:'#1A895A',paddingLeft:10,paddingRight:10}}>
                <div style={{position:'absolute',top:5,right:5,padding:5}}><span style={{color:'#FFF',borderBottomStyle:'solid',borderBottomColor:'#FFF',borderBottomWidth:1}} onClick={()=>{
                    this.props.history.push({ pathname: "/ShareBusinessDetailCus", search:'?weightMode='+this.state.weightMode+'&stallId='+otherUtil.encDec(this.state.searchWholesale.stallId)+'&customerId='+otherUtil.encDec(this.state.searchWholesale.customerId)});
                }}>{stringUtil.language(108)} &gt;&gt;</span></div>
                <div style={{height:70,display:'flex',flexDirection:'column',justifyContent:'center',borderBottomStyle:'solid',borderBottomColor:'#FFF',borderBottomWidth:1}}>
                    <span style={{color:'#FFF',fontSize:25,fontWeight:'bold'}}>{this.state.customerInfoDO.customerName}</span>
                    <span style={{color:'#F5F306',fontSize:18,fontWeight:'bold'}}>{stringUtil.language(1124)}【{this.state.customerInfoDO.shopName}】{stringUtil.language(621)}：</span>
                </div>
                <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{width:'35%',color:'#FFF'}}>{stringUtil.language(1091)}：{arithUtil.fenToYuan(this.state.customerInfoDO.customerInitArrears)}{stringUtil.currency(1)}</span>
                    <span style={{width:'35%',color:'#FFF'}}>{stringUtil.language(877)}{arithUtil.fenToYuan(this.state.customerInfoDO.wholesaleMoney)}{stringUtil.currency(1)}</span>
                    <span style={{width:'30%',color:'#F5F306'}}>{stringUtil.language(476)}</span>
                </div>
                <div style={{height:25,display:'flex',flexDirection:'row'}}>
                    <span style={{width:'35%',color:'#FFF'}}>{stringUtil.language(879)}{arithUtil.fenToYuan(this.state.customerInfoDO.discountMoney)}{stringUtil.currency(1)}</span>
                    <span style={{width:'35%',color:'#FFF'}}>{stringUtil.language(1126)}：{arithUtil.fenToYuan(this.state.customerInfoDO.collectMoney)}{stringUtil.currency(1)}</span>
                    <div style={{width:'30%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.customerInfoDO.customerArrears)}</span><span style={{color:'#F5F306'}}>{stringUtil.currency(1)}</span>
                    </div>
                </div>
            </div>
            <div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:5,paddingRight:5}}>
                <span style={{width:'32%',height:'35px',lineHeight:'35px',color:this.state.tag==0?'#000':'#808080',fontSize:18,fontWeight:this.state.tag==0?'bold':'normal',backgroundColor:this.state.tag==0?'#FDCB0C':'#D9D9D9',textAlign:'center'}} onClick={()=>{
                    this.state.tag = 0;
                    this.state.searchWholesale.collection = -1;
                    this.state.searchWholesale.page = 0;
                    this.initWholesale();
                }}>{stringUtil.language(331)}</span>
                <span style={{width:'32%',height:'35px',lineHeight:'35px',color:this.state.tag==1?'#000':'#808080',fontSize:18,fontWeight:this.state.tag==1?'bold':'normal',backgroundColor:this.state.tag==1?'#FDCB0C':'#D9D9D9',textAlign:'center'}} onClick={()=>{
                    this.state.tag = 1;
                    this.state.searchWholesale.collection = 0;
                    this.state.searchWholesale.page = 0;
                    this.initWholesale();
                }}>{stringUtil.language(1143)}{stringUtil.language(866)}</span>
                <span style={{width:'32%',height:'35px',lineHeight:'35px',color:this.state.tag==2?'#000':'#808080',fontSize:18,fontWeight:this.state.tag==2?'bold':'normal',backgroundColor:this.state.tag==2?'#FDCB0C':'#D9D9D9',textAlign:'center'}} onClick={()=>{
                    this.state.tag = 2;
                    this.state.searchCollectPayment.page = 0;
                    this.initCollectPayment();
                }}>{stringUtil.language(1043)+'信息'}</span>
            </div>
            <div style={{height:20,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5}}><span style={{color:'#F00',fontSize:10,fontWeight:'bold'}}>{stringUtil.language(1182)}计：{this.state.tag==2?this.state.searchCollectPayment.totalCount+stringUtil.language(1192)+stringUtil.language(1043):this.state.searchWholesale.totalCount+'个'+stringUtil.language(866)}。</span></div>
            {this.state.tag==0||this.state.tag==1?<div style={{position:'absolute',left:0,top:180,right:0,bottom:(this.state.wxPromitShow==null||this.state.wxPromitShow)?60:0,paddingLeft:5,paddingRight:5,backgroundColor:'#F0F0F0',overflow:'auto',border: '1px solid #eee'}}>
                <PaginateUpload onPullDownRefresh={()=>{
                    this.state.searchWholesale.page = 0;
                    this.initWholesale();
                }} onPullUpLoadMore={()=>{
                    if(this.state.wholesaleList.length<this.state.searchWholesale.totalCount){
                        this.state.searchWholesale.page = this.state.searchWholesale.page+1;
                        this.initWholesale();
                    }
                }} >
                    {this.state.wholesaleList.map((item,key)=>{
                        return(<div style={{height:100,display:'flex',flexDirection:'column',backgroundColor:'#FFF',marginBottom:7}} key={key} onClick={()=>{
                            this.props.history.push({ pathname: "/ShareWholesaleConfirmInfo", search:'?weightMode='+this.state.weightMode+'&wholesaleId='+otherUtil.encDec(item.id)+'&stallId='+otherUtil.encDec(this.state.searchWholesale.stallId)+'&customerId='+otherUtil.encDec(this.state.searchWholesale.customerId)});
                        }}>
                            <div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#D3D3D3',borderBottomWidth:1,paddingLeft:5,paddingRight:5}}>
                                <div style={{width:'32%'}}><span style={{fontSize:16,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(item.createTime),'Y-M-D')}</span><br/><span style={{color:'#A9A9A9',fontSize:10,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(item.createTime),'h:m:s')}</span></div>
                                <div style={{width:'27%'}}><span style={{fontSize:16,fontWeight:'bold'}}>{stringUtil.language(1175)}{stringUtil.subStrEnd(item.wholesaleNo,4)}{stringUtil.language(1189)}</span><br/><span style={{color:'#A9A9A9',fontSize:10,fontWeight:'bold'}}>{stringUtil.language(1122)}：{item.workerId==0?stringUtil.language(431):item.workerName}</span></div>
                                <div style={{width:'24%',display:'flex',flexDirection:'column',alignItems:'flex-end'}}><span style={{fontSize:15,fontWeight:'bold'}}>{arithUtil.fenToYuan(item.money)}{stringUtil.currency(1)}</span><br/><span style={{fontSize:10,fontWeight:'bold'}}> </span></div>
                                <div style={{width:'17%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                                    {item.collectionFinish==0?<span style={{width:'90%',height:'25px',lineHeight:'25px',color:'#FFF',backgroundColor:'#1EAE73',textAlign:'center'}}>{stringUtil.language(973)}</span>:
                                    <img style={{width:70,transform:[{rotate:'-20deg'}]}} loading="lazy" src={require('../images/collect_finish.png').default} title={stringUtil.language(84)}/>}
                                </div>
                            </div>
                            {item.commodities.map((itemb,keyb)=>{
                                if(keyb<2){
                                    let comTotalMoney=itemb.goodsMoney;
                                    for(let i=0;i<itemb.costs.length;i++){
                                        comTotalMoney = comTotalMoney.add(itemb.costs[i].money);
                                    }
                                    return(<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:5,paddingRight:5}} key={keyb}>
                                        <span style={{width:'32%',color:'#808080',fontSize:13}}>{stringUtil.subStr(itemb.commodityName,6)}</span>
                                        <span style={{width:'27%',color:'#808080',fontSize:13}}>{itemb.number}{stringUtil.language(1173)}{arithUtil.keToWeight(itemb.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}</span>
                                        {itemb.mode==0?<span style={{width:'24%',color:'#808080',fontSize:13,textAlign:'right'}}>{arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)}/{this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)}</span>:
                                        <span style={{width:'24%',color:'#808080',fontSize:13,textAlign:'right'}}>{arithUtil.fenToYuan(itemb.unitPrice,2)}</span>}
                                        <span style={{width:'17%',color:'#808080',fontSize:13,textAlign:'right'}}>{arithUtil.fenToYuan(comTotalMoney)}{stringUtil.currency(1)}</span>
                                    </div>);
                                }
                            })}
                            {item.commodities.length>2?<span style={{color:'#F00'}}>...{item.commodities.length}{stringUtil.language(989)}</span>:''}
                        </div>);
                    })}
                </PaginateUpload>
            </div>:''}
            {this.state.tag==2?<div style={{position:'absolute',left:0,top:180,right:0,bottom:(this.state.wxPromitShow==null||this.state.wxPromitShow)?50:0,paddingLeft:5,paddingRight:5,backgroundColor:'#F0F0F0',overflow:'auto',border: '1px solid #eee'}}>
                <PaginateUpload onPullDownRefresh={()=>{
                    this.state.searchCollectPayment.page = 0;
                    this.initCollectPayment();
                }} onPullUpLoadMore={()=>{
                    if(this.state.collectPaymentList.length<this.state.searchCollectPayment.totalCount){
                        this.state.searchCollectPayment.page = this.state.searchCollectPayment.page+1;
                        this.initCollectPayment();
                    }
                }} >
                    {this.state.collectPaymentList.map((item,key)=>{
                        return(<div style={{height:45,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF',paddingLeft:5,paddingRight:5,marginBottom:7}} key={key}>
                            <div style={{width:'32%'}}><span style={{fontSize:14,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span><br/><span style={{color:'#A9A9A9',fontSize:10,fontWeight:'bold'}}>{arithUtil.formatToTimeStr(new Date(item.time),'h:m:s')}</span></div>
                            <div style={{width:'27%'}}><span style={{fontSize:14,fontWeight:'bold'}}>{stringUtil.language(1175)}{stringUtil.subStrEnd(item.customerCollectNo,4)}{stringUtil.language(1192)}</span><br/><span style={{color:'#A9A9A9',fontSize:10,fontWeight:'bold'}}>{stringUtil.language(1185)}银：{item.workerId==0?stringUtil.language(431):item.workerName}</span></div>
                            <div style={{width:'24%',display:'flex',flexDirection:'column',alignItems:'flex-end'}}><span style={{color:'#F00',fontSize:14,fontWeight:'bold'}}>{stringUtil.language(1080)}:{arithUtil.fenToYuan(item.collectMoney)}</span><span style={{fontSize:10,color:'#A9A9A9',fontWeight:'bold'}}>{item.accountName}</span></div>
                            <div style={{width:'17%',display:'flex',flexDirection:'column',alignItems:'flex-end'}}><span style={{fontSize:13}}>{stringUtil.language(1042)}:{arithUtil.fenToYuan(item.discountMoney)}</span><span style={{fontSize:10,fontWeight:'bold'}}> </span></div>
                        </div>);
                    })}
                </PaginateUpload>
            </div>:''}
            {(this.state.wxPromitShow==null||this.state.wxPromitShow)?<div style={{width:'100%',height:60,position:'absolute',bottom:0}}>
                <i className="iconfontIndexCss" style={{width:60,height:60,fontSize:20,color:'#F00',position:'absolute',left:0,cursor:'pointer'}} onClick={()=>{this.setState({wxPromitShow:false});}}>&#xe631;</i>
                <div style={{width:'100%',height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} onClick={()=>{
                    window.location.href='https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa49b84cef2c68a81&redirect_uri='
                    +encodeURIComponent(urlUtil.IP_ADDRESS_URL+'/share/ShareWxPromitConcern')
                    +'&response_type=code&scope=snsapi_userinfo&state='
                    +JSON.stringify({shopId:otherUtil.encDec(this.state.searchWholesale.stallId),customerId:otherUtil.encDec(this.state.searchWholesale.customerId)})+'#wechat_redirect';
                }}><img style={{width:'95%',height:'100%',borderRadius:5}} loading="lazy" src={require('../images/wx_promit_concern.png').default} title={stringUtil.language(84)}/></div>
            </div>:''}
        </div>)
    }
}

const styles = {};