import React,{Component} from 'react';
import {PropTypes} from 'prop-types';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';

export default class SearchInput extends Component{
    constructor(props){
        super(props);
        this.state={};
    }
    onChangeEvents = (value) => {
        const { onChangeEvents } = this.props;
        (onChangeEvents instanceof Function) && onChangeEvents(value);
    }
    componentDidMount() {}

    render(){
        return(<div style={{width:this.props.width,height:this.props.height,display:'flex',flexDirection:'row',alignItems:'center',position:'relative'}}>
            <input style={arithUtil.stylesAdd(this.props.styles,{width:'100%',height:'100%',borderRadius:2,paddingLeft:5})} onChange={(event)=>{
                this.timeout && clearTimeout(this.timeout);
                this.timeout=setTimeout(()=>{
                    this.onChangeEvents(event.target.value);
                    this.timeout && clearTimeout(this.timeout);
                },500);
            }} placeholder={this.props.placeholder}/>
            <i className="iconfontIndexCss" style={{fontSize:10,color:'#A9A9A9',position:'absolute',top:'30%',right:3}}>&#xe62c;</i>
        </div>)
    }
    componentWillUnmount() {
        this.timeout && clearTimeout(this.timeout);
    }
}

SearchInput.propsTypes={
    width:PropTypes.any,
    height:PropTypes.any,
    placeholder:PropTypes.string,
    styles:PropTypes.object,
    onChangeEvents:PropTypes.func.isRequired,
};
SearchInput.defaultProps = {
    width:'100%',
    height:'100%',
    placeholder:stringUtil.language(32),
    styles:{
        borderStyle:'solid',
        borderColor:'#D3D3D3',
        borderWidth:1
    }
};

const styles = {};