import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';

export default class TabSettingStallInfo extends Component{
  constructor(props){
    super(props);
    this.state={
        weightMode:localStorage.getItem("shopConfigInfo")&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit?JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        costClauseItemAll:[],//系统stringUtil.language(886)
        saleCostItemAll:[],
        currCostClauseItem:{},
        delShowPop:false,
        costClausePop:false,
    };
  }
  componentDidMount(){
      this.initShopConfig();
      this.initSaleCostData();
      stringUtil.httpRequest(urlUtil.COST_CLAUSE_URL,{scope:0},['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.state.costClauseItemAll = successResponse.data;
              this.setState({costClauseItemAll: this.state.costClauseItemAll});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  initShopConfig(){
      stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              localStorage.setItem("shopConfigInfo",JSON.stringify(successResponse.data));this.setState({});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
  }
  initSaleCostData(){
      stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({saleCostItemAll:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'98.5%',height:'97%',display:'flex',flexDirection:'row',padding:'0.5%',position:'relative'}}>
        <img style={{width:'70px',height:'70px',borderRadius:arithUtil.pxToDp(70/2)}} loading="lazy" src={arithUtil.imageShow(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).logo:null,2)} title={stringUtil.language(84)}/>
        <div style={{position:'absolute',left:arithUtil.pxToDp(70+10),top:0,right:0,bottom:0}}>
              <div style={{height:arithUtil.pxToDp(70),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                  <div>
                      <span style={{fontSize:25}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}</span><br/>
                      <span style={{color:'#A9A9A9'}}>{stringUtil.language(781)} {localStorage.getItem("currentStallItem")?arithUtil.formatToTimeStr(new Date(JSON.parse(localStorage.getItem("currentStallItem")).time),stringUtil.language(19)):''}</span>
                  </div>
                  {localStorage.getItem("currentStallItem")==null || JSON.parse(localStorage.getItem("currentStallItem")).shopRole.shopRole==0?<span style={{width:arithUtil.pxToDp(100),height:'30px',color:'#FFF',lineHeight:'30px',borderRadius:5,backgroundColor:'#d2855d',marginRight:arithUtil.pxToDp(50),display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                    this.props.context.props.context.props.navigation.navigate('Main',{mainPageItem:'GoOnAppMoneyActivity',mainStacks:[
                        {title:stringUtil.language(1128),navigatePage:''},
                        {title:stringUtil.language(782),navigatePage:'GoOnAppMoneyActivity',paramsPage:stringUtil.language(782)+'参数'}
                    ]})
                  }}>{stringUtil.language(1001)}</span>:<div/>}
                  {otherUtil.authorityFindCode("010101")!=null?<div style={{position:'absolute',right:-7,top:-7,cursor:'pointer'}} onClick={()=>{
                      this.props.context.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'ShopUpdateActivity',mainStacks:[
                        {title:stringUtil.language(1128),navigatePage:''},
                        {title:stringUtil.language(783),navigatePage:'ShopUpdateActivity',paramsPage:{currShopInfo:localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")):{}}}
                      ]}});
                  }}>
                      <i className="iconfontIndexCss" style={{fontSize:50,color:'#52A056'}}>&#xe653;</i>
                      <span style={{color:'#FFF',fontSize:12,position:'absolute',right:6,top:20,transform:['rotate(45deg)']}}>{stringUtil.language(1092)}</span>
                  </div>:<div style={{position:'absolute'}}/>}
              </div>
              <span style={{height:arithUtil.pxToDp(35),borderBottomWidth:1,borderBottomColor:'#DCDCDC',textAlignVertical:'center'}}>{stringUtil.language(784)}</span>
              <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                  <span style={{width:arithUtil.pxToDp(100),color:'#808080'}}>{stringUtil.language(384)}</span><span style={{color:'#808080'}}>{localStorage.getItem("currentStallItem")!=null && JSON.parse(localStorage.getItem("currentStallItem")).marketName}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                  <span style={{width:arithUtil.pxToDp(100),color:'#808080'}}>{stringUtil.language(785)}</span><span style={{color:'#808080'}}>
                      {localStorage.getItem("currentStallItem")!=null&&JSON.parse(localStorage.getItem("currentStallItem")).adname!=null?JSON.parse(localStorage.getItem("currentStallItem")).adname:''}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      {localStorage.getItem("currentStallItem")!=null&&JSON.parse(localStorage.getItem("currentStallItem")).address!=null?JSON.parse(localStorage.getItem("currentStallItem")).address:''}
                  </span>
              </div>
              <span style={{height:arithUtil.pxToDp(35),borderBottomWidth:1,borderBottomColor:'#DCDCDC',textAlignVertical:'center'}}>{stringUtil.language(786)}</span>
              <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}}>
                  <i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                  <span style={{width:arithUtil.pxToDp(100),color:'#808080'}}>{stringUtil.language(495)}</span><span style={{color:'#F00',fontWeight:'bold'}}>{arithUtil.fenToYuan(localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).initBalance:0)}{stringUtil.currency(1)}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                  if (otherUtil.authorityFindCode("010101")!=null) {
                      this.setState({ isDateTimePickerVisible: true });
                  }
              }}><i className="iconfontIndexCss" style={{fontSize:20,color:'#D3D3D3',marginRight:arithUtil.pxToDp(10)}}>&#xe654;</i>
                  <span style={{width:arithUtil.pxToDp(100),color:'#808080'}}>{stringUtil.language(787)}</span><span style={{color:'#808080'}}>{JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime!=0?stringUtil.language(1002)+' '+JSON.parse(localStorage.getItem("shopConfigInfo")).settleTime+' '+stringUtil.language(1198):''}</span>
              </div>
              <div style={{height:arithUtil.pxToDp(35),borderBottomWidth:1,borderBottomColor:'#DCDCDC',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                  {stringUtil.language(80)}{otherUtil.authorityFindCode("010101")!=null?<i className="iconfontIndexCss" style={{fontSize:30,color:'#D2855D',cursor:'pointer'}} onClick={()=>{
                    this.setState({costClauseVisible:!this.state.costClauseVisible});
                  }}>&#xe601;</i>:<div/>}
              </div>
              <div style={{position:'absolute',left:0,top:300,right:0,bottom:0,borderColor:'#F5F5F5',borderWidth: 1,overflow:'auto'}}>
                  {this.state.saleCostItemAll.map((item,key)=>{
                      return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                          <span style={{width:'25%',color:'#808080'}}>{key+1+"、"+item.costClauseName}</span>
                          <span style={{width:'18%',color:'#808080'}}>{(item.costClausePayee==0?stringUtil.language(1049):item.costClausePayee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                          <span style={{width:'20%',color:'#808080'}}>{item.costClauseCalMode==0?stringUtil.language(868)+'/'+stringUtil.language(1173):item.costClauseCalMode==1?stringUtil.language(869)+'/'+stringUtil.language(1023):item.costClauseCalMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                          <span style={{width:'15%',color:'#D2855D',textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                          {otherUtil.authorityFindCode("010101")!=null?<i className="iconfontIndexCss" style={{width:'12%',fontSize:20,color:'#F00',textAlign:'center',cursor:'pointer'}} onClick={()=>{
                              this.state.currCostClauseItem = JSON.parse(JSON.stringify(item));
                              this.setState({delShowPop:!this.state.delShowPop});
                          }}>&#xe612;</i>:<div/>}
                      </div>)
                  })}
              </div>
        </div>
          <MaskModal title={stringUtil.language(158)} visible={this.state.costClauseVisible} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.4)}
                content={<div style={{paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                      {this.state.costClauseItemAll.map((item,key)=>{
                          return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:arithUtil.pxToDp(5),paddingRight:arithUtil.pxToDp(5),cursor:'pointer'}} key={key} onClick={()=>{
                            this.state.currCostClauseItem = JSON.parse(JSON.stringify(item));
                            this.setState({costClauseVisible:false,costClausePop:true});
                          }}>
                              <span>{item.name}</span>
                              <span>{item.calMode==0?stringUtil.language(868)+'/'+stringUtil.language(1173):item.calMode==1?stringUtil.language(869)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):item.calMode==2?stringUtil.language(396):stringUtil.language(1029)}</span>
                              <span>{(item.payee==0?stringUtil.language(1049):item.payee==1?stringUtil.language(1030):stringUtil.language(1031))+stringUtil.language(1032)}</span>
                          </div>);
                      })}
                  </div>}
                closeBnTopEvents={()=>{this.setState({costClauseVisible:false});}}/>
          <MaskModal visible={this.state.costClausePop} title={stringUtil.subStr(this.state.currCostClauseItem.name,6)+'【'+(this.state.currCostClauseItem.calMode==0?stringUtil.language(868):this.state.currCostClauseItem.calMode==1?stringUtil.language(869):this.state.currCostClauseItem.calMode==2?stringUtil.language(396):stringUtil.language(788))+'】'} width={400} context={this}
              content={<div style={{height:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}} key={this.state.currCostClauseItem.id}>
                    <span>{this.state.currCostClauseItem.calMode==2?stringUtil.language(789)+'：':this.state.currCostClauseItem.calMode==3?stringUtil.language(790)+'：':''}</span>
                    <input style={{width:200,height:40,borderLeftWidth:0,borderTopWidth:0,borderRightWidth:0,borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#d0d0d0',textAlign:'center'}} placeholder={stringUtil.language(366)+'..'} type='number' onChange={(event)=>{
                        this.state.currCostClauseItem.moneyUpdate = event.target.value;
                    }}/>
                    <span>{this.state.currCostClauseItem.calMode==0?stringUtil.currency(1)+'/'+stringUtil.language(1173):this.state.currCostClauseItem.calMode==1?stringUtil.currency(1)+'/'+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172)):this.state.currCostClauseItem.calMode==2?'%':stringUtil.currency(1)}</span>
                </div>}
              okBnEvents={(event) => {
                  if(this.state.currCostClauseItem.moneyUpdate==null || this.state.currCostClauseItem.moneyUpdate==0){ToastBox.error(stringUtil.language(366));return;}
                  this.setState({costClausePop: false});
                  let moneyVal = 0;
                  if (this.state.currCostClauseItem.calMode==0) {
                      moneyVal = arithUtil.yuanToFen(this.state.currCostClauseItem.moneyUpdate);
                  }else if (this.state.currCostClauseItem.calMode==1) {
                      moneyVal = arithUtil.yuanToFen(this.state.currCostClauseItem.moneyUpdate);
                      if(this.state.weightMode==1)moneyVal = moneyVal.mul(2);//输stringUtil.language(1195)的是原/stringUtil.language(1172)，即stringUtil.language(1172)的情况
                  }else if (this.state.currCostClauseItem.calMode==2) {
                      moneyVal = parseFloat(this.state.currCostClauseItem.moneyUpdate);
                  }else if (this.state.currCostClauseItem.calMode==3) {
                      moneyVal = arithUtil.yuanToFen(this.state.currCostClauseItem.moneyUpdate);
                  }
                  stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,{costClauseId:this.state.currCostClauseItem.id,money:moneyVal},['key','put',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          this.initSaleCostData();
                          ToastBox.success(stringUtil.language(388));
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
              }} closeBnEvents={() => {this.setState({costClausePop: false});}}/>
          <MaskModal title={stringUtil.language(256)} visible={this.state.isDateTimePickerVisible} width={arithUtil.pxToDp(300)} height={arithUtil.ProportionTo(document.body.clientHeight,0.4)}
              content={<div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',paddingLeft:10,paddingRight:10}}>
                      {[{},{},{},{},{},{},{},{},{},{},{},{}].map((item,key)=>{
                          return(<div style={{width:50,height:50,display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderColor:'#32CD32',borderWidth: 1,margin:2,borderRadius:3,cursor:'pointer'}} onClick={()=>{
                              stringUtil.httpRequest(urlUtil.BASIC_SHOP_CONFIG_SETTLE_TIME_URL,{settleTime:key},['key','put',1],(successResponse)=>{
                                  if (successResponse.status==200) {
                                      this.setState({ isDateTimePickerVisible: false, });
                                      this.initShopConfig();
                                      ToastBox.success(stringUtil.language(388)+'！');
                                  }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                              },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                          }} key={key}>{key}</div>)
                      })}
                  </div>}
              closeBnTopEvents={() => {this.setState({ isDateTimePickerVisible: false, });}}/>
          <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                      {stringUtil.language(505)}{this.state.currCostClauseItem.costClauseName}？
                  </div>}
              okBnEvents={(event) => {
                  this.setState({delShowPop: false});
                  stringUtil.httpRequest(urlUtil.BASIC_SALE_COST_URL,{costClauseId:this.state.currCostClauseItem.costClauseId},['key','delete',1],(successResponse)=>{
                      if (successResponse.status==200) {
                          ToastBox.success(stringUtil.language(388));
                          this.initSaleCostData();
                      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                  },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
              }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
      </div>)
  }
}

const styles = {
  meInputText:{
      width:arithUtil.pxToDp(300),
      height:arithUtil.pxToDp(45),
      marginLeft:arithUtil.pxToDp(10),
      marginRight:arithUtil.pxToDp(10),
      marginTop:arithUtil.pxToDp(30),
      paddingLeft:arithUtil.pxToDp(10),
      borderLeftWidth:0,
      borderTopWidth:0,
      borderRightWidth:0,
      borderBottomStyle:'solid',
      borderBottomWidth:1,
      borderBottomColor:'#d0d0d0',
  },
};