import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import TabMoneyCustomerCollect from './TabMoneyCustomerCollect';
import TabMoneyOtherCollect from './TabMoneyOtherCollect';
import TabMoneySupplierPayment from './TabMoneySupplierPayment';
import TabMoneyOwnerPayment from './TabMoneyOwnerPayment';
import TabMoneyStallExpend from './TabMoneyStallExpend';
import TabMoneyOwnerExpend from './TabMoneyOwnerExpend';
import TabMoneyOtherPayment from './TabMoneyOtherPayment';
import TabMoneyTransfer from './TabMoneyTransfer';
import FundAccountActivity from './FundAccountActivity';
import AccountCollectExpend from './AccountCollectExpend';
import WorkerCollectExpend from './WorkerCollectExpend';

export default class TabMoney extends Component{
  constructor(props){
    super(props);
    let colPayIndex = -1;
    if(otherUtil.authorityFindCode("030101")!=null) colPayIndex = 0;
    else if(otherUtil.authorityFindCode("030201")!=null) colPayIndex = 1;
    else if(otherUtil.authorityFindCode("030401")!=null) colPayIndex = 2;
    else if(otherUtil.authorityFindCode("030501")!=null) colPayIndex = 3;
    else if(otherUtil.authorityFindCode("030301")!=null) colPayIndex = 4;
    else if(otherUtil.authorityFindCode("030601")!=null) colPayIndex = 5;
    else if(otherUtil.authorityFindCode("030701")!=null) colPayIndex = 6;
    else if(otherUtil.authorityFindCode("030801")!=null) colPayIndex = 7;
    else{}
    this.state = {
        pageIndex:colPayIndex!=-1?0:1,
        colPayIndex: colPayIndex,
        capitalSettleVO:{}
    }
  }
  componentDidMount(){
      stringUtil.httpRequest(urlUtil.SUMM_CAPITAL_SETTLE_URL,null,['key','get',1],(successResponse)=>{
          if (successResponse.status==200) {
              this.setState({capitalSettleVO:successResponse.data});
          }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
      },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }

  render(){
      return(<div style={{width:'100%',height:'98%',display:'flex',flexDirection:'column',position:'relative'}}>
        <div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center',borderStyle:'solid',borderColor:'#FFF',borderWidth:0.5}}>
          {this.state.colPayIndex!=-1?<div style={{width:'25%',height:'100%',backgroundColor:this.state.pageIndex==0?'#34AE66':'#605F5F',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:0})}}><span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(1230)}</span></div>:''}
          <div style={{width:this.state.colPayIndex!=-1?'25%':'33.33%',height:'100%',backgroundColor:this.state.pageIndex==1?'#34AE66':'#605F5F',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:1})}}><span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(1231)}</span></div>
          <div style={{width:this.state.colPayIndex!=-1?'25%':'33.33%',height:'100%',backgroundColor:this.state.pageIndex==2?'#34AE66':'#605F5F',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:2})}}><span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(1232)}</span></div>
          <div style={{width:this.state.colPayIndex!=-1?'25%':'33.33%',height:'100%',backgroundColor:this.state.pageIndex==3?'#34AE66':'#605F5F',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{this.setState({pageIndex:3})}}><span style={{color:'#FFF',fontSize:20,fontWeight:'bold'}}>{stringUtil.language(1233)}</span></div>
        </div>
        {this.state.pageIndex==0?<div style={{width:'100%',position:'absolute',left:0,top:40,right:0,bottom:0,display:'flex',flexDirection:'row'}}>
          <div style={{width:'25%',backgroundColor:'#F0F0F0'}}>
            {(()=>{
                this.state.totalInfo = {collectTotal:0,paymentTotal:0,balanceTotal:0}
                let capitalSettleVOTemp = this.state.capitalSettleVO.customerCollect!=null?this.state.capitalSettleVO:{
                  customerCollect:0,shopCollect:0,ownerExpend:0,shopExpend:0,supplierPay:0,workerPay:0,ownerPay:0
                };
                this.state.totalInfo.collectTotal = (capitalSettleVOTemp.customerCollect).add(capitalSettleVOTemp.shopCollect);
                this.state.totalInfo.paymentTotal = (capitalSettleVOTemp.ownerExpend).add(capitalSettleVOTemp.shopExpend).add(capitalSettleVOTemp.supplierPay).add(capitalSettleVOTemp.workerPay).add(capitalSettleVOTemp.ownerPay);
                this.state.totalInfo.balanceTotal = (this.state.totalInfo.collectTotal).sub(this.state.totalInfo.paymentTotal);
            })()}
            <span style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:'#CCCCCC',fontSize:16,fontWeight:'bold',paddingLeft:20})}>{stringUtil.language(267)}{arithUtil.fenToYuan(this.state.totalInfo.collectTotal)}</span>
              {otherUtil.authorityFindCode("030101")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 0 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:0 }); }}>
                  <span style={{color:this.state.colPayIndex===0?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(268)}{arithUtil.fenToYuan(this.state.capitalSettleVO.customerCollect)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 0 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(268)}{arithUtil.fenToYuan(this.state.capitalSettleVO.customerCollect)}</span><span></span>
              </div>}
              {otherUtil.authorityFindCode("030201")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 1 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:1 }); }}>
                  <span style={{color:this.state.colPayIndex===1?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(269)}{arithUtil.fenToYuan(this.state.capitalSettleVO.shopCollect)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 1 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(269)}{arithUtil.fenToYuan(this.state.capitalSettleVO.shopCollect)}</span><span></span>
              </div>}
            <span style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:'#CCCCCC',fontSize:16,fontWeight:'bold',paddingLeft:20})}>{stringUtil.language(270)}{arithUtil.fenToYuan(this.state.totalInfo.paymentTotal)}</span>
              {otherUtil.authorityFindCode("030401")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 2 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:2 }); }}>
                  <span style={{color:this.state.colPayIndex===2?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(271)}{arithUtil.fenToYuan(this.state.capitalSettleVO.shopExpend)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 2 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(271)}{arithUtil.fenToYuan(this.state.capitalSettleVO.shopExpend)}</span><span></span>
              </div>}
              {otherUtil.authorityFindCode("030501")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 3 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:3 }); }}>
                  <span style={{color:this.state.colPayIndex===3?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(272)}{arithUtil.fenToYuan(this.state.capitalSettleVO.supplierPay)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 3 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(272)}{arithUtil.fenToYuan(this.state.capitalSettleVO.supplierPay)}</span><span></span>
              </div>}
              {otherUtil.authorityFindCode("030301")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 4 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:4 }); }}>
                  <span style={{color:this.state.colPayIndex===4?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(273)}{arithUtil.fenToYuan(this.state.capitalSettleVO.ownerExpend)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 4 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(273)}{arithUtil.fenToYuan(this.state.capitalSettleVO.ownerExpend)}</span><span></span>
              </div>}
              {otherUtil.authorityFindCode("030601")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 5 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:5 }); }}>
                  <span style={{color:this.state.colPayIndex===5?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(274)}{arithUtil.fenToYuan(this.state.capitalSettleVO.ownerPay)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 5 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(274)}{arithUtil.fenToYuan(this.state.capitalSettleVO.ownerPay)}</span><span></span>
              </div>}
              {otherUtil.authorityFindCode("030701")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 6 ? '#FFF':'#F0F0F0',cursor:'pointer'})} 
                  onClick={()=>{ this.setState({ colPayIndex:6 }); }}>
                  <span style={{color:this.state.colPayIndex===6?'#FF8D1A':'#696969',fontWeight:this.state.colPayIndex===6?'bold':'normal',fontSize:16}}>{stringUtil.language(275)}{arithUtil.fenToYuan(this.state.capitalSettleVO.workerPay)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{backgroundColor:this.state.colPayIndex === 6 ? '#FFF':'#F0F0F0'})}>
                  <span style={{fontSize:16}}>{stringUtil.language(275)}{arithUtil.fenToYuan(this.state.capitalSettleVO.workerPay)}</span><span></span>
              </div>}
            <span style={arithUtil.stylesAdd(styles.bodyLeftItem,{fontWeight:'bold',color:'#F00',textAlign:'right'})}>{stringUtil.language(276)}{arithUtil.fenToYuan(this.state.totalInfo.balanceTotal)}</span>
            {otherUtil.authorityFindCode("030801")!=null?<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{paddingLeft:20,backgroundColor:this.state.colPayIndex === 7 ? '#FFF':'#CCCCCC',cursor:'pointer'})} 
                onClick={()=>{ this.setState({ colPayIndex:7 }); }}>
                <span style={{color:this.state.colPayIndex===7?'#FF8D1A':'#696969',fontSize:16,fontWeight:'bold'}}>{stringUtil.language(690)}</span><span>{'>'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </div>:<div style={arithUtil.stylesAdd(styles.bodyLeftItem,{paddingLeft:20,backgroundColor:this.state.colPayIndex === 7 ? '#FFF':'#CCCCCC'})}>
                <span style={{fontSize:16,fontWeight:'bold'}}>{stringUtil.language(690)}</span><span></span>
            </div>}
          </div>
          <div style={{width:'75%',backgroundColor:'#FFF'}}>{
            this.state.colPayIndex == 0?<TabMoneyCustomerCollect context={this}/>:
            this.state.colPayIndex == 1?<TabMoneyOtherCollect context={this}/>:
            this.state.colPayIndex == 2?<TabMoneyStallExpend context={this}/>:
            this.state.colPayIndex == 3?<TabMoneySupplierPayment context={this}/>:
            this.state.colPayIndex == 4?<TabMoneyOwnerExpend context={this}/>:
            this.state.colPayIndex == 5?<TabMoneyOwnerPayment context={this}/>:
            this.state.colPayIndex == 6?<TabMoneyOtherPayment context={this}/>:
            this.state.colPayIndex == 7?<TabMoneyTransfer context={this}/>:''
          }</div>
        </div>:this.state.pageIndex==1?<div style={{width:'100%',position:'absolute',left:0,top:40,right:0,bottom:0}}>
          <AccountCollectExpend context={this}/>
        </div>:this.state.pageIndex==2?<div style={{width:'100%',position:'absolute',left:0,top:40,right:0,bottom:0}}>
          <WorkerCollectExpend context={this}/>
        </div>:this.state.pageIndex==3?<div style={{width:'100%',position:'absolute',left:0,top:40,right:0,bottom:0}}>
          <FundAccountActivity context={this}/>
        </div>:''}
      </div>)
  }
}

const styles = {
  bodyLeftItem:{
    width:'95%',
    height:'9.03%',
    textAlignVertical:'center',
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    alignItems:'center',
    fontSize:15,
    paddingLeft:30,
    paddingRight:5,
    borderTopWidth:1,
    borderTopColor:'#DCDCDC'
  }
};