import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

 //{stringUtil.language(658)}
 export default class CustomerAccountsDetailsActivity extends Component{
    constructor(props) {
      super(props);
      let paramsPage = this.props.navigationStacks[this.props.navigationStacks.length-1].paramsPage;
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        customerReconciliationVO:{},
        customersAll:[],customersDatas:[],
        currCustomerItem:{},
        dateTimeStr:arithUtil.getCurrDateTime(1,true),
        dateTimeStrEnd:arithUtil.getCurrDateTime(1,true),
        customerSearchShowPop:false,
        wholesalePCMDO:{}
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.OAUTH_CUSTOMER_URL,{state:1},['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.customersAll = successResponse.data;
                this.state.customersAll.shift();
                if (this.state.customersAll.length>0) {
                    this.state.currCustomerItem = JSON.parse(JSON.stringify(this.state.customersAll[0]));
                    this.initData();
                }
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    initData(){
        stringUtil.httpRequest(urlUtil.SUMM_CUSTOMER_RECONCILIATION_URL+'/'+this.state.currCustomerItem.id,{
            startDate:this.state.dateTimeStr,
            endDate:this.state.dateTimeStrEnd,
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.setState({customerReconciliationVO:successResponse.data});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'94%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#A9A9A9'}}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{width:50}}>{stringUtil.language(903)}</span><div style={{height:30,width:200,display:'flex',flexGrow:1,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderStyle:'solid',borderColor:'#D0D0D0',borderWidth:1,borderRadius:2,paddingLeft:5,paddingRight:5,backgroundColor:'#FFF',cursor:'pointer'}} onClick={(event)=>{
                                this.state.customerSearchShowPop = !this.state.customerSearchShowPop;
                                this.setState({customersDatas:this.state.customersAll});
                            }}><span>{this.state.currCustomerItem.noteName}</span><i className="iconfontIndexCss" style={{fontSize:9,color:'#A9A9A9'}}>&#xe603;</i>
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15}}>
                            <span style={{width:50}}>{stringUtil.language(904)}</span>
                            <div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStr)} onChange={(date) => {
                                this.state.dateTimeStr = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>&nbsp;{stringUtil.language(1174)}&nbsp;<div style={styles.time}><DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.dateTimeStrEnd)} onChange={(date) => {
                                this.state.dateTimeStrEnd = arithUtil.formatToTimeStr(date,'Y-M-D');
                                this.initData();
                            }} /></div>
                        </div>
                        <MaskModal title={stringUtil.language(457)} visible={this.state.customerSearchShowPop} width={300} height={arithUtil.ProportionTo(document.body.clientHeight,0.85)}
                            content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}}>
                                <div style={{height:'7%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#DCDCDC',borderBottomWidth: 1}}>
                                    <input style={{width:150,height:25,borderStyle:'solid',borderColor:'#d0d0d0',borderWidth:1,borderRadius:2,padding:0,paddingLeft:5}} id='keyId' onChange={(event)=>{
                                        this.state.keySearch = event.target.value;
                                        if(this.state.keySearch=='')this.setState({customersDatas:this.state.customersAll});
                                    }} placeholder={stringUtil.language(32)}/>
                                    <span style={{width:50,height:'25px',color:'#FFF',lineHeight:'25px',borderRadius:3,backgroundColor:'#d2855d',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                        this.state.customersDatas = [];
                                        if (this.state.keySearch!=null&&this.state.keySearch!='') {
                                            for(let i=0;i<this.state.customersAll.length;i++){
                                                if ((this.state.customersAll[i].noteName).indexOf(this.state.keySearch)!=-1) {
                                                    this.state.customersDatas.push(this.state.customersAll[i]);
                                                }
                                            }
                                        }else{this.state.customersDatas = this.state.customersAll;}
                                        this.setState({customersDatas:this.state.customersDatas});
                                    }}>{stringUtil.language(1034)}</span>
                                </div>
                                <div style={{height:'91%',overflow:'auto'}}>
                                    {(this.state.customersDatas?this.state.customersDatas:this.state.customersAll).map((item,key)=>{
                                        return(<div style={{height:40,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:10,paddingRight:10,borderBottomStyle:'solid',borderBottomColor:'#F0F0F0',borderBottomWidth:1,cursor:'pointer'}} key={key} onClick={()=>{
                                            this.state.currCustomerItem = item;
                                            this.state.customerSearchShowPop = false;
                                            this.initData();
                                        }}>
                                            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                                <img style={{width:30,height:30,borderRadius:15}} loading="lazy" src={arithUtil.imageShow(item.headIcon)} title={stringUtil.language(84)}/>
                                                <div style={{marginLeft:7,display:'flex',flexDirection:'column',justifyContent:'center'}}>
                                                    <span style={{color:item.warning!=2?'#696969':'#D3D3D3',fontWeight:item.warning!=2?'bold':'normal'}}>{item.noteName}</span>
                                                    <span style={{color:'#FF8C00',fontSize:'8px'}}>{item.warning==1||item.quota!=0?(item.warning==2?stringUtil.language(458)+'/':'')+stringUtil.language(1066)+arithUtil.fenToYuan(item.quota):(item.warning==2?stringUtil.language(458):'')}</span>
                                                </div>
                                            </div>
                                            <span style={{color:'#F00'}}>{arithUtil.fenToYuan(item.arrears)}</span>
                                        </div>)
                                    })}
                                </div>
                            </div>} closeBnTopEvents={() => {this.setState({customerSearchShowPop: false})}}/>
                    </div>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                            stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_PRINT_CUSTOMER_MULTIPLE_URL,{
                                startTime:parseInt(arithUtil.timeToSettleNumber(this.state.dateTimeStr,0)+''),
                                endTime:parseInt(arithUtil.timeToSettleNumber(this.state.dateTimeStrEnd)+''),
                                customerId:this.state.currCustomerItem.id
                            },['key','get',1],(successResponse)=>{
                                if (successResponse.status==200) {
                                    this.setState({wholesalePCMDO:successResponse.data,printWholesaleShow:true},()=>{
                                        const printArea = document.getElementById('printWholesale');
                                        const iframe = document.createElement('IFRAME');
                                        let doc = null;
                                        iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                        document.body.appendChild(iframe);
                                        doc = iframe.contentWindow.document;
                                        doc.write(printArea.innerHTML);
                                        doc.close();
                                        iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                        iframe.contentWindow.print();
                                        if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                        this.setState({printWholesaleShow:false});
                                    });
                                }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                            },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                        }}><i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(459)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            this.setState({isShowTabTitle:true},()=>{
                                const printArea = document.getElementById('printArea');
                                const iframe = document.createElement('IFRAME');
                                let doc = null;
                                iframe.setAttribute('style', 'position:absolute;width:0px;height:0px;left:500px;top:500px;');
                                document.body.appendChild(iframe);
                                doc = iframe.contentWindow.document;
                                doc.write(printArea.innerHTML);
                                doc.close();
                                iframe.contentWindow.focus();// 获取iframe的焦stringUtil.language(1198)，stringUtil.language(1190)iframe开始stringUtil.language(1045)
                                iframe.contentWindow.print();
                                if (navigator.userAgent.indexOf("MSIE")>0){document.body.removeChild(iframe);}
                                this.setState({isShowTabTitle:false});
                            });
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe867;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(460)}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:15,cursor:'pointer'}} onClick={()=>{
                            let resArr=new Array();
                            resArr.push(`<tr style="height: 50px"><td align="center" colspan="11"><b><font size="3">${this.state.currCustomerItem.noteName}${stringUtil.language(461)}（${this.state.dateTimeStr+stringUtil.language(1174)+this.state.dateTimeStrEnd}）</font></b></td></tr>
                            <tr><td align="right" style="color: #F00" colspan="11"><b><font size="3">{stringUtil.language(288)}${arithUtil.fenToYuan(this.state.customerReconciliationVO.beforeMoney)}${stringUtil.currency(1)}</font></b></td></tr>
                            <tr><td align="center"><b><font size="3">${stringUtil.language(1067)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1020)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1021)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1022)}【${this.state.weightMode==0?stringUtil.language(1023)+'】':'stringUtil.language(1172)】'}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1050)}</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1068)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1069)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(462)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1070)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(1061)}【${stringUtil.currency(1)}】</font></b></td>
                            <td align="center"><b><font size="3">${stringUtil.language(463)}【${stringUtil.currency(1)}】</font></b></td></tr>`);
                            let totalInfo = {orderNumberTotal:0,orderWeightTotal:0,orderGoodsMoneyTotal:0,orderCotherTotal:0,orderOotherTotal:0,orderAdvanceTotal:0,orderTotal:0,moneyMoneyTotal:0,moneyDiscountTotal:0}
                            for(let i=0;i<(this.state.customerReconciliationVO.items?this.state.customerReconciliationVO.items:[]).length;i++){
                                let item = this.state.customerReconciliationVO.items[i];
                                let debtTotalVal = 0;
                                if(item.type==0){//stringUtil.language(1037)
                                    if(i==0){
                                        debtTotalVal = (this.state.customerReconciliationVO.beforeMoney).add(item.money);
                                        this.state.customerReconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }else{
                                        debtTotalVal = (this.state.customerReconciliationVO.items[i-1].debtTotalVal).add(item.money);
                                        this.state.customerReconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }
                                    let costsTotal = 0;
                                    for(let j=0;j<item.wholesale.costs.length;j++){
                                        costsTotal = costsTotal.add(item.wholesale.costs[j].money);
                                    }
                                    totalInfo.orderOotherTotal = totalInfo.orderOotherTotal.add(costsTotal);
                                    totalInfo.orderAdvanceTotal = totalInfo.orderAdvanceTotal.add(item.wholesale.advanceMoney);
                                    totalInfo.orderTotal = totalInfo.orderTotal.add(item.money);
                                    if(item.wholesale.commodities!=null&&item.wholesale.commodities.length>0){
                                        let costsbTotal = 0;
                                        for(let j=0;j<item.wholesale.commodities[0].costs.length;j++){
                                            costsbTotal = costsbTotal.add(item.wholesale.commodities[0].costs[j].money);
                                        }
                                        totalInfo.orderNumberTotal = totalInfo.orderNumberTotal.add(item.wholesale.commodities[0].number);
                                        if(item.wholesale.commodities[0].mode==0) totalInfo.orderWeightTotal = totalInfo.orderWeightTotal.add(item.wholesale.commodities[0].weight);
                                        totalInfo.orderGoodsMoneyTotal = totalInfo.orderGoodsMoneyTotal.add(item.wholesale.commodities[0].goodsMoney);
                                        totalInfo.orderCotherTotal = totalInfo.orderCotherTotal.add(costsbTotal);
                                        resArr.push(`<tr><td align="left" rowspan="${item.wholesale.commodities.length}"><b><font size="3">${arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</font></b></td>
                                        <td align="left"><b><font size="3">${item.wholesale.commodities[0].commodityName}</font></b></td>
                                        <td align="right"><b><font size="3">${item.wholesale.commodities[0].number}</font></b></td>
                                        <td align="right"><b><font size="3">${arithUtil.keToWeight(item.wholesale.commodities[0].weight,this.state.weightMode)}</font></b></td>
                                        <td align="right"><b><font size="3">${item.wholesale.commodities[0].mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.wholesale.commodities[0].unitPrice,2),0,this.state.weightMode)):arithUtil.fenToYuan(item.wholesale.commodities[0].unitPrice,2)}</font></b></td>
                                        <td align="right"><b><font size="3">${arithUtil.fenToYuan(item.wholesale.commodities[0].goodsMoney)}</font></b></td>
                                        <td align="right"><b><font size="3">${arithUtil.fenToYuan(costsbTotal)}</font></b></td>
                                        <td align="right" rowspan="${item.wholesale.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(costsTotal)}</font></b></td>
                                        <td align="right" rowspan="${item.wholesale.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(item.wholesale.advanceMoney)}</font></b></td>
                                        <td align="right" rowspan="${item.wholesale.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(item.money)}</font></b></td>
                                        <td align="right" rowspan="${item.wholesale.commodities.length}"><b><font size="3">${arithUtil.fenToYuan(debtTotalVal)}</font></b></td></tr>`);
                                        for(let j=0;j<item.wholesale.commodities.length;j++){
                                            if(j==0)continue;
                                            costsbTotal = 0;
                                            for(let k=0;k<item.wholesale.commodities[j].costs.length;k++){
                                                costsbTotal = costsbTotal.add(item.wholesale.commodities[j].costs[k].money);
                                            }
                                            totalInfo.orderNumberTotal = totalInfo.orderNumberTotal.add(item.wholesale.commodities[j].number);
                                            if(item.wholesale.commodities[j].mode==0) totalInfo.orderWeightTotal = totalInfo.orderWeightTotal.add(item.wholesale.commodities[j].weight);
                                            totalInfo.orderGoodsMoneyTotal = totalInfo.orderGoodsMoneyTotal.add(item.wholesale.commodities[j].goodsMoney);
                                            totalInfo.orderCotherTotal = totalInfo.orderCotherTotal.add(costsbTotal);
                                            resArr.push(`<tr><td align="left"><b><font size="3">${item.wholesale.commodities[j].commodityName}</font></b></td>
                                            <td align="right"><b><font size="3">${item.wholesale.commodities[j].number}</font></b></td>
                                            <td align="right"><b><font size="3">${arithUtil.keToWeight(item.wholesale.commodities[j].weight,this.state.weightMode)}</font></b></td>
                                            <td align="right"><b><font size="3">${item.wholesale.commodities[j].mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.wholesale.commodities[j].unitPrice,2),0,this.state.weightMode)):arithUtil.fenToYuan(item.wholesale.commodities[j].unitPrice,2)}</font></b></td>
                                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(item.wholesale.commodities[j].goodsMoney)}</font></b></td>
                                            <td align="right"><b><font size="3">${arithUtil.fenToYuan(costsbTotal)}</font></b></td></tr>`);
                                        }
                                    }
                                }else{//stringUtil.language(1038)
                                    if(i==0){
                                        debtTotalVal = (this.state.customerReconciliationVO.beforeMoney).sub(item.money).sub(item.discount);
                                        this.state.customerReconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }else{
                                        debtTotalVal = (this.state.customerReconciliationVO.items[i-1].debtTotalVal).sub(item.money).sub(item.discount);
                                        this.state.customerReconciliationVO.items[i].debtTotalVal = debtTotalVal;
                                    }
                                    totalInfo.moneyMoneyTotal = totalInfo.moneyMoneyTotal.add(item.money);
                                    totalInfo.moneyDiscountTotal = totalInfo.moneyDiscountTotal.add(item.discount);
                                    resArr.push(`<tr><td align="left"><b><font size="3">${arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</font></b></td>
                                    <td align="left" style="color: #F00" colspan="9"><b><font size="3">${stringUtil.language(905)}${arithUtil.fenToYuan(item.money)} ${stringUtil.currency(1)}，${stringUtil.language(879)}${arithUtil.fenToYuan(item.discount)} ${stringUtil.currency(1)}</font></b></td>
                                    <td align="right"><b><font size="3">${arithUtil.fenToYuan(debtTotalVal)}</font></b></td></tr>`);
                                }
                            }
                            resArr.push(`<tr style="height: 25px"><td></td></tr>
                            <tr><td align="left" colspan="11"><b><font size="3">${stringUtil.language(289)}${this.state.totalInfo.orderNumberTotal}，${arithUtil.keToWeight(this.state.totalInfo.orderWeightTotal,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}${stringUtil.language(907)}${arithUtil.fenToYuan(this.state.totalInfo.orderGoodsMoneyTotal)}${stringUtil.currency(1)}${stringUtil.language(195)}${arithUtil.fenToYuan(this.state.totalInfo.orderCotherTotal)}${stringUtil.currency(1)}${stringUtil.language(196)}${arithUtil.fenToYuan(this.state.totalInfo.orderOotherTotal)}${stringUtil.currency(1)}${stringUtil.language(908)}${arithUtil.fenToYuan(this.state.totalInfo.orderAdvanceTotal)}${stringUtil.currency(1)}${stringUtil.language(909)}${arithUtil.fenToYuan(this.state.totalInfo.orderTotal)}${stringUtil.currency(1)}</font></b></td></tr>
                            <tr><td align="left" colspan="11"><b><font size="3">${stringUtil.language(290)}${arithUtil.fenToYuan(this.state.totalInfo.moneyMoneyTotal)}${stringUtil.currency(1)}${stringUtil.language(906)}${arithUtil.fenToYuan(this.state.totalInfo.moneyDiscountTotal)}${stringUtil.currency(1)}</font></b></td></tr>`);
                            otherUtil.createExcel(resArr.join(''),this.state.currCustomerItem.noteName+stringUtil.language(461)+'（'+this.state.dateTimeStr+stringUtil.language(1174)+this.state.dateTimeStrEnd+'）');
                        }}>
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe644;</i>
                            <span style={{color:'#52A056',fontSize:15,marginLeft:5}}>{stringUtil.language(1071)}Excel</span>
                        </div>
                    </div>
                </div>
                <div style={{width:'100%',height:'92%',overflowY:'auto'}} id='printArea'>
                    {this.state.isShowTabTitle?<div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:25,fontWeight:'bold'}}>【{this.state.currCustomerItem.noteName}】{stringUtil.language(197)}</span>
                        <span style={{fontSize:17}}>{this.state.dateTimeStr+' '+stringUtil.language(1174)+' ' +this.state.dateTimeStrEnd}</span>
                    </div>:''}
                    <span style={{height:arithUtil.pxToDp(40),color:'#F00',fontSize:20,display:'flex',flexDirection:'row',alignItems:'center'}}>{stringUtil.language(288)}{arithUtil.fenToYuan(this.state.customerReconciliationVO.beforeMoney)}{stringUtil.currency(1)}</span>
                    <div style={{display:'flex',flexDirection:'row'}}>
                        <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'10%'})}>{stringUtil.language(1067)}</span>
                        <div style={{width:'54%',display:'flex',flexDirection:'row'}}>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1020)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1021)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1022)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1050)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1068)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTitle,{width:'16.67%'})}>{stringUtil.language(1069)}</span>
                        </div>
                        <span style={styles.tableTitle}>{stringUtil.language(910)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1070)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(1061)}</span>
                        <span style={styles.tableTitle}>{stringUtil.language(463)}</span>
                    </div>
                    {(()=>{this.state.totalInfo = {orderNumberTotal:0,orderWeightTotal:0,orderGoodsMoneyTotal:0,orderCotherTotal:0,orderOotherTotal:0,orderAdvanceTotal:0,orderTotal:0,moneyMoneyTotal:0,moneyDiscountTotal:0}})()}
                    {this.state.customerReconciliationVO.items!=null?this.state.customerReconciliationVO.items.map((item,key)=>{//ReconciliationItem
                        let debtTotalVal = 0;
                        if(key==0){
                            if (item.type==0) {
                                debtTotalVal = (this.state.customerReconciliationVO.beforeMoney).add(item.money);
                                this.state.customerReconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }else{
                                debtTotalVal = (this.state.customerReconciliationVO.beforeMoney).sub(item.money).sub(item.discount);
                                this.state.customerReconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }
                        }else{
                            if (item.type==0) {//stringUtil.language(1037)
                                debtTotalVal = (this.state.customerReconciliationVO.items[key-1].debtTotalVal).add(item.money);
                                this.state.customerReconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }else{//stringUtil.language(1038)
                                debtTotalVal = (this.state.customerReconciliationVO.items[key-1].debtTotalVal).sub(item.money).sub(item.discount);
                                this.state.customerReconciliationVO.items[key].debtTotalVal = debtTotalVal;
                            }
                        }
                        let itemShowView = <div/>;
                        if (item.type==0) {
                            let costsTotal = 0;
                            for(let i=0;i<item.wholesale.costs.length;i++){
                                costsTotal = costsTotal.add(item.wholesale.costs[i].money);
                            }
                            this.state.totalInfo.orderOotherTotal = this.state.totalInfo.orderOotherTotal.add(costsTotal);
                            this.state.totalInfo.orderAdvanceTotal = this.state.totalInfo.orderAdvanceTotal.add(item.wholesale.advanceMoney);
                            this.state.totalInfo.orderTotal = this.state.totalInfo.orderTotal.add(item.money);
                            itemShowView = <div style={{display:'flex',flexDirection:'row',borderStyle:'solid',borderWidth:0.5,borderColor:'#F0F0F0'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',borderBottomWidth:0})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                <div style={{width:'54%',borderLeftWidth:0.5,borderLeftColor:'#F0F0F0',borderRightWidth:0.5,borderRightColor:'#F0F0F0'}}>
                                    {item.wholesale.commodities.map((itemb,keyb)=>{
                                        let costsbTotal = 0;
                                        for(let i=0;i<itemb.costs.length;i++){
                                            costsbTotal = costsbTotal.add(itemb.costs[i].money);
                                        }
                                        this.state.totalInfo.orderNumberTotal = this.state.totalInfo.orderNumberTotal.add(itemb.number);
                                        if(itemb.mode==0) this.state.totalInfo.orderWeightTotal = this.state.totalInfo.orderWeightTotal.add(itemb.weight);
                                        this.state.totalInfo.orderGoodsMoneyTotal = this.state.totalInfo.orderGoodsMoneyTotal.add(itemb.goodsMoney);
                                        this.state.totalInfo.orderCotherTotal = this.state.totalInfo.orderCotherTotal.add(costsbTotal);
                                        return(<div style={{display:'flex',flexDirection:'row'}} key={key+''+keyb}>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{stringUtil.subStr(itemb.commodityName,5)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{itemb.number}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{arithUtil.keToWeight(itemb.weight,this.state.weightMode)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{itemb.mode==0?(arithUtil.unitPriceTo(arithUtil.fenToYuan(itemb.unitPrice,2),0,this.state.weightMode)):arithUtil.fenToYuan(itemb.unitPrice,2)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{arithUtil.fenToYuan(itemb.goodsMoney)}</span>
                                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16.67%',borderWidth:0})}>{arithUtil.fenToYuan(costsbTotal)}</span>
                                        </div>)
                                    })}
                                </div>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(costsTotal)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(item.wholesale.advanceMoney)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{borderBottomWidth:0})}>{arithUtil.fenToYuan(debtTotalVal)}</span>
                            </div>;
                        }else{
                            this.state.totalInfo.moneyMoneyTotal = this.state.totalInfo.moneyMoneyTotal.add(item.money);
                            this.state.totalInfo.moneyDiscountTotal = this.state.totalInfo.moneyDiscountTotal.add(item.discount);
                            itemShowView = <div style={{display:'flex',flexDirection:'row'}} key={key}>
                                <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D')}</span>
                                <div style={{width:'81%',display:'flex',flexDirection:'row'}}>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'100%',color:'#F00',textAlign:'left',paddingLeft:10})}>{stringUtil.language(905)}{arithUtil.fenToYuan(item.money)} {stringUtil.currency(1)}，{stringUtil.language(879)}{arithUtil.fenToYuan(item.discount)} {stringUtil.currency(1)}</span>
                                </div>
                                <span style={styles.tableTd}>{arithUtil.fenToYuan(debtTotalVal)}</span>
                            </div>;
                        }
                        return(itemShowView)
                    }):<div/>}
                    <div style={{marginTop:arithUtil.pxToDp(15),paddingBottom:10}}>
                        <span style={{color:'#D2855D'}}>{stringUtil.language(289)}{this.state.totalInfo.orderNumberTotal}，{arithUtil.keToWeight(this.state.totalInfo.orderWeightTotal,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}{stringUtil.language(907)}{arithUtil.fenToYuan(this.state.totalInfo.orderGoodsMoneyTotal)}{stringUtil.currency(1)}{stringUtil.language(195)}{arithUtil.fenToYuan(this.state.totalInfo.orderCotherTotal)}{stringUtil.currency(1)}{stringUtil.language(196)}{arithUtil.fenToYuan(this.state.totalInfo.orderOotherTotal)}{stringUtil.currency(1)}{stringUtil.language(908)}{arithUtil.fenToYuan(this.state.totalInfo.orderAdvanceTotal)}{stringUtil.currency(1)}{stringUtil.language(909)}{arithUtil.fenToYuan(this.state.totalInfo.orderTotal)}{stringUtil.currency(1)}</span><br/>
                        <span style={{color:'#D2855D'}}>{stringUtil.language(290)}{arithUtil.fenToYuan(this.state.totalInfo.moneyMoneyTotal)}{stringUtil.currency(1)}{stringUtil.language(906)}{arithUtil.fenToYuan(this.state.totalInfo.moneyDiscountTotal)}{stringUtil.currency(1)}</span>
                    </div>
                </div>
                {this.state.printWholesaleShow?<div style={{width:'100%',overflowY:'auto'}} id='printWholesale'>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}><span style={{fontSize:25,fontWeight:'bold'}}>{this.state.wholesalePCMDO.printTitle}</span></div>
                    <div style={{height:40,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                        <span style={{fontSize:15}}>{this.state.wholesalePCMDO.marketName}</span>
                        <span style={{fontSize:15}}>{this.state.dateTimeStr} {stringUtil.language(1174)} {this.state.dateTimeStrEnd}</span>
                    </div>
                    <div style={{height:35,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:13}}>{stringUtil.language(903)}</span><span style={{fontSize:20,fontWeight:'bold'}}>{this.state.wholesalePCMDO.customerName}</span>
                        </div>
                        <span style={{fontSize:13}}>{stringUtil.language(911)}{this.state.wholesalePCMDO.customerAdname?this.state.wholesalePCMDO.customerAdname:''}{this.state.wholesalePCMDO.customerAddress?this.state.wholesalePCMDO.customerAddress:''}</span>
                    </div>
                    <div style={{height:25,display:'flex',flexDirection:'row',alignItems:'center',borderTopStyle:'solid',borderTopColor:'#000',borderTopWidth:'1px',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                        <span style={{width:'13%'}}>{stringUtil.language(1067)}</span>
                        <span style={{width:'23%'}}>{stringUtil.language(1020)}</span>
                        <span style={{width:'9%',textAlign:'right'}}>{stringUtil.language(1021)}</span>
                        <span style={{width:'12%',textAlign:'right'}}>{stringUtil.language(1022)}</span>
                        <span style={{width:'16%',textAlign:'right'}}>{stringUtil.language(1050)}</span>
                        <span style={{width:'13%',textAlign:'right'}}>{stringUtil.language(1041)}</span>
                        <span style={{width:'14%',textAlign:'right'}}>{stringUtil.language(891)}</span>
                    </div>
                    {(()=>{this.state.wholesaleTotalInfo={number:0,weight:0,money:0}})()}
                    {this.state.wholesalePCMDO.commodities?this.state.wholesalePCMDO.commodities.map((item,key)=>{
                        this.state.wholesaleTotalInfo.number=this.state.wholesaleTotalInfo.number+item.number;
                        this.state.wholesaleTotalInfo.weight=(this.state.wholesaleTotalInfo.weight).add(item.weight);
                        let money=item.goodsMoney;
                        if(item.costs==null)item.costs=[];
                        for(let i=0;i<item.costs.length;i++){
                            money=money.add(item.costs[i].money);
                        }
                        this.state.wholesaleTotalInfo.money=(this.state.wholesaleTotalInfo.money).add(money);
                        return(<div style={{height:40,display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={{width:'13%'}}>{arithUtil.formatToTimeStrs(new Date(item.time),'M'+stringUtil.language(1178)+'D'+stringUtil.language(1179))}</span>
                            <span style={{width:'23%'}}>{(key+1)+'.'+stringUtil.subStr(item.commodityName,10)}</span>
                            <span style={{width:'9%',textAlign:'right'}}>{item.number}</span>
                            <span style={{width:'12%',textAlign:'right'}}>{arithUtil.keToWeight(item.weight,this.state.weightMode)}</span>
                            <span style={{width:'16%',textAlign:'right'}}>{item.mode==1?(arithUtil.fenToYuan(item.unitPrice,2)+(item.unitMaster?'/'+item.unitMaster:'/'+stringUtil.language(1173))):(arithUtil.unitPriceTo(arithUtil.fenToYuan(item.unitPrice,2),0,this.state.weightMode)+(this.state.weightMode==0?'/'+stringUtil.language(1023):'/'+stringUtil.language(1172)))}</span>
                            <span style={{width:'13%',textAlign:'right'}}>{arithUtil.fenToYuan(money)}</span>
                            <span style={{width:'14%',textAlign:'right'}}>{item.workerId==0?stringUtil.language(431):stringUtil.subStr(item.workerName,5)}</span>
                        </div>);
                    }):''}
                    <div style={{height:30,fontSize:18,fontWeight:'bold',display:'flex',flexDirection:'row',alignItems:'center',marginTop:30}}>{this.state.wholesalePCMDO.printWholesaleText}</div>
                    <div style={{height:45,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomColor:'#000',borderBottomWidth:'1px'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1072)}:{this.state.wholesaleTotalInfo.number}{stringUtil.language(1173)}{arithUtil.keToWeight(this.state.wholesaleTotalInfo.weight,this.state.weightMode)+(this.state.weightMode==0?stringUtil.language(1023):stringUtil.language(1172))}{stringUtil.language(464)}{arithUtil.fenToYuan(this.state.wholesalePCMDO.advanceMoney)}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            {this.state.wholesalePCMDO.costs?<div style={{height:25,display:'flex',flexDirection:'row',alignItems:'flex-start'}}>
                                {this.state.wholesalePCMDO.costs.map((item,key)=>{
                                    return(<span style={{marginRight:10}} key={key}>{item.costClauseName}:{arithUtil.fenToYuan(item.money)}</span>);
                                })}
                            </div>:''}{/*20220711吕stringUtil.language(1027)这样显示*/}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(465)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.wholesalePCMDO.money).add(this.state.wholesalePCMDO.billMoney))}</span>
                        </div>
                    </div>
                    <div style={{height:50,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(466)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan((this.state.wholesalePCMDO.customerArrears).add((this.state.wholesalePCMDO.collectMoney).add(this.state.wholesalePCMDO.discountMoney)).sub((this.state.wholesalePCMDO.money).add(this.state.wholesalePCMDO.billMoney)))}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span>{stringUtil.language(1038)}:</span><span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.collectMoney)}</span>
                            {this.state.wholesalePCMDO.discountMoney?<span>,{stringUtil.language(1042)}:</span>:''}{this.state.wholesalePCMDO.discountMoney?<span style={{fontSize:25,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.discountMoney)}</span>:''}
                        </div>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{fontSize:35}}>{stringUtil.language(467)}:</span><span style={{fontSize:35,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.wholesalePCMDO.customerArrears)}</span>
                        </div>
                    </div>
                    <div style={{height:30,display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <span style={{fontSize:13}}>{stringUtil.language(1030)}:{this.state.wholesalePCMDO.shopName}</span>
                            <span style={{fontSize:13}}>{stringUtil.language(1073)}:{this.state.wholesalePCMDO.shopMobile}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <span style={{fontSize:13}}>地址:{this.state.wholesalePCMDO.shopAdname?this.state.wholesalePCMDO.shopAdname:''}{this.state.wholesalePCMDO.shopAddress?this.state.wholesalePCMDO.shopAddress:''}</span>
                            <span style={{fontSize:13}}>{stringUtil.language(1033)}:{(localStorage.getItem("currentUserItem")?JSON.parse(localStorage.getItem("currentUserItem")).noteName:'')+arithUtil.formatToTimeStr(new Date(),'M.D h:m')}{stringUtil.language(1180)}</span>
                        </div>
                    </div>
                </div>:''}
            </div>
        </div>)
    }
}

const styles = {
    tableTitle:{
        width:'9%',
        height:arithUtil.pxToDp(30),
        fontSize:12,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    tableTd:{
        width:'9%',
        height:arithUtil.pxToDp(30),
        fontSize:12,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 0.5
    },
    time:{
        height:arithUtil.pxToDp(30),
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
    },
};