import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
 import * as stringUtil from '../utils/stringUtil.js';
 import * as otherUtil from '../utils/otherUtil.js';
 import urlUtil from '../utils/urlUtil.js';
 import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import Pagination from '../components/Pagination';
import SearchInput from '../components/SearchInput.js';
import TabMoneyCustomerCollectFind from './TabMoneyCustomerCollectFind';
import TabMoneyCustomerCollectDebt from './TabMoneyCustomerCollectDebt';

export default class TabMoneyCustomerCollect extends Component{
  constructor(props){
    super(props);
    this.state={
        positionVal: 0,
        totalTime:arithUtil.getCurrDateTime(),
        accountsAll:[],
        visibleCollection:false,
        searchs:{
            state:1,
            warehouseId:localStorage.getItem("currentWarehouseItem")?(JSON.parse(localStorage.getItem("currentWarehouseItem"))).id:-1,
            collectionFinish:0,//-1stringUtil.language(1097)，0stringUtil.language(1087)，1stringUtil.language(1141)
            customerId:-1,
            customerName:'',
            targetId:-1,//stringUtil.language(970)工，-1stringUtil.language(1097)
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrBefourDateTime(10,''),0)+''),//20240830确定非要把时间调成这样
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        personsAll:[],
        wholesaleSearchDtoObj:{},
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        wholesaleItemList:[],
        currWholesaleItem:{},
        customerCollectInsertItem:{collects:[]},
        keyTagVal:-1,
    };
  }
  componentDidMount(){
    this.getDataList();
    stringUtil.httpRequest(urlUtil.OAUTH_WORKER_URL,{state:0},['key','get',1],(successResponse)=>{
      if (successResponse.status==200) {
          let workersAll = successResponse.data;
          for(let i=0;i<workersAll.length;i++){
              if (workersAll[i].state==0) {
                  this.state.personsAll.push({targetType:2,targetId:workersAll[i].id,noteName:workersAll[i].noteName});
              }
          }
          this.state.personsAll.unshift({targetType:2,targetId:0,noteName:stringUtil.language(431)});
          this.state.personsAll.unshift({targetType:-1,targetId:-1,noteName:stringUtil.language(346)});
          this.setState({personsAll:this.state.personsAll});
      }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    stringUtil.httpRequest(urlUtil.ACCOUNT_URL,{state:1},['key','get',1],(successResponse)=>{
        if (successResponse.status==200) {
            this.state.accountsAll= successResponse.data;
            this.setState({accountsAll: this.state.accountsAll});
        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
  }
  getDataList(){
        stringUtil.httpRequest(urlUtil.SALE_WHOLESALE_SEARCH_URL,{
            state:this.state.searchs.state,
            warehouseId:this.state.searchs.warehouseId,
            collectionFinish:this.state.searchs.collectionFinish,
            workerId:this.state.searchs.targetId,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),
            customerId:this.state.searchs.customerId,
            customerName:this.state.searchs.customerName,
            currPage:this.state.currentPage-1,
            pageSize:20
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.wholesaleSearchDtoObj = successResponse.data;
                this.state.totalNum = this.state.wholesaleSearchDtoObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.state.wholesaleItemList = JSON.parse(JSON.stringify(this.state.wholesaleSearchDtoObj.items));
                this.setState({
                    totalNum:this.state.totalNum,
                    totalPage:this.state.totalPage,
                    wholesaleItemList:this.state.wholesaleItemList
                });
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
    var _this= this;
    _this.setState({goValue : e.target.value})
    var value = e.target.value;
    if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
    else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
    else _this.pageClick(value);
    }
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }
    collectTotal(){
        this.state.customerCollectInsertItem.collectMoney = 0;//stringUtil.language(894)
        this.state.customerCollectInsertItem.discountMoney = 0;//总stringUtil.language(1042)
        if (this.state.customerCollectInsertItem.collects.length>0) {
            for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
                let collectDetailedTemp = this.state.customerCollectInsertItem.collects[i];
                if (collectDetailedTemp.collectMoney!=0) {
                    this.state.customerCollectInsertItem.collectMoney = (this.state.customerCollectInsertItem.collectMoney).add(collectDetailedTemp.collectMoney);
                }
                if (collectDetailedTemp.discountMoney!=0) {
                    this.state.customerCollectInsertItem.discountMoney = (this.state.customerCollectInsertItem.discountMoney).add(collectDetailedTemp.discountMoney);
                }
            }
        //   this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
        }
    }

  render(){
      return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
        <div style={{width:'100%',height:'7.5%',display:'flex',flexDirection:'row',padding:1}}>
            <div style={{width:'100%',display:'flex',flexDirection:'row',borderStyle:'solid',borderWidth:1,borderColor:'#FF8D1A',borderRadius:5}}><span style={{width:otherUtil.authorityFindCode("030101")!=null?'33.33%':'50%',fontSize:15,backgroundColor:this.state.positionVal==0?'#FF8D1A':'transparent',color:this.state.positionVal==0?'#FFF':'#808080',display:'flex',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                this.state.positionVal=0;
                this.getDataList();}}>{stringUtil.language(691)}</span>
            <span style={{width:otherUtil.authorityFindCode("030101")!=null?'33.33%':'50%',fontSize:15,backgroundColor:this.state.positionVal==1?'#FF8D1A':'transparent',color:this.state.positionVal==1?'#FFF':'#808080',display:'flex',justifyContent:'center',alignItems:'center',borderLeftStyle:'solid',borderLeftWidth:1,borderLeftColor:'#FF8D1A',cursor:'pointer'}} onClick={()=>{ this.setState({ positionVal:1 }); }}>{stringUtil.language(692)}</span>
            {otherUtil.authorityFindCode("030101")!=null?<span style={{width:'33.33%',fontSize:15,backgroundColor:this.state.positionVal==2?'#FF8D1A':'transparent',color:this.state.positionVal==2?'#FFF':'#808080',display:'flex',justifyContent:'center',alignItems:'center',borderLeftStyle:'solid',borderLeftWidth:1,borderLeftColor:'#FF8D1A',cursor:'pointer'}} onClick={()=>{ this.setState({ positionVal:2 }); }}>{stringUtil.language(693)}</span>:''}</div>
        </div>
        <div style={{width:'100%',height:'92.5%'}}>{this.state.positionVal==0?<div style={{width:'100%',height:'100%'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',paddingLeft:7,paddingRight:7}}>
                    <div style={{width:200,height:25,display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
                        <SearchInput onChangeEvents={(value)=>{
                            this.state.searchs.customerName = value;
                            this.getDataList();
                        }} placeholder={stringUtil.language(1010)+stringUtil.language(1048)+'名'+stringUtil.language(1034)+'..'}/>
                    </div>
                    <select style={{width:150,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                        this.state.searchs.targetId = JSON.parse(event.target.value).targetId;
                        this.getDataList();
                    }}>
                        {this.state.personsAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.noteName}</option>)
                        })}
                    </select>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center',backgroundColor:'#FFF'}}>
                        <div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                                this.getDataList();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div> {stringUtil.language(1174)} <div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                                this.getDataList();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div>
                    </div>
                </div>
                <div style={{height:'92%',paddingLeft:arithUtil.pxToDp(7),paddingRight:arithUtil.pxToDp(7)}}>
                    <div style={{height:'5%',backgroundColor:'#F2F2F2',display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <span style={{width:'19%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1040)}</span>
                        <span style={{width:'17%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1039)}</span>
                        <span style={{width:'15%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1048)}</span>
                        <span style={{width:'12%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1085)}</span>
                        <span style={{width:'13%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1141)}+{stringUtil.language(1079)}</span>
                        <span style={{width:'12%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1087)}</span>
                        <span style={{width:'12%',color:'#A9A9A9',textAlign:'center'}}>{stringUtil.language(1026)}</span>
                    </div>
                    <div style={{height:'85%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                        {this.state.wholesaleItemList.map((item,key)=>{
                            let collectedMoneyObj = {collectMoney:0,discountMoney:0};
                            let wholesaleMoneysList = item.wholesaleMoneys;
                            for(let i=0;i<wholesaleMoneysList.length;i++){
                                let wholesaleCollectDetailedList = wholesaleMoneysList[i].wholesaleCollects;
                                for(let j=0;j<wholesaleCollectDetailedList.length;j++){
                                    let wholesaleCollectDetailedItem = wholesaleCollectDetailedList[j];
                                    collectedMoneyObj.collectMoney = (collectedMoneyObj.collectMoney).add(wholesaleCollectDetailedItem.collectMoney);
                                    collectedMoneyObj.discountMoney = (collectedMoneyObj.discountMoney).add(wholesaleCollectDetailedItem.discountMoney);
                                }
                            }
                            let noCollectMoney = (item.money).sub(collectedMoneyObj.collectMoney).sub(collectedMoneyObj.discountMoney);
                            item.noCollectMoney = noCollectMoney;
                            return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                                <span style={{width:'19%',fontSize:16}}>CK.{item.wholesaleNo}</span>
                                <span style={{width:'17%',fontSize:10}}>{arithUtil.formatToTimeStr(new Date(item.finishTime),'Y-M-D h:m:s')}</span>
                                <span style={{width:'15%',fontSize:16}}>{stringUtil.subStr(item.customerName,6)}</span>
                                <span style={{width:'12%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(item.money)}</span>
                                <span style={{width:'13%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(collectedMoneyObj.collectMoney)}+{arithUtil.fenToYuan(collectedMoneyObj.discountMoney)}</span>
                                <span style={{width:'12%',fontSize:16,textAlign:'center'}}>{arithUtil.fenToYuan(noCollectMoney)}</span>
                                {item.collectionFinish==0?<div style={{width:'12%',justifyContent:'center'}}>
                                    {otherUtil.authorityFindCode("030102")!=null?<span style={{width:'100%',height:'25px',color:'#FFF',lineHeight:'25px',fontSize:15,borderRadius:2,backgroundColor:'#52A056',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                        if(!item.money){ToastBox.error(stringUtil.language(347));return;}
                                        this.state.currWholesaleItem = JSON.parse(JSON.stringify(item));
                                        this.state.customerCollectInsertItem = {time:this.state.totalTime,collects:[],collectMoney:0,discountMoney:0};
                                        if(this.state.currWholesaleItem.wholesaleMoneys!=null&&this.state.currWholesaleItem.wholesaleMoneys.length>0){
                                            for(let i=0;i<this.state.currWholesaleItem.wholesaleMoneys.length;i++){
                                                let collectedMoneyObj = {collectMoney:0,discountMoney:0};
                                                let wholesaleMoneysList = this.state.currWholesaleItem.wholesaleMoneys[i];
                                                for(let j=0;j<wholesaleMoneysList.wholesaleCollects.length;j++){
                                                    collectedMoneyObj.collectMoney = (collectedMoneyObj.collectMoney).add(wholesaleMoneysList.wholesaleCollects[j].collectMoney);
                                                    collectedMoneyObj.discountMoney = (collectedMoneyObj.discountMoney).add(wholesaleMoneysList.wholesaleCollects[j].discountMoney);
                                                }
                                                let noCollectMoney = (wholesaleMoneysList.arrears).sub(collectedMoneyObj.collectMoney).sub(collectedMoneyObj.discountMoney);//stringUtil.language(1087)
                                                this.state.currWholesaleItem.wholesaleMoneys[i].noCollectMoney = noCollectMoney;
                                                this.state.customerCollectInsertItem.collects.push({
                                                    collectMode:1,
                                                    wholesaleId:this.state.currWholesaleItem.id,
                                                    targetType:wholesaleMoneysList.targetType,
                                                    targetId:wholesaleMoneysList.targetId,
                                                    discountMoney:0,
                                                    collectMoney:noCollectMoney,//stringUtil.language(1087)
                                                    targetName:wholesaleMoneysList.targetName,
                                                });
                                            }
                                            this.collectTotal();
                                        }
                                        this.setState({visibleCollection:!this.state.visibleCollection});
                                    }}>{stringUtil.language(980)}</span>:<div style={{position:'absolute'}}/>}
                                </div>:<div style={{width:'12%',justifyContent:'center'}}>
                                    <span style={{width:'100%',height:30,color:'#F00',fontSize:15,borderStyle:'solid',borderWidth:1,borderColor:'#F00',textAlign:'center',textAlignVertical:'center'}}>{stringUtil.language(981)}</span>
                                </div>}
                            </div>);
                        })}
                    </div>
                    <div style={{height:'10%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                        {this.state.totalPage>1?<Pagination total={this.state.totalNum}
                            current={this.state.currentPage}
                            totalPage={this.state.totalPage}
                            goValue={this.state.goValue}
                            pageClick={this.pageClick.bind(this)}
                            goPrev={this.goPrevClick.bind(this)}
                            goNext={this.goNext.bind(this)}
                            switchChange={this.goSwitchChange.bind(this)}/>:''}
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                            <span style={{color:'#F00',whiteSpace:'nowrap',marginRight:50}}>{stringUtil.language(1142)}：{this.state.totalNum}{stringUtil.language(694)}：{arithUtil.fenToYuan(this.state.wholesaleSearchDtoObj.money-this.state.wholesaleSearchDtoObj.collectMoney-this.state.wholesaleSearchDtoObj.discountMoney)}{stringUtil.currency(1)}</span>
                            <div style={styles.time}>
                                <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTime(this.state.totalTime,'Y-M-D'))} onChange={(date) => {
                                    this.state.totalTime = arithUtil.formatToTimeStr(date,'Y-M-D')+' '+arithUtil.getCurrDateTime(2);
                                    this.setState({totalTime:this.state.totalTime});
                                }} />
                                <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                            </div>
                        </div>
                    </div>
                </div>
                <MaskModal title={stringUtil.language(348)} visible={this.state.visibleCollection} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                    content={<div style={{height:'100%',paddingLeft:10,paddingRight:10}} key={this.state.currWholesaleItem.id}>
                        <div style={{width:'100%',height:'15%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',borderBottomStyle:'solid',borderBottomWidth:1,borderBottomColor:'#C0C0C0'}}>
                            <span style={{fontSize:16,paddingLeft:10}}>{stringUtil.language(903)}{this.state.currWholesaleItem.customerName+stringUtil.subStr(this.state.currWholesaleItem.customerAlias?this.state.currWholesaleItem.customerAlias:'',5)}</span>
                            <div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{width:60,color:'#A9A9A9'}}>{stringUtil.language(904)}</span>
                                    <DatePicker locale='zhCN' selected={arithUtil.strToDate(this.state.customerCollectInsertItem.time)} onChange={(date) => {
                                        this.state.customerCollectInsertItem.time = arithUtil.formatToTimeStr(date,'Y-M-D')+arithUtil.formatToTimeStr(new Date((this.state.currWholesaleItem.finishTime).add(30000)),' h:m:s');
                                        this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                                    }} />
                                </div>
                                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                    <span style={{fontSize:16}}>{stringUtil.language(880)}</span>
                                    <span style={{fontSize:25,color:'#F00',fontWeight:'bold'}}>{this.state.currWholesaleItem.noCollectMoney!=null?arithUtil.fenToYuan(this.state.currWholesaleItem.noCollectMoney):0}{stringUtil.currency(1)}</span>
                                </div>
                            </div>
                        </div>
                        <span style={{fontSize:12,color:'#D3D3D3',textAlignVertical:'center'}}>  ● {stringUtil.language(17)}</span>
                        <div style={{height:'55%',borderStyle:'solid',borderColor:'#DCDCDC',borderWidth: 1,overflow:'auto'}}>
                            {this.state.currWholesaleItem.wholesaleMoneys!=null?this.state.currWholesaleItem.wholesaleMoneys.map((item,key)=>{
                                // this.state.customerCollectInsertItem.collects.push({
                                //     collectMode:1,
                                //     wholesaleId:this.state.currWholesaleItem.id,
                                //     targetType:item.targetType,
                                //     targetId:item.targetId,
                                //     discountMoney:0,
                                //     collectMoney:0,
                                //     targetName:item.targetName,
                                // });
                                return(<div style={{height:arithUtil.pxToDp(45),display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}} key={key}>
                                    <span style={{color:'#52A056',fontWeight:'bold'}}>&nbsp;&nbsp;{item.targetType==1?stringUtil.language(426):stringUtil.subStr(item.targetName,7)}</span>
                                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                                        <span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>¥ {arithUtil.fenToYuan(item.noCollectMoney)}</span>&nbsp;&nbsp;&nbsp;
                                        <span>{stringUtil.language(905)}</span>
                                        <input ref={'collectMoneyVal'+key} style={arithUtil.stylesAdd(styles.textInput,{width:90})} type='number' placeholder='0' defaultValue={this.state.customerCollectInsertItem.collects[key].collectMoney?arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].collectMoney):''} onChange={(event)=>{
                                            this.state.customerCollectInsertItem.collects[key].collectMoney = event.target.value!=''?arithUtil.yuanToFen(event.target.value):0;
                                            this.collectTotal();
                                        }}/><span>{stringUtil.currency(1)}，</span>
                                        <span>{stringUtil.language(879)}</span>
                                        <input style={arithUtil.stylesAdd(styles.textInput,{width:90})} type='number' placeholder='0' defaultValue={this.state.customerCollectInsertItem.collects[key].discountMoney?arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].discountMoney):''} onChange={(event)=>{
                                            this.state.customerCollectInsertItem.collects[key].discountMoney = event.target.value!=''?arithUtil.yuanToFen(event.target.value):0;
                                            let collectAddDiscount = (this.state.customerCollectInsertItem.collects[key].collectMoney).add(this.state.customerCollectInsertItem.collects[key].discountMoney);
                                            if(collectAddDiscount>item.noCollectMoney){
                                                this.state.customerCollectInsertItem.collects[key].collectMoney = (item.noCollectMoney).sub(this.state.customerCollectInsertItem.collects[key].discountMoney);
                                                this.refs['collectMoneyVal'+key].value=arithUtil.fenToYuan(this.state.customerCollectInsertItem.collects[key].collectMoney);
                                            }
                                            this.collectTotal();
                                        }}/><span>{stringUtil.currency(1)}&nbsp;&nbsp;</span>
                                    </div>
                                </div>)
                            }):<div/>}
                        </div>
                        <div style={{height:'30%'}}>
                            {/* <span style={{height:arithUtil.pxToDp(30),textAlignVertical:'center',color:'#D2855D',fontWeight:'bold'}}>总计：{stringUtil.language(1080)} {arithUtil.fenToYuan(this.state.customerCollectInsertItem.collectMoney)} {stringUtil.currency(1)}{stringUtil.language(906)} {arithUtil.fenToYuan(this.state.customerCollectInsertItem.discountMoney)} {stringUtil.currency(1)}</span> */}
                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                                <div style={{width:'100%',overflowY:'hidden',overflow:'auto',whiteSpace:'nowrap'/*水平滚动*/,display:'flex',flexDirection:'row'}}>{/* 水平滚动 */}
                                    {this.state.accountsAll.map((item,key)=>{
                                        let accountIndexKey = this.state.customerCollectInsertItem.accountIndex;
                                        return(<div style={{width:65,height:65,borderStyle:'solid',borderWidth:1,borderColor:'#DCDCDC',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',cursor:'pointer'}} key={key} onClick={()=>{
                                            this.state.customerCollectInsertItem.accountId = item.id;
                                            this.state.customerCollectInsertItem.accountIndex = key;
                                            this.setState({customerCollectInsertItem:this.state.customerCollectInsertItem});
                                        }}>
                                            {item.type==0?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#F4B015':'#A9A9A9'}}>&#xe632;</i>:
                                            item.type==1?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#3BCA72':'#A9A9A9'}}>&#xe605;</i>:
                                            item.type==2?<i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#5A9EF7':'#A9A9A9'}}>&#xe65f;</i>:
                                            <i className="iconfontIndexCss" style={{fontSize:30,color:(accountIndexKey!=null&&accountIndexKey==key)?'#CC4B46':'#A9A9A9'}}>&#xe607;</i>}
                                            <span style={{color:(accountIndexKey!=null&&accountIndexKey==key)?'#696969':'#A9A9A9'}}>{item.name}</span>
                                        </div>)
                                    })}
                                </div>
                                <span style={{width:'70px',height:'70px',color:'#FFF',lineHeight:'70px',borderRadius:3,backgroundColor:'#FF8C00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer',marginLeft:10}} onClick={()=>{
                                        if (this.state.currWholesaleItem.noCollectMoney&&this.state.customerCollectInsertItem.collectMoney==0&&this.state.customerCollectInsertItem.discountMoney==0) {
                                            ToastBox.error(stringUtil.language(96));return;
                                        }
                                        if (this.state.customerCollectInsertItem.accountId==null) {
                                            ToastBox.error(stringUtil.language(95));return;
                                        }
                                        let customerCollectInsertVo = {collects:[]};
                                        customerCollectInsertVo.time = arithUtil.timeToNumber(this.state.customerCollectInsertItem.time);
                                        customerCollectInsertVo.customerId = this.state.currWholesaleItem.customerId;
                                        customerCollectInsertVo.accountId = this.state.customerCollectInsertItem.accountId;
                                        customerCollectInsertVo.discountMoney = this.state.customerCollectInsertItem.discountMoney;
                                        customerCollectInsertVo.collectMoney = this.state.customerCollectInsertItem.collectMoney;
                                        customerCollectInsertVo.describe = this.state.customerCollectInsertItem.describe!=null?this.state.customerCollectInsertItem.describe:'';
                                        for(let i=0;i<this.state.customerCollectInsertItem.collects.length;i++){
                                            let temp = this.state.customerCollectInsertItem.collects[i];
                                            if ((temp.collectMoney!=null&&!isNaN(temp.collectMoney)&&temp.collectMoney!=0) || (temp.discountMoney!=null&&!isNaN(temp.discountMoney)&&temp.discountMoney!=0)) {
                                                let collectDetailedTemp = {
                                                    collectMode:1,//0.stringUtil.language(913) 1stringUtil.language(1185)stringUtil.language(865)
                                                    wholesaleId:temp.wholesaleId,
                                                    targetType:temp.targetType,
                                                    targetId:temp.targetId,
                                                    discountMoney:temp.discountMoney,
                                                    collectMoney:temp.collectMoney
                                                };
                                                customerCollectInsertVo.collects.push(collectDetailedTemp);
                                            }
                                        }
                                        this.setState({visibleCollection:false});
                                        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_URL,customerCollectInsertVo,['json','post',1],(successResponse)=>{
                                            if (successResponse.status==200) {
                                                ToastBox.success(stringUtil.language(388));
                                                this.getDataList();
                                            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                    }}>{stringUtil.language(859)}</span>
                            </div>
                        </div>
                    </div>}closeBnTopEvents={()=>{this.setState({visibleCollection:false});}}/>
            </div>:
            this.state.positionVal==1?<TabMoneyCustomerCollectDebt context={this}/>:
            this.state.positionVal==2?<TabMoneyCustomerCollectFind context={this}/>:''}</div>
      </div>)
  }
}

const styles = {
  textInput:{
      height:arithUtil.pxToDp(35),
      borderStyle:'solid',
      borderColor:'#d0d0d0',
      borderWidth:1,
      borderRadius:2,
      padding:0,
      paddingLeft:arithUtil.pxToDp(5),
      textAlign:'center',
      textAlignVertical:'center'
  },
  time:{
    height:25,
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'center',
    borderStyle:'solid',
    borderWidth:1,
    borderColor:'#A9A9A9',
    borderRadius:3,
    paddingLeft:3,
    paddingRight:3
  },
};