import React,{Component} from 'react';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

//暂时未用到
 export default class ShareCollectDoc extends Component{
    constructor(props) {
      super(props);
      let search = this.props.location.search;
      this.state={
        collectDoc:{id:stringUtil.getUrlParamsVal('collectId',search)?parseInt(otherUtil.encDec(stringUtil.getUrlParamsVal('collectId',search),1)):0}
      }
    }
    componentDidMount(){
        stringUtil.httpRequest(urlUtil.FINANCE_CUSTOMER_COLLECT_PRINT_URL+'/'+this.state.collectDoc.id,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let collectInfo = otherUtil.cusCollectInfoNull(successResponse.data);
                collectInfo.id=this.state.collectDoc.id;
                this.setState({collectDoc:collectInfo});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }

    render(){
        return(<div style={{width:'100%',height:'100%',backgroundColor:'#FFF'}}>
            <div style={{width:'100%',height:50,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <span style={{fontSize:20}}>{localStorage.getItem("currentStallItem")?JSON.parse(localStorage.getItem("currentStallItem")).name:''}</span><span style={{fontSize:14}}>{stringUtil.language(1038)}确认{stringUtil.language(1189)}</span>
            </div>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
                <span style={{fontSize:12}}>{this.state.collectDoc.time}</span>
            </div><hr/>
            <div style={{height:160,display:'flex',flexDirection:'column',justifyContent:'space-around',paddingLeft:'15%',paddingTop:10}}>
                <span style={{fontSize:17}}>{stringUtil.language(1048)}{stringUtil.language(922)}{this.state.collectDoc.customerName}</span>
                <span style={{fontSize:17}}>{stringUtil.language(486)}：{arithUtil.fenToYuan((this.state.collectDoc.customerArrears).add(this.state.collectDoc.collectMoney).add(this.state.collectDoc.discountMoney))}{stringUtil.currency(1)}</span>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{fontSize:17}}>{stringUtil.language(1080)}{stringUtil.language(883)}</span><span style={{color:'#20BFB0',fontSize:17}}>{arithUtil.fenToYuan(this.state.collectDoc.collectMoney)}</span><span style={{fontSize:17}}>{stringUtil.currency(1)}</span>
                </div>
                <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                    <span style={{fontSize:17}}>{stringUtil.language(488)}：</span><span style={{color:'#20BFB0',fontSize:17}}>{arithUtil.fenToYuan(this.state.collectDoc.discountMoney)}</span><span style={{fontSize:17}}>{stringUtil.currency(1)}</span>
                </div>
            </div><br/>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',paddingLeft:'15%'}}>
                <span style={{fontSize:17}}>{stringUtil.language(1127)}{stringUtil.language(883)}</span><span style={{color:'#F00',fontSize:20,fontWeight:'bold'}}>{arithUtil.fenToYuan(this.state.collectDoc.customerArrears)}</span><span style={{fontSize:17}}>{stringUtil.currency(1)}</span>
            </div>
            <br/><br/>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}><span style={{fontSize:15}}>{stringUtil.language(1048)}签名：_________________</span></div>
            <div style={{position:'absolute',left:10,bottom:0,display:'flex',flexDirection:'column'}}>
                <span style={{fontSize:11}}>联系人：{(this.state.collectDoc.workerId==0?stringUtil.language(431):this.state.collectDoc.workerName)+'（'+this.state.collectDoc.workerMobile+'）'}</span>
                <span style={{fontSize:11}}>{stringUtil.language(911)}{this.state.collectDoc.shopAdname+this.state.collectDoc.shopAddress}</span>
            </div>
        </div>)
    }
}

const styles = {};