import React,{Component} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as arithUtil from '../utils/arithUtil.js';
import * as stringUtil from '../utils/stringUtil.js';
import * as otherUtil from '../utils/otherUtil.js';
import urlUtil from '../utils/urlUtil.js';
import ToastBox from '../components/ToastBox';
import MaskModal from '../components/MaskModal';
import TabTopActivity from '../components/TabTopActivity';
import Pagination from '../components/Pagination';

export default class CountsOrdersActivity extends Component{
    constructor(props) {
      super(props);
      this.state={
        weightMode:0,//0stringUtil.language(1023)，1市stringUtil.language(1172)
        searchs:{
            warehouseId:-1,
            startTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1),0)+''),
            endTime:parseInt(arithUtil.timeToNumber(arithUtil.getCurrDateTime(1))+''),
        },
        warehousesAll:[],
        infoShowPop:false,
        delShowPop:false,
        confirmShowPop:false,
        totalNum:'',//总记录数
        currentPage: 1, //当前{stringUtil.language(1199)}{stringUtil.language(1184)}
        goValue:'',
        totalPage:'',//总{stringUtil.language(1199)}数
        takeStockItemsData:[],
        currTakeStockItem:{},
      };
      if(localStorage.getItem("shopConfigInfo")!=null&&JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit!=null) this.state.weightMode = JSON.parse(localStorage.getItem("shopConfigInfo")).weightUnit;
    }
    componentDidMount(){
        this.getDataList();
        stringUtil.httpRequest(urlUtil.WAREHOUSE_URL,null,['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                this.state.warehousesAll= successResponse.data;
                this.state.warehousesAll.unshift({id:-1,name:stringUtil.language(453)});
                this.setState({warehousesAll:this.state.warehousesAll});
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    getDataList(){
        stringUtil.httpRequest(urlUtil.STOCK_SEARCH_URL,{
            warehouseId:this.state.searchs.warehouseId,
            startTime:arithUtil.settleSub(this.state.searchs.startTime),
            endTime:arithUtil.settleSub(this.state.searchs.endTime),
            currPage:this.state.currentPage-1
        },['key','get',1],(successResponse)=>{
            if (successResponse.status==200) {
                let takeStockObj = successResponse.data;
                this.state.totalNum = takeStockObj.count;
                this.state.totalPage = Math.ceil( this.state.totalNum / 20);//stringUtil.language(1151)总{stringUtil.language(1199)}数= 总记录数 / 每{stringUtil.language(1199)}显示的条数
                this.state.takeStockItemsData = JSON.parse(JSON.stringify(takeStockObj.items));
                this.setState({
                    totalNum:this.state.totalNum,
                    totalPage:this.state.totalPage,
                    takeStockItemsData:this.state.takeStockItemsData
                });
            }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
        },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
    }
    goPrevClick(){//上一步
        var _this = this;
        let cur = this.state.currentPage;
        if(cur > 1) _this.pageClick( cur - 1);
    }
    goNext(){//下一步
        var _this = this;
        let cur = _this.state.currentPage;
        if(cur < _this.state.totalPage) _this.pageClick(cur + 1);
    }
    goSwitchChange(e){//跳stringUtil.language(1181)到指定{stringUtil.language(1199)}
		var _this= this;
		_this.setState({goValue : e.target.value})
		var value = e.target.value;
		if(!/^[1-9]\d*$/.test(value)) {/*ToastBox.error('必须大于1的整数！');*/}
		else if(parseInt(value) > parseInt(_this.state.totalPage)) ToastBox.error(stringUtil.language(139));
		else _this.pageClick(value);
	}
    pageClick(pageNum){//执行翻{stringUtil.language(1199)}。pageNum即将加载的{stringUtil.language(1199)}{stringUtil.language(1184)}
        let _this = this;
        if(pageNum != _this.state.currentPage){
            _this.state.currentPage = pageNum
        }
        this.getDataList();//分{stringUtil.language(1199)}请求服务器{stringUtil.language(1171)}
    }

    render(){
        return(<div style={{width:'98.5%',height:'97%',padding:'0.5%'}}>
            <TabTopActivity viewHeight='6%' context={this.props.context} navigationStacks={this.props.navigationStacks}/>
            <div style={{height:'93%',paddingLeft:arithUtil.pxToDp(8),paddingRight:arithUtil.pxToDp(8),backgroundColor:'#FFF'}}>
                <div style={{height:'8%',display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
                    {otherUtil.authorityFindCode("020702")!=null?<div style={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                        this.props.navigationStacks.push({title:stringUtil.language(454),navigatePage:'CountsOrderAddActivity',paramsPage:'盘'+stringUtil.language(1198)+stringUtil.language(1129)+'参数'});
                        this.props.context.props.history.push({ pathname: "/Main", params:{mainPageItem:'CountsOrderAddActivity',mainStacks:this.props.navigationStacks}});
                    }}>
                        <i className="iconfontIndexCss" style={{fontSize:17,color:'#52A056'}}>&#xe601;</i>
                        <span style={{color:'#52A056'}}> {stringUtil.language(454)}</span>
                    </div>:<div style={{position:'absolute'}}/>}
                    <select style={{width:250,height:25,borderStyle:'solid',borderColor:'#D3D3D3',borderWidth:1,borderRadius:2,fontSize:12}} onChange={(event)=>{
                        this.state.searchs.warehouseId = JSON.parse(event.target.value).id;
                        this.getDataList();
                    }}>
                        {this.state.warehousesAll.map((item,key)=>{
                        return(<option value={JSON.stringify(item)} key={key}>{item.name}</option>)
                        })}
                    </select>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.startTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.startTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'),0);
                                this.getDataList();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div> {stringUtil.language(1174)} <div style={styles.time}>
                            <DatePicker locale='zhCN' selected={arithUtil.strToDate(arithUtil.formatToTimeStr(new Date(arithUtil.settleSub(this.state.searchs.endTime,1)),'Y-M-D'))} onChange={(date) => {
                                this.state.searchs.endTime = arithUtil.timeToNumber(arithUtil.formatToTimeStr(date,'Y-M-D'));
                                this.getDataList();
                            }} />
                            <i className="iconfontIndexCss" style={{fontSize:17,color:'#A9A9A9'}}>&#xe6e3;</i>
                        </div>
                    </div>
                </div>
                <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',color:'#A9A9A9'})}>{stringUtil.language(1039)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(897)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',color:'#A9A9A9'})}>{stringUtil.language(898)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',color:'#A9A9A9'})}>{stringUtil.language(1064)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%',color:'#A9A9A9'})}>{stringUtil.language(899)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'8%',color:'#A9A9A9'})}>{stringUtil.language(1065)}</span>
                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',color:'#A9A9A9'})}>{stringUtil.language(1026)}</span>
                </div>
                <div style={{height:this.state.totalPage>1?'77%':'87%',borderStyle:'solid',borderColor:'#F0F0F0',borderWidth: 1,overflow:'auto'}}>
                    {this.state.takeStockItemsData.map((item,key)=>{
                        let numberTotal = 0;
                        for(let i=0;i<item.commodities.length;i++){
                            numberTotal = numberTotal.add(item.commodities[i].number);
                        }
                        return(<div style={{height:arithUtil.pxToDp(40),display:'flex',flexDirection:'row',alignItems:'center'}} key={key}>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'17%',textAlign:'left'})}>{arithUtil.formatToTimeStr(new Date(item.time),'Y-M-D h:m:s')}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',textAlign:'left'})}>{item.workerName}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'10%',textAlign:'left'})}>{item.approvalName}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'20%',textAlign:'left',fontSize:10})}>{stringUtil.subStr(item.warehouseName,25)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'19%'})}>{item.commodities.length} {stringUtil.language(1177)} {numberTotal} {stringUtil.language(1173)}</span>
                            <span style={arithUtil.stylesAdd(styles.tableTd,{width:'8%'})}>{item.state==0?stringUtil.language(900):stringUtil.language(901)}</span>
                            <div style={arithUtil.stylesAdd(styles.tableTd,{width:'16%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'})}>
                                <i className="iconfontIndexCss" style={{fontSize:22,color:'#696969',cursor:'pointer'}} onClick={()=>{
                                    this.state.currTakeStockItem = JSON.parse(JSON.stringify(item));
                                    stringUtil.httpRequest(urlUtil.SALE_TAKE_STOCK_URL+'/'+this.state.currTakeStockItem.id,null,['key','get',1],(successResponse)=>{
                                        if (successResponse.status==200) {
                                            this.state.currTakeStockItem = JSON.parse(JSON.stringify(successResponse.data));
                                            this.setState({
                                                currTakeStockItem: this.state.currTakeStockItem,
                                                infoShowPop: !this.state.infoShowPop
                                            })
                                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                                }}>&#xe64c;</i>
                                {otherUtil.authorityFindCode("020703")!=null?<i className="iconfontIndexCss" style={{fontSize:22,color:'#F00',cursor:'pointer'}} onClick={()=>{
                                    this.state.currTakeStockItem = JSON.parse(JSON.stringify(item));
                                    this.setState({delShowPop:!this.state.delShowPop});
                                }}>&#xe612;</i>:<div style={{position:'absolute'}}/>}
                            </div>
                        </div>);
                    })}
                </div>
                {this.state.totalPage>1?<div style={{height:'10%'}}>
                    <Pagination total={this.state.totalNum}
                        current={this.state.currentPage}
                        totalPage={this.state.totalPage}
                        goValue={this.state.goValue}
                        pageClick={this.pageClick.bind(this)}
                        goPrev={this.goPrevClick.bind(this)}
                        goNext={this.goNext.bind(this)}
                        switchChange={this.goSwitchChange.bind(this)}/>
                </div>:''}
            </div>
            <MaskModal visible={this.state.delShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(34)}
                    </div>}
                okBnEvents={(event) => {
                    this.setState({delShowPop: false});
                    stringUtil.httpRequest(urlUtil.SALE_TAKE_STOCK_URL+'/'+this.state.currTakeStockItem.id,null,['key','delete',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            ToastBox.success(stringUtil.language(388));
                            this.getDataList();
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`)}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`)});
                }} closeBnEvents={() => {this.setState({delShowPop: false})}}/>
            <MaskModal title={stringUtil.language(193)} visible={this.state.infoShowPop} width={600} height={arithUtil.ProportionTo(document.body.clientHeight,0.8)}
                content={<div style={{height:'100%',paddingLeft:arithUtil.pxToDp(10),paddingRight:arithUtil.pxToDp(10)}}>
                    <div style={{height:'9%',display:'flex',flexDirection:'column',justifyContent:'center'}}>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <span>{stringUtil.language(287)}{this.state.currTakeStockItem.warehouseName}</span><span style={{color:'#D2855D'}}>{arithUtil.formatToTimeStr(new Date(this.state.currTakeStockItem.time),'Y-M-D h:m:s')}</span>
                        </div>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                            <span>{stringUtil.language(455)}{this.state.currTakeStockItem.workerName}</span>
                        </div>
                    </div>
                    <div style={arithUtil.stylesAdd(styles.tableTitle,{height:'5%'})}>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'26%',color:'#A9A9A9'})}>{stringUtil.language(1020)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'51%',color:'#A9A9A9'})}>{stringUtil.language(456)}</span>
                        <span style={arithUtil.stylesAdd(styles.tableTd,{width:'23%',color:'#A9A9A9'})}>{stringUtil.language(902)}</span>
                    </div>
                    <div style={{height:'70%',borderStyle:'solid',borderColor:'#F2F2F2',borderWidth: 1,overflow:'auto'}}>
                        {this.state.currTakeStockItem.commodities!=null?(()=>{
                            this.state.currTakeStockItem.numberTotal = 0;
                            return(this.state.currTakeStockItem.commodities.map((item,key)=>{
                                this.state.currTakeStockItem.numberTotal = (this.state.currTakeStockItem.numberTotal!=null?this.state.currTakeStockItem.numberTotal:0).add(item.number);
                                return(<div style={{height:arithUtil.pxToDp(30),display:'flex',flexDirection:'row'}} key={key}>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'26%',textAlign:'left'})}>{stringUtil.showCode()==1||stringUtil.showCode()==2?(item.commodityCode?item.commodityCode:''):''}{!stringUtil.showCode()||stringUtil.showCode()==2?item.commodityName:''}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'51%',textAlign:'left'})}>{item.targetType==0?stringUtil.subStr(item.targetName,10):item.targetType==1?stringUtil.language(426):stringUtil.language(438)} {stringUtil.language(1237,item.cargoNo)}</span>
                                    <span style={arithUtil.stylesAdd(styles.tableTd,{width:'23%'})}>{item.number}</span>
                                </div>);
                            }))
                        })():<div/>}
                    </div>
                    <div style={{height:'16%'}}>
                        <span style={{textAlignVertical:'center',color:'#D2855D',fontSize:12}}>{stringUtil.language(194)} {this.state.currTakeStockItem.commodities!=null?this.state.currTakeStockItem.commodities.length:0} {stringUtil.language(1177)} {this.state.currTakeStockItem.numberTotal!=null?this.state.currTakeStockItem.numberTotal:0} {stringUtil.language(1173)}</span>
                        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center',marginTop:arithUtil.pxToDp(10)}}>
                            <span>{stringUtil.language(858)}{this.state.currTakeStockItem.description}</span>
                            {this.state.currTakeStockItem.state==0?
                            <span style={{width:arithUtil.pxToDp(85),height:'30px',color:'#FFF',lineHeight:'30px',backgroundColor:'#F00',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',cursor:'pointer'}} onClick={()=>{
                                this.setState({confirmShowPop:!this.state.confirmShowPop});
                              }}>确认</span>:<div/>}
                        </div>
                    </div>
                </div>}
                closeBnTopEvents={() => {this.setState({ infoShowPop: false, });}}/>
            <MaskModal visible={this.state.confirmShowPop} content={<div style={{width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
                        {stringUtil.language(8)}
                    </div>}
                okBnEvents={(event) => {
                    this.setState({infoShowPop: false,confirmShowPop: false});
                    stringUtil.httpRequest(urlUtil.SALE_TAKE_STOCK_URL+'/'+this.state.currTakeStockItem.id,null,['key','put',1],(successResponse)=>{
                        if (successResponse.status==200) {
                            this.getDataList();
                            ToastBox.success(stringUtil.language(388));
                        }else{ToastBox.error(successResponse.message+`(${successResponse.status})`);}
                    },(errorResponse)=>{ToastBox.error(errorResponse.message+`(${errorResponse.status})`);});
                }} closeBnEvents={() => {this.setState({confirmShowPop: false})}}/>
        </div>)
    }
}

const styles = {
    inputText:{
        height:arithUtil.pxToDp(30),
        borderStyle:'solid',
        borderColor:'#d0d0d0',
        borderWidth:1,
        borderRadius:2,
        padding:0,
        paddingLeft:arithUtil.pxToDp(5)
    },
    tableTitle:{
        borderStyle:'solid',
        borderColor:'#F0F0F0',
        borderWidth: 1,
        backgroundColor:'#F2F2F2',
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    tableTd:{
        width:'33%',
        fontSize:13,
        textAlign:'center',
        textAlignVertical:'center',
    },
    time:{
        height:25,
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        borderStyle:'solid',
        borderWidth:1,
        borderColor:'#A9A9A9',
        borderRadius:3,
        paddingLeft:3,
        paddingRight:3
    },
};